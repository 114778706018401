import React from "react";
import ReactDOM from 'react-dom'
import { isMobile } from "react-device-detect";
import moment from "moment";
import debounce from 'lodash/debounce';
import * as yup from 'yup';

import { Doughnut, Bar } from 'react-chartjs-2';

import {
    Row,
    Col,
    Form,
    Button,
    Dropdown,
    ButtonGroup,
    Spinner,
    Badge,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import Switch from "react-switch";

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

import Utils from "../../utils/utils";
import { BinActivity, JobStepBinActivity, AuditTrailObjectType, ActiveBinNumberRequestFilter } from "../../utils/enums";
import { StyledBlockerLoading, StyledOverlay } from "../../utils/styles";

import { ReactComponent as QrcodeIcon } from "../../../_metronic/assets/img/bin_center/qrcode.svg";
import { ReactComponent as ScanTextIcon } from "../../../_metronic/assets/img/bin_center/scan_text.svg";

import makeDropdownList from "../../components/dropdown/makeDropdownList";
import RSuiteSelectPicker from '../../components/OLD/Select/RSuiteSelectPicker';
import DropdownToggle from '../../components/dropdown/DropdownToggle';
import DataTable from "../../components/OLD/DataTable/DataTable";
import DataTableScrollSync from '../../components/OLD/DataTable/DataTableScrollSync.jsx';
import DataTableAjaxPagination from '../../components/OLD/DataTable/Pagination/DataTableAjaxPaginationV5';
import IconButton from "../../components/Buttons/IconButton";
import IconButtonSearch from '../../components/input/IconButtonSearch';

import AuditTrailDialog from '../../components/OLD/Pages/AuditTrail/AuditTrailDialog.jsx';
import AuditTrailFunctions from '../../components/OLD/Pages/AuditTrail/AuditTrailFunctions.jsx';

import ViewLayout from "./layout/ViewLayout.jsx";
import DetailsPanel from "./partial/DetailsPanel";

import ReduxDeletePopup from '../../components/popup/ReduxDeletePopup';
import reduxDeletePopupSlice from '../../components/popup/reduxDeletePopupSlice';

import ReduxRemovePopup from '../../components/popup/ReduxRemovePopup';
import reduxRemovePopupSlice from '../../components/popup/reduxRemovePopupSlice';

import ReduxConfirmPopup from '../../components/popup/ReduxConfirmPopup';
import reduxConfirmPopupSlice from '../../components/popup/reduxConfirmPopupSlice';

import ReduxPopup from '../../components/popup/ReduxPopup';
import reduxPopupSlice from '../../components/popup/reduxPopupSlice';

import ImportPopup from './import/ImportPopup';
import MergePopup from './dialog/MergePopup';
import BinNumberPopup from './dialog/BinNumberPopup';

import { connect } from 'react-redux'
import { clear } from "../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut } from '../../../setup/redux/dispatch/actions'
import binCenter from "./redux/binCenter";
import binCenterTable from "./redux/binCenterTable";
import binCenterTableLive from "./redux/binCenterTableLive";
import binCenterDetailsPanel from "./redux/binCenterDetailsPanel";
import binCenterImport from "./redux/binCenterImport";
import binCenterLive from "./redux/binCenterLive";
import binCenterMergePopup from "./redux/binCenterMergePopup";
import binCenterBinNumberPopup from "./redux/binCenterBinNumberPopup";
import signalRslice from "../../components/signalR/signalRslice";
import cancelToken from "../../../setup/redux/slices/cancelToken";


const DropdownMenu = makeDropdownList(Button);


const StyledSubTitle = styled(Col)`
  font-size: 16px;
  font-weight: 600;
  color: #BABABA;
  line-height: 16px;
  margin-bottom: 15px;
`;

const StyledCount = styled(Col)`
  font-size: 16px;
  font-weight: 700;
  color: #444444;
  line-height: 16px;
`;

const StyledRSuiteSelectPicker = styled(RSuiteSelectPicker)`
  min-width: 150px;
`;

const StyledBadge = styled(Badge)`
    font-size: 13px;
    line-height: 16px;

    a {
        position: relative;
        top: 2px;
        padding-left: 3px;
    }
`;

const StyledBinDraft = styled.a`
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;

    ${props => {
        if(props.active){
            return `
                color: #000AFF !important;
                user-select: none !important;
                cursor: not-allowed !important;

                &:hover, &:active, &:focus {
                    opacity: 1 !important;
                }
            `;
        } else {
            return `
                text-decoration: none;
                color: #BABABA !important;

                &:hover {
                    opacity: 0.8 !important;
                }
            `;
        }
    }}
`;



const formFields = {
    binNumberName: {
      id: 'binNumberName',
      label: 'Bin ID',
      placeholder: ' ',
    },
    binTypeId: {
      id: 'binTypeId',
      label: 'Bin type',
      placeholder: ' ',
    },
}
const formSchema = yup.object().shape({
    isEdit: yup.bool().oneOf([true, false]),
  
    binNumberName: yup.string().required().label(formFields.binNumberName.label),
    binTypeId: yup.number().nullable().required().label(formFields.binTypeId.label),
});



class BinCenterList extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.pageName = 'bin_center';
        this.intervalId = null;

        this.refChartBinCountByType = React.createRef();
        this.refChartActiveInactive = React.createRef();
        this.refChartActiveBins = React.createRef();
        this.refChartInOut = React.createRef();
        this.refChartActivitiesBins = React.createRef();
        this.tableRef = React.createRef();

        // TABLE

        const binNumberTemplate = (id, row, col, obj, index) => {
            return <>
                {
                    row.binNumberId
                    ?
                    <a href={'#'}
                        className={'link-primary-underline d-flex align-items-center'}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();

                            this.props.action.binCenterDetailsPanel.show({ show: true, id: row.binNumberId, binTypeId: row.binTypeId });
                        }}
                    >
                        {row.binNumber}
                    </a>
                    :
                    row.binNumber
                }
            </>
        };
        const binNumberExportTemplate = (row) => {
            return row.binNumber;
        };
        const binNumberExportCSVTemplate = (row) => {
            return row.binNumber;
        };

        const isActiveTemplate = (id, row, col, obj, index) => {
            return <div className={(row.isActive) ? 'text-danger' : ''}>{(row.isActive) ? 'OUT' : 'IN'}</div>
        };
        const isActiveExportTemplate = (row) => {
            return row.isActive === true ? "OUT" : "IN";
        };
        const isActiveExportCSVTemplate = (row) => {
            return row.isActive === true ? "OUT" : "IN";
        };
        
        const daysOutTemplate = (id, row, col, obj, index) => {
            return row.daysOutDisplay;
        };
        const daysOutExportTemplate = (row) => {
            return row.daysOutDisplay;
        };
        const daysOutExportCSVTemplate = (row) => {
            return row.daysOutDisplay;
        };

        const jobNumberTemplate = (id, row, col, obj, index) => {
            return <a 
                href={'/jobs-form?id=' + row.jobId}
                className={'link-primary-underline d-flex align-items-center'}
                target={'_blank'}
                rel="noreferrer"
            >
                {row.jobNumber ? row.jobNumber : row.jobId ? row.jobId : ''}
                {row.jobId ? <span className={'material-icons ps-1'} style={{ fontSize: '18px' }}>open_in_new</span> : ''}
            </a>
        };
        const jobNumberExportTemplate = (row) => {
            return (row.jobNumber) ? row.jobNumber : row.jobId ? row.jobId : '';
        };
        const jobNumberExportCSVTemplate = (row) => {
            return (row.jobNumber) ? row.jobNumber : row.jobId ? row.jobId : '';
        };

        const binOutDateTemplate = (id, row, col, obj, index) => {
            if(row.isActive === true){
                return (row && row.binOutDate) ? moment(row.binOutDate).format(Utils.getDefaultDateTimeFormat()) : ''
            } else {
                return (row && row.binInDate) ? moment(row.binInDate).format(Utils.getDefaultDateTimeFormat()) : ''
            }
        };
        const binOutDateExportTemplate = (row) => {
            if(row.isActive === true){
                return (row && row.binOutDate) ? moment(row.binOutDate).format(Utils.getDefaultDateTimeFormat()) : ''
            } else {
                return (row && row.binInDate) ? moment(row.binInDate).format(Utils.getDefaultDateTimeFormat()) : ''
            }
        };
        const binOutDateExportCSVTemplate = (row) => {
            if(row.isActive === true){
                return (row && row.binOutDate) ? moment(row.binOutDate).format(Utils.getDefaultDateTimeFormat()) : ''
            } else {
                return (row && row.binInDate) ? moment(row.binInDate).format(Utils.getDefaultDateTimeFormat()) : ''
            }
        };


        let columns = [
            {
                id: 'customerSiteBinHistoryId',
                filterKey: 'customerSiteBinHistoryId',
                name: 'customerSiteBinHistoryId',
                label: 'ID',
                visible: false
            },
            {
                id: "binNumber",
                detailsId: "binNumber",
                name: "binNumber",
                label: "Bin ID",
                responsiveLabel: "Bin ID",
                width: "40px",
                orderable: true,
                template: binNumberTemplate,
                exportTemplate: binNumberExportTemplate,
                exportCSVTemplate: binNumberExportCSVTemplate,
            },
            {
                id: "binTypeName",
                detailsId: "binTypeName",
                name: "binTypeName",
                label: "Bin type",
                responsiveLabel: "Bin type",
                width: "150px",
                orderable: true,
            },
            {
                id: "isActive",
                detailsId: "isActive",
                name: "isActive",
                label: "Status",
                responsiveLabel: "Status",
                width: "50px",
                orderable: true,
                template: isActiveTemplate,
                exportTemplate: isActiveExportTemplate,
                exportCSVTemplate: isActiveExportCSVTemplate,
            },
            {
                id: "daysOut",
                detailsId: "daysOut",
                name: "daysOut",
                label: "Aging (days)",
                responsiveLabel: "Aging (days)",
                width: "140px",
                align: 'center',
                orderable: true,
                template: daysOutTemplate,
                exportTemplate: daysOutExportTemplate,
                exportCSVTemplate: daysOutExportCSVTemplate,
            },
            {
                id: "wasteTypeName",
                detailsId: "wasteTypeName",
                name: "wasteTypeName",
                label: "For waste type",
                responsiveLabel: "For waste type",
                width: "150px",
                orderable: true,
            },
            {
                id: "jobTemplateName",
                detailsId: "jobTemplateName",
                name: "jobTemplateName",
                label: "Job type",
                responsiveLabel: "Job type",
                width: "170px",
                orderable: true,
            },
            {
                id: "jobNumber",
                detailsId: "jobNumber",
                name: "jobNumber",
                label: "DO #",
                responsiveLabel: "DO #",
                width: "200px",
                orderable: true,
                template: jobNumberTemplate,
                exportTemplate: jobNumberExportTemplate,
                exportCSVTemplate: jobNumberExportCSVTemplate,
            },
            {
                id: "binOutDate",
                detailsId: "binOutDate",
                name: "binOutDate",
                label: "Last check out/in",
                responsiveLabel: "Last check out/in",
                width: "200px",
                orderable: false,
                template: binOutDateTemplate,
                exportTemplate: binOutDateExportTemplate,
                exportCSVTemplate: binOutDateExportCSVTemplate,
            },
            {
                id: "address",
                detailsId: "address",
                name: "address",
                label: "Site location",
                responsiveLabel: "Site location",
                width: "300px",
                orderable: true,
            },
            {
                id: "customerName",
                detailsId: "customerName",
                name: "customerName",
                label: "Customer",
                responsiveLabel: "Customer",
                width: "150px",
                orderable: true,
            },
        ];
        this.props.action.binCenterTable.setColumns(columns);


        let columnVisibility = [
            false,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
        ];
        this.props.action.binCenterTable.setColumnsVisibility(columnVisibility);


        // LIVE TABLE

        const binNumberTemplateLive = (id, row, col, obj, index) => {
            return <Row>
                <Col xs={6}>
                    {
                        row.binNumberId
                        ?
                        <a href={'#'}
                            className={'link-primary-underline d-flex align-items-center'}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                this.props.action.binCenterDetailsPanel.show({ show: true, id: row.binNumberId, binTypeId: row.binTypeId });
                            }}
                        >
                            <span>{row.binNumber}</span>
                        </a>
                        :
                        row.binNumber
                    }
                </Col>
                {row.isUsingQr && <Col xs={'auto'}>
                    <QrcodeIcon />
                </Col>}
                {row.isUsingTextDetection && <Col xs={'auto'}>
                    <ScanTextIcon style={{ position: 'relative', top: '-2px', width: '18px', height: '18px' }} />
                </Col>}
            </Row>
        };
        const binNumberExportTemplateLive = (row) => {
            return row.binNumber;
        };
        const binNumberExportCSVTemplateLive = (row) => {
            return row.binNumber;
        };

        const binActivityTemplateLive = (id, row, col, obj, index) => {
            return Utils.getBinActivityLabel(row.binActivity)
        };
        const binActivityExportTemplateLive = (row) => {
            return Utils.getBinActivityLabel(row.binActivity)
        };
        const binActivityExportCSVTemplateLive = (row) => {
            return Utils.getBinActivityLabel(row.binActivity)
        };

        const jobNumberTemplateLive = (id, row, col, obj, index) => {
            return <a 
                href={'/jobs-form?id=' + row.jobId}
                className={'link-primary-underline d-flex align-items-center'}
                target={'_blank'}
                rel="noreferrer"
            >
                {row.jobNumber ? row.jobNumber : row.jobId ? row.jobId : ''}
                {row.jobId ? <span className={'material-icons ps-1'} style={{ fontSize: '18px' }}>open_in_new</span> : ''}
            </a>
        };
        const jobNumberExportTemplateLive = (row) => {
            return (row.jobNumber) ? row.jobNumber : row.jobId ? row.jobId : '';
        };
        const jobNumberExportCSVTemplateLive = (row) => {
            return (row.jobNumber) ? row.jobNumber : row.jobId ? row.jobId : '';
        };

        const binOutDateTemplateLive = (id, row, col, obj, index) => {
            return (row && row.jobCompletedDate) ? moment(row.jobCompletedDate).format(Utils.getDefaultTimeFormat()) : ''
        };
        const binOutDateExportTemplateLive = (row) => {
            return (row && row.jobCompletedDate) ? moment(row.jobCompletedDate).format(Utils.getDefaultTimeFormat()) : ''
        };
        const binOutDateExportCSVTemplateLive = (row) => {
            return (row && row.jobCompletedDate) ? moment(row.jobCompletedDate).format(Utils.getDefaultTimeFormat()) : ''
        };


        let columnsLive = [
            // {
            //     id: 'customerSiteBinHistoryId',
            //     filterKey: 'customerSiteBinHistoryId',
            //     name: 'customerSiteBinHistoryId',
            //     label: 'ID',
            //     visible: false
            // },
            {
                id: "binNumber",
                detailsId: "binNumber",
                name: "binNumber",
                label: "Bin ID",
                responsiveLabel: "Bin ID",
                width: "40px",
                orderable: true,
                template: binNumberTemplateLive,
                exportTemplate: binNumberExportTemplateLive,
                exportCSVTemplate: binNumberExportCSVTemplateLive,
            },
            {
                id: "binTypeName",
                detailsId: "binTypeName",
                name: "binTypeName",
                label: "Bin type",
                responsiveLabel: "Bin type",
                width: "150px",
                orderable: true,
            },
            {
                id: "binActivity",
                detailsId: "binActivity",
                name: "binActivity",
                label: "Check IN/OUT",
                responsiveLabel: "Check IN/OUT",
                width: "50px",
                orderable: true,
                template: binActivityTemplateLive,
                exportTemplate: binActivityExportTemplateLive,
                exportCSVTemplate: binActivityExportCSVTemplateLive,
            },
            {
                id: "wasteTypeName",
                detailsId: "wasteTypeName",
                name: "wasteTypeName",
                label: "For waste type",
                responsiveLabel: "For waste type",
                width: "150px",
                orderable: true,
            },
            {
                id: "jobTemplateName",
                detailsId: "jobTemplateName",
                name: "jobTemplateName",
                label: "Job type",
                responsiveLabel: "Job type",
                width: "170px",
                orderable: true,
            },
            {
                id: "jobNumber",
                detailsId: "jobNumber",
                name: "jobNumber",
                label: "DO #",
                responsiveLabel: "DO #",
                width: "200px",
                orderable: true,
                template: jobNumberTemplateLive,
                exportTemplate: jobNumberExportTemplateLive,
                exportCSVTemplate: jobNumberExportCSVTemplateLive,
            },
            {
                id: "binOutDate",
                detailsId: "binOutDate",
                name: "binOutDate",
                label: "Activity time",
                responsiveLabel: "Activity time",
                width: "200px",
                orderable: true,
                template: binOutDateTemplateLive,
                exportTemplate: binOutDateExportTemplateLive,
                exportCSVTemplate: binOutDateExportCSVTemplateLive,
            },
            {
                id: "address",
                detailsId: "address",
                name: "address",
                label: "Site location",
                responsiveLabel: "Site location",
                width: "300px",
                orderable: true,
            },
            {
                id: "customerName",
                detailsId: "customerName",
                name: "customerName",
                label: "Customer",
                responsiveLabel: "Customer",
                width: "150px",
                orderable: true,
            },
        ];
        this.props.action.binCenterTableLive.setColumns(columnsLive);


        let columnVisibilityLive = [
            // false,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
        ];
        this.props.action.binCenterTableLive.setColumnsVisibility(columnVisibilityLive);


        this.state = {
            isLoadingFormDrawer: false,
            isVisibleFormDrawer: false,
            isBlockingFormDrawer: false,
            rowInitialValues: {
              isEdit: false,
              binNumberName: '',
              binTypeId: null,
              binTypeName: '',
              customFormParams: {
                binTypeItems: [],
              }
            },
            rowValues: null,
            
            isOpenAuditTrail: false,
            objectId: null,
            
            isDisabledBatchAction: true,
        };
    }


    async componentDidMount() {
        await this.props.action.binCenterTable.tableGetFilters({
            auth: this.props.auth,
            settingsType: this.props.settingsType,
            pageName: this.pageName,
        });

        await this.props.action.binCenterLive.getCookies({
            auth: this.props.auth,
            settingsType: this.props.settingsType,
            pageName: this.pageName,
        });

        this.callReadApi();
        this.callReadStatsApi(this.setTimer);
    }
    
    async componentWillUnmount() {
        clearInterval(this.intervalId);
        await this.props.action.cancelToken.cancel();
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
        this.setRefreshPage(nextProps);
    }


    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            'bin_center-create', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                if(isError){
                    if (typeof err === 'string') {
                        
                    } else if (err && err.length > 0){
                        Utils.toast(err[0], 'error');
                    }
                }
                
                this.setState({
                    isLoadingFormDrawer: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if(this.state.isError){
                    } else {
                        Utils.showDrawer(this.formDrawer, false);
                    }
                    
                    if(callback){
                        callback(data);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            'bin_center-update', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                if(isError){
                    if (typeof err === 'string') {

                    } else if (err && err.length > 0){
                        Utils.toast(err[0], 'error');
                    }
                }
                    
                this.setState({
                    isLoadingFormDrawer: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    if(this.state.isError){
                    } else {
                        Utils.showDrawer(this.formDrawer, false);
                    }
                    
                    if(callback){
                        callback(data);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            'bin_center-details', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                  isBlockingFormDrawer: false,
      
                  isLoading: isLoading,
                  isError: isError,
                  err: err,
                }, () => {
                  if(this.state.isError){
                    Utils.showDrawer(this.formDrawer, false);
                  }
      
                  if(callback){
                    callback(data);
                  }
                });
            }
        );
    }
    setRefreshPage = (nextProps) => {
        if(nextProps.binCenterLive.value > 0){
            if(nextProps.binCenterLive.timeLeft == 0){
                nextProps.action.binCenterLive.resetTimeLeft();
                // window.location.reload();
                this.callReadApi();
                this.callReadStatsApi();
            } else {
                if (this.props.binCenterLive.timeLeft == 15){
                    Utils.toast('Bin list will be refreshed in 15 seconds', 'info', null, 2000);
                }
            }
        }
    }
    setTimer = async (state) => {
        if(state){
            this.intervalId = setInterval(() => {
                if(this.props.binCenterLive.value > 0){
                  this.props.action.binCenterLive.updateTimeLeft();
                }
            }, 1000);
        } else {
            clearInterval(this.intervalId);
        }
    }
    

    /* API */
    callReadTableApi = (callback = null) => {
        let data = {
            currentPage: this.props.binCenterTable.currentPage,
            pageSize: this.props.binCenterTable.rowsPerPageSelected,
            searchQuery: this.props.binCenterTable.search,
            sortColumn: this.props.binCenterTable.sortColumn,
            sortDir: this.props.binCenterTable.sortDir,
            
            binTypeId: this.props.binCenterTable.binTypeId,
            isActive: this.props.binCenterTable.isActive,
            activeFilter: this.props.binCenterTable.activeFilter,
            binStatus: this.props.binCenterTable.binStatus,
            isValidated: this.props.binCenterTable.isValidated,
        };

        this.props.action.binCenterTable.callReadApi(data, (state: boolean, data: any) => {});
    }

    callReadTableLiveApi = (callback = null) => {
        let data = {
            currentPage: this.props.binCenterTableLive.currentPage,
            pageSize: this.props.binCenterTable.rowsPerPageSelected,
            searchQuery: this.props.binCenterTableLive.search,
            sortColumn: this.props.binCenterTableLive.sortColumn,
            sortDir: this.props.binCenterTableLive.sortDir,
            
            binStatus: this.props.binCenterTableLive.binStatus,
        };

        this.props.action.binCenterTableLive.callReadApi(data, (state: boolean, data: any) => {});
    }

    callReadApi = async (callback = null) => {
        await this.props.action.cancelToken.cancel();

        if(this.props.binCenterLive.isLive){
            this.callReadTableLiveApi();
        } else {
            this.callReadTableApi();
        }
    }

    callReadSearchApi = debounce(async () => {
        this.callReadApi();
    }, 300)

    getSearchValue = () => {
        if(this.props.binCenterLive.isLive){
            return this.props.binCenterTableLive.search;
        } else {
            return this.props.binCenterTable.search;
        }
    }
    
    setSearchValue = async (value = '') => {
        if(this.props.binCenterLive.isLive){
            await this.props.action.binCenterTableLive.setSearchParams(value);
        } else {
            await this.props.action.binCenterTable.setSearchParams(value);
        }
    }
    
    clearBinCountByType = (state = true) => {
        if(!this.props.binCenterTable.isLoading && !this.props.binCenterTableLive.isLoading){
            if(this.refChartBinCountByType && this.refChartBinCountByType.current){
                let isChecked = undefined;
    
                try {
                    let chart = this.refChartBinCountByType.current;
                    
                    // Use hidden if you want to hide bar in chart (default is undefined), use isHidden if you don't want that (default is undefined)
                    chart.getDatasetMeta(0).data.forEach((sector, dataIndex) => {
                        sector.isHidden = isChecked;
                    });
                    
                    setTimeout(() => {
                        chart.update();
                    });
                } catch(e){}
    
                if(state){
                    this.props.action.binCenterTable.setBinTypeId({ id: null, checked: false }, () => {
                        this.callReadApi();
                    });
                }
            }
        }
    }
    getLabelBinCountByType = () => {
        let label = '';
        
        if(this.props.binCenter.dataBinCountByType && this.props.binCenter.dataBinCountByType.labels && this.props.binCenter.dataBinCountByType.labels.length > 0){
            let item = this.props.binCenter.dataBinCountByType.labels.find(x => x.id === this.props.binCenterTable.binTypeId);
            if(item){
                label = item.label
            }
        }

        return label;
    }
    hasBinCountByType = () => {
        let binTypeIdValue = (this.props.binCenterTable.binTypeId != null) ? true : false;
  
        return binTypeIdValue;
    }

    clearActiveInactive = (state = true) => {
        if(!this.props.binCenterTable.isLoading && !this.props.binCenterTableLive.isLoading){
            if(this.refChartActiveInactive && this.refChartActiveInactive.current){
                let isChecked = undefined;
    
                try {
                    let chart = this.refChartActiveInactive.current;
                                    
                    const items = chart.options.plugins.legend.labels.generateLabels(chart);
                    let allLabelIdex = items.map(x => x.text);
                    
                    // Use hidden if you want to hide bar in chart (default is null), use isHidden if you don't want that (default is undefined)
                    allLabelIdex.forEach((lbl: any, l: number) => {
                        chart.getDatasetMeta(l).isHidden = isChecked;
                    })
                    
                    setTimeout(() => {
                        chart.update();
                    });
                } catch(e){}
    
                if(state){
                    this.props.action.binCenterTable.setIsActive({ id: null, checked: false }, () => {
                        this.callReadApi();
                    });
                }
            }
        }
    }
    getLabelActiveInactive = () => {
        let label = '';
        
        if(this.props.binCenterTable.isActive === false){
            label = 'IN';
        } else if(this.props.binCenterTable.isActive === true){
            label = 'OUT';
        }

        return label;
    }
    hasActiveInactive = () => {
        let activeFilterValue = (this.props.binCenterTable.isActive != null) ? true : false;
  
        return activeFilterValue;
    }

    clearActiveBins = (state = true) => {
        if(!this.props.binCenterTable.isLoading && !this.props.binCenterTableLive.isLoading){
            if(this.refChartActiveBins && this.refChartActiveBins.current){
                let isChecked = undefined;
    
                try {
                    let chart = this.refChartActiveBins.current;
                                    
                    const items = chart.options.plugins.legend.labels.generateLabels(chart);
                    let allLabelIdex = items.map(x => x.text);
                    
                    // Use hidden if you want to hide bar in chart (default is null), use isHidden if you don't want that (default is undefined)
                    allLabelIdex.forEach((lbl: any, l: number) => {
                        chart.getDatasetMeta(l).isHidden = isChecked;
                    })
                    
                    setTimeout(() => {
                        chart.update();
                    });
                } catch(e){}
    
                if(state){
                    this.props.action.binCenterTable.setActiveFilter({ id: null, checked: false }, () => {
                        this.callReadApi();
                    });
                }
            }
        }
    }
    getLabelActiveBins = () => {
        let label = '';
        
        if(this.props.binCenterTable.activeFilter == ActiveBinNumberRequestFilter.Seven){
            label = '1-7';
        } else if(this.props.binCenterTable.activeFilter == ActiveBinNumberRequestFilter.Fourteen){
            label = '8 - 20';
        } else if(this.props.binCenterTable.activeFilter == ActiveBinNumberRequestFilter.TwentyOne){
            label = '21 - 30';
        } else if(this.props.binCenterTable.activeFilter == ActiveBinNumberRequestFilter.ThirtyPlus){
            label = '31 - 60';
        }

        return label;
    }
    hasActiveBins = () => {
        let isActiveValue = (this.props.binCenterTable.activeFilter != null) ? true : false;
  
        return isActiveValue;
    }

    clearInOut = (state = true) => {
        if(!this.props.binCenterTable.isLoading && !this.props.binCenterTableLive.isLoading){
            if(this.refChartInOut && this.refChartInOut.current){
                let isChecked = undefined;
    
                try {
                    let chart = this.refChartInOut.current;
                    
                    const items = chart.options.plugins.legend.labels.generateLabels(chart);
                    let allLabelIdex = items.map(x => x.text);
                    
                    // Use hidden if you want to hide bar in chart (default is null), use isHidden if you don't want that (default is undefined)
                    allLabelIdex.forEach((lbl: any, l: number) => {
                        chart.getDatasetMeta(l).isHidden = isChecked;
                    })
                    
                    setTimeout(() => {
                        chart.update();
                    });
                } catch(e){}
    
                if(state){
                    this.props.action.binCenterTable.setBinStatus({ id: null, checked: false }, () => {
                        this.callReadApi();
                    });
                }
            }
        }
    }
    getLabeInOut = () => {
        let label = '';
        
        if(this.props.binCenterTable.binStatus.toString() == BinActivity.IN.toString()){
            label = 'IN';
        } else if(this.props.binCenterTable.binStatus.toString() == BinActivity.OUT.toString()){
            label = 'OUT';
        }

        return label;
    }
    hasInOut = () => {
        let binStatusValue = (this.props.binCenterTable.binStatus != null) ? true : false;
  
        return binStatusValue;
    }

    clearActivitiesBins = (state = true) => {
        if(!this.props.binCenterTable.isLoading && !this.props.binCenterTableLive.isLoading){
            if(this.refChartActivitiesBins && this.refChartActivitiesBins.current){
                let isChecked = undefined;
    
                try {
                    let chart = this.refChartActivitiesBins.current;
                    
                    const items = chart.options.plugins.legend.labels.generateLabels(chart);
                    let allLabelIdex = items.map(x => x.text);
                    
                    // Use hidden if you want to hide bar in chart (default is null), use isHidden if you don't want that (default is undefined)
                    allLabelIdex.forEach((lbl: any, l: number) => {
                        chart.getDatasetMeta(l).isHidden = isChecked;
                    })
                    
                    setTimeout(() => {
                        chart.update();
                    });
                } catch(e){}
    
                if(state){
                    this.props.action.binCenterTableLive.setBinStatusFilter({ id: null, checked: false }, () => {
                        this.callReadApi();
                    });
                }
            }
        }
    }
    getLabelActivitiesBins = () => {
        let label = '';

        if(this.props.binCenterTableLive.binStatus == BinActivity.IN){
            label = 'CHECK IN';
        } else if(this.props.binCenterTableLive.binStatus == BinActivity.OUT){
            label = 'CHECK OUT';
        } else if(this.props.binCenterTableLive.binStatus == BinActivity.NEED_ATTENTION){
            label = 'NEED ATTENTION';
        }

        return label;
    }
    hasActivitiesBins = () => {
        let binStatusValue = (this.props.binCenterTableLive.binStatus != null) ? true : false;
  
        return binStatusValue;
    }

    clearAllFilters = async () => {
        if(!this.props.binCenterTable.isLoading && !this.props.binCenterTableLive.isLoading){
            if(this.props.binCenterLive.isLive){
                this.clearActivitiesBins(false)
    
                await this.props.action.binCenterTableLive.setClearFilter(() => {
                    this.callReadApi();
                });
            } else {
                this.clearBinCountByType(false)
                this.clearActiveInactive(false)
                this.clearActiveBins(false)
                this.clearInOut(false)
    
                await this.props.action.binCenterTable.setClearFilter(() => {
                    this.callReadApi();
                });
            }
        }
    }
    
    hasFiltersTable = () => {
        let binTypeIdValue = (this.props.binCenterTable.binTypeId != null) ? true : false;
        let activeFilterValue = (this.props.binCenterTable.activeFilter != null) ? true : false;
        let isActiveValue = (this.props.binCenterTable.isActive != null) ? true : false;
        let binStatusValue = (this.props.binCenterTable.binStatus != null) ? true : false;
  
        return ((binTypeIdValue == true) || (activeFilterValue == true)  || (isActiveValue == true) || (binStatusValue == true))? true : false;
    }
    hasFiltersTableLive = () => {
        let binStatusValue = (this.props.binCenterTableLive.binStatus != null) ? true : false;
  
        return binStatusValue;
    }
    hasFilters = () => {
        if(this.props.binCenterLive.isLive){
            return this.hasFiltersTableLive();
        } else {
            return this.hasFiltersTable();
        }
    }
    
    clearSearchValue = async () => {
        await this.props.action.binCenterTableLive.setSearchParams('');
        await this.props.action.binCenterTable.setSearchParams('');
    }

    callReadStatsApi = (callback = null) => {
        if(this.props.binCenterLive.isLive){
            this.props.action.binCenter.callActivitiesBinsApi(null, (state: boolean, data: any) => {
                if(callback){
                    callback(true);
                }
            });

        } else {
            if(callback){
                callback(false);
            }

            this.props.action.binCenter.callBinCountByTypeApi({
                currentPage: 1,
                pageSize: Utils.getMaxPageSize(),
                searchQuery: null,
                sortColumn: 'binTypeName',
                sortDir: 'asc',
                isIncludeInactive: false,
            }, (state: boolean, data: any) => {});
            
            this.props.action.binCenter.callActiveInactiveApi(null, (state: boolean, data: any) => {});
    
            this.props.action.binCenter.callActiveBinsApi(null, (state: boolean, data: any) => {});
    
            this.props.action.binCenter.callInOutApi({
                fromDate: moment().subtract(7,'d').format(Utils.getAPIDateFormat()),
                toDate: moment().format(Utils.getAPIDateFormat()),
            }, (state: boolean, data: any) => {});
        }
    }

    callExportApi = (callback = null) => {
        let data = null;
        if(this.props.binCenterLive.isLive){
            data = {
                currentPage: this.props.binCenterTableLive.currentPage,
                pageSize: this.props.binCenterTable.rowsPerPageSelected,
                searchQuery: this.props.binCenterTableLive.search,
                sortColumn: this.props.binCenterTableLive.sortColumn,
                sortDir: this.props.binCenterTableLive.sortDir,
                
                binStatus: this.props.binCenterTableLive.binStatus,
            };

        } else {
            data = {
                currentPage: this.props.binCenterTable.currentPage,
                pageSize: this.props.binCenterTable.rowsPerPageSelected,
                searchQuery: this.props.binCenterTable.search,
                sortColumn: this.props.binCenterTable.sortColumn,
                sortDir: this.props.binCenterTable.sortDir,
                
                binTypeId: this.props.binCenterTable.binTypeId,
                isActive: this.props.binCenterTable.isActive,
                activeFilter: this.props.binCenterTable.activeFilter,
                binStatus: this.props.binCenterTable.binStatus,
                isValidated: this.props.binCenterTable.isValidated,
            };
        }

        this.props.action.binCenter.callDownloadExcelApi(data, (state: boolean, data: any, type: any) => {
            if(state){
                Utils.saveByteArray('Bin Center', data, type);
            }
        });
    }

    callCreateApi = (data, callback = null) => {
        this.setState({
          isLoadingFormDrawer: true,
        });
    
        this.props.dispatchApiCallPost(data, 'bin_center-create', 'binnumber', data, callback, null);
    }

    callUpdateApi = (data, callback = null) => {
        this.setState({
          isLoadingFormDrawer: true,
        });
    
        this.props.dispatchApiCallPut(data, 'bin_center-update', 'binnumber', data, callback, null);
    }

    callDetailsApi = (id = null, callback = null) => {
        if(id){
          this.setState({
            isBlockingFormDrawer: true,
          });
      
          this.props.dispatchApiCallGet(null, 'bin_center-details', 'binnumber/' + id, null, callback, () => {});
        } else {
          if(callback){
            callback(null)
          }
        }
    }
    /* END API */


    /* TOP */
    setTextDoughnut = (text = '') => {
        return {
            beforeDraw: function(chart) {
                const { ctx, width, height } = chart;
                const centerX = width / 2;
                const centerY = height / 2;

                ctx.font = '700 16px Inter';
                ctx.fillStyle = '#444444';
                const firstText = text;
                const firstTextWidth = ctx.measureText(firstText).width;
                const firstTextX = centerX - firstTextWidth / 2;
                const firstTextY = centerY;
                ctx.fillText(firstText, firstTextX, firstTextY);

                ctx.font = '400 10px Inter'
                ctx.fillStyle = '#444444';
                const secondText = "TOTAL";
                const secondTextWidth = ctx.measureText(secondText).width;
                const secondTextX = centerX - secondTextWidth / 2;
                const secondTextY = centerY + 15;
                ctx.fillText(secondText, secondTextX, secondTextY);

                ctx.restore();
            } 
        }
    }
    getTextBarHorisontal = (text = '') => {
        return {
            afterDatasetsDraw: function(chart, easing) {
                const { ctx } = chart;

                chart.data.datasets.forEach(function (dataset, i) {
                    var meta = chart.getDatasetMeta(i);
                    if (!meta.hidden) {
                        meta.data.forEach(function(element, index) {
                            ctx.font = '800 12px Inter'
                            ctx.fillStyle = '#ffffff';
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';

                            var position = element.tooltipPosition();
                            ctx.fillText(((dataset.label.count > 0) ? dataset.label.count : ''), position.x - (element.width / 2), position.y);
                        });
                    }
                });
            }
        }
    }
    

    getLegendDoughnutInit = (isActive = null) => {
        return {
            id: "htmlLegendInit",
            afterInit(chart, args, options) {
                // if(chart && chart.data && chart.data.datasets && chart.data.datasets.length > 0){
                //     chart.data.datasets.forEach((item, i) => {
                //         if(isActive == true){
                //             chart.getDatasetMeta(0).hidden = true;
                //             chart.getDatasetMeta(1).hidden = false;
                //         } else if (isActive == false){
                //             chart.getDatasetMeta(0).hidden = false;
                //             chart.getDatasetMeta(1).hidden = true;
                //         } else {
                //             chart.getDatasetMeta(i).hidden = null;
                //         }
                //     });
                // }
            }
        }
    }
    getLegendDoughnut = (isBox = false, callback = null) => {
        return {
            id: "htmlLegend",
            afterUpdate(chart, args, options) {
                const ul = document.getElementById(options.containerID);

                // Remove old legend items
                ul.innerHTML = '';
                
                // Reuse the built-in legendItems generator
                const items = chart.options.plugins.legend.labels.generateLabels(chart);
                let allLabelIdex = items.map(x => x.index);
            
                items.forEach((item) => {
                    const li = document.createElement("div");
                    li.className = "col-6";
            
                    li.onclick = () => {
                        const { type } = chart.config;
                        if (type === "pie" || type === "doughnut") {
                            let isCheckedData = chart.getDatasetMeta(0).data[item.index].isHidden;
                            let isChecked = (isCheckedData === undefined) ? false : !isCheckedData;

                            // Use hidden if you want to hide bar in chart (default is undefined), use isHidden if you don't want that (default is undefined)
                            chart.getDatasetMeta(0).data.forEach((sector, dataIndex) => {
                                if(isChecked){
                                    sector.isHidden = undefined;
                                } else {
                                    if (dataIndex !== item.index) {
                                        sector.isHidden = true;
                                    } else {
                                        sector.isHidden = isChecked;
                                    }
                                }
                            });

                            if(callback){
                                callback(item, isChecked);
                            }
                        } else {
                            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                        }
                        
                        // ul.innerHTML = '';
                        // setTimeout(() => {
                        //     chart.update();
                        // });
                    };
            
                    let jsxElement = <div
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        <Row className={'align-items-center g-4 p-0'}>
                            <Col xs={5}
                                style={{
                                    fontSize: '12px',
                                    lineHeight: '12px',
                                    fontWeight: (chart.getDatasetMeta(0).data[item.index].isHidden === undefined) ? '400' : chart.getDatasetMeta(0).data[item.index].isHidden ? '300' : '600',
                                    color: chart.getDatasetMeta(0).data[item.index].isHidden ? '#cccccc' : '#444444',
                                    textAlign: 'end',
                                    textDecoration: chart.getDatasetMeta(0).data[item.index].isHidden ? "line-through" : ""
                                }}
                            >{item.text.label}</Col>
                            <Col xs={2}
                                style={{
                                    background: item.fillStyle,
                                    borderColor: item.strokeStyle,
                                    borderWidth: String(item.lineWidth) + "px",
                                    position: 'relative',
                                    width: isBox ? '20px' : '16px',
                                    height: isBox ? '20px' : '16px',
                                    borderRadius: isBox ? '0px' : '50%',
                                    display: 'inline-flex'
                                }}
                            ></Col>
                            <Col xs={5}
                                style={{
                                    fontSize: '16px',
                                    lineHeight: '16px',
                                    fontWeight: '800',
                                    color: chart.getDatasetMeta(0).data[item.index].isHidden ? '#cccccc' : '#444444',
                                    textDecoration: chart.getDatasetMeta(0).data[item.index].isHidden ? "line-through" : ""
                                }}
                            >{item.text.count}</Col>
                        </Row>
                    </div>
                    
                    ReactDOM.render(jsxElement, li);
                    ul.appendChild(li);
                });
            }
        }
    }

    getLegendBarVerticalActiveInactiveInit = (isActive = null) => {
        return {
            id: "htmlLegendInit",
            afterInit(chart, args, options) {
                if(chart && chart.data && chart.data.datasets && chart.data.datasets.length > 0){
                    // Use hidden if you want to hide bar in chart (default is null), use isHidden if you don't want that (default is undefined)
                    chart.data.datasets.forEach((item, i) => {
                        if(isActive == true){
                            chart.getDatasetMeta(0).isHidden = true;
                            chart.getDatasetMeta(1).isHidden = false;
                        } else if (isActive == false){
                            chart.getDatasetMeta(0).isHidden = false;
                            chart.getDatasetMeta(1).isHidden = true;
                        } else {
                            chart.getDatasetMeta(i).isHidden = undefined;
                        }
                    });
                }
            }
        }
    }
    getLegendBarVerticalActiveInactive = (isBox = true, callback = null) => {
        return {
            id: "htmlLegend",
            afterUpdate(chart, args, options) {
                const ul = document.getElementById(options.containerID);
                
                // Remove old legend items
                ul.innerHTML = '';
            
                // Reuse the built-in legendItems generator
                const items = chart.options.plugins.legend.labels.generateLabels(chart);
                let allLabelIdex = items.map(x => x.text);

                items.forEach((item) => {
                    const li = document.createElement("div");
                    li.className = "col-6 col-lg-12";
            
                    li.onclick = () => {
                        const { type } = chart.config;
                        if (type === "pie" || type === "doughnut") {
                            chart.toggleDataVisibility(item.index);
                        } else {
                            let isCheckedData = chart.getDatasetMeta(item.datasetIndex).isHidden;
                            let isChecked = (isCheckedData === undefined) ? false : !isCheckedData;
                            
                            // Use hidden if you want to hide bar in chart (default is null), use isHidden if you don't want that (default is undefined)
                            allLabelIdex.forEach((lbl: any, l: number) => {
                                if(isChecked){
                                    chart.getDatasetMeta(l).isHidden = undefined;
                                } else {
                                    if (l !== item.datasetIndex) {
                                        chart.getDatasetMeta(l).isHidden = true;
                                    } else {
                                        chart.getDatasetMeta(l).isHidden = isChecked;
                                    }
                                }
                            })

                            if(callback){
                                callback(item, isChecked);
                            }
                        }
                        
                        // ul.innerHTML = '';
                        // setTimeout(() => {
                        //     chart.update();
                        // });
                    };
            
                    let jsxElement = <div
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        <Row className={'align-items-center g-4 p-0'}>
                            <Col xs={5}
                                style={{
                                    fontSize: '12px',
                                    lineHeight: '12px',
                                    fontWeight: (chart.getDatasetMeta(item.datasetIndex).isHidden === undefined) ? '400' : chart.getDatasetMeta(item.datasetIndex).isHidden ? '300' : '600',
                                    color: chart.getDatasetMeta(item.datasetIndex).isHidden ? '#cccccc' : '#444444',
                                    textAlign: 'end',
                                    textDecoration: chart.getDatasetMeta(item.datasetIndex).isHidden ? "line-through" : ""
                                }}
                            >{item.text.label}</Col>
                            <Col xs={2}
                                style={{
                                    background: item.fillStyle,
                                    borderColor: item.strokeStyle,
                                    borderWidth: String(item.lineWidth) + "px",
                                    position: 'relative',
                                    width: isBox ? '20px' : '16px',
                                    height: isBox ? '20px' : '16px',
                                    borderRadius: isBox ? '0px' : '50%',
                                    display: 'inline-flex'
                                }}
                            ></Col>
                            <Col xs={5}
                                style={{
                                    fontSize: '16px',
                                    lineHeight: '16px',
                                    fontWeight: '800',
                                    color: chart.getDatasetMeta(item.datasetIndex).isHidden ? '#cccccc' : '#444444',
                                    textDecoration: chart.getDatasetMeta(item.datasetIndex).isHidden ? "line-through" : ""
                                }}
                            >{item.text.count}</Col>
                        </Row>
                    </div>
                    
                    ReactDOM.render(jsxElement, li);
                    ul.appendChild(li);
                });
            }
        }
    }

    getLegendBarVerticalInOutInit = (isActive = null) => {
        return {
            id: "htmlLegendInit",
            afterInit(chart, args, options) {
                // if(chart && chart.data && chart.data.datasets && chart.data.datasets.length > 0){
                //     chart.data.datasets.forEach((item, i) => {
                //         if(isActive == true){
                //             chart.getDatasetMeta(0).hidden = true;
                //             chart.getDatasetMeta(1).hidden = false;
                //         } else if (isActive == false){
                //             chart.getDatasetMeta(0).hidden = false;
                //             chart.getDatasetMeta(1).hidden = true;
                //         } else {
                //             chart.getDatasetMeta(i).hidden = null;
                //         }
                //     });
                // }
            }
        }
    }
    getLegendBarVerticalInOut = (isBox = true, callback = null) => {
        return {
            id: "htmlLegend",
            afterUpdate(chart, args, options) {
                const ul = document.getElementById(options.containerID);
                
                // Remove old legend items
                ul.innerHTML = '';
            
                // Reuse the built-in legendItems generator
                const items = chart.options.plugins.legend.labels.generateLabels(chart);
                let allLabelIdex = items.map(x => x.text.label);
            
                items.forEach((item) => {
                    const li = document.createElement("div");
                    li.className = "col-6 col-lg-12";
            
                    li.onclick = () => {
                        const { type } = chart.config;
                        if (type === "pie" || type === "doughnut") {
                            chart.toggleDataVisibility(item.index);
                        } else {
                            let isCheckedData = chart.getDatasetMeta(item.datasetIndex).isHidden;
                            let isChecked = (isCheckedData === undefined) ? false : !isCheckedData;
                            
                            // Use hidden if you want to hide bar in chart (default is null), use isHidden if you don't want that (default is undefined)
                            allLabelIdex.forEach((lbl: any, l: number) => {
                                if(isChecked){
                                    chart.getDatasetMeta(l).isHidden = undefined;
                                } else {
                                    if (l !== item.datasetIndex) {
                                        chart.getDatasetMeta(l).isHidden = true;
                                    } else {
                                        chart.getDatasetMeta(l).isHidden = isChecked;
                                    }
                                }
                            })

                            if(callback){
                                callback(item, isChecked);
                            }
                        }
                        
                        // ul.innerHTML = '';
                        // setTimeout(() => {
                        //     chart.update();
                        // });
                    };
            
                    let jsxElement = <div
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        <Row className={'align-items-center g-4 p-0'}>
                            <Col xs={5}
                                style={{
                                    fontSize: '12px',
                                    lineHeight: '12px',
                                    fontWeight: (chart.getDatasetMeta(item.datasetIndex).isHidden === undefined) ? '400' : chart.getDatasetMeta(item.datasetIndex).isHidden ? '300' : '600',
                                    color: chart.getDatasetMeta(item.datasetIndex).isHidden ? '#cccccc' : '#444444',
                                    textAlign: 'end',
                                    textDecoration: chart.getDatasetMeta(item.datasetIndex).isHidden ? "line-through" : ""
                                }}
                            >{item.text.label}</Col>
                            <Col xs={2}
                                style={{
                                    background: item.fillStyle,
                                    borderColor: item.strokeStyle,
                                    borderWidth: String(item.lineWidth) + "px",
                                    position: 'relative',
                                    width: isBox ? '20px' : '16px',
                                    height: isBox ? '20px' : '16px',
                                    borderRadius: isBox ? '0px' : '50%',
                                    display: 'inline-flex'
                                }}
                            ></Col>
                            <Col xs={5}
                                style={{
                                    fontSize: '16px',
                                    lineHeight: '16px',
                                    fontWeight: '800',
                                    color: chart.getDatasetMeta(item.datasetIndex).isHidden ? '#cccccc' : '#444444',
                                    textDecoration: chart.getDatasetMeta(item.datasetIndex).isHidden ? "line-through" : ""
                                }}
                            >{item.text.count}</Col>
                        </Row>
                    </div>
                    
                    ReactDOM.render(jsxElement, li);
                    ul.appendChild(li);
                });
            }
        }
    }
    
    getLegendBarHorisontalActiveBinsInit = (isActive = null) => {
        return {
            id: "htmlLegendInit",
            afterInit(chart, args, options) {
                // if(chart && chart.data && chart.data.datasets && chart.data.datasets.length > 0){
                //     chart.data.datasets.forEach((item, i) => {
                //         if(isActive == true){
                //             chart.getDatasetMeta(0).hidden = true;
                //             chart.getDatasetMeta(1).hidden = false;
                //         } else if (isActive == false){
                //             chart.getDatasetMeta(0).hidden = false;
                //             chart.getDatasetMeta(1).hidden = true;
                //         } else {
                //             chart.getDatasetMeta(i).hidden = null;
                //         }
                //     });
                // }
            }
        }
    }
    getLegendBarHorisontalActiveBins = (isBox = true, callback = null) => {
        return {
            id: "htmlLegend",
            afterUpdate(chart, args, options) {
                const ul = document.getElementById(options.containerID);
                
                // Remove old legend items
                ul.innerHTML = '';
            
                // Reuse the built-in legendItems generator
                const items = chart.options.plugins.legend.labels.generateLabels(chart);
                let allLabelIdex = items.map(x => x.text.label);
            
                items.forEach((item) => {
                    const li = document.createElement("div");
                    li.className = "col-6 col-lg-auto";
            
                    li.onclick = () => {
                        const { type } = chart.config;
                        if (type === "pie" || type === "doughnut") {
                            chart.toggleDataVisibility(item.index);
                        } else {
                            let isCheckedData = chart.getDatasetMeta(item.datasetIndex).isHidden;
                            let isChecked = (isCheckedData === undefined) ? false : !isCheckedData;
                            
                            // Use hidden if you want to hide bar in chart (default is null), use isHidden if you don't want that (default is undefined)
                            allLabelIdex.forEach((lbl: any, l: number) => {
                                if(isChecked){
                                    chart.getDatasetMeta(l).isHidden = undefined;
                                } else {
                                    if (l !== item.datasetIndex) {
                                        chart.getDatasetMeta(l).isHidden = true;
                                    } else {
                                        chart.getDatasetMeta(l).isHidden = isChecked;
                                    }
                                }
                            })

                            if(callback){
                                callback(item, isChecked);
                            }
                        }
                        
                        // ul.innerHTML = '';
                        // setTimeout(() => {
                        //     chart.update();
                        // });
                    };
            
                    let jsxElement = <div
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        <Row className={'align-items-center g-4 p-0'}>
                            <Col xs={2}
                                style={{
                                    background: item.fillStyle,
                                    borderColor: item.strokeStyle,
                                    borderWidth: String(item.lineWidth) + "px",
                                    position: 'relative',
                                    width: isBox ? '20px' : '16px',
                                    height: isBox ? '20px' : '16px',
                                    borderRadius: isBox ? '0px' : '50%',
                                    display: 'inline-flex'
                                }}
                            ></Col>
                            <Col xs={'auto'}
                                style={{
                                    fontSize: '12px',
                                    lineHeight: '12px',
                                    fontWeight: (chart.getDatasetMeta(item.datasetIndex).isHidden === undefined) ? '400' : chart.getDatasetMeta(item.datasetIndex).isHidden ? '300' : '600',
                                    color: chart.getDatasetMeta(item.datasetIndex).isHidden ? '#cccccc' : '#444444',
                                    textDecoration: chart.getDatasetMeta(item.datasetIndex).isHidden ? "line-through" : ""
                                }}
                            >{item.text.label}</Col>
                        </Row>
                    </div>
                    
                    ReactDOM.render(jsxElement, li);
                    ul.appendChild(li);
                });
            }
        }
    }
    
    getLegendBarHorisontalActivitiesBinsInit = (isActive = null) => {
        return {
            id: "htmlLegendInit",
            afterInit(chart, args, options) {
                // if(chart && chart.data && chart.data.datasets && chart.data.datasets.length > 0){
                //     chart.data.datasets.forEach((item, i) => {
                //         if(isActive == true){
                //             chart.getDatasetMeta(0).hidden = true;
                //             chart.getDatasetMeta(1).hidden = false;
                //         } else if (isActive == false){
                //             chart.getDatasetMeta(0).hidden = false;
                //             chart.getDatasetMeta(1).hidden = true;
                //         } else {
                //             chart.getDatasetMeta(i).hidden = null;
                //         }
                //     });
                // }
            }
        }
    }
    getLegendBarHorisontalActivitiesBins = (isBox = true, callback = null) => {
        return {
            id: "htmlLegend",
            afterUpdate(chart, args, options) {
                const ul = document.getElementById(options.containerID);
                
                // Remove old legend items
                ul.innerHTML = '';
            
                // Reuse the built-in legendItems generator
                const items = chart.options.plugins.legend.labels.generateLabels(chart);
                let allLabelIdex = items.map(x => x.text.label);
            
                items.forEach((item) => {
                    const li = document.createElement("div");
                    li.className = "col-6 col-lg-auto";
            
                    li.onclick = () => {
                        const { type } = chart.config;
                        if (type === "pie" || type === "doughnut") {
                            chart.toggleDataVisibility(item.index);
                        } else {
                            let isCheckedData = chart.getDatasetMeta(item.datasetIndex).isHidden;
                            let isChecked = (isCheckedData === undefined) ? false : !isCheckedData;
                            
                            // Use hidden if you want to hide bar in chart (default is null), use isHidden if you don't want that (default is undefined)
                            allLabelIdex.forEach((lbl: any, l: number) => {
                                if(isChecked){
                                    chart.getDatasetMeta(l).isHidden = undefined;
                                } else {
                                    if (l !== item.datasetIndex) {
                                        chart.getDatasetMeta(l).isHidden = true;
                                    } else {
                                        chart.getDatasetMeta(l).isHidden = isChecked;
                                    }
                                }
                            })

                            if(callback){
                                callback(item, isChecked);
                            }
                        }
                        
                        // ul.innerHTML = '';
                        // setTimeout(() => {
                        //     chart.update();
                        // });
                    };
            
                    let jsxElement = <div
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        <Row className={'align-items-center g-4 p-0'}>
                            <Col xs={2}
                                style={{
                                    background: item.fillStyle,
                                    borderColor: item.strokeStyle,
                                    borderWidth: String(item.lineWidth) + "px",
                                    position: 'relative',
                                    width: isBox ? '20px' : '16px',
                                    height: isBox ? '20px' : '16px',
                                    borderRadius: isBox ? '0px' : '50%',
                                    display: 'inline-flex'
                                }}
                            ></Col>
                            <Col xs={'auto'}
                                style={{
                                    fontSize: '12px',
                                    lineHeight: '12px',
                                    fontWeight: (chart.getDatasetMeta(item.datasetIndex).isHidden === undefined) ? '400' : chart.getDatasetMeta(item.datasetIndex).isHidden ? '300' : '600',
                                    color: chart.getDatasetMeta(item.datasetIndex).isHidden ? '#cccccc' : '#444444',
                                    textDecoration: chart.getDatasetMeta(item.datasetIndex).isHidden ? "line-through" : ""
                                }}
                            >{item.text.label}</Col>
                        </Row>
                    </div>
                    
                    ReactDOM.render(jsxElement, li);
                    ul.appendChild(li);
                });
            }
        }
    }


    sectionBinCountByType = () => {
        const containerID = 'legend-container-1';

        const options = {
            responsive: false,
            plugins: {
                htmlLegend: {
                  containerID: containerID
                },
                legend: {
                    display: false,
                },
                tooltip: {
                    yAlign: 'bottom',
                    xAlign: 'center',
                    callbacks: {
                        label: (item) => {
                            return ' ' + item.label.label + ' - ' + item.label.count + ' bins'
                        },
                    },
                }
            },
            maintainAspectRatio: false,
            onHover: (event, chartElement) => {
                event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
            },
            onClick: (evt, element) => {
                try {
                    let item = element[0];
                    let chart = evt.chart;
                    let id = chart.data.labels[item.index].id;

                    let isCheckedData = chart.getDatasetMeta(0).data[item.index].isHidden;
                    let isChecked = (isCheckedData === undefined) ? false : !isCheckedData;

                    // Use hidden if you want to hide bar in chart (default is undefined), use isHidden if you don't want that (default is undefined)
                    chart.getDatasetMeta(0).data.forEach((sector, dataIndex) => {
                        if(isChecked){
                            sector.isHidden = undefined;
                        } else {
                            if (dataIndex !== item.index) {
                                sector.isHidden = true;
                            } else {
                                sector.isHidden = isChecked;
                            }
                        }
                    });
                    
                    // setTimeout(() => {
                    //     chart.update();
                    // });
                    
                    this.props.action.binCenterTable.setBinTypeId({ id: id, checked: isChecked }, () => {
                        this.callReadApi();
                    });
                } catch(e){
                    let chart = evt.chart;

                    chart.getDatasetMeta(0).data.forEach((sector, dataIndex) => {
                        sector.isHidden = undefined;
                    });
                    
                    // setTimeout(() => {
                    //     chart.update();
                    // });
                    
                    this.props.action.binCenterTable.setBinTypeId({ id: null, checked: false }, () => {
                        this.callReadApi();
                    });
                }
            }
        };

        return <Row className={'px-2 px-lg-10'}>
            <StyledSubTitle xs={12}>Bin count by type</StyledSubTitle>
            <Col xs={12} className={'pb-5'}>
                {
                this.props.binCenter.isLoadingBinCountByType
                ?
                <div style={{ display: 'flex', alignItems: 'center', height: '150px' }}><Spinner animation="border" size="sm" style={{ margin: '0 auto' }} /></div>
                :
                <Row className={'align-items-center'}>
                    <Col xs={12} lg={'auto'}>
                        <Doughnut ref={this.refChartBinCountByType} options={options} data={this.props.binCenter.dataBinCountByType} plugins={[
                            // this.getLegendDoughnutInit(),
                            this.getLegendDoughnut(false, (item, isChecked) => {
                                this.props.action.binCenterTable.setBinTypeId({ id: item.text.id, checked: isChecked }, () => {
                                    this.callReadApi();
                                });
                            }), 
                            this.setTextDoughnut(this.props.binCenter.totalBinCountByType)
                        ]} width={150} />
                    </Col>
                    <Col xs={12} lg={7} xl={6} className={'mt-5 mt-lg-0'}>
                        <Row id="legend-container-1" className={'align-items-center g-8'}></Row>
                    </Col>
                </Row>
                }
            </Col>
        </Row>
    }

    sectionActiveInactive = () => {
        const containerID = 'legend-container-2';

        const options = {
            responsive: !isMobile,
            aspectRatio: 3 / 0.8,
            plugins: {
                htmlLegend: {
                  containerID: containerID
                },
                legend: {
                    display: false,
                },
                tooltip: {
                    yAlign: 'bottom',
                    xAlign: 'center',
                    callbacks: {
                        label: (item) => {
                            return ' ' + item.dataset.label.label + ' - ' + item.raw[1] + ' bins'
                        },
                    },
                },
            },
            scales: {
              x: {
                display: true,
                grid: {
                    display: false,
                },
              },
              y: {
                display: true,
                grid: {
                    drawTicks: false,
                    borderDash: [3, 3],
                },
                ticks: {
                  beginAtZero: true,
                  precision: 0
                }
              },
            },
            maintainAspectRatio: false,
            onHover: (event, chartElement) => {
                event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
            },
            onClick: (evt, element) => {
                try {
                    let item = element[0];
                    let chart = evt.chart;
                    let id = chart.data.datasets[item.datasetIndex].label.id;

                    const items = chart.options.plugins.legend.labels.generateLabels(chart);
                    let allLabelIdex = items.map(x => x.text);

                    let isCheckedData = chart.getDatasetMeta(item.datasetIndex).isHidden;
                    let isChecked = (isCheckedData === undefined) ? false : !isCheckedData;
                    
                    // Use hidden if you want to hide bar in chart (default is null), use isHidden if you don't want that (default is undefined)
                    allLabelIdex.forEach((lbl: any, l: number) => {
                        if(isChecked){
                            chart.getDatasetMeta(l).isHidden = undefined;
                        } else {
                            if (l !== item.datasetIndex) {
                                chart.getDatasetMeta(l).isHidden = true;
                            } else {
                                chart.getDatasetMeta(l).isHidden = isChecked;
                            }
                        }
                    })
                    
                    // setTimeout(() => {
                    //     chart.update();
                    // });

                    this.props.action.binCenterTable.setIsActive({ id: id, checked: isChecked }, () => {
                        this.callReadApi();
                    });
                } catch(e){}
            }
        };
        
        return <Row className={'px-2 px-lg-10'}>
            <StyledSubTitle xs={12}>Bin count by status</StyledSubTitle>
            <Col xs={12} className={'pb-5'}>{
                this.props.binCenter.isLoadingActiveInactive
                ?
                <div style={{ display: 'flex', alignItems: 'center', height: '150px' }}><Spinner animation="border" size="sm" style={{ margin: '0 auto' }} /></div>
                :
                <Row className={'align-items-center'}>
                    <Col xs={12} lg={10}>
                        <Bar ref={this.refChartActiveInactive} options={options} data={this.props.binCenter.dataActiveInactive} plugins={[
                            this.getLegendBarVerticalActiveInactiveInit(this.props.binCenterTable.isActive),
                            this.getLegendBarVerticalActiveInactive(true, (item, isChecked) => {
                                this.props.action.binCenterTable.setIsActive({ id: item.text.id, checked: isChecked }, () => {
                                    this.callReadApi();
                                });
                            })
                        ]} height={150} width={300} />
                    </Col>
                    <Col xs={12} lg={true} className={'mt-5 mt-lg-0'}>
                        <Row id="legend-container-2" className={'align-items-center g-8'}></Row>
                    </Col>
                </Row>
                }
            </Col>
        </Row>
    }
    
    sectionActiveBins = () => {
        const containerID = 'legend-container-3';

        const options = {
            responsive: true,
            indexAxis: 'y',
            aspectRatio: 5.5 / 1,
            plugins: {
                htmlLegend: {
                  containerID: containerID
                },
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: true,
                    callbacks: { 
                        title: (data) => { 
                            return data[0].dataset.label.label
                        },
                        label: (data) => { 
                            return data.dataset.label.count
                        }
                    },
                },
            },
            scales: {
              x: {
                display: false,
              },
              y: {
                display: false,
              },
            },
            animation: {
                duration: 0,
            },
            maintainAspectRatio: true,
            onHover: (event, chartElement) => {
                event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
            },
            onClick: (evt, element) => {
                try {
                    let item = element[0];
                    let chart = evt.chart;
                    let id = chart.data.datasets[item.datasetIndex].label.id;

                    const items = chart.options.plugins.legend.labels.generateLabels(chart);
                    let allLabelIdex = items.map(x => x.text);

                    let isCheckedData = chart.getDatasetMeta(item.datasetIndex).isHidden;
                    let isChecked = (isCheckedData === undefined) ? false : !isCheckedData;
                    
                    // Use hidden if you want to hide bar in chart (default is null), use isHidden if you don't want that (default is undefined)
                    allLabelIdex.forEach((lbl: any, l: number) => {
                        if(isChecked){
                            chart.getDatasetMeta(l).isHidden = undefined;
                        } else {
                            if (l !== item.datasetIndex) {
                                chart.getDatasetMeta(l).isHidden = true;
                            } else {
                                chart.getDatasetMeta(l).isHidden = isChecked;
                            }
                        }
                    })
                    
                    // setTimeout(() => {
                    //     chart.update();
                    // });

                    this.props.action.binCenterTable.setActiveFilter({ id: id, checked: isChecked }, () => {
                        this.callReadApi();
                    });
                } catch(e){}
            }
        };

        return <Row className={'px-2 px-lg-10'}>
            <StyledSubTitle xs={12}>Active Bins on site </StyledSubTitle>
            <Col xs={12} className={'pb-5'}>
                {
                this.props.binCenter.isLoadingActiveBins
                ?
                <div style={{ display: 'flex', alignItems: 'center', height: '150px' }}><Spinner animation="border" size="sm" style={{ margin: '0 auto' }} /></div>
                :
                <Row className={'align-items-center'}>
                    <Col xs={12} lg={10}>
                        <Bar ref={this.refChartActiveBins} options={options} data={this.props.binCenter.dataActiveBins} plugins={[
                            // this.getLegendBarHorisontalActiveBinsInit(),
                            this.getLegendBarHorisontalActiveBins(true, (item, isChecked) => {
                                this.props.action.binCenterTable.setActiveFilter({ id: item.text.id, checked: isChecked }, () => {
                                    this.callReadApi();
                                });
                            }), this.getTextBarHorisontal()
                        ]} />
                    </Col>
                    <StyledCount xs={12} lg={'auto'} className={'mt-5 mt-lg-0'}>
                        {this.props.binCenter.totalActiveBins}
                    </StyledCount>
                    <Col xs={12} className={'mt-5 mt-lg-0'}>
                        <Row id="legend-container-3" className={'align-items-center g-8 ps-3'}></Row>
                    </Col>
                </Row>
                }
            </Col>
        </Row>
    }
    
    sectionInOut = () => {
        const containerID = 'legend-container-4';

        const options = {
            responsive: !isMobile,
            aspectRatio: 3 / 0.8,
            plugins: {
                htmlLegend: {
                  containerID: containerID
                },
                legend: {
                    display: false,
                },
                tooltip: {
                    yAlign: 'bottom',
                    xAlign: 'center',
                    callbacks: {
                        label: (item) => {
                            return ' ' + item.dataset.label.label + ' - ' + item.raw[1] + ' bins'
                        },
                    },
                },
            },
            scales: {
              x: {
                display: true,
                grid: {
                    display: false,
                },
              },
              y: {
                display: true,
                grid: {
                    drawTicks: false,
                    borderDash: [3, 3],
                },
                ticks: {
                  beginAtZero: true,
                  precision: 0
                }
              }
            },
            maintainAspectRatio: true,
            onHover: (event, chartElement) => {
                event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
            },
            onClick: (evt, element) => {
                try {
                    let item = element[0];
                    let chart = evt.chart;
                    let id = chart.data.datasets[item.datasetIndex].label.id;

                    const items = chart.options.plugins.legend.labels.generateLabels(chart);
                    let allLabelIdex = items.map(x => x.text);

                    let isCheckedData = chart.getDatasetMeta(item.datasetIndex).isHidden;
                    let isChecked = (isCheckedData === undefined) ? false : !isCheckedData;
                    
                    // Use hidden if you want to hide bar in chart (default is null), use isHidden if you don't want that (default is undefined)
                    allLabelIdex.forEach((lbl: any, l: number) => {
                        if(isChecked){
                            chart.getDatasetMeta(l).isHidden = undefined;
                        } else {
                            if (l !== item.datasetIndex) {
                                chart.getDatasetMeta(l).isHidden = true;
                            } else {
                                chart.getDatasetMeta(l).isHidden = isChecked;
                            }
                        }
                    })
                    
                    // setTimeout(() => {
                    //     chart.update();
                    // });

                    this.props.action.binCenterTable.setBinStatus({ id: id, checked: isChecked }, () => {
                        this.callReadApi();
                    });
                } catch(e){}
            }
        };

        return <Row className={'px-2 px-lg-10'}>
            <StyledSubTitle xs={12}>Last 7 days check in/out</StyledSubTitle>
            <Col xs={12} className={'pb-5'}>
                {
                this.props.binCenter.isLoadingInOut
                ?
                <div style={{ display: 'flex', alignItems: 'center', height: '150px' }}><Spinner animation="border" size="sm" style={{ margin: '0 auto' }} /></div>
                :
                <Row className={'align-items-center'}>
                    <Col xs={12} lg={10}>
                        <Bar ref={this.refChartInOut} options={options} data={this.props.binCenter.dataInOut} plugins={[
                            // this.getLegendBarVerticalInOutInit(),
                            this.getLegendBarVerticalInOut(true, (item, isChecked) => {
                                this.props.action.binCenterTable.setBinStatus({ id: item.text.id, checked: isChecked }, () => {
                                    this.callReadApi();
                                });
                            })
                        ]} />
                    </Col>
                    <Col xs={12} lg={true} className={'mt-5 mt-lg-0'}>
                        <Row id="legend-container-4" className={'align-items-center g-8'}></Row>
                    </Col>
                </Row>
                }
            </Col>
        </Row>
    }
    
    sectionActivitiesBins = () => {
        const containerID = 'legend-container-5';

        const options = {
            responsive: true,
            indexAxis: 'y',
            aspectRatio: 6 / 1,
            plugins: {
                htmlLegend: {
                  containerID: containerID
                },
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: true,
                    callbacks: { 
                        title: (data) => { 
                            return data[0].dataset.label.label
                        },
                        label: (data) => { 
                            return data.dataset.label.count
                        }
                    },
                },
            },
            scales: {
              x: {
                display: false,
              },
              y: {
                display: false,
              },
            },
            animation: {
                duration: 0,
            },
            maintainAspectRatio: true,
            onHover: (event, chartElement) => {
                event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
            },
            onClick: (evt, element) => {
                try {
                    let item = element[0];
                    let chart = evt.chart;
                    let id = chart.data.datasets[item.datasetIndex].label.id;

                    const items = chart.options.plugins.legend.labels.generateLabels(chart);
                    let allLabelIdex = items.map(x => x.text);

                    let isCheckedData = chart.getDatasetMeta(item.datasetIndex).isHidden;
                    let isChecked = (isCheckedData === undefined) ? false : !isCheckedData;
                    
                    // Use hidden if you want to hide bar in chart (default is null), use isHidden if you don't want that (default is undefined)
                    allLabelIdex.forEach((lbl: any, l: number) => {
                        if(isChecked){
                            chart.getDatasetMeta(l).isHidden = undefined;
                        } else {
                            if (l !== item.datasetIndex) {
                                chart.getDatasetMeta(l).isHidden = true;
                            } else {
                                chart.getDatasetMeta(l).isHidden = isChecked;
                            }
                        }
                    })
                    
                    // setTimeout(() => {
                    //     chart.update();
                    // });

                    this.props.action.binCenterTableLive.setBinStatusFilter({ id: id, checked: isChecked }, () => {
                        this.callReadApi();
                    });
                } catch(e){}
            }
        };

        return <Row className={'px-2 px-lg-10'}>
            <StyledSubTitle xs={12}>Bin activities today</StyledSubTitle>
            <Col xs={12} className={'pb-5'}>
                {
                this.props.binCenter.isLoadingActivitiesBins
                ?
                <div style={{ display: 'flex', alignItems: 'center', height: '150px' }}><Spinner animation="border" size="sm" style={{ margin: '0 auto' }} /></div>
                :
                <Row className={'align-items-center'}>
                    <Col xs={12} lg={true}>
                        <Bar ref={this.refChartActivitiesBins} options={options} data={this.props.binCenter.dataActivitiesBins} plugins={[
                            // this.getLegendBarHorisontalActivitiesBinsInit(),
                            this.getLegendBarHorisontalActivitiesBins(true, (item, isChecked) => {
                                this.props.action.binCenterTableLive.setBinStatusFilter({ id: item.text.id, checked: isChecked }, () => {
                                    this.callReadApi();
                                });
                            }),
                            this.getTextBarHorisontal()
                        ]} />
                    </Col>
                    {/* <StyledCount xs={12} lg={'auto'} className={'mt-5 mt-lg-0'}>
                        {this.props.binCenter.totalActivitiesBins}
                    </StyledCount> */}
                    <Col xs={12} className={'mt-5 mt-lg-0'}>
                        <Row id="legend-container-5" className={'align-items-center g-8 ps-3'}></Row>
                    </Col>
                </Row>
                }
            </Col>
        </Row>
    }
    /* END TOP */


    /* TABLE */
    tableHeaderSection = () => {
        return <Row className={"align-items-center"}>
            {!this.props.binCenterLive.isLive && <Col xs={12} className={'mb-5'}>
                <Row className={"align-items-center gx-2"}>
                    <Col xs={'auto'}>
                        <StyledBinDraft href={'#'}
                            active={this.props.binCenterTable.isValidated != false}
                            className={'d-flex align-items-center'}
                            onClick={async (e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                if(this.props.binCenterTable.isValidated == false){
                                    this.setState({
                                        isDisabledBatchAction: true
                                    }, () => {
                                        try {
                                            if(this.tableRef && this.tableRef.current){
                                                this.tableRef.current.setSelected();
                                            }
                                        }catch(e){}
                                    });

                                    await this.props.action.binCenterTable.setIsValidated(null, () => {
                                        this.callReadApi();
                                    });
                                }
                            }}
                        >
                            Bin list
                        </StyledBinDraft>
                    </Col>
                    <Col xs={'auto'}>|</Col>
                    <Col xs={'auto'}>
                        <StyledBinDraft href={'#'}
                            active={this.props.binCenterTable.isValidated == false}
                            className={'d-flex align-items-center'}
                            onClick={async (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                
                                if(this.props.binCenterTable.isValidated != false){
                                    this.setState({
                                        isDisabledBatchAction: true
                                    }, () => {
                                        try {
                                            if(this.tableRef && this.tableRef.current){
                                                this.tableRef.current.setSelected();
                                            }
                                        }catch(e){}
                                    });

                                    await this.props.action.binCenterTable.setIsValidated(false, () => {
                                        this.callReadApi();
                                    });
                                }
                            }}
                        >
                            Draft
                        </StyledBinDraft>
                    </Col>
                </Row>
            </Col>}
            <Col xs={12} md={2} lg={'auto'} className={'d-flex align-items-center mb-2 mb-md-0'}>
                <Switch
                    id={'is_history_live'}

                    checked={this.props.binCenterLive.isLive}
                    onChange={async (checked) => {
                        await this.props.action.binCenterLive.setLive(checked);
                        await this.props.action.binCenterTable.tableGetFilters({
                            auth: this.props.auth,
                            settingsType: this.props.settingsType,
                            pageName: this.pageName,
                        });

                        this.setState({
                            isDisabledBatchAction: true
                        }, () => {
                            try {
                                if(this.tableRef && this.tableRef.current){
                                    this.tableRef.current.setSelected();
                                }
                            }catch(e){}
                        });

                        this.clearSearchValue();
                        this.callReadApi();
                        this.callReadStatsApi(this.setTimer);
                    }}

                    onColor="#f7f7f7"
                    onHandleColor="#DC3545"
                    
                    offColor="#f7f7f7"
                    offHandleColor="#D4D4D4"

                    handleDiameter={26}
                    width={60}
                    height={28}

                    uncheckedIcon={false}
                    checkedIcon={false}
                />
                <span className={'ms-3'}>{this.props.binCenterLive.isLive ? 'LIVE' : 'HISTORY'}</span>
            </Col>
            {this.props.binCenterLive.isLive && <Col xs={12} md={'auto'} className={'pb-2 pb-md-0'}>
                <Form.Control
                    as = {StyledRSuiteSelectPicker}

                    id={'live_time'}
                    placeholder={''}
                    items={this.props.binCenterLive.items}
                    value={this.props.binCenterLive.value}
                    isLoading={false}
                    searchable={false}
                    cleanable={false}
                    readOnly={false}
                    isDefault={true}
                    onSelect={async (value, item) => {
                        await this.props.action.binCenterLive.selectedValue({
                            auth: this.props.auth,
                            settingsType: this.props.settingsType,
                            pageName: this.pageName,
                            item: item,
                        });
                    }}
                />
            </Col>}
            <Col xs={12} md={'auto'} lg={'auto'} className={'pb-2 pb-md-0'}>
                <IconButtonSearch 
                    value={this.getSearchValue()}
                    isOpen={(this.getSearchValue() !== '') ? true : false}
                    disabled={this.props.binCenterTable.isLoading || this.props.binCenterTableLive.isLoading}
                    onPressEnter={async (value) => {
                        this.setSearchValue(value);
                        this.callReadSearchApi();
                    }}
                    onClear={async () => {
                        this.setSearchValue('');
                        this.callReadSearchApi();
                    }}
                />

                {/* <Form.Control 
                    type={'text'}
                    placeholder={'Search...'}
                    disabled={this.props.binCenterTable.isLoading || this.props.binCenterTableLive.isLoading}
                    autoFocus
                    value={this.getSearchValue()}
                    onChange={(e) => {
                        this.setSearchValue(e.target.value);
                    }}
                    onKeyDown={async (e) => {
                        if ((e.charCode || e.keyCode) === 13) {
                          e.preventDefault();
                          e.stopPropagation();
        
                          this.callReadSearchApi();
                        }
                    }}
                /> */}
            </Col>
            <Col xs={12} md={'auto'} lg={'auto'} xl={'auto'} className={'pb-2 pb-md-0'}>
                <Row className={"align-items-center"}>
                    {this.hasFilters() && <Col xs={12} md={'auto'} className={'pb-2 pb-md-0'}>
                        <a 
                            className={(this.props.binCenterTable.isLoading || this.props.binCenterTableLive.isLoading) ? 'link-secondary cursor-not-allowed' : 'link-danger'}
                            href={'/'}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                
                                this.clearAllFilters();
                            }}
                        >Clear Filters</a>
                    </Col>}

                    {
                    this.props.binCenterLive.isLive
                    ?
                    <Col xs={12} md={'auto'} className={'pb-2 pb-md-0'}>
                        <Row className={"align-items-center"}>

                            {this.hasActivitiesBins() && <Col xs={'auto'} className={'pb-2 pb-md-0'}>
                                <StyledBadge bg="light" text="dark">
                                    {this.getLabelActivitiesBins()}
                                    <a 
                                        className={(this.props.binCenterTable.isLoading || this.props.binCenterTableLive.isLoading) ? 'link-default-icon cursor-not-allowed' : 'link-danger-icon'}
                                        href={'/'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            
                                            this.clearActivitiesBins();
                                        }}
                                    >
                                        <i className={'material-icons'}>close</i>
                                    </a>
                                </StyledBadge>
                            </Col>}

                        </Row>
                    </Col>
                    :
                    <Col xs={12} md={'auto'} className={'pb-2 pb-md-0'}>
                        <Row className={"align-items-center"}>

                            {this.hasBinCountByType() && <Col xs={'auto'} className={'pb-2 pb-md-0'}>
                                <StyledBadge bg="light" text="dark">
                                    {this.getLabelBinCountByType()}
                                    <a 
                                        className={(this.props.binCenterTable.isLoading || this.props.binCenterTableLive.isLoading) ? 'link-default-icon cursor-not-allowed' : 'link-danger-icon'}
                                        href={'/'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            
                                            this.clearBinCountByType();
                                        }}
                                    >
                                        <i className={'material-icons'}>close</i>
                                    </a>
                                </StyledBadge>
                            </Col>}

                            {this.hasActiveInactive() && <Col xs={'auto'} className={'pb-2 pb-md-0'}>
                                <StyledBadge bg="light" text="dark">
                                    {this.getLabelActiveInactive()}
                                    <a 
                                        className={(this.props.binCenterTable.isLoading || this.props.binCenterTableLive.isLoading) ? 'link-default-icon cursor-not-allowed' : 'link-danger-icon'}
                                        href={'/'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            
                                            this.clearActiveInactive();
                                        }}
                                    >
                                        <i className={'material-icons'}>close</i>
                                    </a>
                                </StyledBadge>
                            </Col>}

                            {this.hasActiveBins() && <Col xs={'auto'} className={'pb-2 pb-md-0'}>
                                <StyledBadge bg="light" text="dark">
                                    {this.getLabelActiveBins()}
                                    <a 
                                        className={(this.props.binCenterTable.isLoading || this.props.binCenterTableLive.isLoading) ? 'link-default-icon cursor-not-allowed' : 'link-danger-icon'}
                                        href={'/'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            
                                            this.clearActiveBins();
                                        }}
                                    >
                                        <i className={'material-icons'}>close</i>
                                    </a>
                                </StyledBadge>
                            </Col>}

                            {this.hasInOut() && <Col xs={'auto'} className={'pb-2 pb-md-0'}>
                                <StyledBadge bg="light" text="dark">
                                    {this.getLabeInOut()}
                                    <a 
                                        className={(this.props.binCenterTable.isLoading || this.props.binCenterTableLive.isLoading) ? 'link-default-icon cursor-not-allowed' : 'link-danger-icon'}
                                        href={'/'}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            
                                            this.clearInOut();
                                        }}
                                    >
                                        <i className={'material-icons'}>close</i>
                                    </a>
                                </StyledBadge>
                            </Col>}

                        </Row>
                    </Col>
                    }

                </Row>
            </Col>

            <Col xs={true} className={''}></Col>

            {!this.props.binCenterLive.isLive && <Col xs={'auto'} className={'text-center'}>
                <Dropdown as={ButtonGroup}>
                    <Button
                        className={'px-3'}
                        size={"sm"}
                        onClick={() => {
                            this.props.action.binCenterBinNumberPopup.setShow({ id: null });
                        }}
                    >Add New Bin</Button>
                    <Dropdown.Toggle split className={'px-5'} size={"sm"} />
                    <Dropdown.Menu>
                        <Dropdown.Item
                            active={false}
                            onClick={() => {
                                this.props.action.binCenterImport.show();
                            }}
                        >Import Bins</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>}
            {(!this.props.binCenterLive.isLive && !this.state.isDisabledBatchAction) && <Col xs={'auto'} className={'text-center'}>
                <Dropdown alignRight>
                    <Dropdown.Toggle size={"sm"}>
                        <span className={'px-3'}>Batch Action</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            active={false}
                            onClick={async () => {
                                let selected = '';
                                if (this.tableRef && this.tableRef.current) {
                                    let selectedArr = this.tableRef.current.getSelectedRows();
                                    if (selectedArr && selectedArr.length > 0) {
                                        selected = selectedArr.map(x => x.binNumberId).join(',');
                                    }

                                    await this.props.action.signalRslice.connect({
                                        callback: (percent) => {
                                            this.props.action.signalRslice.onPercentage(percent);
                                        },
                                        callbackInit: (hubId) => {
                                            let isActive = true;
                                            this.props.action.binCenter.callActivateDraftsApi({ ids: selected, isActive: isActive, hubId: this.props.signalRslice.hubId }, (state: boolean, data: any) => {
                                                if(state){
                                                    Utils.toast('Successfully saved!', 'success');
                                                    this.tableRef.current.setSelected();

                                                    this.callReadApi();
                                                    this.callReadStatsApi();
                                                }
                                                
                                                this.props.action.signalRslice.disconnect();
                                            });
                                        }
                                    });
                                }
                            }} 
                        >Save Bin</Dropdown.Item>
                        <Dropdown.Item
                            active={false}
                            onClick={() => {
                                let selected = '';
                                if (this.tableRef && this.tableRef.current) {
                                    let selectedArr = this.tableRef.current.getSelectedRows();
                                    if (selectedArr && selectedArr.length > 0) {
                                        selected = selectedArr.map(x => x.binNumberId).join(',');
                                    }

                                    this.props.action.reduxRemovePopupSlice.setShow({ item: selected });
                                }
                            }} 
                        ><span className={'text-danger'}>Delete Bin</span></Dropdown.Item>
                    </Dropdown.Menu>
                    </Dropdown>
            </Col>}
            {!this.props.binCenterLive.isLive && <Col xs={'auto'} className={'text-center'}>
                <Dropdown>
                    <Dropdown.Toggle as={DropdownToggle} isButton className={'px-3'} variant={"light"} size={"sm"}>
                        <SettingsOutlinedIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            active={false}
                            onClick={() => {
                                this.callExportApi();
                            }}
                        >Export File</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>}
        </Row>
    }


    tableSection = () => {
        return <DataTableScrollSync className={"table-sync-v4 with-details"}>{this.setDataTable()}</DataTableScrollSync>
    }

    setDataTable = () => {
        return <DataTable
            ref={this.tableRef}

            className={"table-style-v4"}
            classNameWrapper={"table-v4"}

            pageName={this.pageName}
            rowId={'customerSiteBinHistoryId'}
            caption={'Bin Center'}

            noMarginBottom={false}
            verticalAlign={'middle'}

            stickyHeader={false}
            isHeader={true}
            resizable={false}
            isDragableHeaderColumns={false}
            isDragable={false}
            isCheckbox={(this.props.binCenterTable.isValidated == false)}
            isBorder={false}
            isPagination={false}
            isVirtualize={true}

            columnVisibility={this.props.binCenterTable.columnVisibility}
            disabledResizeColumns={[0]}

            isLoading={this.props.binCenterTable.isLoading}
            rowsPerPageSelected={this.props.binCenterTable.rowsPerPageSelected}
            rowsPerPageOptions={this.props.binCenterTable.rowsPerPageOptions}

            search={this.props.binCenterTable.search}
            rows={this.props.binCenterTable.rows}
            columns={this.props.binCenterTable.columns}

            onRowClick={(id, row) => {
                
            }}

            onRemove={(row) => {

            }}

            onClickCheckAll={(obj, checked, isDisabledBatchAction) => {
                this.setState({
                    isDisabledBatchAction: isDisabledBatchAction
                });
            }}
            onCheckRow={(obj, id, isDisabledBatchAction) => {
                this.setState({
                    isDisabledBatchAction: isDisabledBatchAction
                });
            }}

            onSortEnd={(columns, columnVisibility) => {
                
            }}
            onOrder={async (name, sort) => {
                await this.props.action.binCenterTable.setSortParams({
                    sortColumn: name,
                    sortDir: sort,
                });
                this.callReadApi();
            }}
            onResize={(columns) => {
                
            }}
        />
    }

    paginationSection = () => {
		return <DataTableAjaxPagination
            page={this.props.binCenterTable.currentPage}
            selected={this.props.binCenterTable.rowsPerPageSelected}
            count={this.props.binCenterTable.totalRows}
            fixed={true}
            onPageChange={async (page) => {
                Utils.scrollToEl('.global-service-page');
                await this.props.action.binCenterTable.setCurrentPage(page);
                this.callReadApi();
            }}
            onOptionsChange={async (option) => {
                Utils.scrollToEl('.global-service-page');
                await this.props.action.binCenterTable.setPerPageSelected(option);
                await this.props.action.binCenterTable.tableSetFilters({
                  auth: this.props.auth,
                  settingsType: this.props.settingsType,
                  pageName: this.pageName,
                });
                this.callReadApi();
            }}
        />
	}


    tableLiveSection = () => {
        return <DataTableScrollSync className={"table-sync-v4 with-details"}>{this.setDataTableLive()}</DataTableScrollSync>
    }

    setDataTableLive = () => {
        return <DataTable
            className={"table-style-v4"}
            classNameWrapper={"table-v4"}

            pageName={this.pageName}
            // rowId={'customerSiteBinHistoryId'}
            caption={'Bin Center'}

            noMarginBottom={false}
            verticalAlign={'middle'}

            stickyHeader={false}
            isHeader={true}
            resizable={false}
            isDragableHeaderColumns={false}
            isDragable={false}
            isCheckbox={false}
            isBorder={false}
            isPagination={false}
            isVirtualize={true}

            columnVisibility={this.props.binCenterTableLive.columnVisibility}
            disabledResizeColumns={[]}

            isLoading={this.props.binCenterTableLive.isLoading}
            rowsPerPageSelected={this.props.binCenterTable.rowsPerPageSelected}
            rowsPerPageOptions={this.props.binCenterTableLive.rowsPerPageOptions}

            search={this.props.binCenterTableLive.search}
            rows={this.props.binCenterTableLive.rows}
            columns={this.props.binCenterTableLive.columns}

            onRowClick={(id, row) => {
                
            }}

            onRemove={(row) => {

            }}

            onClickCheckAll={(obj, checked, isDisabledBatchAction) => {
                
            }}
            onCheckRow={(obj, id, isDisabledBatchAction) => {
                
            }}

            onSortEnd={(columns, columnVisibility) => {
                
            }}
            onOrder={async (name, sort) => {
                await this.props.action.binCenterTableLive.setSortParams({
                    sortColumn: name,
                    sortDir: sort,
                });
                this.callReadApi();
            }}
            onResize={(columns) => {
                
            }}
        />
    }

    paginationLiveSection = () => {
		return <DataTableAjaxPagination
            page={this.props.binCenterTableLive.currentPage}
            selected={this.props.binCenterTable.rowsPerPageSelected}
            count={this.props.binCenterTableLive.totalRows}
            fixed={true}
            onPageChange={async (page) => {
                Utils.scrollToEl('.global-service-page');
                await this.props.action.binCenterTableLive.setCurrentPage(page);
                this.callReadApi();
            }}
            onOptionsChange={async (option) => {
                Utils.scrollToEl('.global-service-page');
                await this.props.action.binCenterTable.setPerPageSelected(option);
                await this.props.action.binCenterTable.tableSetFilters({
                  auth: this.props.auth,
                  settingsType: this.props.settingsType,
                  pageName: this.pageName,
                });
                this.callReadApi();
            }}
        />
	}
    /* END TABLE */


    /* POPUP */
    setAuditTrailDialog = () => {
        return <AuditTrailDialog 
          isOpen={this.state.isOpenAuditTrail}
          objectId={this.state.objectId}
          auditTrailObjectType={AuditTrailObjectType.BinNumber}
          title={"Bin Number Audit Trail"}
          onClose={() => {
            this.setState({
              isOpenAuditTrail: false,
              objectId: null,
            });
          }}
          details={(obj, index, currentVersion, previousVersion) => {
            return AuditTrailFunctions.getDetails(AuditTrailObjectType.BinNumber, obj, index, currentVersion, previousVersion);
          }}
          manageData={(currentVersion, previousVersion, callback) => {
            return AuditTrailFunctions.getManageData(AuditTrailObjectType.BinNumber, currentVersion, previousVersion, callback);
          }}
        />
    }
    /* END POPUP */


    render() {
        return <div className={'global-service-page'}>
            <ViewLayout
                section1={this.sectionBinCountByType()}
                section2={this.sectionActiveInactive()}
                section3={this.sectionActiveBins()}
                section4={this.sectionInOut()}
                section5={this.sectionActivitiesBins()}
                tableHeaderSection={this.tableHeaderSection()}
                tableSection={this.tableSection}
                paginationSection={this.paginationSection()}
                tableLiveSection={this.tableLiveSection}
                paginationLiveSection={this.paginationLiveSection()}
            />
            
            <ImportPopup />

            <MergePopup
                title={'Merge to Bin ID'}
                cancel={'Cancel'}
                save={'Merge'}
                onCancel={async () => {
                    
                }}
                onSave={async (data) => {
                    this.props.action.binCenter.callMergeApi(data, (state: boolean, data: any) => {
                        if(state){
                            this.callReadApi();
                            this.callReadStatsApi();
                            this.props.action.binCenterMergePopup.setHide();
                            this.props.action.reduxDeletePopupSlice.setHide()
                            this.props.action.binCenterDetailsPanel.show({ show: false });
                        }
                    });
                }}
            />

            <BinNumberPopup
                title={'Bin ID'}
                cancel={'Cancel'}
                save={'Save'}
                onCancel={async () => {
                    
                }}
                onCreate={async (data) => {
                    this.callReadApi();
                    this.callReadStatsApi();
                }}
                onUpdate={async (data) => {
                    this.callReadApi();
                    this.callReadStatsApi();
                    
                    this.props.action.binCenterDetailsPanel.callDetailsApi(this.props.binCenterDetailsPanel.id, (state: boolean, data: any) => {});
                }}
                onUpdateError={async (data) => {
                    this.props.action.reduxPopupSlice.setShow({ show: true, item: data });
                }}
                onReload={() => {
                    this.props.action.binCenterDetailsPanel.callDetailsApi(this.props.binCenterDetailsPanel.id, (state: boolean, data: any) => {});
                }}
            />

            <DetailsPanel
                onReload={() => {
                    this.props.action.binCenterDetailsPanel.show({ show: false });
                    this.callReadApi();
                    this.callReadStatsApi();
                }}
                onAuditTrail={(id) => {
                    this.setState({
                        isOpenAuditTrail: true,
                        objectId: id,
                    });
                }}
            />

            <ReduxDeletePopup
                title={'Delete'}
                body={'Are you sure you want to delete?'}
                cancel={'Cancel'}
                save={'Yes, please delete'}
                loading={this.props.binCenter.isLoading}
                onCancel={async () => {
                    
                }}
                onSave={async () => {
                    if(this.props.reduxDeletePopupSlice.item){
                        this.props.action.binCenter.callDeleteApi({ binNumberId: this.props.reduxDeletePopupSlice.item }, (state: boolean, data: any) => {
                            if(state){
                                this.callReadApi();
                                this.callReadStatsApi();
                                this.props.action.reduxDeletePopupSlice.setHide()
                                this.props.action.binCenterDetailsPanel.show({ show: false });
                            }
                        });
                    }
                }}
            />
            
            <ReduxRemovePopup
                title={'Delete'}
                body={'Are you sure you want to remove?'}
                cancel={'Cancel'}
                save={'Yes, please delete'}
                loading={this.props.binCenter.isLoading}
                onCancel={async () => {
                    
                }}
                onSave={async () => {
                    if(this.props.reduxRemovePopupSlice.item){
                        let selected = this.props.reduxRemovePopupSlice.item;

                        await this.props.action.signalRslice.connect({
                            callback: (percent) => {
                                this.props.action.signalRslice.onPercentage(percent);
                            },
                            callbackInit: (hubId) => {
                                let isActive = true;
                                this.props.action.binCenter.callDeleteDraftsApi({ ids: selected, isActive: isActive, hubId: this.props.signalRslice.hubId }, (state: boolean, data: any) => {
                                    if(state){
                                        Utils.toast("Successfully deleted!", 'success'); 
                                        this.tableRef.current.setSelected();

                                        this.callReadApi();
                                        this.callReadStatsApi();
                                        this.props.action.reduxRemovePopupSlice.setHide()
                                    }
                                    
                                    this.props.action.signalRslice.disconnect();
                                });
                            }
                        });
                    }
                }}
            />
            
            <ReduxConfirmPopup
                title={'Mark as collected'}
                body={'Are you sure you want to mark as collected?'}
                cancel={'No'}
                save={'Yes'}
                loading={false}
                onCancel={async () => {
                    
                }}
                onSave={async () => {
                    if(this.props.reduxConfirmPopupSlice.item){
                        let item = this.props.reduxConfirmPopupSlice.item;

                        if(item){
                            this.props.action.binCenter.callMarkAsCollectedApi(item?.params, (state: boolean, data: any) => {
                                if(state){
                                    this.props.action.binCenterDetailsPanel.callDetailsApi(item?.id, (state: boolean, data: any) => {});
                                    this.props.action.binCenterDetailsPanel.callDetailsHistoryFirstTimeApi({
                                        binNumberId: item?.id,
                                        currentPage: 1,
                                        pageSize: 10,
                                        searchQuery: item?.search,
                                        sortColumn: 'updated',
                                        sortDir: 'desc',
                                    }, (state: boolean, data: any) => {});
                                    
                                    this.callReadApi();
                                    this.callReadStatsApi();
                                    
                                    this.props.action.reduxConfirmPopupSlice.setHide()
                                    this.props.action.binCenterDetailsPanel.show({ show: false });
                                }
                            });
                        }
                    }
                }}
            />

            <ReduxPopup
                title={'Merge to Bin ID'}
                body={this.props.reduxPopupSlice.item?.error}
                cancel={'No'}
                save={'Yes'}
                saveVariant={'danger'}
                dialogClassName={'mw-30-vw'}
                isHtml={false}
                loading={false}
                onCancel={async () => {
                    
                }}
                onSave={async () => {
                    let item = this.props.reduxPopupSlice.item;

                    if(item){
                        let data = {
                            mergeToBinNumberId: item.mergeToBinNumberId,
                            binNumberId: item.binNumberId,
                        }
                        this.props.action.binCenter.callMergeApi(data, (state: boolean, data: any) => {
                            if(state){
                                this.callReadApi();
                                this.callReadStatsApi();
            
                                this.props.action.reduxPopupSlice.setHide()
                                this.props.action.binCenterBinNumberPopup.setHide();
                                this.props.action.binCenterDetailsPanel.show({ show: false });
                            }
                        });
                    }

                }}
            />

            {this.setAuditTrailDialog()}

            {(this.props.binCenter.isLoading || this.props.binCenterBinNumberPopup.isLoading) && <StyledBlockerLoading>
                <StyledOverlay />
                <Spinner animation="border" variant="default" />
            </StyledBlockerLoading>}
        </div>
    }
}


const mapStateToProps = state => {
    return {
      ...state,
      ...Utils.mapStateToProps(state),
    };
};
const mapDispatchToProps = (dispatch) => ({
    dispatchApiCallGet: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallGet(p1, p2, p3, p4, p5, p6)),
    dispatchApiCallPost: (p1, p2, p3, p4, p5, p6, p7) => dispatch(dispatchApiCallPost(p1, p2, p3, p4, p5, p6, p7)),
    dispatchApiCallPut: (p1, p2, p3, p4, p5, p6, p7) => dispatch(dispatchApiCallPut(p1, p2, p3, p4, p5, p6, p7)),
    clear: (payload) => dispatch(clear(payload)),
    action: {
      binCenter: {
        callBinCountByTypeApi: (payload, callback) => dispatch(binCenter.callBinCountByTypeApi(payload, callback)),
        callActiveInactiveApi: (payload, callback) => dispatch(binCenter.callActiveInactiveApi(payload, callback)),
        callActiveBinsApi: (payload, callback) => dispatch(binCenter.callActiveBinsApi(payload, callback)),
        callInOutApi: (payload, callback) => dispatch(binCenter.callInOutApi(payload, callback)),
        callActivitiesBinsApi: (payload, callback) => dispatch(binCenter.callActivitiesBinsApi(payload, callback)),
        callDeleteApi: (payload, callback) => dispatch(binCenter.callDeleteApi(payload, callback)),
        callMergeApi: (payload, callback) => dispatch(binCenter.callMergeApi(payload, callback)),
        callDownloadExcelApi: (payload, callback) => dispatch(binCenter.callDownloadExcelApi(payload, callback)),
        callDeleteDraftsApi: (payload, callback) => dispatch(binCenter.callDeleteDraftsApi(payload, callback)),
        callActivateDraftsApi: (payload, callback) => dispatch(binCenter.callActivateDraftsApi(payload, callback)),
        callMarkAsCollectedApi: (payload, callback) => dispatch(binCenter.callMarkAsCollectedApi(payload, callback)),
      },
      binCenterTable: {
        tableGetFilters: (payload) => dispatch(binCenterTable.tableGetFilters(payload)),
        tableSetFilters: (payload) => dispatch(binCenterTable.tableSetFilters(payload)),
        setColumns: (payload) => dispatch(binCenterTable.setColumns(payload)),
        setColumnsVisibility: (payload) => dispatch(binCenterTable.setColumnsVisibility(payload)),
        setSearchParams: (payload) => dispatch(binCenterTable.setSearchParams(payload)),
        setSortParams: (payload) => dispatch(binCenterTable.setSortParams(payload)),
        setCurrentPage: (payload) => dispatch(binCenterTable.setCurrentPage(payload)),
        setPerPageSelected: (payload) => dispatch(binCenterTable.setPerPageSelected(payload)),
        setClearFilter: (payload) => dispatch(binCenterTable.setClearFilter(payload)),
        callReadApi: (payload, callback) => dispatch(binCenterTable.callReadApi(payload, callback)),
        setBinTypeId: (payload, callback) => dispatch(binCenterTable.setBinTypeId(payload, callback)),
        setActiveFilter: (payload, callback) => dispatch(binCenterTable.setActiveFilter(payload, callback)),
        setIsActive: (payload, callback) => dispatch(binCenterTable.setIsActive(payload, callback)),
        setBinStatus: (payload, callback) => dispatch(binCenterTable.setBinStatus(payload, callback)),
        setIsValidated: (payload, callback) => dispatch(binCenterTable.setIsValidated(payload, callback)),
      },
      binCenterTableLive: {
        setColumns: (payload) => dispatch(binCenterTableLive.setColumns(payload)),
        setColumnsVisibility: (payload) => dispatch(binCenterTableLive.setColumnsVisibility(payload)),
        setSearchParams: (payload) => dispatch(binCenterTableLive.setSearchParams(payload)),
        setSortParams: (payload) => dispatch(binCenterTableLive.setSortParams(payload)),
        setCurrentPage: (payload) => dispatch(binCenterTableLive.setCurrentPage(payload)),
        setPerPageSelected: (payload) => dispatch(binCenterTableLive.setPerPageSelected(payload)),
        setClearFilter: (payload) => dispatch(binCenterTableLive.setClearFilter(payload)),
        callReadApi: (payload, callback) => dispatch(binCenterTableLive.callReadApi(payload, callback)),
        setBinStatusFilter: (payload, callback) => dispatch(binCenterTableLive.setBinStatusFilter(payload, callback)),
      },
      binCenterDetailsPanel: {
        show: (payload) => dispatch(binCenterDetailsPanel.show(payload)),
        callDetailsApi: (payload, callback) => dispatch(binCenterDetailsPanel.callDetailsApi(payload, callback)),
        callDetailsHistoryFirstTimeApi: (payload, callback) => dispatch(binCenterDetailsPanel.callDetailsHistoryFirstTimeApi(payload, callback)),
      },
      binCenterImport: {
        show: (payload) => dispatch(binCenterImport.show(payload)),
      },
      binCenterLive: {
        setLive: (payload) => dispatch(binCenterLive.setLive(payload)),
        getCookies: (payload) => dispatch(binCenterLive.getCookies(payload)),
        selectedValue: (payload) => dispatch(binCenterLive.selectedValue(payload)),
        updateTimeLeft: (payload) => dispatch(binCenterLive.updateTimeLeft(payload)),
        resetTimeLeft: (payload) => dispatch(binCenterLive.resetTimeLeft(payload)),
      },
      reduxDeletePopupSlice: {
        setHide: (payload) => dispatch(reduxDeletePopupSlice.setHide(payload)),
      },
      reduxRemovePopupSlice: {
        setShow: (payload) => dispatch(reduxRemovePopupSlice.setShow(payload)),
        setHide: (payload) => dispatch(reduxRemovePopupSlice.setHide(payload)),
      },
      reduxConfirmPopupSlice: {
        setShow: (payload) => dispatch(reduxConfirmPopupSlice.setShow(payload)),
        setHide: (payload) => dispatch(reduxConfirmPopupSlice.setHide(payload)),
      },
      reduxPopupSlice: {
        setShow: (payload) => dispatch(reduxPopupSlice.setShow(payload)),
        setHide: (payload) => dispatch(reduxPopupSlice.setHide(payload)),
      },
      binCenterMergePopup: {
        setHide: (payload) => dispatch(binCenterMergePopup.setHide(payload)),
      },
      binCenterBinNumberPopup: {
        setShow: (payload) => dispatch(binCenterBinNumberPopup.setShow(payload)),
        setHide: (payload) => dispatch(binCenterBinNumberPopup.setHide(payload)),
      },
      signalRslice: {
        connect: (payload) => dispatch(signalRslice.connect(payload)),
        disconnect: (payload) => dispatch(signalRslice.disconnect(payload)),
        onPercentage: (payload) => dispatch(signalRslice.onPercentage(payload)),
      },
      cancelToken: {
        cancel: (payload) => dispatch(cancelToken.cancel(payload)),
      },
    }
});
  
  
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BinCenterList);