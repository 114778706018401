import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from './auth/saga'

import * as commonProfileCrud from './commonProfile/reducer'
import commonProfileCrudSaga from './commonProfile/saga'
import * as commonProfileCrudApi from '../../app/api/CRUD/CommonProfileCRUD'

import * as dispatchCrud from './dispatch/reducer'
import dispatchCrudSaga from './dispatch/saga'
import * as dispatchCrudApi from '../../app/api/CRUD/DispatchCRUD'

import cancelToken from './slices/cancelToken'
import dateSection from './slices/dateSection'
import tableSection from './slices/tableSection'
import jobStatuses from './slices/jobStatuses'
import realTimeUpdate from './slices/realTimeUpdate'
import checkboxCount from './slices/checkboxCount'
import taxRate from './slices/taxRate'

import billingDefault from '../../app/views/billings/redux/billingDefault'
import billingInvoiceStatusSection from '../../app/views/billings/redux/billingInvoiceStatusSection'
import billingQuickbookXero from '../../app/views/billings/redux/billingQuickbookXero'
import billingBatchAction from '../../app/views/billings/redux/billingBatchAction'
import billing from '../../app/views/billings/redux/billing'
import billingRefresh from '../../app/views/billings/redux/billingRefresh'
import invoiceNumber from '../../app/views/billings/redux/invoiceNumber'
import invoiceNumberForm from '../../app/views/billings/redux/invoiceNumberForm'
import invoiceThisJob from '../../app/views/billings/redux/invoiceThisJob'
import invoiceNumberBatch from '../../app/views/billings/redux/invoiceNumberBatch'

import fileInputButtonSlice from '../../app/components/Buttons/fileInputButton/FileInputButtonSlice';
import signalRslice from '../../app/components/signalR/signalRslice';
import geofenceMapFormSlice from '../../app/components/geofence/geofenceMapFormSlice';
import geofenceMapViewSlice from '../../app/components/geofence/geofenceMapViewSlice';
import reduxPopupSlice from '../../app/components/popup/reduxPopupSlice';
import reduxDeletePopupSlice from '../../app/components/popup/reduxDeletePopupSlice';
import reduxRemovePopupSlice from '../../app/components/popup/reduxRemovePopupSlice';
import reduxConfirmPopupSlice from '../../app/components/popup/reduxConfirmPopupSlice';

import publicIframe from '../../app/views/jobs/redux/publicIframe'
import importWeighChit from '../../app/views/jobs/redux/importWeighChit'
import advancedMappingXero from '../../app/views/Manage/Company/redux/advancedMappingXero'
import advancedMappingQuickBook from '../../app/views/Manage/Company/redux/advancedMappingQuickBook'
import weighChitCustomFields from '../../app/views/Manage/Company/redux/weighChitCustomFields'
import notifications from '../../app/views/Manage/Company/redux/notifications'
import refreshWeighChit from '../../app/views/jobs/redux/refreshWeighChit'
import payrollBetaLayout from '../../app/views/Manage/Payroll/redux/payrollBetaLayout'
import payrollBetaForm from '../../app/views/Manage/Payroll/redux/payrollBetaForm'
import payrollBetaCopyJobTypes from '../../app/views/Manage/Payroll/redux/payrollBetaCopyJobTypes'
import payrollBetaCopyGeofence from '../../app/views/Manage/Payroll/redux/payrollBetaCopyGeofence'
import driversDetails from '../../app/views/Manage/Drivers/redux/driversDetails'
import bulkUpdateJobForm from '../../app/views/jobs/redux/bulkUpdateJobForm'
import binCenter from '../../app/views/binCenter/redux/binCenter'
import binCenterTable from '../../app/views/binCenter/redux/binCenterTable'
import binCenterTableLive from '../../app/views/binCenter/redux/binCenterTableLive'
import binCenterDetailsPanel from '../../app/views/binCenter/redux/binCenterDetailsPanel'
import binCenterImport from '../../app/views/binCenter/redux/binCenterImport'
import binCenterLive from '../../app/views/binCenter/redux/binCenterLive'
import binCenterMergePopup from '../../app/views/binCenter/redux/binCenterMergePopup'
import binCenterBinNumberPopup from '../../app/views/binCenter/redux/binCenterBinNumberPopup'
import driverTrackingSlice from '../../app/views/jobs/redux/driverTrackingSlice'
import ganttChartSlice from '../../app/views/jobs/GanttChart/slice'
import ganttChartFiltersSlice from '../../app/views/jobs/GanttChart/Filters/slice'
import ganttChartDriversSlice from '../../app/views/jobs/GanttChart/Drivers/slice'
import ganttChartTaskSlice from '../../app/views/jobs/GanttChart/Task/slice'
import ganttChartMultipleTripsSlice from '../../app/views/jobs/GanttChart/MultipleTrips/slice'
import ganttChartJobDetailsSlice from '../../app/views/jobs/GanttChart/JobDetails/slice'
import ganttChartUnsetTimeSlice from '../../app/views/jobs/GanttChart/UnsetTime/slice'
import ganttChartUnassignedSlice from '../../app/views/jobs/GanttChart/Unassigned/slice'
import ganttChartRouteMapSlice from '../../app/views/jobs/GanttChart/RouteMap/slice'
import mapSlice from '../../app/views/map/slice'

import customerMiniSlice from '../../app/views/jobs/Customer/slice'
import customerSiteMiniSlice from '../../app/views/jobs/CustomerSite/slice'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  commonProfileCrud: commonProfileCrud.reducer,
  dispatchCrud: dispatchCrud.reducer,
  
  cancelToken: cancelToken.reducer,
  dateSection: dateSection.reducer,
  tableSection: tableSection.reducer,
  jobStatuses: jobStatuses.reducer,
  realTimeUpdate: realTimeUpdate.reducer,
  checkboxCount: checkboxCount.reducer,
  taxRate: taxRate.reducer,
  
  billingDefault: billingDefault.reducer,
  billingInvoiceStatusSection: billingInvoiceStatusSection.reducer,
  billingQuickbookXero: billingQuickbookXero.reducer,
  billingBatchAction: billingBatchAction.reducer,
  billing: billing.reducer,
  billingRefresh: billingRefresh.reducer,
  invoiceNumber: invoiceNumber.reducer,
  invoiceNumberForm: invoiceNumberForm.reducer,
  invoiceThisJob: invoiceThisJob.reducer,
  invoiceNumberBatch: invoiceNumberBatch.reducer,

  fileInputButtonSlice: fileInputButtonSlice.reducer,
  signalRslice: signalRslice.reducer,
  geofenceMapFormSlice: geofenceMapFormSlice.reducer,
  geofenceMapViewSlice: geofenceMapViewSlice.reducer,
  reduxPopupSlice: reduxPopupSlice.reducer,
  reduxDeletePopupSlice: reduxDeletePopupSlice.reducer,
  reduxRemovePopupSlice: reduxRemovePopupSlice.reducer,
  reduxConfirmPopupSlice: reduxConfirmPopupSlice.reducer,

  publicIframe: publicIframe.reducer,
  importWeighChit: importWeighChit.reducer,
  advancedMappingXero: advancedMappingXero.reducer,
  advancedMappingQuickBook: advancedMappingQuickBook.reducer,
  weighChitCustomFields: weighChitCustomFields.reducer,
  notifications: notifications.reducer,
  refreshWeighChit: refreshWeighChit.reducer,
  payrollBetaLayout: payrollBetaLayout.reducer,
  payrollBetaForm: payrollBetaForm.reducer,
  payrollBetaCopyJobTypes: payrollBetaCopyJobTypes.reducer,
  payrollBetaCopyGeofence: payrollBetaCopyGeofence.reducer,
  driversDetails: driversDetails.reducer,
  bulkUpdateJobForm: bulkUpdateJobForm.reducer,
  binCenter: binCenter.reducer,
  binCenterTable: binCenterTable.reducer,
  binCenterTableLive: binCenterTableLive.reducer,
  binCenterDetailsPanel: binCenterDetailsPanel.reducer,
  binCenterImport: binCenterImport.reducer,
  binCenterLive: binCenterLive.reducer,
  binCenterMergePopup: binCenterMergePopup.reducer,
  binCenterBinNumberPopup: binCenterBinNumberPopup.reducer,
  driverTrackingSlice: driverTrackingSlice.reducer,
  ganttChartSlice: ganttChartSlice.reducer,
  ganttChartFiltersSlice: ganttChartFiltersSlice.reducer,
  ganttChartDriversSlice: ganttChartDriversSlice.reducer,
  ganttChartTaskSlice: ganttChartTaskSlice.reducer,
  ganttChartMultipleTripsSlice: ganttChartMultipleTripsSlice.reducer,
  ganttChartJobDetailsSlice: ganttChartJobDetailsSlice.reducer,
  ganttChartUnsetTimeSlice: ganttChartUnsetTimeSlice.reducer,
  ganttChartUnassignedSlice: ganttChartUnassignedSlice.reducer,
  ganttChartRouteMapSlice: ganttChartRouteMapSlice.reducer,
  mapSlice: mapSlice.reducer,
  
  customerMiniSlice: customerMiniSlice.reducer,
  customerSiteMiniSlice: customerSiteMiniSlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    auth.saga(),
    commonProfileCrudSaga(commonProfileCrudApi),
    dispatchCrudSaga(dispatchCrudApi),
  ])
}
