import React from 'react';
import { useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";

import {
  Row,
  Col,
  Button,
  Form,
  DropdownButton,
  ButtonGroup,
  Dropdown,
  Badge,
} from 'react-bootstrap';
import styled from 'styled-components';

import { Spin } from "antd";

import Carousel from 'react-elastic-carousel';

import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import updateLocale from 'dayjs/plugin/updateLocale';


// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../../setup'
import slice, { DATE_FORMAT, isTodayDate } from './slice';
import { getFilterCount } from './Filters/slice';

import MultipleTripsList from './MultipleTrips/List';
// import multipleTripsSlice from './MultipleTrips/slice';

import JobDetailsList from './JobDetails/List';
// import jobDetailsSlice from './JobDetails/slice';

import RouteMapList from './RouteMap/List';
// import routeMapSlice from './RouteMap/slice';

import UnsetTimeList from './UnsetTime/List';
// import unsetTimeSlice from './UnsetTime/slice';

import UnassignedList from './Unassigned/List';
import unassignedSlice from './Unassigned/slice';

import BulkUpdateJobForm from "../Dialogs/BulkUpdateJobForm";
// import bulkUpdateJobForm from "../redux/bulkUpdateJobForm";

// enums
import Utils from '../../../utils/utils';
// import {  } from '../../../utils/enums';

import DailyListComponent, { GanttTablePeriod, GanttTableJobInfo, GanttType } from './DailyList';
import WeeklyListComponent from './WeeklyList';
import MonthlyListComponent from './MonthlyList';

import { ReactComponent as BarChart2Icon } from "../../../../_metronic/assets/icons/bar-chart-2.svg";
import { ReactComponent as CalendarIcon } from "../../../../_metronic/assets/icons/calendar.svg";
import { ReactComponent as FilterIcon } from "../../../../_metronic/assets/icons/filter.svg";
import { ReactComponent as SearchIcon } from "../../../../_metronic/assets/icons/search.svg";
import { ReactComponent as SettingsIcon } from "../../../../_metronic/assets/icons/settings.svg";
import { ReactComponent as LeftIcon } from "../../../../_metronic/assets/icons/gantt-left.svg";
import { ReactComponent as RightIcon } from "../../../../_metronic/assets/icons/gantt-right.svg";

// ----------------------------------------------------------------------


dayjs.extend(updateLocale);
dayjs.updateLocale('en', { weekStart: 1 });


const StyledCol = styled(Col)`
  position: sticky;
  top: 65px;
  background-color: white;
  z-index: 5;

  @media (max-width: 767px) {
    position: relative;
    top: unset;
  }
`;


// ----------------------------------------------------------------------


export const LegendList = () => {
  return <Row className='align-items-center'>
    <Col xs={'auto'} className={'position-relative'}><div className='dot dot-assigned'></div>Assigned</Col>
    <Col xs={'auto'} className={'position-relative'}><div className='dot dot-dispatched'></div>Dispatched</Col>
    <Col xs={'auto'} className={'position-relative'}><div className='dot dot-started'></div>Started</Col>
    <Col xs={'auto'} className={'position-relative'}><div className='dot dot-inprogress'></div>In Progress</Col>
    <Col xs={'auto'} className={'position-relative'}><div className='dot dot-completed'></div>Completed</Col>
    <Col xs={'auto'} className={'position-relative'}><div className='dot dot-cancelled'></div>Cancelled/Failed</Col>
  </Row>
}


const PageComponent = () => {
  let history = useHistory();

  const refDailyList = React.useRef<any>(null);
  const refUnassignedlist = React.useRef<any>(null);

  const { isLoadingDaily, isLoadingWeekly, isLoadingMonthly, showActualJobTime, period, selectedDate, jobInfo, isLoadingDailyStatus, statusDaily, search, tasksDaily, isLoadingDispatchAll, allData } = useSelector((state: RootState) => state.ganttChartSlice);
  const { details } = useSelector((state: RootState) => state.ganttChartFiltersSlice);
  const dispatch = useDispatch<any>();
  
  const [show, setShow] = React.useState(true);
  const [searchValue, setSearchValue] = React.useState(search);
  

  const debouncedSearch = React.useCallback(
    _.debounce((callback) => {
      if(callback){
        callback()
      }
    }, 500),
    []
  );


  const dispatchAll = () => {
    let arr: any = [];
    if(allData && allData.length > 0){
      allData.forEach((driver: any) => {
        if(driver && driver.jobs && driver.jobs.length > 0){
          driver.jobs.forEach((job: any) => {
            if(job.jobStatusId == statusDaily?.assigned?.jobStatusId){
              arr.push(job.jobId);
            }
          });
        }
      });
    }

    if(arr && arr.length > 0){
      let jobIds = arr.join(',');
        
      let params = {
        jobIds: jobIds,
        jobStatusId: statusDaily?.dispatched?.jobStatusId,
      };
      dispatch(slice.callDispatchAllApi(params, (state: boolean, data: any) => {
        if(state){
          if(data && data.successJobs && data.successJobs.length > 0){
            if(refDailyList && refDailyList.current){
              refDailyList.current.updateTasksStatus(data.successJobs.map((x: any) => x.job));
            }
          }

          if(refDailyList && refDailyList.current){
            refDailyList.current.callAPI();
          }
        }
      }));
    } else {
      Utils.toast('There are no assigned jobs', 'error');
    }

    // if(tasksDaily && tasksDaily.length > 0){
    //   let tasks = tasksDaily.filter((x: any) => x.type == GanttType.Task);
    //   let assignedTasks = tasks.filter((x: any) => x.data.jobStatusId == statusDaily?.assigned?.jobStatusId);
      
    //   if(assignedTasks && assignedTasks.length > 0){
    //     let jobIds = assignedTasks.map((x: any) => x.data.jobId).join(',');
        
    //     let params = {
    //       jobIds: jobIds,
    //       jobStatusId: statusDaily?.dispatched?.jobStatusId,
    //     };
    //     dispatch(slice.callDispatchAllApi(params, (state: boolean, data: any) => {
    //       if(state){
    //         if(data && data.successJobs && data.successJobs.length > 0){
    //           if(refDailyList && refDailyList.current){
    //             refDailyList.current.updateTasksStatus(data.successJobs.map((x: any) => x.job));
    //           }
    //         }

    //         if(refDailyList && refDailyList.current){
    //           refDailyList.current.callAPI();
    //         }
    //       }
    //     }));
    //   } else {
    //     Utils.toast('There are no assigned jobs', 'error');
    //   }
    // } else {
    //   Utils.toast('There are no jobs in list', 'error');
    // }
  }


  const topSection = () => {
    return <Row className='align-items-center g-4'>
      <Col xs={12} md={'auto'} className={'title'}>Drivers</Col>
      <Col xs={true} className='d-none d-md-block'></Col>
      <Col xs={'auto'}>
        <DropdownButton
          as={ButtonGroup}
          variant={'custom-outlined'}
          size='sm'
          title={period.title}
          disabled={isLoadingDaily || isLoadingWeekly || isLoadingMonthly}
        >
          <Dropdown.Header>Sort by</Dropdown.Header>
          <Dropdown.Item
            onClick={() => {
              dispatch(slice.setPeriod(GanttTablePeriod.Daily));
              dispatch(slice.setTasksDaily([]));
            }}
          >
            <Form.Check 
              type={'radio'}
              name={'sort_by_period'}
              id={'sort_by_Daily'}
              label={GanttTablePeriod.Daily.title}
              checked={(period.picker === GanttTablePeriod.Daily.picker)}
              onChange={() => {}}
            />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              dispatch(slice.setPeriod(GanttTablePeriod.Weekly));
            }}
          >
            <Form.Check 
              type={'radio'}
              name={'sort_by_period'}
              id={'sort_by_Weekly'}
              label={GanttTablePeriod.Weekly.title}
              checked={(period.picker === GanttTablePeriod.Weekly.picker)}
              onChange={() => {}}
            />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              dispatch(slice.setPeriod(GanttTablePeriod.Monthly));
            }}
          >
            <Form.Check 
              type={'radio'}
              name={'sort_by_period'}
              id={'sort_by_Monthly'}
              label={GanttTablePeriod.Monthly.title}
              checked={(period.picker === GanttTablePeriod.Monthly.picker)}
              onChange={() => {}}
            />
          </Dropdown.Item>
        </DropdownButton>
      </Col>
      <Col xs={'auto'}>
        <Row className={'custom-date-picker-wrapper ' + ((isLoadingDaily || isLoadingWeekly || isLoadingMonthly) ? 'disabled'  : '') + ' align-items-center g-0'}>
          <Col xs={'auto'}>
            <Button
              className='px-2 py-1'
              variant={'custom-none-secondary'}
              size='sm'
              disabled={isLoadingDaily || isLoadingWeekly || isLoadingMonthly}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                let currentDate = moment(selectedDate, DATE_FORMAT);

                let newDate = null;
                if(period.picker === GanttTablePeriod.Monthly.picker){
                  newDate = currentDate.subtract(1, 'month').startOf('month').format(DATE_FORMAT);
                } else if(period.picker === GanttTablePeriod.Weekly.picker){
                  newDate = currentDate.subtract(1, 'week').startOf('week').format(DATE_FORMAT);
                } else {
                  newDate = currentDate.subtract(1, 'day').format(DATE_FORMAT);
                }

                dispatch(slice.setSelectedDate(newDate));
              }}
            >
              <LeftIcon />
            </Button>
          </Col>
          <Col xs={true}>
            <DatePicker 
              className={'custom-date-picker'}
              inputReadOnly={true}
              allowClear={false}
              showWeek={(period.picker === GanttTablePeriod.Weekly.picker)}
              suffixIcon={(isLoadingDaily || isLoadingWeekly || isLoadingMonthly) ? <Spin size={'small'} style={{ position: 'relative', top: '2px' }} /> : <CalendarIcon />}
              disabled={isLoadingDaily || isLoadingWeekly || isLoadingMonthly}
              picker={period.picker}
              value={dayjs(selectedDate, DATE_FORMAT)}
              format={(value: any) => {
                if(period.picker === GanttTablePeriod.Monthly.picker){
                  return dayjs(value).format('MMM YYYY')
                } else if(period.picker === GanttTablePeriod.Weekly.picker){
                  return dayjs(value).startOf('week').format('MMM DD, YYYY') + ' - ' + dayjs(value).endOf('week').format('MMM DD, YYYY');
                } else {
                  return dayjs(value).format('MMM DD, YYYY');
                }
              }}
              onChange={(date: any, dateString: any) => {
                dispatch(slice.setSelectedDate(dayjs(date).format(DATE_FORMAT)));
              }}
            />
          </Col>
          <Col xs={'auto'}>
            <Button
              className='px-2 py-1'
              variant={'custom-none-secondary'}
              size='sm'
              disabled={isLoadingDaily || isLoadingWeekly || isLoadingMonthly}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                let currentDate = moment(selectedDate, DATE_FORMAT);

                let newDate = null;
                if(period.picker === GanttTablePeriod.Monthly.picker){
                  newDate = currentDate.add(1, 'month').startOf('month').format(DATE_FORMAT);
                } else if(period.picker === GanttTablePeriod.Weekly.picker){
                  newDate = currentDate.add(1, 'week').startOf('week').format(DATE_FORMAT);
                } else {
                  newDate = currentDate.add(1, 'day').format(DATE_FORMAT);
                }

                dispatch(slice.setSelectedDate(newDate));
              }}
            >
              <RightIcon />
            </Button>
          </Col>
        </Row>
      </Col>

      {(period.picker === GanttTablePeriod.Daily.picker) && <>
        <Col xs={'auto'}>
          <Button
            variant={'custom-outlined'}
            size='sm'
            disabled={isLoadingDaily || isTodayDate(selectedDate)}
            onClick={() => {
              let dateValue: any = moment().startOf('day').format(DATE_FORMAT);
              dispatch(slice.setSelectedDate(dateValue));
              dispatch(slice.setPeriod(GanttTablePeriod.Daily));
            }}
          >
            Today
          </Button>
        </Col>
        <Col xs={'auto'} className='d-none d-md-block'><div className='v-divider'></div></Col>
        <Col xs={'auto'}>
          <Button
            variant={'custom-outlined'}
            size='sm'
            disabled={isLoadingDaily || !(statusDaily?.unassigned?.count > 0)}
            onClick={() => {
              dispatch(unassignedSlice.setShow({ show: true, details: null }));
            }}
          >
            <Spin spinning={isLoadingDailyStatus} size={'small'} wrapperClassName='d-inline-block'>{statusDaily?.unassigned?.count}</Spin>
            <span className='ps-2 d-inline-block'>Unassigned Jobs</span>
          </Button>
        </Col>
        <Col xs={'auto'}>
          <Button
            variant={'custom-outlined'}
            size='sm'
            disabled={isLoadingDaily || isLoadingDispatchAll}
            onClick={() => {
              dispatchAll();
            }}
          >
            Dispatch All
          </Button>
        </Col>
      </>}
    </Row>
  }
  
  const filterSection = () => {
    return <Row className='align-items-center g-4'>
      <Col xs={'auto'}>
        <div className={'search-input'}>
          <SearchIcon />
          <Form.Control
            type="text"
            size='sm'
            placeholder={'Search jobs'}
            value={searchValue}
            disabled={isLoadingDaily}
            onChange={(e) => {
              setSearchValue(e.target.value);
              debouncedSearch(() => {
                dispatch(slice.setSearch(e.target.value));
              });
            }}
          />
        </div>
      </Col>
      <Col xs={'auto'}>
        <Button
          variant={'custom-outlined'}
          size='sm'
          disabled={isLoadingDaily}
          onClick={(e: any) => {
            if(refDailyList && refDailyList.current){
              refDailyList.current.showFilters(e);
            }
          }}
        >
          <FilterIcon /> {(getFilterCount(details) > 0) && <Badge className='custom-filter-badge' bg='primary'>{getFilterCount(details)}</Badge>} Filters
        </Button>
      </Col>
      <Col xs={true} className='d-none d-md-block'></Col>
      {/* <Col xs={'auto'} className='d-none d-md-block'><div className='v-divider'></div></Col> */}
      <Col xs={'auto'}>
        <Button
          variant={'custom-outlined'}
          size='sm'
          disabled={isLoadingDaily}
          onClick={() => {
            setShow(!show);
          }}
        >
          <BarChart2Icon /> {(show) ? 'Hide Statistics' : 'Show Statistics'}
        </Button>
      </Col>
      <Col xs={'auto'}>
        <DropdownButton
          className={'job-info-dropdown'}
          as={ButtonGroup}
          variant={'custom-outlined'}
          size='sm'
          autoClose='outside'
          title={<><SettingsIcon /> Job Info</>}
          disabled={isLoadingDaily}
        >
          <Dropdown.Header>Fixed information</Dropdown.Header>
          <Dropdown.Item disabled>Job Type</Dropdown.Item>
          <Dropdown.Item disabled>Customer name</Dropdown.Item>
          <Dropdown.Item disabled>Address</Dropdown.Item>

          <Dropdown.Divider />
          <Dropdown.Header>Available informations</Dropdown.Header>
          {GanttTableJobInfo.map((item, i) => {
            return <Dropdown.Item
              key={'available_informations_item_' + item.id}
              onClick={() => {
                if(jobInfo.findIndex((x: any) => x.id == item.id) > -1){
                  dispatch(slice.removeJobInfo(item));
                } else {
                  dispatch(slice.addJobInfo(item));
                }
              }}
            >
              <Form.Check 
                type={'checkbox'}
                id={'available_informations_' + item.id}
                label={item.title}
                disabled
                checked={(jobInfo.findIndex((x: any) => x.id == item.id) > -1)}
                onChange={() => {}}
              />
            </Dropdown.Item>
          })}
        </DropdownButton>
      </Col>
    </Row>
  }

  const statusSection = () => {
      return <Row className='align-items-center'>
        <Col xs={12}>
          <Carousel
            className={'custom-carousel'}
            itemsToShow={9}
            outerSpacing={0}
            itemPadding={[0, 0]}
            initialActiveIndex={0}
            breakPoints={[
              { width: 1, itemsToShow: 1 },
              { width: 200, itemsToShow: 2 },
              { width: 300, itemsToShow: 2 },
              { width: 400, itemsToShow: 2 },
              { width: 500, itemsToShow: 3 },
              { width: 600, itemsToShow: 3 },
              { width: 700, itemsToShow: 3 },
              { width: 800, itemsToShow: 4 },
              { width: 900, itemsToShow: 6 },
              { width: 992, itemsToShow: 7 },
              { width: 1199, itemsToShow: 9 },
            ]}
            showEmptySlots={false}
            showArrows={true}
            pagination={false}
            isRTL={false}
          >
            <div className='custom-carousel-item active'>
              <Spin spinning={isLoadingDailyStatus} size={'small'}>
                <div className='count'>{statusDaily?.total?.count}</div>
                <div className='text'>{statusDaily?.total?.jobStatusName}</div>
                <div className='v-divider v-divider-full position-absolute end-0' style={{ top: '-10px', height: 'calc(100% + 20px)' }}></div>
              </Spin>
            </div>
            <div className='custom-carousel-item'>
              <Spin spinning={isLoadingDailyStatus} size={'small'}>
                <div className='count assigned'>{statusDaily?.unallocated?.count}</div>
                <div className='text'>{statusDaily?.unallocated?.jobStatusName}</div>
                <div className='v-divider v-divider-full position-absolute top-0 end-0'></div>
              </Spin>
            </div>
            <div className='custom-carousel-item'>
              <Spin spinning={isLoadingDailyStatus} size={'small'}>
                <div className='count assigned'>{statusDaily?.assigned?.count}</div>
                <div className='text'>{statusDaily?.assigned?.jobStatusName}</div>
                <div className='v-divider v-divider-full position-absolute top-0 end-0'></div>
              </Spin>
            </div>
            <div className='custom-carousel-item'>
              <Spin spinning={isLoadingDailyStatus} size={'small'}>
                <div className='count dispatched'>{statusDaily?.dispatched?.count}</div>
                <div className='text'>{statusDaily?.dispatched?.jobStatusName}</div>
                <div className='v-divider v-divider-full position-absolute top-0 end-0'></div>
              </Spin>
            </div>
            <div className='custom-carousel-item'>
              <Spin spinning={isLoadingDailyStatus} size={'small'}>
                <div className='count acknowledged'>{statusDaily?.acknowledged?.count}</div>
                <div className='text'>{statusDaily?.acknowledged?.jobStatusName}</div>
                <div className='v-divider v-divider-full position-absolute top-0 end-0'></div>
              </Spin>
            </div>
            <div className='custom-carousel-item'>
              <Spin spinning={isLoadingDailyStatus} size={'small'}>
                <div className='count started'>{statusDaily?.started?.count}</div>
                <div className='text'>{statusDaily?.started?.jobStatusName}</div>
                <div className='v-divider v-divider-full position-absolute top-0 end-0'></div>
              </Spin>
            </div>
            <div className='custom-carousel-item'>
              <Spin spinning={isLoadingDailyStatus} size={'small'}>
                <div className='count inprogress'>{statusDaily?.inProgress?.count}</div>
                <div className='text'>{statusDaily?.inProgress?.jobStatusName}</div>
                <div className='v-divider v-divider-full position-absolute top-0 end-0'></div>
              </Spin>
            </div>
            <div className='custom-carousel-item'>
              <Spin spinning={isLoadingDailyStatus} size={'small'}>
                <div className='count completed'>{statusDaily?.completed?.count}</div>
                <div className='text'>{statusDaily?.completed?.jobStatusName}</div>
                <div className='v-divider v-divider-full position-absolute top-0 end-0'></div>
              </Spin>
            </div>
            <div className='custom-carousel-item'>
              <Spin spinning={isLoadingDailyStatus} size={'small'}>
                <div className='count cancelled'>{statusDaily?.unsuccessful?.count}</div>
                <div className='text'>{statusDaily?.unsuccessful?.jobStatusName}</div>
              </Spin>
            </div>
          </Carousel>
        </Col>
      </Row>
  }

  const legendSection = () => {
    return <Row className='align-items-center'>
      <Col xs={12} sm={true}>
        <LegendList />
      </Col>
      <Col xs={12} sm={'auto'} className='pt-7 pt-sm-0 ps-0'>
        <Form.Check
          id={'showActualJobTimeID'}
          type="switch"
          label={'Show Actual Job Time'}
          disabled={isLoadingDaily}
          checked={showActualJobTime}
          onChange={(e) => {
            dispatch(slice.showActualJobTime(e.target.checked));
          }}
        />
      </Col>
      {/* <Col xs={12} sm={'auto'} className='pt-7 pt-sm-0 ps-0'>
        <Form.Check
          id={'isVerticalID'}
          type="switch"
          label={'Vertical'}
          checked={isVertical}
          onChange={(e) => {
            dispatch(slice.setIsVertical(e.target.checked));
          }}
        />
      </Col> */}
    </Row>
  }

  const listSection = () => {
    if(period.picker === GanttTablePeriod.Daily.picker){
      return <DailyListComponent ref={refDailyList} />
    } else if(period.picker === GanttTablePeriod.Weekly.picker){
      return <WeeklyListComponent />
    } else if(period.picker === GanttTablePeriod.Monthly.picker){
      return <MonthlyListComponent />
    }
  }


  return <div className={'new-gantt-chart-page'}>
    <Row>
      <StyledCol xs={12} className={'top-section'} style={{ borderBottom: ((period.picker === GanttTablePeriod.Daily.picker) ? '1px solid var(--bs-gray-100)' : '0px') }}>
        {topSection()}
      </StyledCol>
      {(period.picker === GanttTablePeriod.Daily.picker) && <Col xs={12} className={'filter-section'}>
        {filterSection()}
      </Col>}
      {(period.picker === GanttTablePeriod.Daily.picker) && <Col xs={12} className={'status-section ' + (show ? 'd-block' : 'd-none')}>
        {statusSection()}
      </Col>}
      {(period.picker === GanttTablePeriod.Daily.picker) && <Col xs={12} className={'legend-section'}>
        {legendSection()}
      </Col>}
      <Col xs={12} className={'list-section'}>
        {listSection()}
      </Col>
    </Row>

    <MultipleTripsList />
    <JobDetailsList />
    <RouteMapList />

    <UnsetTimeList
      onSave={(rows: any) => {
        if(rows && rows.length > 0){
          if(refDailyList && refDailyList.current){
            refDailyList.current.updateTasksStatus(rows.map((x: any) => x.job));
          }
        }

        if(refDailyList && refDailyList.current){
          refDailyList.current.callAPI();
        }
      }}
    />

    <UnassignedList
      ref={refUnassignedlist}
      onDispatchAll={(data: any) => {
        if(data && data.successJobs && data.successJobs.length > 0){
          if(refDailyList && refDailyList.current){
            refDailyList.current.updateTasksStatus(data.successJobs.map((x: any) => x.job));
          }
        }

        if(refDailyList && refDailyList.current){
          refDailyList.current.callAPI();
        }
      }}
      onUpdate={(data: any) => {
        if(data && data.successJobs && data.successJobs.length > 0){
          if(refDailyList && refDailyList.current){
            refDailyList.current.updateTasksStatus(data.successJobs.map((x: any) => x.job));
          }
        }

        if(refDailyList && refDailyList.current){
          refDailyList.current.callAPI();
        }
      }}
    />

    <BulkUpdateJobForm
      onUpdate={(data: any, results: any) => {
        if(refUnassignedlist && refUnassignedlist.current){
          refUnassignedlist.current.updateList(results);
        }

        if(refDailyList && refDailyList.current){
          refDailyList.current.callAPI();
          refDailyList.current.callStatusAPI();
        }
      }}
    />
  </div>
}

export default PageComponent;
