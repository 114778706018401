import React, { useState } from "react";
import _ from 'lodash';

import { compose, withProps, lifecycle, withHandlers } from "recompose";
import {
  GoogleMap,
  Marker,
  Polyline,
  withScriptjs,
  withGoogleMap,
} from "react-google-maps";

import { connect } from "react-redux";
import { dispatchApiCallPost, dispatchApiCallGet } from '../../../../setup/redux/dispatch/actions'
import { clear } from "../../../../setup/redux/actions";
import Utils from '../../../utils/utils'
import { AccountSettingType } from '../../../utils/enums'

const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env;
const URL = "https://maps.googleapis.com/maps/api/js?key=" + REACT_APP_GOOGLE_MAP_API_KEY + "&v=3.exp&libraries=geometry,drawing,places";


const DEF_CENTER = { lat: 1.353915, lng: 103.822901 };
const DEF_ZOOM = 16

         
const CustomInlineGoogleMap = compose(
  withProps({
    googleMapURL: URL,
    loadingElement: <div style={{ width: "100%", height: "100%" }} />,
    containerElement: <div style={{ width: "100%", height: "100%" }} />,
    mapElement: <div style={{ width: "100%", height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
  withHandlers((props) => {
    return {
      callGetDistanceApi: () => (data, callback) => {
        if(data && data.length > 0){
          props.dispatchApiCallPost(data, 'map_route_get_distance', 'route/get-distance', null, callback, () => {});
        }
      },
      callReadAccountSettingsLatTypeApi: () => (callback) => {
        props.dispatchApiCallGet(null, 'map_route-account_setting_type_lat', 'AccountSetting/type/' + AccountSettingType.DefaultMapPositionLatitude, null, callback, () => {});
      },
      callReadAccountSettingsLngTypeApi: () => (callback) => {
        props.dispatchApiCallGet(null, 'map_route-account_setting_type_lng', 'AccountSetting/type/' + AccountSettingType.DefaultMapPositionLongitude, null, callback, () => {});
      },
      callReadAccountSettingsZoomTypeApi: () => (callback) => {
        props.dispatchApiCallGet(null, 'map_route-account_setting_type_zoom', 'AccountSetting/type/' + AccountSettingType.DefaultMapPositionZoom, null, callback, () => {});
      }
    }
  }),
  lifecycle({

    componentWillMount() {
      this.setState({
        distancesMarkers: [],

        calcDistance: (distancesMarkers) => {
          this.props.callGetDistanceApi(distancesMarkers, (data) => {
            if(this.props.onDistance){
              this.props.onDistance([data]);
            }
          });
        },

      });
    },
    
    componentWillReceiveProps(nextProps) {
      if (nextProps.distancesMarkers !== this.state.distancesMarkers) {
        let distancesMarkers = (nextProps.distancesMarkers && nextProps.distancesMarkers.length > 0) ? nextProps.distancesMarkers : [];
        this.setState({
          distancesMarkers: distancesMarkers
        }, () => {
          this.state.calcDistance(distancesMarkers);
        });
      }
    },

  }),
)((props) => {
  const [center, setCenter] = useState(props.center ? props.center : null);
  const [defaultCenter, setDefaultCenter] = useState(props.defaultCenter ? props.defaultCenter : null);
  const [defaultZoom, setDefaultZoom] = useState(props.defaultZoom ? props.defaultZoom : DEF_ZOOM);
  
  const [streetViewControl] = useState(((props.streetViewControl === false) || (props.streetViewControl === true)) ? props.streetViewControl : false);
  const [mapTypeControl] = useState(((props.mapTypeControl === false) || (props.mapTypeControl === true)) ? props.mapTypeControl : false);

  const [driverLocation, setDriverLocation] = useState((props.driverLocation && props.driverLocation.length > 0) ? props.driverLocation : []);
  const [allDrivers, setAllDrivers] = useState((props.allDrivers && props.allDrivers.length > 0) ? props.allDrivers : []);


  React.useEffect(() => {
    props.callReadAccountSettingsLatTypeApi((latitude) => {
      props.callReadAccountSettingsLngTypeApi((longitude) => {
        props.callReadAccountSettingsZoomTypeApi((zoomItem) => {
          let lat = (latitude && latitude.value && latitude.value != '') ? latitude.value : DEF_CENTER.lat;
          let lng = (longitude && longitude.value && longitude.value != '') ? longitude.value : DEF_CENTER.lng;
          let zoom = (zoomItem && zoomItem.value && zoomItem.value != '') ? zoomItem.value : '';
          
          let defCenter = (lat != '' && lng != '') ? { lat: parseFloat(lat), lng: parseFloat(lng) } : DEF_CENTER;
          let defZoom = (zoom != '' && zoom != '') ? parseInt(zoom) : DEF_ZOOM;
          
          setDefaultZoom(defZoom);
          setDefaultCenter(defCenter);
        });
      });
    });
  }, []);


  React.useEffect(() => {
    Utils.reduxProps(props,
      'map_route_get_distance', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        if(callback){
          callback((data) ? data : 0);
        }

        props.clear();
      }
    );

    Utils.reduxProps(props,
      'map_route-account_setting_type_lat', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        if(callback){
          callback(data);
        }

        props.clear();
      }
    );

    Utils.reduxProps(props,
      'map_route-account_setting_type_lng', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        if(callback){
          callback(data);
        }

        props.clear();
      }
    );

    Utils.reduxProps(props,
      'map_route-account_setting_type_zoom', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        if(callback){
          callback(data);
        }

        props.clear();
      }
    );
  }, [props]);


  React.useEffect(() => {
    let driver = (allDrivers && allDrivers.length > 0) ? allDrivers.find(x => x.driverId === props.driverId) : null;
    if(!driver){
      setCenter(props.center ? props.center : null);
    }
  }, [ props.center ]);

  React.useEffect(() => {
    setDriverLocation((props.driverLocation && props.driverLocation.length > 0) ? props.driverLocation : []);
  }, [ props.driverLocation ]);

  React.useEffect(() => {
    setAllDrivers((props.allDrivers && props.allDrivers.length > 0) ? props.allDrivers : []);
  }, [ props.allDrivers ]);


  const getBearing = (from, to) => {
    try {
      const lat1 = from.lat * (Math.PI / 180);
      const lng1 = from.lng * (Math.PI / 180);
      const lat2 = to.lat * (Math.PI / 180);
      const lng2 = to.lng * (Math.PI / 180);
      
      const y = Math.sin(lng2 - lng1) * Math.cos(lat2);
      const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1);
      
      const bearing = Math.atan2(y, x);
      return (bearing * (180 / Math.PI) + 360) % 360;
    } catch(e){
      return 0;
    }
  };

  const getDriverLocationsPath = () => {
    return <>
      <Polyline
        path={driverLocation.map((item, i) => ({ lat: item.latitude, lng: item.longitude }))}
        options={{
          strokeColor: "#1253f9",
          strokeOpacity: 1.0,
          strokeWeight: 11,
        }}
      />
      {driverLocation.map((item, i) => {
        let position = { lat: item.latitude, lng: item.longitude };
        let toPosition = driverLocation[i + 1] ? { lat: driverLocation[i + 1]?.latitude, lng: driverLocation[i + 1]?.longitude } : null;
        
        return <Marker
          key={'driver_location_marker_' + i}
          position={position}
          title={'Updated: ' + item.updatedDisplay}
          icon={{
            // path: window.google.maps.SymbolPath.CIRCLE,
            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            scale: 2.5,
            fillColor: "#FFFFFF",
            fillOpacity: 1,
            strokeWeight: 1,
            strokeColor: "#000000",
            rotation: i < driverLocation.length - 1 ? getBearing(position, toPosition) : 0,
          }}
        />
      })}
    </>
  }

  const getAllDrivers = () => {
    if(!props.isEdit && props.isCustomerSIteSelected){
      if(props.driverId){
        let driver = (allDrivers && allDrivers.length > 0) ? allDrivers.find(x => x.driverId === props.driverId) : null;
        if(driver){
          return <Marker
            key={'single_driver_location_marker_' + 1}
            {...driver}
            icon={{
              url: driver.icon,
              scaledSize: new window.google.maps.Size(26, 32),
              labelOrigin: new window.google.maps.Point(13, 41),
            }}
          />
        }
      } else {
        return <>
          {allDrivers.map((item, i) => {
            return <Marker
              key={'all_driver_location_marker_' + i}
              {...item}
              icon={{
                url: item.icon,
                scaledSize: new window.google.maps.Size(26, 32),
                labelOrigin: new window.google.maps.Point(13, 41),
              }}
              onClick={(e) => {
                if(props && props.onClickMarker){
                  props.onClickMarker(e, item, item?.item);
                }
              }}
            />
          })}
        </>
      }
    }
  }


  const googleMap = () => {
    if (typeof window.google !== 'undefined' && typeof window.google.maps !== 'undefined') {
      return (
        <div>
          {defaultCenter && <GoogleMap
            defaultZoom={defaultZoom}
            defaultCenter={defaultCenter}
            center={center ? center : defaultCenter}
            defaultOptions={{
              disableDefaultUI: true,
              disableDoubleClickZoom: true,
              rotateControl: false,
              fullscreenControl: true,
              streetViewControl: streetViewControl,
              streetViewControlOptions: {
                position: 7,
              },
              mapTypeControl: mapTypeControl,
            }}
          >
            {center && (
              <Marker position={center} />
            )}

            {getDriverLocationsPath()}
            {getAllDrivers()}
          </GoogleMap>}
        </div>
      );
    }
  };

  return googleMap();
});


const mapDispatchToProps = {
  dispatchApiCallPost,
  dispatchApiCallGet,
  clear,
}
export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CustomInlineGoogleMap);
