/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';
import moment from 'moment';

import * as dispatchCrudApi from '../../api/CRUD/DispatchCRUD'
import Utils from "../../utils/utils";
// import {  } from "../../utils/enums";

import { ParseResult } from "../../utils/interfaces";

import MarkerIcon from "../../../_metronic/assets/img/icons/marker-driver.svg";


interface InitState {
  isLoading: boolean,
  items: Array<any>,
  
  isLoadingAllDrivers: boolean,
  allDrivers: Array<any>,
}


function NewReducer() {
  const name = 'mapSlice';


  const initialState: InitState = {
    isLoading: false,
    items: [],

    isLoadingAllDrivers: false,
    allDrivers: [],
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    startLatestLocation: (state: InitState) => {
      state.isLoading = true;
      state.items = [];
    },
    finishLatestLocation: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.length > 0) ? action.payload : [];
      
      let arr: any = [];
      if(data && data.length > 0){
        arr = data.map((item: any, i: number) => {
          let coordinate = {
            lat: item.latitude,
            lng: item.longitude,
          };

          let driver = (item && item.driver) ? item.driver : null;
          let driverName = (driver && driver.driverName) ? driver.driverName : '';
      
          let value = 'driverid_' + item.driverId + '_drivername_' + driverName;
          
          return {
              type: 3,
              value: value,
              title: 'Updated: ' + moment(item.updated).format(Utils.getDefaultDateTimeFormat()),
              item: item,
              items: [],
              position: coordinate,
              icon: MarkerIcon,
              label: {
                text: driverName,
                className: 'marker-label'
              },
              isOpen: false,
              selectedJob: 0,
              infoWindow: null,
          }
        });
      }
      
      state.items = arr;
      state.isLoading = false;
    },
    
    startAllLatestLocationDrivers: (state: InitState) => {
      state.isLoadingAllDrivers = true;
      state.allDrivers = [];
    },
    finishAllLatestLocationDrivers: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.length > 0) ? action.payload : [];
      
      let arr: any = [];
      if(data && data.length > 0){
        arr = data.map((item: any, i: number) => {
          let coordinate = {
            lat: item.latitude,
            lng: item.longitude,
          };

          let driver = (item && item.driver) ? item.driver : null;
          let driverName = (driver && driver.driverName) ? driver.driverName : '';
      
          let value = 'driverid_' + item.driverId + '_drivername_' + driverName;
          
          return {
              value: value,
              driverId: item.driverId,
              title: 'Updated: ' + moment(item.updated).format(Utils.getDefaultDateTimeFormat()),
              item: item,
              position: coordinate,
              icon: MarkerIcon,
              label: {
                text: driverName,
                color: "white",
                className: "marker-label",
              },
              isOpen: false,
              infoWindow: null,
          }
        });
      }
      
      state.allDrivers = arr;
      state.isLoadingAllDrivers = false;
    },
  };


  const apis = {
    callLatestLocationApi: (ids: Array<any>, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startLatestLocation());

      await dispatchCrudApi.createApi({ driverIds: ids }, 'driver/latest-location').then(result => {
        let data = (ids && ids.length > 0) ? result.data : [];
        
        callback(true, data);
        dispatch(actions.finishLatestLocation(data));
      }).catch(error => {
          let res: ParseResult = {
            isError: false,
            errorMessage: null,
            status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
            res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
            Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishLatestLocation(null));
      });
    },

    callAllLatestLocationDriversApi: (ids: Array<any>, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startAllLatestLocationDrivers());

      await dispatchCrudApi.createApi({ driverIds: ids }, 'driver/latest-location').then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishAllLatestLocationDrivers(data));
      }).catch(error => {
          let res: ParseResult = {
            isError: false,
            errorMessage: null,
            status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
            res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
            Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishAllLatestLocationDrivers(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();