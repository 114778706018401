/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import * as dispatchCrudApi from '../../../../api/CRUD/DispatchCRUD'
import Utils from "../../../../utils/utils";
// import {  } from "../../../../utils/enums";

import { ParseResult } from "../../../../utils/interfaces";


interface InitState {
  isLoading: boolean,
  status: Array<any>,
  selectedStatus: any,
  availableStatuses: Array<any>,
  unavailableStatuses: Array<any>,
}

function NewReducer() {
  const name = 'ganttChartTaskSlice';


  const initialState: InitState = {
    isLoading: false,
    status: [],
    selectedStatus: null,
    availableStatuses: [
      'Assigned',
      'Dispatched',
      'Acknowledged',
      'Started',
      'In Progress',
      'Completed',
      'Failed',
      'Cancelled',
    ],
    unavailableStatuses: [
      'completed',
      'cancelled',
      'failed',
      'rejected',
    ],
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setStatus: (state: InitState, action: PayloadAction<any>) => {
      state.status = action.payload;
      state.selectedStatus = (action.payload && action.payload.length > 0) ? action.payload[0].jobStatusName : null;
    },

    startReadStatus: (state: InitState) => {
      state.isLoading = true;
    },
    finishReadStatus: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];

      let arr: Array<any> = [];
      if(data && data.length > 0){
        if(state.selectedStatus == 'Assigned'){
          data.forEach((item: any) => {
            if(state.availableStatuses.includes(item.jobStatusName)){
              arr.push({ ...item, disabled: false });
            }
          });

        } else if(state.selectedStatus == 'Dispatched'){
          data.forEach((item: any) => {
            if(state.availableStatuses.includes(item.jobStatusName)){
              arr.push({ ...item, disabled: false });
            }
          });

        } else if(state.selectedStatus == 'Acknowledged'){
          data.forEach((item: any) => {
            if(state.availableStatuses.includes(item.jobStatusName)){
              arr.push({ ...item, disabled: false });
            }
          });

        } else if(state.selectedStatus == 'Started'){
          data.forEach((item: any) => {
            if(state.availableStatuses.includes(item.jobStatusName)){
              arr.push({ ...item, disabled: [ 'Assigned', 'Dispatched', 'Acknowledged' ].some((x: any) => x == item.jobStatusName) });
            }
          });
          
        } else if(state.selectedStatus == 'In Progress'){
          data.forEach((item: any) => {
            if(state.availableStatuses.includes(item.jobStatusName)){
              arr.push({ ...item, disabled: [ 'Assigned', 'Dispatched', 'Acknowledged', 'Started' ].some((x: any) => x == item.jobStatusName) });
            }
          });
          
        } else if(state.selectedStatus == 'Completed'){
          data.forEach((item: any) => {
            if(state.availableStatuses.includes(item.jobStatusName)){
              arr.push({ ...item, disabled: [ 'Assigned', 'Dispatched', 'Acknowledged', 'Started', 'In Progress' ].some((x: any) => x == item.jobStatusName) });
            }
          });
        }
      }
      state.status = arr;
      state.isLoading = false;
    },
  };


  const apis = {
    callReadStatusApi: (search: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadStatus());

      let params: any = {
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
        searchQuery: search,
      }

      await dispatchCrudApi.readApi(params, 'jobStatus').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishReadStatus(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishReadStatus(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();