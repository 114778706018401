/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import * as dispatchCrudApi from '../../../../api/CRUD/DispatchCRUD'
import Utils from "../../../../utils/utils";
// import {  } from "../../../../utils/enums";

import { ParseResult } from "../../../../utils/interfaces";


interface InitState {
  show: boolean,
  details: any,
  period: any,
  isLoading: boolean,
  items: Array<any>,

  isLoadingStatus: boolean,
  status: any,
}

function NewReducer() {
  const name = 'ganttChartJobDetailsSlice';


  const initialState: InitState = {
    show: false,
    details: null,
    period: null,
    isLoading: false,
    items: [],
    
    isLoadingStatus: false,
    status: {
      total: {
        count: 0,
        jobStatusName: "Total jobs"
      },
      completed: {
        count: 0,
        jobStatusName: "Completed"
      },
      unsuccessful: {
        count: 0,
        jobStatusName: "Unsuccessful"
      },
      assigned: {
        count: 0,
        jobStatusName: "Assigned",
      },
    },
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, details: any, period: any }>) => {
      state.period = action.payload.period;
      state.details = action.payload.details;
      state.show = action.payload.show;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      state.items = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.items = data;
    },

    startReadStatus: (state: InitState) => {
      state.isLoadingStatus = true;
      state.status = {
        total: {...state.status.total, count: 0 },
        completed: {...state.status.completed, count: 0 },
        unsuccessful: {...state.status.unsuccessful, count: 0 },
        assigned: {...state.status.assigned, count: 0 },
      };
    },
    finishReadStatus: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];

      let statusTotal = {...state.status.total, count: 0 };
      let statusCompleted = {...state.status.completed, count: 0 };
      let statusUnsuccessful = {...state.status.unsuccessful, count: 0 };
      let statusAssigned = {...state.status.assigned, count: 0 };

      if (data && data.length > 0) {
        let statusItem2 = data.filter((x: any) => (
          x.jobStatusName.toLowerCase().trim() === 'Assigned'.toLowerCase().trim()
        ));
        if (statusItem2 && statusItem2.length > 0) {
          statusTotal.count = statusTotal.count + statusItem2[0].count;
          statusAssigned.count = statusItem2[0].count;
          statusAssigned.jobStatusId = statusItem2[0].jobStatusId;
        }

        let statusItem3 = data.filter((x: any) => (
          x.jobStatusName.toLowerCase().trim() === 'Dispatched'.toLowerCase().trim()
        ));
        if (statusItem3 && statusItem3.length > 0) {
          statusTotal.count = statusTotal.count + statusItem3[0].count;
        }
        
        let statusItem4 = data.filter((x: any) => (
          x.jobStatusName.toLowerCase().trim() === 'Acknowledged'.toLowerCase().trim()
        ));
        if (statusItem4 && statusItem4.length > 0) {
          statusTotal.count = statusTotal.count + statusItem4[0].count;
        }

        let statusItem5 = data.filter((x: any) => (
          x.jobStatusName.toLowerCase().trim() === 'Started'.toLowerCase().trim()
        ));
        if (statusItem5 && statusItem5.length > 0) {
          statusTotal.count = statusTotal.count + statusItem5[0].count;
        }

        let statusItem6 = data.filter((x: any) => (
          x.jobStatusName.toLowerCase().trim() === 'In Progress'.toLowerCase().trim()
        ));
        if (statusItem6 && statusItem6.length > 0) {
          statusTotal.count = statusTotal.count + statusItem6[0].count;
        }

        let statusItem7 = data.filter((x: any) => (
          x.jobStatusName.toLowerCase().trim() === 'Completed'.toLowerCase().trim()
        ));
        if (statusItem7 && statusItem7.length > 0) {
          statusTotal.count = statusTotal.count + statusItem7[0].count;
          statusCompleted.count = statusItem7[0].count;
          statusCompleted.jobStatusId = statusItem7[0].jobStatusId;
        }

        let statusItem8 = data.filter((x: any) => (
          (x.jobStatusName.toLowerCase().trim() === 'Failed'.toLowerCase().trim()) ||
          (x.jobStatusName.toLowerCase().trim() === 'Cancelled'.toLowerCase().trim())
        ));
        if (statusItem8 && statusItem8.length > 0) {
          statusTotal.count = statusTotal.count + statusItem8[0].count + statusItem8[1].count;
          statusUnsuccessful.count = statusItem8[0].count + statusItem8[1].count;
          statusUnsuccessful.jobStatusId = [statusItem8[0].jobStatusId, statusItem8[1].jobStatusId];
        }
      }

      state.status = {
        total: statusTotal,
        completed: statusCompleted,
        unsuccessful: statusUnsuccessful,
        assigned: statusAssigned,
      };
      state.isLoadingStatus = false;
    },
  };


  const apis = {
    callReadApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        let paramsData: any = params;

        await dispatchCrudApi.createApi(paramsData, 'job/get-list').then((result: any) => {
            let data = result.data;
            
            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch((error: any) => {
            let res: ParseResult = {
                isError: false,
                errorMessage: null,
                status: null,
            };
    
            Utils.parseErrorTS(error, (result: ParseResult): void => {
                res = result
            });
            
            let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
            if(res.isError && err){
                Utils.toast(err, 'error');
            }
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },
    
    callReadStatusApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadStatus());

      await dispatchCrudApi.readApi(params, 'job/status/stat').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishReadStatus(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishReadStatus(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();