/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from 'moment';
import _ from 'lodash';

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";
import { ParseResult } from "../../../utils/interfaces";
import { JobStepType } from '../../../utils/enums';

import { GanttTablePeriod, GanttType } from './DailyList';
import { isTimeFilterIncluded } from './Filters/slice';


export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'hh:mm';
export const VIEW_DATE_FORMAT = 'MMM DD, yyyy';
export const VIEW_TIME_FORMAT = 'HH:mm';


export const getStatusName = (data: any) => {
  let statusName = '';

  if(data){
    statusName = data.statusName.toString().toLowerCase().trim().replaceAll(' ', '');
  }

  return statusName
}
export const isDraggableResizable = (data: any) => {
  let resizable = true;
  let draggable = true;

  if(data){
    if((data.statusName === 'Started') || (data.statusName === 'In Progress') || (data.statusName === 'Completed') || (data.statusName === 'Cancelled') || (data.statusName === 'Failed') || (data.statusName === 'Rejected')){
      resizable = false;
      draggable = false;
    }
  }

  return {
    resizable: resizable,
    draggable: draggable,
  }
}
export const getMinutes = (jobDurationHours: any = 0, jobDurationMinutes: any = 0, defValue = 1) => {
  let min = (jobDurationHours * 60) + jobDurationMinutes;
  return (min && min > 0) ? min : defValue;
}
export const isTodayDate = (selectedDate: any = null) => {
  return (selectedDate === moment().startOf('day').format(DATE_FORMAT))
}
export const isFutureDate = (selectedDate: any = null, period: any = 'day') => {
  let fromDate = moment(selectedDate, Utils.getDefaultDateFormat()).startOf(period);
  let toDate = moment(selectedDate, Utils.getDefaultDateFormat()).endOf(period);

  let currentFromDate = moment().startOf(period);
  let currentToDate = moment().endOf(period);

  return fromDate.isAfter(currentToDate);
}
export const getJobInfo = (job: any = null) => {
  let data: any = null;

  if(job){
    let position = { lat: null, lng: null };

    if(job.steps && job.steps.length > 0){
      for(let i = 0; i < job.steps.length; i++){
        if(job.steps[i].customerSiteId && job.steps[i].customerSiteId > 0){
          position = { lat: job.steps[i].latitude, lng: job.steps[i].longitude };
        }
      }
    }

    let statusName = getStatusName(job);
    let statusColor = getColorByStatus(statusName);

	  let shouldntTaskShow = ([ 'draft' ].includes(statusName)) ? false : true;
    let isStarted = ([ 'unassigned', 'assigned', 'dispatched', 'acknowledged' ].includes(statusName)) ? false : true;
    let isInProgress = ([ 'inprogress' ].includes(statusName)) ? true : false;
    let isCompleted = ([ 'completed' ].includes(statusName)) ? true : false;
    let hasJobTimeSpecific = (job.jobTimeSpecific && job.jobTimeSpecific !== '') ? true : false;
    let hasActualJobTimeSpecific = (job.actualJobTimeSpecific && job.actualJobTimeSpecific !== '') ? true : false;

	  let parentJobId = (job && job.parentJobId) ? job.parentJobId : null;
	  let parentJobRelation = (job && job.parentJobRelation) ? job.parentJobRelation : null;
	  let isTripJob = (parentJobId === null && (parentJobRelation === null || parentJobRelation === 1)) ? false : true;

    let jobDurationHours = (job.jobDurationHours && job.jobDurationHours > 0) ? job.jobDurationHours : 0; 
    let jobDurationMinutes = (job.jobDurationMinutes && job.jobDurationMinutes > 0) ? job.jobDurationMinutes : 0; 
    let minutes = getMinutes(jobDurationHours, jobDurationMinutes, 0);

    let planedFrom = (isStarted) ? hasJobTimeSpecific ? moment(job.jobTimeSpecific) : moment(job.jobDate) : hasJobTimeSpecific ? moment(job.jobTimeSpecific) : null;
    let planedTo = planedFrom ? planedFrom.clone().add(minutes, 'minutes') : null;
    let planedToReal = planedFrom ? planedFrom.clone().add(minutes, 'minutes') : null;

    let actualFrom = hasActualJobTimeSpecific ? moment(job.actualJobTimeSpecific) : moment(job.jobDate);
    let actualToReal = job.jobAttemptCompletedDate ? moment(job.jobAttemptCompletedDate) : null;
    let actualTo = job.jobAttemptCompletedDate ? moment(job.jobAttemptCompletedDate) : null;

    let jobTime = job.jobDate ? moment(job.jobDate).format(VIEW_TIME_FORMAT) : null;
    let startedTime = job.actualJobTimeSpecific ? moment(job.actualJobTimeSpecific).format(VIEW_TIME_FORMAT) : null;
    let inProgressTime = job.inProgressTime ? moment(job.inProgressTime).format(VIEW_TIME_FORMAT) : null;
    let completedTime = job.jobAttemptCompletedDate ? moment(job.jobAttemptCompletedDate).format(VIEW_TIME_FORMAT) : null;
    
    if(planedFrom && planedTo){
      data = {
        jobId: job.jobId,
        address: job.location,
        position: position,
        statusName: statusName,
        statusColor: statusColor,
        jobTime: jobTime,
        shouldntTaskShow: shouldntTaskShow,
        parentJobId: parentJobId,
        parentJobRelation: parentJobRelation,
        isTripJob: isTripJob,
        isStarted: isStarted,
        startedTime: startedTime,
        isInProgress: isInProgress,
        inProgressTime: inProgressTime,
        isCompleted: isCompleted,
        completedTime: completedTime,
        binType: (job.binTypeIn && job.binTypeIn != '') ? job.binTypeIn : job.binTypeOut,
        wasteType: (job.wasteTypeIn && job.wasteTypeIn != '') ? job.wasteTypeIn : job.wasteTypeOut,
        hasJobTimeSpecific: hasJobTimeSpecific,
        hasActualJobTimeSpecific: hasActualJobTimeSpecific,
        minutes: minutes,
        planedFromObj: planedFrom,
        planedFrom: planedFrom ? planedFrom.format(VIEW_TIME_FORMAT) : null,
        planedToObj: planedTo,
        planedTo: planedTo ? planedTo.format(VIEW_TIME_FORMAT) : null,
        planedToRealObj: planedToReal,
        planedToReal: planedToReal ? planedToReal.format(VIEW_TIME_FORMAT) : '',
        actualFromObj: actualFrom,
        actualFrom: actualFrom ? actualFrom.format(VIEW_TIME_FORMAT) : null,
        actualToObj: actualTo,
        actualTo: actualTo ? actualTo.format(VIEW_TIME_FORMAT) : null,
        actualToRealObj: actualToReal,
        actualToReal: actualToReal ? actualToReal.format(VIEW_TIME_FORMAT) : '',
        fromTime: isCompleted ? actualFrom ? actualFrom.format(VIEW_TIME_FORMAT) : '' : planedFrom ? planedFrom.format(VIEW_TIME_FORMAT) : '',
        toTime: isCompleted ? actualTo ? actualTo.format(VIEW_TIME_FORMAT) : '' : planedTo ? planedTo.format(VIEW_TIME_FORMAT) : '',
        data: job,
      }
    }
  }

  return data;
}
export const getColorByStatus = (status = '') => {
  if(status === 'unsettime'){
    return '#F0F0F1'
  } else if(status === 'unassigned'){
    return '#C3C4C9'
  } else if(status === 'assigned'){
    return '#C3C4C9'

  } else if(status === 'dispatched'){
    return '#7F818D'
  } else if(status === 'acknowledged'){
    return '#7F818D'

  } else if(status === 'started'){
    return '#185CFF'
  } else if(status === 'inprogress'){
    return '#BD1FF5'
  } else if(status === 'completed'){
    return '#43936C'

  } else if(status === 'cancelled'){
    return '#CB3A31'
  } else if(status === 'failed'){
    return '#CB3A31'
  } else if(status === 'rejected'){
    return '#CB3A31'

  } else {
    return '#7F818D'
  }
}


interface InitState {
  isInitLoading: boolean,
  isVertical: boolean,
  showActualJobTime: boolean,

  search: any,
  selectedDate: any,
  period: any,
  jobInfo: Array<any>,

  isLoadingDaily: boolean,
  allData: Array<any>,
  rowsDaily: Array<any>,
  rowsActualDaily: Array<any>,
  tasksDaily: Array<any>,
  sortDaily: any,
  isLoadingDailyStatus: boolean,
  statusDaily: any,

  isLoadingWeekly: boolean,
  rowsWeekly: Array<any>,
  sortWeekly: any,
  isLoadingWeeklyStatus: boolean,
  statusWeekly: any,

  isLoadingMonthly: boolean,
  rowsMonthly: Array<any>,
  sortMonthly: any,
  isLoadingMonthlyStatus: boolean,
  statusMonthly: any,

  isLoadingDispatchAll: boolean,
  isLoadingDispatch: boolean,
  isLoadingTime: boolean,
}


function NewReducer() {
  const name = 'ganttChart';


  const initialState: InitState = {
    isInitLoading: false,
    isVertical: true,
    showActualJobTime: false,

    search: '',
    selectedDate: moment().startOf('day').format(DATE_FORMAT),
    period: GanttTablePeriod.Daily,
    jobInfo: [],

    isLoadingDaily: false,
    allData: [],
    rowsDaily: [],
    rowsActualDaily: [],
    tasksDaily: [],
    sortDaily: { sortColumn: 'updated', sortDir: 'desc' },
    isLoadingDailyStatus: false,
    statusDaily: {
      total: {
        count: 0,
        jobStatusId: null,
        jobStatusName: "Total jobs",
      },
      unallocated: {
        count: 0,
        jobStatusId: null,
        jobStatusName: "Unset Time",
      },
      unassigned: {
        count: 0,
        jobStatusId: null,
        jobStatusName: "Unassigned",
      },
      assigned: {
        count: 0,
        jobStatusId: null,
        jobStatusName: "Assigned",
      },
      dispatched: {
        count: 0,
        jobStatusId: null,
        jobStatusName: "Dispatched",
      },
      acknowledged: {
        count: 0,
        jobStatusId: null,
        jobStatusName: "Acknowledged",
      },
      started: {
        count: 0,
        jobStatusId: null,
        jobStatusName: "Started",
      },
      inProgress: {
        count: 0,
        jobStatusId: null,
        jobStatusName: "In Progress",
      },
      completed: {
        count: 0,
        jobStatusId: null,
        jobStatusName: "Completed",
      },
      unsuccessful: {
        count: 0,
        jobStatusId: null,
        jobStatusName: "Cancelled/Failed",
      },
    },

    isLoadingWeekly: false,
    rowsWeekly: [],
    sortWeekly: { sortColumn: 'totalJobCount', sortDir: 'desc' },
    isLoadingWeeklyStatus: false,
    statusWeekly: {
      total: {
        count: 0,
        jobStatusName: "Total jobs"
      },
      completed: {
        count: 0,
        jobStatusName: "Completed"
      },
      unsuccessful: {
        count: 0,
        jobStatusName: "Unsuccessful"
      },
      assigned: {
        count: 0,
        jobStatusName: "Assigned",
      },
    },

    isLoadingMonthly: false,
    rowsMonthly: [],
    sortMonthly: { sortColumn: 'totalJobCount', sortDir: 'desc' },
    isLoadingMonthlyStatus: false,
    statusMonthly: {
      total: {
        count: 0,
        jobStatusName: "Total jobs"
      },
      completed: {
        count: 0,
        jobStatusName: "Completed"
      },
      unsuccessful: {
        count: 0,
        jobStatusName: "Unsuccessful"
      },
      assigned: {
        count: 0,
        jobStatusName: "Assigned",
      },
    },
    
    isLoadingDispatchAll: false,
    isLoadingDispatch: false,
    isLoadingTime: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },

    setIsInitLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isInitLoading = action.payload;
    },

    setIsVertical: (state: InitState, action: PayloadAction<boolean>) => {
      state.isVertical = action.payload;
    },
    showActualJobTime: (state: InitState, action: PayloadAction<boolean>) => {
      state.showActualJobTime = action.payload;
    },

    setSearch: (state: InitState, action: PayloadAction<any>) => {
      state.search = action.payload;
    },
    setSelectedDate: (state: InitState, action: PayloadAction<any>) => {
      state.selectedDate = action.payload;
    },
    setPeriod: (state: InitState, action: PayloadAction<any>) => {
      state.period = action.payload;
    },
    addJobInfo: (state: InitState, action: PayloadAction<any>) => {
      let item = action.payload;

      let jobInfo = state.jobInfo;
      let index = jobInfo.findIndex((x: any) => x.id == item.id);
      if (index === -1) {
        jobInfo.push(item);
      }
      
      state.jobInfo = jobInfo;
    },
    removeJobInfo: (state: InitState, action: PayloadAction<any>) => {
      let item = action.payload;

      let jobInfo = state.jobInfo;
      let index = jobInfo.findIndex((x: any) => x.id == item.id);
      if (index > -1) {
        jobInfo.splice(index, 1);
      }

      state.jobInfo = jobInfo;
    },
    
    setSortDaily: (state: InitState, action: PayloadAction<{ sortColumn: string, sortDir: string }>) => {
      state.sortDaily = action.payload;
    },
    setSortWeekly: (state: InitState, action: PayloadAction<{ sortColumn: string, sortDir: string }>) => {
      state.sortWeekly = action.payload;
    },
    setSortMonthly: (state: InitState, action: PayloadAction<{ sortColumn: string, sortDir: string }>) => {
      state.sortMonthly = action.payload;
    },

    setIsLoadingDaily: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoadingDaily = action.payload;
    },
    setTasksDaily: (state: InitState, action: PayloadAction<Array<any>>) => {
      state.tasksDaily = action.payload;
      state.isLoadingDaily = false;
    },

    startReadDaily: (state: InitState) => {
      state.isLoadingDaily = true;
      state.allData = [];
      state.rowsDaily = [];
      state.rowsActualDaily = [];
    },
    finishReadDaily: (state: InitState, action: PayloadAction<{ data: any, filters: any }>) => {
      let payload = (action.payload && action.payload.data && action.payload.data) ? action.payload.data : null;
      let filters = (action.payload && action.payload.filters && action.payload.filters) ? action.payload.filters : null;

      let data = (payload && payload.data && payload.data.length > 0) ? payload.data : [];

      let rowsItems: Array<any> = [];
      let rowsActulItems: Array<any> = [];
      let tasksDaily: Array<any> = [];

      if(data && data.length > 0){
        data.forEach((itemDriver: any, d: number) => {
          rowsItems.push({
            id: itemDriver.driverId,
            headerHtml: '<div class="my-custom-row" data-id="' + itemDriver.driverId + '"></div>',
            type: GanttType.Row,
            data: itemDriver,
          });

          rowsActulItems.push({
            id: itemDriver.driverId,
            headerHtml: '<div class="my-custom-row" data-id="' + itemDriver.driverId + '"></div>',
            type: GanttType.Row,
            data: itemDriver,
          });
          // if(itemDriver && itemDriver.jobs && itemDriver.jobs.length > 0 && itemDriver.jobs.filter((x: any) => x.statusName == 'Completed').length > 0){
            rowsActulItems.push({
              id: itemDriver.driverId + '_actual',
              enableDragging: false,
              enableResize: false,
              headerHtml: '<div class="my-custom-row" data-id="' + itemDriver.driverId + '_actual"></div>',
              type: GanttType.Actual,
              data: itemDriver,
              classes: 'actual-row',
            });
          // }

          if(itemDriver && itemDriver.jobs && itemDriver.jobs.length > 0){
            itemDriver.jobs.forEach((itemJob: any, j: number) => {
              let jobObj = getJobInfo(itemJob);
              if(jobObj){
                let parentJobItem = itemDriver.jobs.find((x: any) => x.jobId == jobObj.parentJobId);
                if(jobObj.parentJobId && !parentJobItem){
                  jobObj.isTripJob = false;
                } else {
                  if(parentJobItem && (parentJobItem.jobTimeSpecific == jobObj.jobTimeSpecific)){
                    jobObj.isTripJob = true;
                  }
                }

                if(jobObj.shouldntTaskShow && !jobObj.isTripJob){
                  let isTimeFilter = isTimeFilterIncluded(filters);
                  let shouldShowTask = (isTimeFilter && itemJob.hasJobTimeSpecific == false) ? false : true;
              
                  if(shouldShowTask){
                    let planedDiffInMinutes = jobObj.planedToObj ? jobObj.planedToObj.diff(jobObj.planedFrom, 'minutes') : null;
                    if(planedDiffInMinutes && planedDiffInMinutes < 1){
                      jobObj.planedToObj = jobObj.planedFromObj ? jobObj.planedFromObj.clone().add(1, 'minutes') : null;
                    }
                    
                    let isDraggableResizableObj = isDraggableResizable(itemJob);

                    let childJobsIds = itemDriver.jobs.filter((x: any) => x.parentJobId == itemJob.jobId).map((x: any) => x.jobId);

                    if(jobObj.planedFrom && jobObj.planedTo){
                      // Planed job time
                      tasksDaily.push({
                        id: itemJob.jobId,
                        resourceId: itemDriver.driverId,
                        draggable: isDraggableResizableObj.draggable,
                        resizable: isDraggableResizableObj.resizable,
                        amountDone: 0,
                        classes: jobObj.statusName,
                        type: GanttType.Task,
                        data: itemJob,
                        from: jobObj.planedFromObj,
                        to: jobObj.planedToObj,
                        fromTime: jobObj.planedFromObj ? jobObj.planedFromObj.format(VIEW_TIME_FORMAT) : '',
                        toTime: jobObj.planedToRealObj ? jobObj.planedToRealObj.format(VIEW_TIME_FORMAT) : '',
                        binType: jobObj.binType,
                        wasteType: jobObj.wasteType,
                        address: jobObj.address,
                        statusName: jobObj.statusName,
                        childJobsIds: childJobsIds,
                      });
                    }

                    if(jobObj.statusName === 'completed'){
                      let actualDiffInMinutes = jobObj.actualToObj.diff(jobObj.actualFromObj, 'minutes');
                      if(actualDiffInMinutes < 1){
                        jobObj.actualToObj = jobObj.actualFromObj.clone().add(1, 'minutes');
                      }
                      
                      if(jobObj.actualFrom && jobObj.actualTo){
                        tasksDaily.push({
                          id: itemJob.jobId + '_actual',
                          resourceId: itemDriver.driverId + '_actual',
                          draggable: false,
                          resizable: false,
                          amountDone: 0,
                          classes: 'completed',
                          type: GanttType.Actual,
                          data: itemJob,
                          from: jobObj.actualFromObj,
                          to: jobObj.actualToObj,
                          fromTime: jobObj.actualFromObj ? jobObj.actualFromObj.format(VIEW_TIME_FORMAT) : '',
                          toTime: jobObj.actualToRealObj ? jobObj.actualToRealObj.format(VIEW_TIME_FORMAT) : '',
                          binType: jobObj.binType,
                          wasteType: jobObj.wasteType,
                          address: jobObj.address,
                          statusName: jobObj.statusName,
                          childJobsIds: [],
                        });
                      }
                    }
                  }
                }
              }
            });
          }
        });
      }

      rowsItems.push({
        id: 'empty_row_test_1',
        headerHtml: '<div class="my-custom-row"></div>',
        type: GanttType.Empty,
        data: null,
        classes: 'empty-row',
      });
      rowsItems.push({
        id: 'empty_row_test_2',
        headerHtml: '<div class="my-custom-row"></div>',
        type: GanttType.Empty,
        data: null,
        classes: 'empty-row',
      });

      state.allData = data;
      state.rowsDaily = rowsItems;
      state.rowsActualDaily = rowsActulItems;
      state.tasksDaily = tasksDaily;
      state.isLoadingDaily = false;
    },

    startReadDailyStatus: (state: InitState) => {
      state.isLoadingDailyStatus = true;
      state.statusDaily = {
        total: {...state.statusDaily.total, count: 0, jobStatusId: null },
        unallocated: {...state.statusDaily.unallocated, count: 0, jobStatusId: null },
        unassigned: {...state.statusDaily.unassigned, count: 0, jobStatusId: null },
        assigned: {...state.statusDaily.assigned, count: 0, jobStatusId: null },
        dispatched: {...state.statusDaily.dispatched, count: 0, jobStatusId: null },
        acknowledged: {...state.statusDaily.acknowledged, count: 0, jobStatusId: null },
        started: {...state.statusDaily.started, count: 0, jobStatusId: null },
        inProgress: {...state.statusDaily.inProgress, count: 0, jobStatusId: null },
        completed: {...state.statusDaily.completed, count: 0, jobStatusId: null },
        unsuccessful: {...state.statusDaily.unsuccessful, count: 0, jobStatusId: null },
      };
    },
    finishReadDailyStatus: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      
      let statusTotal = {...state.statusDaily.total, count: 0, jobStatusId: null };
      let statusUnallocated = {...state.statusDaily.unallocated, count: 0, jobStatusId: null };
      let statusUnassigned = {...state.statusDaily.unassigned, count: 0, jobStatusId: null };
      let statusAssigned = {...state.statusDaily.assigned, count: 0, jobStatusId: null };
      let statusDispatched = {...state.statusDaily.dispatched, count: 0, jobStatusId: null };
      let statusAcknowledged = {...state.statusDaily.acknowledged, count: 0, jobStatusId: null };
      let statusStarted = {...state.statusDaily.started, count: 0, jobStatusId: null };
      let statusInProgress = {...state.statusDaily.inProgress, count: 0, jobStatusId: null };
      let statusCompleted = {...state.statusDaily.completed, count: 0, jobStatusId: null };
      let statusUnsuccessful = {...state.statusDaily.unsuccessful, count: 0, jobStatusId: null };

      if (data && data.length > 0) {
        let statusItem0 = data.filter((x: any) => (
          x.jobStatusName.toLowerCase().trim() === 'Unassigned'.toLowerCase().trim()
        ));
        if (statusItem0 && statusItem0.length > 0) {
          statusTotal.count = statusTotal.count + statusItem0[0].count;
          statusUnassigned.count = statusItem0[0].count;
          statusUnassigned.jobStatusId = statusItem0[0].jobStatusId;
        }

        let statusItem1 = data.filter((x: any) => (
          x.jobStatusName.toLowerCase().trim() === 'Unset Time'.toLowerCase().trim()
        ));
        if (statusItem1 && statusItem1.length > 0) {
          statusUnallocated.count = statusItem1[0].count;
          statusUnallocated.jobStatusId = statusItem1[0].jobStatusId;
        }

        let statusItem2 = data.filter((x: any) => (
          x.jobStatusName.toLowerCase().trim() === 'Assigned'.toLowerCase().trim()
        ));
        if (statusItem2 && statusItem2.length > 0) {
          statusTotal.count = statusTotal.count + statusItem2[0].count;
          statusAssigned.count = statusItem2[0].count;
          statusAssigned.jobStatusId = statusItem2[0].jobStatusId;
        }

        let statusItem3 = data.filter((x: any) => (
          x.jobStatusName.toLowerCase().trim() === 'Dispatched'.toLowerCase().trim()
        ));
        if (statusItem3 && statusItem3.length > 0) {
          statusTotal.count = statusTotal.count + statusItem3[0].count;
          statusDispatched.count = statusItem3[0].count;
          statusDispatched.jobStatusId = statusItem3[0].jobStatusId;
        }
        
        let statusItem4 = data.filter((x: any) => (
          x.jobStatusName.toLowerCase().trim() === 'Acknowledged'.toLowerCase().trim()
        ));
        if (statusItem4 && statusItem4.length > 0) {
          statusTotal.count = statusTotal.count + statusItem4[0].count;
          statusAcknowledged.count = statusItem4[0].count;
          statusAcknowledged.jobStatusId = statusItem4[0].jobStatusId;
        }

        let statusItem5 = data.filter((x: any) => (
          x.jobStatusName.toLowerCase().trim() === 'Started'.toLowerCase().trim()
        ));
        if (statusItem5 && statusItem5.length > 0) {
          statusTotal.count = statusTotal.count + statusItem5[0].count;
          statusStarted.count = statusItem5[0].count;
          statusStarted.jobStatusId = statusItem5[0].jobStatusId;
        }

        let statusItem6 = data.filter((x: any) => (
          x.jobStatusName.toLowerCase().trim() === 'In Progress'.toLowerCase().trim()
        ));
        if (statusItem6 && statusItem6.length > 0) {
          statusTotal.count = statusTotal.count + statusItem6[0].count;
          statusInProgress.count = statusItem6[0].count;
          statusInProgress.jobStatusId = statusItem6[0].jobStatusId;
        }

        let statusItem7 = data.filter((x: any) => (
          x.jobStatusName.toLowerCase().trim() === 'Completed'.toLowerCase().trim()
        ));
        if (statusItem7 && statusItem7.length > 0) {
          statusTotal.count = statusTotal.count + statusItem7[0].count;
          statusCompleted.count = statusItem7[0].count;
          statusCompleted.jobStatusId = statusItem7[0].jobStatusId;
        }

        let statusItem8 = data.filter((x: any) => (
          (x.jobStatusName.toLowerCase().trim() === 'Failed'.toLowerCase().trim()) ||
          (x.jobStatusName.toLowerCase().trim() === 'Cancelled'.toLowerCase().trim())
        ));
        if (statusItem8 && statusItem8.length > 0) {
          statusTotal.count = statusTotal.count + statusItem8[0].count + statusItem8[1].count;
          statusUnsuccessful.count = statusItem8[0].count + statusItem8[1].count;
          statusUnsuccessful.jobStatusId = [statusItem8[0].jobStatusId, statusItem8[1].jobStatusId];
        }
      }
      
      state.statusDaily = {
        total: statusTotal,
        unallocated: statusUnallocated,
        unassigned: statusUnassigned,
        assigned: statusAssigned,
        dispatched: statusDispatched,
        acknowledged: statusAcknowledged,
        started: statusStarted,
        inProgress: statusInProgress,
        completed: statusCompleted,
        unsuccessful: statusUnsuccessful,
      };;
      state.isLoadingDailyStatus = false;
    },

    startReadWeekly: (state: InitState) => {
      state.isLoadingWeekly = true;
      state.rowsWeekly = [];
    },
    finishReadWeekly: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.rowsWeekly = data;
      state.isLoadingWeekly = false;
    },

    startReadWeeklyStatus: (state: InitState) => {
      state.isLoadingWeeklyStatus = true;
      state.statusWeekly = {
        total: {...state.statusWeekly.total, count: 0 },
        completed: {...state.statusWeekly.completed, count: 0 },
        unsuccessful: {...state.statusWeekly.unsuccessful, count: 0 },
        assigned: {...state.statusDaily.assigned, count: 0 },
      };
    },
    finishReadWeeklyStatus: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];

      let statusTotal = {...state.statusWeekly.total, count: 0 };
      let statusCompleted = {...state.statusWeekly.completed, count: 0 };
      let statusUnsuccessful = {...state.statusWeekly.unsuccessful, count: 0 };
      let statusAssigned = {...state.statusDaily.assigned, count: 0 };

      if (data && data.length > 0) {
        if (data && data.length > 0) {
          let statusItem2 = data.filter((x: any) => (
            x.jobStatusName.toLowerCase().trim() === 'Assigned'.toLowerCase().trim()
          ));
          if (statusItem2 && statusItem2.length > 0) {
            statusTotal.count = statusTotal.count + statusItem2[0].count;
            statusAssigned.count = statusItem2[0].count;
            statusAssigned.jobStatusId = statusItem2[0].jobStatusId;
          }
  
          let statusItem3 = data.filter((x: any) => (
            x.jobStatusName.toLowerCase().trim() === 'Dispatched'.toLowerCase().trim()
          ));
          if (statusItem3 && statusItem3.length > 0) {
            statusTotal.count = statusTotal.count + statusItem3[0].count;
          }
          
          let statusItem4 = data.filter((x: any) => (
            x.jobStatusName.toLowerCase().trim() === 'Acknowledged'.toLowerCase().trim()
          ));
          if (statusItem4 && statusItem4.length > 0) {
            statusTotal.count = statusTotal.count + statusItem4[0].count;
          }
  
          let statusItem5 = data.filter((x: any) => (
            x.jobStatusName.toLowerCase().trim() === 'Started'.toLowerCase().trim()
          ));
          if (statusItem5 && statusItem5.length > 0) {
            statusTotal.count = statusTotal.count + statusItem5[0].count;
          }
  
          let statusItem6 = data.filter((x: any) => (
            x.jobStatusName.toLowerCase().trim() === 'In Progress'.toLowerCase().trim()
          ));
          if (statusItem6 && statusItem6.length > 0) {
            statusTotal.count = statusTotal.count + statusItem6[0].count;
          }
  
          let statusItem7 = data.filter((x: any) => (
            x.jobStatusName.toLowerCase().trim() === 'Completed'.toLowerCase().trim()
          ));
          if (statusItem7 && statusItem7.length > 0) {
            statusTotal.count = statusTotal.count + statusItem7[0].count;
            statusCompleted.count = statusItem7[0].count;
            statusCompleted.jobStatusId = statusItem7[0].jobStatusId;
          }
  
          let statusItem8 = data.filter((x: any) => (
            (x.jobStatusName.toLowerCase().trim() === 'Failed'.toLowerCase().trim()) ||
            (x.jobStatusName.toLowerCase().trim() === 'Cancelled'.toLowerCase().trim())
          ));
          if (statusItem8 && statusItem8.length > 0) {
            statusTotal.count = statusTotal.count + statusItem8[0].count + statusItem8[1].count;
            statusUnsuccessful.count = statusItem8[0].count + statusItem8[1].count;
            statusUnsuccessful.jobStatusId = [statusItem8[0].jobStatusId, statusItem8[1].jobStatusId];
          }
        }
      }

      state.statusWeekly = {
        total: statusTotal,
        completed: statusCompleted,
        unsuccessful: statusUnsuccessful,
        assigned: statusAssigned,
      };
      state.isLoadingWeeklyStatus = false;
    },
    
    startReadMonthly: (state: InitState) => {
      state.isLoadingMonthly = true;
      state.rowsMonthly = [];
    },
    finishReadMonthly: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.rowsMonthly = data;
      state.isLoadingMonthly = false;
    },

    startReadMonthlyStatus: (state: InitState) => {
      state.isLoadingMonthlyStatus = true;
      state.statusMonthly = {
        total: {...state.statusMonthly.total, count: 0 },
        completed: {...state.statusMonthly.completed, count: 0 },
        unsuccessful: {...state.statusMonthly.unsuccessful, count: 0 },
        assigned: {...state.statusDaily.assigned, count: 0 },
      };
    },
    finishReadMonthlyStatus: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];

      let statusTotal = {...state.statusMonthly.total, count: 0 };
      let statusCompleted = {...state.statusMonthly.completed, count: 0 };
      let statusUnsuccessful = {...state.statusMonthly.unsuccessful, count: 0 };
      let statusAssigned = {...state.statusDaily.assigned, count: 0 };

      if (data && data.length > 0) {
        if (data && data.length > 0) {
          let statusItem2 = data.filter((x: any) => (
            x.jobStatusName.toLowerCase().trim() === 'Assigned'.toLowerCase().trim()
          ));
          if (statusItem2 && statusItem2.length > 0) {
            statusTotal.count = statusTotal.count + statusItem2[0].count;
            statusAssigned.count = statusItem2[0].count;
            statusAssigned.jobStatusId = statusItem2[0].jobStatusId;
          }
  
          let statusItem3 = data.filter((x: any) => (
            x.jobStatusName.toLowerCase().trim() === 'Dispatched'.toLowerCase().trim()
          ));
          if (statusItem3 && statusItem3.length > 0) {
            statusTotal.count = statusTotal.count + statusItem3[0].count;
          }
          
          let statusItem4 = data.filter((x: any) => (
            x.jobStatusName.toLowerCase().trim() === 'Acknowledged'.toLowerCase().trim()
          ));
          if (statusItem4 && statusItem4.length > 0) {
            statusTotal.count = statusTotal.count + statusItem4[0].count;
          }
  
          let statusItem5 = data.filter((x: any) => (
            x.jobStatusName.toLowerCase().trim() === 'Started'.toLowerCase().trim()
          ));
          if (statusItem5 && statusItem5.length > 0) {
            statusTotal.count = statusTotal.count + statusItem5[0].count;
          }
  
          let statusItem6 = data.filter((x: any) => (
            x.jobStatusName.toLowerCase().trim() === 'In Progress'.toLowerCase().trim()
          ));
          if (statusItem6 && statusItem6.length > 0) {
            statusTotal.count = statusTotal.count + statusItem6[0].count;
          }
  
          let statusItem7 = data.filter((x: any) => (
            x.jobStatusName.toLowerCase().trim() === 'Completed'.toLowerCase().trim()
          ));
          if (statusItem7 && statusItem7.length > 0) {
            statusTotal.count = statusTotal.count + statusItem7[0].count;
            statusCompleted.count = statusItem7[0].count;
            statusCompleted.jobStatusId = statusItem7[0].jobStatusId;
          }
  
          let statusItem8 = data.filter((x: any) => (
            (x.jobStatusName.toLowerCase().trim() === 'Failed'.toLowerCase().trim()) ||
            (x.jobStatusName.toLowerCase().trim() === 'Cancelled'.toLowerCase().trim())
          ));
          if (statusItem8 && statusItem8.length > 0) {
            statusTotal.count = statusTotal.count + statusItem8[0].count + statusItem8[1].count;
            statusUnsuccessful.count = statusItem8[0].count + statusItem8[1].count;
            statusUnsuccessful.jobStatusId = [statusItem8[0].jobStatusId, statusItem8[1].jobStatusId];
          }
        }
      }

      state.statusMonthly = {
        total: statusTotal,
        completed: statusCompleted,
        unsuccessful: statusUnsuccessful,
        assigned: statusAssigned,
      };;
      state.isLoadingMonthlyStatus = false;
    },
    
    startDispatch: (state: InitState) => {
      state.isLoadingDispatch = true;
    },
    finishDispatch: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingDispatch = false;
    },
    
    startTime: (state: InitState) => {
      state.isLoadingTime = true;
    },
    finishTime: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingTime = false;
    },
    
    startDispatchAll: (state: InitState) => {
      state.isLoadingDispatchAll = true;
    },
    finishDispatchAll: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingDispatchAll = false;
    },
  };


  const apis = {
    callReadDailyStatusApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadDailyStatus());

      await dispatchCrudApi.readApi(params, 'job/status/stat').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishReadDailyStatus(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishReadDailyStatus(null));
      });
    },
    callReadDailyApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any, getState: any) => {
      dispatch(actions.startReadDaily());

      await dispatchCrudApi.readApi(params, 'job/group-by-driver').then(result => {
          let data = result.data;

          const { details } = getState().ganttChartFiltersSlice;

          callback(true, data);
          dispatch(actions.finishReadDaily({ data: data, filters: details }));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishReadDaily({ data: null, filters: null }));
      });
    },

    callReadWeeklyApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadWeekly());

      await dispatchCrudApi.readApi(params, 'job/group-by-driver-stats').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishReadWeekly(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishReadWeekly(null));
      });
    },
    callReadWeeklyStatusApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadWeeklyStatus());

      await dispatchCrudApi.readApi(params, 'job/status/stat').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishReadWeeklyStatus(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishReadWeeklyStatus(null));
      });
    },
    
    callReadMonthlyApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadMonthly());

      await dispatchCrudApi.readApi(params, 'job/group-by-driver-stats').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishReadMonthly(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishReadMonthly(null));
      });
    },
    callReadMonthlyStatusApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadMonthlyStatus());

      await dispatchCrudApi.readApi(params, 'job/status/stat').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishReadMonthlyStatus(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishReadMonthlyStatus(null));
      });
    },
    
    callDispatchApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDispatch());

      await dispatchCrudApi.updateApi(params, 'job/' + params.jobId + '/dispatch').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishDispatch(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishDispatch(null));
      });
    },
    
    callTimeApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startTime());

      await dispatchCrudApi.updateApi(params, 'job/' + params.jobId + '/time').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishTime(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishTime(null));
      });
    },
    
    callDispatchAllApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDispatchAll());

      await dispatchCrudApi.updateApi(params, 'job/' + params.jobId + '/batch-dispatch').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishDispatchAll(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishDispatchAll(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();