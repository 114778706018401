import React from 'react';
import moment from 'moment';

import { 
    Row,
    Col,
    Modal,
    Form,
    InputGroup,
    Button,
    CloseButton,
} from "react-bootstrap-v5";

import RSuiteDatePicker from '../../components/OLD/Datepicker/RSuiteDatePicker';

import Utils from '../../utils/utils';

  
const DateDialog = props => {
    const [show, setShow] = React.useState(((props.show === false) || (props.show === true)) ? props.show : false);

    const [value, setValue] = React.useState(null);
    const [isInvalid, setIsInvalid] = React.useState(false);


    React.useEffect(() => {
        setShow(((props.show === false) || (props.show === true)) ? props.show : false);
        setValue(null);
        setIsInvalid(false);
    }, [props.show]);

    
    return <Modal
        className={props.className}
        show={show}
        onHide={() => {
            if(props.onCancel){
                props.onCancel();
            }
        }}
        size="sm"
        keyboard={false}
        animation={false}
    >
        <form 
            noValidate 
            autoComplete="off"
            onSubmit={(e) => {
                e.stopPropagation();
                e.preventDefault();

                setIsInvalid(!value);

                if(props.onAccept && value){
                    let formatedValue = value ? moment(value).format(Utils.getAPIDateFormat()) : null;
                    props.onAccept(value, formatedValue);
                }
            }}
        >
            <Modal.Header>
                <Row className={'align-items-center gx-0 w-100'}>
                    <Col xs={true}>
                        <Modal.Title>{props.title ? props.title : ''}</Modal.Title>
                    </Col>
                    <Col xs={'auto'}>
                        <CloseButton
                            className={'rounded-2'}
                            onClick={() => {
                                if(props.onCancel){
                                    props.onCancel();
                                }
                            }}
                        />
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>{props.label ? props.label : 'Date'}</Form.Label>
                    <Form.Control
                        as = {RSuiteDatePicker}
                        isInvalid={isInvalid}
                        
                        placeholder={props.placeholder ? props.placeholder : 'Select date'}
                        id={'date_dialog_ID'}
                        value={value}
                        icon={<></>}
                        placement={'autoVerticalStart'}
                        cleanable={true}
                        isDefault={true}
                        disabledDate={props.disabledDate}
                        onChange={(value) => {
                            setValue(value);
                        }}
                        onClear={() => {
                            setValue(null);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{props.error ? props.error : 'You must select a date'}</Form.Control.Feedback>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    type={'button'}
                    size='sm'
                    variant={props.cancelVariant ? props.cancelVariant : 'light'}
                    disabled={false}
                    onClick={() => {
                        if(props.onCancel){
                            props.onCancel();
                        }
                    }}
                >
                    {props.cancelText ? props.cancelText : 'Cancel'}
                </Button>
                <Button 
                    type={'submit'}
                    size='sm'
                    variant={props.okVariant ? props.okVariant : 'primary'}
                    disabled={false}
                >
                    {props.okText ? props.okText : 'OK'}
                </Button>
            </Modal.Footer>
        </form>
    </Modal>
}


export default DateDialog;
