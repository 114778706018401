import React from 'react';
// import { useHistory } from "react-router-dom";
import moment from 'moment';
import _ from 'lodash';
import { useFormik } from 'formik';

import {
  Row,
  Col,
  Button,
  Modal,
  Form,
} from 'react-bootstrap-v5';
import styled from 'styled-components';

import Offcanvas from "react-bootstrap/Offcanvas";
import 'bootstrap/dist/css/bootstrap.min.css';

import Carousel from 'react-elastic-carousel';
import { Spin } from 'antd';

import { DatePicker } from 'rsuite';
import "rsuite/dist/rsuite.min.css";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../../../setup'
import slice, { FormikContext, fields, formSchema, prepareForm, prepareData } from './slice';
import { VIEW_DATE_FORMAT } from '../slice';

// import { ReactComponent as UpIcon } from "../../../../../_metronic/assets/icons/gantt-sort-up.svg";
// import { ReactComponent as DownIcon } from "../../../../../_metronic/assets/icons/gantt-sort-down.svg";

import { updateApi } from '../../../../api/CRUD/DispatchCRUD'

import Utils from '../../../../utils/utils';
// import {  } from '../../../../utils/enums';
// import {  } from "../../../../utils/styles";

// ----------------------------------------------------------------------

const StyledDatePickerField = styled(DatePicker)`
    padding: 0px;

    .rs-picker-toggle {
        padding: 5px 5px 5px 15px;
        padding-right: 5px !important;
        border-radius: 6px;
        border: 0px;
        box-shadow: none !important;

        &.rs-btn {
            padding-bottom: 5px !important;
            padding-top: 5px !important;
        }

        &:hover {
            background-color: white !important;
        }

        input, .rs-picker-toggle-value, .rs-picker-toggle-placeholder {
            font-size: 1.1rem !important;
            font-weight: 500 !important;
            line-height: 1.5 !important;
            color: #6E707E;
        }

        .rs-stack-item {
            &:first-child {
                left: -6px !important;
                position: relative;
            }

            .rs-picker-toggle-textbox {
                padding-left: 0px !important;
            }
            .rs-picker-toggle-placeholder {
                padding-left: 0px !important;
            
                color: #A1A5B7 !important;
            }
        
            svg {
                display: none;
            }
        }
    }
`;
const StyledTimePickerField = styled(DatePicker)`
    padding: 0px;
    padding-right: 0px !important;
    
    .rs-btn-subtle {
        box-shadow: none !important;
        background-color: transparent !important;
    }

    .rs-picker-toggle {
        padding: 5px 5px 5px 15px;
        padding-right: 5px !important;
        border-radius: 6px;

        &:hover {
            background-color: white !important;
        }

        input, .rs-picker-toggle-value, .rs-picker-toggle-placeholder {
            font-size: 1.1rem !important;
            font-weight: 500 !important;
            line-height: 1.5 !important;
            color: #6E707E;
        }

        .rs-stack-item {
            &:first-child {
                left: -6px !important;
                position: relative;
            }
        
            .rs-picker-toggle-textbox {
                padding-left: 0px !important;
            }
            .rs-picker-toggle-placeholder {
                padding-left: 0px !important;
            
                color: #A1A5B7 !important;
            }
        
            svg {
                display: none;
            }
        }
    }
`;


const ListComponent = (props: any) => {
    // let history = useHistory();

    const dispatch = useDispatch();
    const { show, details, isLoading, items } = useSelector((state: RootState) => state.ganttChartUnsetTimeSlice);
    const { selectedDate, statusDaily } = useSelector((state: RootState) => state.ganttChartSlice);
    
    const pageCaption = 'Unset Time Job';

    const [ sortColumnDir, setSortColumnDir ] = React.useState<any>({ sortColumn: 'jobDate', sortDir: 'asc' });



    React.useEffect(() => {
        if(show){
            apiCall();
        }
    }, [show]);


    const getFromToDate = () => {
        let fromDate = moment(selectedDate, Utils.getDefaultDateFormat()).startOf('day').format(Utils.getAPIDateFormat());
        let toDate = moment(selectedDate, Utils.getDefaultDateFormat()).endOf('day').format(Utils.getAPIDateFormat());
    
        return {
          fromDate: fromDate,
          toDate: toDate,
        }
    }

    const apiCall = () => {
        let fromToDate = getFromToDate();

        let params = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            fromDate: fromToDate.fromDate,
            toDate: fromToDate.toDate,
            sortColumn: sortColumnDir?.sortColumn,
            sortDir: sortColumnDir?.sortDir,
            driverFilter: details?.data?.driverId?.toString(),
            JobStatusFilter: statusDaily?.assigned?.jobStatusId?.toString() + ',' + statusDaily?.dispatched?.jobStatusId?.toString(),
            isUnsetTime: true,
        };
        dispatch(slice.callReadApi(params, (state: boolean, data: any) => {
            let form = prepareForm(data?.data);
            setValues(form);
        }));
    }


    const formik = useFormik({
        initialValues: [],
        validationSchema: formSchema(),
        validateOnMount: false,
        validateOnChange: false,
        onSubmit: values => {
            onSubmit(values, (data: any, rows: any) => {
                if(props && props.onSave){
                    props.onSave(rows);
                }
                
                onCancel();
            });
        },
    });
    const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = formik;


    const onSubmit = (values: any, callback: (data: any, rows: any) => void) => {
        dispatch(slice.setLoading(true));
        let data = prepareData(values);

        let arr: any = [];
        if (data && data.length > 0) {
            arr = data.map((item: any) => {
                return {
                    api: updateApi,
                    path: 'job/' + item.jobId + '/time',
                    params: () => {
                        let jobDurationHours = (item && item.jobDurationHours) ? item.jobDurationHours : 2;
                        let jobDurationMinutes = (item && item.jobDurationMinutes) ? item.jobDurationMinutes : 0;

                        return {
                            jobId: item.jobId,
                            jobStatusId: item.jobStatusId,
                            driverId: item.driverId,
                            vehicleId: item.vehicleId,
                            jobDate: item.scheduledDate,
                            jobDurationHours: jobDurationHours,
                            jobDurationMinutes: jobDurationMinutes,
                            jobTimeFrom: item.scheduledTime,
                            jobTimeSpecific: item.scheduledTime,
                        }
                    },
                }
            });
        }

        Utils.callAllApi(arr, null, (results: any) => {
            let arr: any = [];
            
            if(results && results.length > 0){
                results.forEach((item: any) => {
                    if(item && item.status && item.status === 200){
                        if(item.data && item.data.data){
                            arr.push(item.data.data);
                        }
                    }
                });
            }

            callback(data, arr);
            dispatch(slice.setLoading(false));
        });
    }
    const onCancel = () => {
        let form = prepareForm(null);
        dispatch(slice.setShow({ show: false, details: null }));
        setValues(form);
        dispatch(slice.resetSlice());
    }

    
    const statusSection = () => {
        let fromToDate = moment(selectedDate, Utils.getDefaultDateFormat()).format(VIEW_DATE_FORMAT);

        return <Row className='align-items-center'>
          <Col xs={12}>
            <Carousel
              className={'custom-carousel unset-time'}
              itemsToShow={9}
              outerSpacing={0}
              itemPadding={[0, 0]}
              initialActiveIndex={0}
              breakPoints={[
                { width: 1, itemsToShow: 1 },
                { width: 200, itemsToShow: 2 },
              ]}
              showEmptySlots={false}
              showArrows={true}
              pagination={false}
              isRTL={false}
            >
                <div className='custom-carousel-item'>
                    <Spin spinning={false} size={'small'}>
                    <div className='count'>Driver</div>
                    <div className='text'>{details?.data?.driverName}</div>
                    <div className='v-divider v-divider-full position-absolute end-0' style={{ top: '-10px', height: 'calc(100% + 20px)' }}></div>
                    </Spin>
                </div>
              
                <div className='custom-carousel-item'>
                    <Spin spinning={false} size={'small'}>
                        <div className='count'>Date</div>
                        <div className='text'>{fromToDate}</div>
                    </Spin>
                </div>
                
            </Carousel>
          </Col>
        </Row>
    }

    const tableSection = () => {
		return <table className='gantt-chart-side-panel-table w-100 mt-4'>
            <thead>
                <tr>
                    <th style={{ minWidth: '150px' }}>{fields.customerName.label}</th>
                    <th style={{ minWidth: '150px' }}>{fields.jobNumber.label}</th>
                    <th style={{ minWidth: '150px' }}>{fields.jobTemplateName.label}</th>
                    <th style={{ minWidth: '300px' }}>{fields.location.label}</th>
                    <th style={{ minWidth: '150px', maxWidth: '150px' }}>{fields.scheduledDate.label}</th>
                    <th style={{ minWidth: '150px', maxWidth: '150px' }}>{fields.scheduledTime.label}</th>
                </tr>
            </thead>
            
            <tbody>
            {
                (values && values.form && values.form.length > 0)
                ?
                values.form.map((item: any, i: number)=> {
                    return <tr key={'no-responsive-table gantt-chart-unset-time-table-row-' + i}>
                        <td className='text-left'>{(item.customerName && item.customerName !== '') ? item.customerName : ''}</td>
                        <td className='text-left'>
                            <a href={'/jobs-form?id=' + item.jobId} 
                                target='_blank'
                                className='menu-link'
                            >
                                {(item.jobNumber && item.jobNumber !== '') ? item.jobNumber : ''}
                            </a>
                        </td>
                        <td className='text-left'>{(item.jobTemplateName && item.jobTemplateName !== '') ? item.jobTemplateName : ''}</td>
                        <td className='text-left'>{(item.location && item.location !== '') ? item.location : ''}</td>

                        <td>
                            <Form.Group>
                                <Form.Control
                                    as = {StyledDatePickerField}
                                    isInvalid={!!(errors && errors.form && errors.form[i] && errors.form[i].scheduledDate)}
                                    
                                    placeholder={fields.scheduledDate.placeholder}
                                    id={fields.scheduledDate.id}
                                    value={item.scheduledDate}
                                    icon={<></>}
                                    placement={'bottom'}
                                    readOnly={false}
                                    cleanable={true}
                                    oneTap={false}
                                    isoWeek
                                    // disabledDate={(date) => {
                                    //     return ((!values.isEdit || values.isCopy)) ? false : (moment(date).isAfter(moment().subtract(1, 'days'))) ? false;
                                    // }}
                                    onChange={async (value: any) => {
                                        if(value){
                                            let jobDate = moment(value).format(Utils.getAPIDateFormat());
                                            let jobTime = (item.scheduledTime) ? moment(item.scheduledTime).format(Utils.getDefaultTimeFormat()) : moment().format(Utils.getDefaultTimeFormat());
                                            let jobDateTime = moment(jobDate + ' ' + jobTime, Utils.getAPIDateTimeFormat())
                                            let jobDateTimeObj = jobDateTime.toDate();
                                            
                                            await setFieldValue('form.' + i + '.scheduledDate', jobDateTimeObj);

                                            if(item.scheduledTime){
                                                await setFieldValue('form.' + i + '.scheduledTime', jobDateTimeObj);
                                            }
                                        } else {
                                            await setFieldValue('form.' + i + '.scheduledDate', null);
                                        }
                                    }}
                                />
                                {/* <Form.Control.Feedback type="invalid">{(errors && errors.form && errors.form[i] && errors.form[i].scheduledDate) && errors.form[i].scheduledDate}</Form.Control.Feedback> */}
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group>
                                <Form.Control
                                    as = {StyledTimePickerField}
                                    isInvalid={!!(errors && errors.form && errors.form[i] && errors.form[i].scheduledTime)}
                                    
                                    appearance={'subtle'}
                                    preventOverflow
                                    showMeridian={false}
                                    format="HH:mm"
                                    hideMinutes={(minute: any) => minute % 5 !== 0}
                                    ranges={props.ranges ? props.ranges : [
                                        {
                                            label: 'Now',
                                            value: new Date()
                                        }
                                    ]}

                                    placeholder={fields.scheduledTime.placeholder}
                                    id={fields.scheduledTime.id}
                                    value={item.scheduledTime}
                                    placement={'bottom'}
                                    readOnly={false}
                                    cleanable={true}
                                    onChange={async (value: any) => {
                                        if(value){
                                            let jobDate = (item.scheduledDate) ? moment(item.scheduledDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                                            let jobTime = moment(value).format(Utils.getDefaultTimeFormat());
                                            let jobDateTime = moment(jobDate + ' ' + jobTime, Utils.getAPIDateTimeFormat())
                                            let jobDateTimeObj = jobDateTime.toDate();
                                            
                                            await setFieldValue('form.' + i + '.scheduledTime', jobDateTimeObj);

                                            if(item.scheduledDate){
                                                await setFieldValue('form.' + i + '.scheduledDate', jobDateTimeObj);
                                            }
                                        } else {
                                            await setFieldValue('form.' + i + '.scheduledTime', null);
                                        }
                                    }}
                                />
                                {/* <Form.Control.Feedback type="invalid">{(errors && errors.form && errors.form[i] && errors.form[i].scheduledTime) && errors.form[i].scheduledTime}</Form.Control.Feedback> */}
                            </Form.Group>
                        </td>
                    </tr>
                })
                :
                <tr>
                    <td colSpan={5} className={'title text-center'}>{(isLoading) ? <Spin spinning={isLoading} size={'small'} /> : 'No results'}</td>
                </tr>
            }
            </tbody>
        </table>
	}


    return <Offcanvas
        backdrop={true}
        enforceFocus={false}
        scroll={false}
        placement={'end'}
        show={show}
        onHide={() => {
            onCancel();
        }}
        style={{ width: '85%' }}
    >
        <>
            <FormikContext.Provider value={formik}>
                <Offcanvas.Header closeButton className='py-7'>
                    <Offcanvas.Title className='mb-0'>{details?.data?.unsetJobTimeCount} {pageCaption}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Spin spinning={isLoading}>
                        <Row className={'g-7'}>
                            <Col xs={12}>{statusSection()}</Col>
                            <Col xs={12}>{tableSection()}</Col>
                        </Row>
                    </Spin>
                </Offcanvas.Body>
                <Modal.Footer>
                    <Row className='px-2 pb-2 pt-5'>
                        <Col xs={true} className='text-end'>
                            <Button
                                className='me-5'
                                variant={'light'}
                                size={'sm'}
                                disabled={isLoading}
                                onClick={(e) => {
                                    onCancel();
                                }}
                            >Cancel</Button>
                            <Button
                                variant={'primary'}
                                size={'sm'}
                                disabled={isLoading}
                                onClick={() => {
                                    handleSubmit()
                                }}
                            >Save Changes</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </FormikContext.Provider>
        </>
    </Offcanvas>
}

export default ListComponent;
