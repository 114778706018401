import React from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';

import {
  Row,
  Col,
  Form,
  Button,
} from 'react-bootstrap-v5';
// import styled from 'styled-components';

import { Select, Tag } from 'antd';

import { Loader } from 'rsuite';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../../../setup'
import slice, { FormikContext, fields, initialValues, formSchema, prepareForm, prepareData, getTimeItems } from './slice';

import { GanttBinWasteType } from '../DailyList';

import { ReactComponent as SearchIcon } from "../../../../../_metronic/assets/icons/search.svg";
import { ReactComponent as UncheckedIcon } from "../../../../../_metronic/assets/icons/gantt-cb-unchecked.svg";
import { ReactComponent as CheckedIcon } from "../../../../../_metronic/assets/icons/gantt-cb-checked.svg";

// import Utils from '../../../../utils/utils';
// import {  } from '../../../../utils/enums';
// import {  } from "../../../../utils/styles";

// ----------------------------------------------------------------------


export const SelectDropdownMenu = ({ menu, search, footer }: any) => {
    return <Row>
        <Col xs={12} className='px-6 py-3' style={{ borderBottom: '1px solid #DEDEE1' }}>{search}</Col>
        <Col xs={12} className='px-4 py-3'>{menu}</Col>
        <Col xs={12} className='px-6 py-3' style={{ backgroundColor: '#F6F9FF' }}>{footer}</Col>
    </Row>
}

export const SelectDropdownMenuItem = ({ values, option, field }: any) => {
    return <Row className={'align-items-center g-4'}>
        <Col xs={'auto'}>{(values?.includes(option?.value)) ? <CheckedIcon className='position-relative' style={{ top: '-2px' }} /> : <UncheckedIcon className='position-relative' style={{ top: '-2px' }} />}</Col>
        <Col xs={true}>{option?.data[field]}</Col>
    </Row>
}

const SelectTag = ({ label, value, closable, onClose }: any) => {
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return <Tag
        className={'custom-primary'}
        bordered={false}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4 }}
    >
        {label}
    </Tag>
}

const SelectDropdownMenuItemStatus = ({ values, option, field }: any) => {
    return <Row className={'align-items-center g-4'}>
        <Col xs={'auto'}>{(values?.includes(option?.value)) ? <CheckedIcon className='position-relative' style={{ top: '-2px' }} /> : <UncheckedIcon className='position-relative' style={{ top: '-2px' }} />}</Col>
        <Col xs={true} className={option?.data[field].toString().trim().toLowerCase().replaceAll(' ', '') + '-txt'}>{option?.data[field]}</Col>
    </Row>
}

const SelectTagStatus = ({ label, value, closable, onClose }: any) => {
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return <Tag
        className={label.toString().trim().toLowerCase().replaceAll(' ', '')}
        bordered={false}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4 }}
    >
        {label}
    </Tag>
}


const FormComponent = (props: any) => {
    const dispatch = useDispatch();
    const { isLoading, details, isLoadingJobType, jobType, isLoadingStatus, status, isLoadingVehicle, vehicle } = useSelector((state: RootState) => state.ganttChartFiltersSlice);

    const [isOpenJobType, setIsOpenJobType] = React.useState<boolean>(false);
    const [searchJobType, setSearchJobType] = React.useState<string>('');

    const [isOpenStatus, setIsOpenStatus] = React.useState<boolean>(false);
    const [searchStatus, setSearchStatus] = React.useState<string>('');

    const [isOpenVehicle, setIsOpenVehicle] = React.useState<boolean>(false);
    const [searchVehicle, setSearchVehicle] = React.useState<string>('');

    const [isOpenJobTimeFrom, setIsOpenJobTimeFrom] = React.useState<boolean>(false);
    const [isOpenJobTimeTo, setIsOpenJobTimeTo] = React.useState<boolean>(false);


    React.useEffect(() => {
        dispatch(slice.setLoading(true));
        let form = prepareForm(details, initialValues);
        setValues(form);
        dispatch(slice.setLoading(false));
    }, []);
    

    const formik = useFormik({
        validateOnMount: false,
        validateOnChange: false,
        initialValues: initialValues,
        validationSchema: formSchema(null),
        onSubmit: values => {
            let data = prepareData(values);

            dispatch(slice.setDetails(values));

            if(props && props.onSave){
                props.onSave(data);
            }
            
            onCancel();
        },
    });
    const { values, errors, setValues, setFieldValue, handleSubmit }: any = formik;


    const onCancel = () => {
        setValues(initialValues);
    }


    const debouncedSearch = React.useCallback(
        _.debounce((callback) => {
            if(callback){
                callback()
            }
        }, 500),
        []
    );


    const setFooter = () => {
        return <Row>
            <Col xs={true} className='text-start'>
                <Button
                    variant={'custom-outlined'}
                    size={'sm'}
                    disabled={isLoading}
                    onClick={(e) => {
                        onCancel();
                    }}
                >Clear</Button>
            </Col>
            <Col xs={true} className='text-end'>
                <Button
                    variant={'custom-primary'}
                    size={'sm'}
                    disabled={isLoading}
                    onClick={() => {
                        handleSubmit()
                    }}
                >Done</Button>
            </Col>
        </Row>
    }

    const setForm = () => {
        return <Row style={{ gap: '16px' }}>
            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.jobType.label}</Form.Label>
                    <Form.Control
                        as = {Select}
                        isInvalid={!!errors.jobType}
                        id={fields.jobType.id}
                        placeholder={fields.jobType.placeholder}

                        mode='multiple'
                        fieldNames={{ value: 'jobTemplateId', label: 'jobTemplateName' }}
                        loading={isLoadingJobType}
                        options={jobType}
                        value={values.jobType}
                        
                        open={isOpenJobType}
                        allowClear={true}
                        showSearch={false}
                        virtual={true}
                        menuItemSelectedIcon={null}

                        tagRender={(props: any) => {
                            return <SelectTag {...props} />
                        }}
                        optionRender={(option: any) => {
                            return <SelectDropdownMenuItem
                                values={values.jobType}
                                option={option}
                                field={'jobTemplateName'}
                            />
                        }}
                        dropdownRender={(menu: any) => {
                            return <SelectDropdownMenu
                                menu={menu}
                                search={<div className={'search-input'}>
                                    <SearchIcon />
                                    <Form.Control
                                        type="text"
                                        size='sm'
                                        placeholder={'Search'}
                                        disabled={isLoadingJobType}
                                        value={searchJobType}
                                        onChange={(e) => {
                                            setSearchJobType(e.target.value);
                                            debouncedSearch(() => {
                                                dispatch(slice.callReadJobTypeApi(e.target.value, (state, data) => {}));
                                            });
                                        }}
                                    />
                                </div>}
                                footer={<Row className={'align-items-center'}>
                                    <Col xs={true} className='text-start'>
                                        <Button
                                            variant={'custom-outlined'}
                                            size={'sm'}
                                            onClick={async (e) => {
                                                await setFieldValue('jobType', []);
                                                setIsOpenJobType(false)
                                            }}
                                        >Clear</Button>
                                    </Col>
                                    <Col xs={true} className='text-end'>
                                        <Button
                                            variant={'custom-primary'}
                                            size={'sm'}
                                            onClick={() => {
                                                setIsOpenJobType(false)
                                            }}
                                        >Done</Button>
                                    </Col>
                                </Row>}
                            />
                        }}
                        onDropdownVisibleChange={(open: any) => {
                            setIsOpenJobType(open)
                            if(open){
                                dispatch(slice.callReadJobTypeApi('', (state: boolean, data: any) => {}));
                            } else {
                                setSearchJobType('');
                            }
                        }}
                        onChange={async (value: any) => {
                            if(value){
                                await setFieldValue('jobType', value);
                            } else {
                                await setFieldValue('jobType', []);
                            }
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.jobType}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            
            <Col xs={12}>
                <Row className='align-items-center gx-2'>
                    <Col xs={12}>
                        <Form.Label>{fields.jobTimeFrom.label}</Form.Label>
                    </Col>
                    <Col xs={12} md={true}>
                        <Form.Group>
                            <Form.Control
                                as = {Select}
                                isInvalid={!!errors.jobTimeFrom}
                                id={fields.jobTimeFrom.id}
                                placeholder={fields.jobTimeFrom.placeholder}

                                fieldNames={{ value: 'value', label: 'label' }}
                                loading={false}
                                options={getTimeItems('from', values.jobTimeFrom, values.jobTimeTo)}
                                value={values.jobTimeFrom}
                                
                                open={isOpenJobTimeFrom}
                                allowClear={true}
                                showSearch={false}
                                virtual={true}
                                menuItemSelectedIcon={null}

                                onDropdownVisibleChange={(open: any) => {
                                    setIsOpenJobTimeFrom(open)
                                }}
                                onChange={async (value: any) => {
                                    if(value){
                                        await setFieldValue('jobTimeFrom', value);
                                    } else {
                                        await setFieldValue('jobTimeFrom', null);
                                    }
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{errors.jobTimeFrom}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={'auto'}>-</Col>
                    <Col xs={12} md={true}>
                        <Form.Group>
                            <Form.Control
                                as = {Select}
                                isInvalid={!!errors.jobTimeTo}
                                id={fields.jobTimeTo.id}
                                placeholder={fields.jobTimeTo.placeholder}

                                fieldNames={{ value: 'value', label: 'label' }}
                                loading={false}
                                options={getTimeItems('to', values.jobTimeFrom, values.jobTimeTo)}
                                value={values.jobTimeTo}
                                
                                open={isOpenJobTimeTo}
                                allowClear={true}
                                showSearch={false}
                                virtual={true}
                                menuItemSelectedIcon={null}

                                onDropdownVisibleChange={(open: any) => {
                                    setIsOpenJobTimeTo(open)
                                }}
                                onChange={async (value: any) => {
                                    if(value){
                                        await setFieldValue('jobTimeTo', value);
                                    } else {
                                        await setFieldValue('jobTimeTo', null);
                                    }
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{errors.jobTimeTo}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            </Col>

            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.status.label}</Form.Label>
                    <Form.Control
                        as = {Select}
                        isInvalid={!!errors.status}
                        id={fields.status.id}
                        placeholder={fields.status.placeholder}

                        mode='multiple'
                        fieldNames={{ value: 'jobStatusId', label: 'jobStatusName' }}
                        loading={isLoadingStatus}
                        options={status}
                        value={values.status}
                        
                        open={isOpenStatus}
                        allowClear={true}
                        showSearch={false}
                        virtual={true}
                        menuItemSelectedIcon={null}

                        tagRender={(props: any) => {
                            return <SelectTagStatus {...props} />
                        }}
                        optionRender={(option: any) => {
                            return <SelectDropdownMenuItemStatus
                                values={values.status}
                                option={option}
                                field={'jobStatusName'}
                            />
                        }}
                        dropdownRender={(menu: any) => {
                            return <SelectDropdownMenu
                                menu={menu}
                                search={<div className={'search-input'}>
                                    <SearchIcon />
                                    <Form.Control
                                        type="text"
                                        size='sm'
                                        placeholder={'Search'}
                                        disabled={isLoadingStatus}
                                        value={searchStatus}
                                        onChange={(e) => {
                                            setSearchStatus(e.target.value);
                                            debouncedSearch(() => {
                                                dispatch(slice.callReadStatusApi(e.target.value, (state, data) => {}));
                                            });
                                        }}
                                    />
                                </div>}
                                footer={<Row className={'align-items-center'}>
                                    <Col xs={true} className='text-start'>
                                        <Button
                                            variant={'custom-outlined'}
                                            size={'sm'}
                                            onClick={async (e) => {
                                                await setFieldValue('status', []);
                                                setIsOpenStatus(false)
                                            }}
                                        >Clear</Button>
                                    </Col>
                                    <Col xs={true} className='text-end'>
                                        <Button
                                            variant={'custom-primary'}
                                            size={'sm'}
                                            onClick={() => {
                                                setIsOpenStatus(false)
                                            }}
                                        >Done</Button>
                                    </Col>
                                </Row>}
                            />
                        }}
                        onDropdownVisibleChange={(open: any) => {
                            setIsOpenStatus(open)
                            if(open){
                                dispatch(slice.callReadStatusApi('', (state: boolean, data: any) => {}));
                            } else {
                                setSearchStatus('');
                            }
                        }}
                        onChange={async (value: any) => {
                            if(value){
                                await setFieldValue('status', value);
                            } else {
                                await setFieldValue('status', []);
                            }
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.status}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.vehicle.label}</Form.Label>
                    <Form.Control
                        as = {Select}
                        isInvalid={!!errors.vehicle}
                        id={fields.vehicle.id}
                        placeholder={fields.vehicle.placeholder}

                        mode='multiple'
                        fieldNames={{ value: 'vehicleId', label: 'vehicleName' }}
                        loading={isLoadingVehicle}
                        options={vehicle}
                        value={values.vehicle}
                        
                        open={isOpenVehicle}
                        allowClear={true}
                        showSearch={false}
                        virtual={true}
                        menuItemSelectedIcon={null}

                        tagRender={(props: any) => {
                            return <SelectTag {...props} />
                        }}
                        optionRender={(option: any) => {
                            return <SelectDropdownMenuItem
                                values={values.vehicle}
                                option={option}
                                field={'vehicleName'}
                            />
                        }}
                        dropdownRender={(menu: any) => {
                            return <SelectDropdownMenu
                                menu={menu}
                                search={<div className={'search-input'}>
                                    <SearchIcon />
                                    <Form.Control
                                        type="text"
                                        size='sm'
                                        placeholder={'Search'}
                                        disabled={isLoadingVehicle}
                                        value={searchVehicle}
                                        onChange={(e) => {
                                            setSearchVehicle(e.target.value);
                                            debouncedSearch(() => {
                                                dispatch(slice.callReadVehicleApi(e.target.value, (state, data) => {}));
                                            });
                                        }}
                                    />
                                </div>}
                                footer={<Row className={'align-items-center'}>
                                    <Col xs={true} className='text-start'>
                                        <Button
                                            variant={'custom-outlined'}
                                            size={'sm'}
                                            onClick={async (e) => {
                                                await setFieldValue('vehicle', []);
                                                setIsOpenVehicle(false)
                                            }}
                                        >Clear</Button>
                                    </Col>
                                    <Col xs={true} className='text-end'>
                                        <Button
                                            variant={'custom-primary'}
                                            size={'sm'}
                                            onClick={() => {
                                                setIsOpenVehicle(false)
                                            }}
                                        >Done</Button>
                                    </Col>
                                </Row>}
                            />
                        }}
                        onDropdownVisibleChange={(open: any) => {
                            setIsOpenVehicle(open)
                            if(open){
                                dispatch(slice.callReadVehicleApi('', (state: boolean, data: any) => {}));
                            } else {
                                setSearchVehicle('');
                            }
                        }}
                        onChange={async (value: any) => {
                            if(value){
                                await setFieldValue('vehicle', value);
                            } else {
                                await setFieldValue('vehicle', []);
                            }
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.vehicle}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            
            <Col xs={12}>
                <Row>
                    <Col xs={12}>
                        <Form.Label>{fields.binType.label}</Form.Label>
                    </Col>
                    <Col xs={true}>
                        <Form.Check 
                            type={'radio'}
                            className={'sm'}
                            name={'filters_bin_type'}
                            id={fields.binType.id + '_in'}
                            label={fields.binType.option1}
                            inline={true}
                            checked={values.binType == GanttBinWasteType.IN}
                            onChange={() => {}}
                            onClick={async (e: any) => {
                                if(values.binType == GanttBinWasteType.IN){
                                    await setFieldValue('binType', GanttBinWasteType.NONE);
                                } else {
                                    await setFieldValue('binType', GanttBinWasteType.IN);
                                }
                            }}
                        />
                    </Col>
                    <Col xs={true}>
                        <Form.Check 
                            type={'radio'}
                            className={'sm'}
                            name={'filters_bin_type'}
                            id={fields.binType.id + '_out'}
                            label={fields.binType.option2}
                            inline={true}
                            checked={values.binType == GanttBinWasteType.OUT}
                            onChange={() => {}}
                            onClick={async (e: any) => {
                                if(values.binType == GanttBinWasteType.OUT){
                                    await setFieldValue('binType', GanttBinWasteType.NONE);
                                } else {
                                    await setFieldValue('binType', GanttBinWasteType.OUT);
                                }
                            }}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    }


    return <FormikContext.Provider value={formik}>
        <Row>
            <Col xs={12} className='px-5 py-5'>{setForm()}</Col>
            <Col xs={12} className='px-5 py-5 mt-5' style={{ backgroundColor: '#F6F9FF' }}>{setFooter()}</Col>
        </Row>
        {isLoading && <Loader center backdrop content={'loading'} />}
    </FormikContext.Provider>
}

export default FormComponent;
