/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as yup from 'yup';
import moment from 'moment';
import _ from 'lodash';

import * as dispatchCrudApi from '../../../../api/CRUD/DispatchCRUD'
import Utils from "../../../../utils/utils";
// import {  } from "../../../../utils/enums";

import { ParseResult } from "../../../../utils/interfaces";


export const fields = {
  customerName: {
    id: 'customerName',
    label: 'Customer Name',
    placeholder: '',
  },
  jobTemplateName: {
    id: 'jobTemplateName',
    label: 'Job Type',
    placeholder: '',
  },
  driverId: {
    id: 'driverId',
    label: 'Driver',
    placeholder: 'Select Driver',
  },
  vehicleId: {
    id: 'vehicleId',
    label: 'Vehicle',
    placeholder: 'Select Vehicle',
  },
  jobStatusId: {
    id: 'jobStatusId',
    label: 'Status',
    placeholder: 'Select Status',
  },
  scheduledDate: {
    id: 'scheduledDate',
    label: 'Scheduled Date',
    placeholder: 'Pick a Date',
  },
  scheduledTime: {
    id: 'scheduledTime',
    label: 'Scheduled Time',
    placeholder: 'Pick a time',
  },
};

export const formSchema = () => {
  return yup.object().shape({
    form: yup.array().of(yup.object().shape({
      driverId: yup.number().nullable().label(fields.driverId.label),
      vehicleId: yup.number().nullable().label(fields.vehicleId.label),
      jobStatusId: yup.number().nullable().label(fields.jobStatusId.label),
      scheduledDate: yup.date().nullable().label(fields.scheduledDate.label),

      scheduledTime: yup.date().when(['scheduledDate'], (scheduledDate) => {
        if(scheduledDate){
          return yup.date().nullable().required().label(fields.scheduledTime.label);
        } else {
          return yup.date().nullable().label(fields.scheduledTime.label);
        }
      }),
    }))
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
  formikContext = useContext(FormikContext);
  if (!formikContext) {
    throw new Error('useFormikContext must be used within a FormikProvider');
  }
  return formikContext;
};


export const prepareForm = (values: any = []) => {
  let form = _.cloneDeep(values);
  let data: any = {
    form: [],
  };
  
  if(form && form.length > 0){
    data.form = form.map((item: any, i: number) => {
      let jobDate = (item.jobDate) ? moment(item.jobDate).toDate() : null;
      let jobTimeSpecific = (item.jobTimeSpecific) ? moment(item.jobTimeSpecific).toDate() : null;

      return { ...item, scheduledDate: jobDate, scheduledTime: jobTimeSpecific };
    });
  }

  return data;
};
export const prepareData = (values: any = null) => {
  let data: any = {};

  if(values.form && values.form.length > 0){
    data = values.form.map((item: any, i: number) => {
      let scheduledDate = item.scheduledDate ? Utils.getLocalIsoDateTime(item.scheduledDate) : null;
      let scheduledTime = item.scheduledTime ? Utils.getLocalIsoDateTime(item.scheduledTime) : null;

      return { ...item, scheduledDate: scheduledDate, scheduledTime: scheduledTime };
    });
  }

  return data;
};


interface InitState {
  show: boolean,
  details: any,
  isLoading: boolean,
  items: Array<any>,
  
  isLoadingDriver: boolean,
  driver: Array<any>,

  isLoadingVehicle: boolean,
  vehicle: Array<any>,

  isLoadingStatus: boolean,
  status: Array<any>,
  availableStatuses: Array<any>,
}

function NewReducer() {
  const name = 'ganttChartUnassignedSlice';


  const initialState: InitState = {
    show: false,
    details: null,
    isLoading: false,
    items: [],

    isLoadingDriver: false,
    driver: [],

    isLoadingVehicle: false,
    vehicle: [],

    isLoadingStatus: false,
    status: [],
    availableStatuses: [
      'Unassigned',
      'Assigned',
      'Dispatched',
      'Acknowledged',
      'Started',
      'In Progress',
      'Completed',
      'Failed',
      'Cancelled',
    ],
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, details: any }>) => {
      state.details = action.payload.details;
      state.show = action.payload.show;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      state.items = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.items = data;
      state.isLoading = false;
    },
    
    startReadDriver: (state: InitState) => {
      state.isLoadingDriver = true;
    },
    finishReadDriver: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.driver = data;
      state.isLoadingDriver = false;
    },

    startReadVehicle: (state: InitState) => {
      state.isLoadingVehicle = true;
    },
    finishReadVehicle: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.vehicle = data;
      state.isLoadingVehicle = false;
    },

    startReadStatus: (state: InitState) => {
      state.isLoadingStatus = true;
    },
    finishReadStatus: (state: InitState, action: PayloadAction<{ data: any, item: any }>) => {
      let data = (action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.length > 0) ? action.payload.data.data : [];
      let item = (action.payload && action.payload.item) ? action.payload.item : null;
      
      let arr: Array<any> = [];
      if(data && data.length > 0){
        if(item.statusName == 'Unassigned'){
          data.forEach((item: any) => {
            if(state.availableStatuses.includes(item.jobStatusName)){
              arr.push({ ...item, statusName: item.jobStatusName, disabled: [ 'Assigned', 'Dispatched', 'Acknowledged', 'Started', 'In Progress', 'Completed', 'Cancelled', 'Failed', 'Rejected' ].some((x: any) => x == item.jobStatusName) });
            }
          });
        } else if(item.statusName == 'Assigned' || item.statusName == 'Draft'){
          data.forEach((item: any) => {
            if(state.availableStatuses.includes(item.jobStatusName)){
              arr.push({ ...item, statusName: item.jobStatusName, disabled: false });
            }
          });

        } else if(item.statusName == 'Dispatched'){
          data.forEach((item: any) => {
            if(state.availableStatuses.includes(item.jobStatusName)){
              arr.push({ ...item, statusName: item.jobStatusName, disabled: false });
            }
          });

        } else if(item.statusName == 'Acknowledged'){
          data.forEach((item: any) => {
            if(state.availableStatuses.includes(item.jobStatusName)){
              arr.push({ ...item, statusName: item.jobStatusName, disabled: false });
            }
          });

        } else if(item.statusName == 'Started'){
          data.forEach((item: any) => {
            if(state.availableStatuses.includes(item.jobStatusName)){
              arr.push({ ...item, statusName: item.jobStatusName, disabled: [ 'Unassigned', 'Assigned', 'Dispatched', 'Acknowledged' ].some((x: any) => x == item.jobStatusName) });
            }
          });
          
        } else if(item.statusName == 'In Progress'){
          data.forEach((item: any) => {
            if(state.availableStatuses.includes(item.jobStatusName)){
              arr.push({ ...item, statusName: item.jobStatusName, disabled: [ 'Unassigned', 'Assigned', 'Dispatched', 'Acknowledged', 'Started' ].some((x: any) => x == item.jobStatusName) });
            }
          });

        } else if(item.statusName == 'Completed'){
          data.forEach((item: any) => {
            if(state.availableStatuses.includes(item.jobStatusName)){
              arr.push({ ...item, statusName: item.jobStatusName, disabled: [ 'Unassigned', 'Assigned', 'Dispatched', 'Acknowledged', 'Started', 'In Progress' ].some((x: any) => x == item.jobStatusName) });
            }
          });

        } else if(item.statusName == 'Cancelled'){
          data.forEach((item: any) => {
            if(state.availableStatuses.includes(item.jobStatusName)){
              arr.push({ ...item, statusName: item.jobStatusName, disabled: [ 'Unassigned', 'Assigned', 'Dispatched', 'Acknowledged', 'Started', 'In Progress', 'Completed', 'Failed' ].some((x: any) => x == item.jobStatusName) });
            }
          });

        } else if(item.statusName == 'Failed'){
          data.forEach((item: any) => {
            if(state.availableStatuses.includes(item.jobStatusName)){
              arr.push({ ...item, statusName: item.jobStatusName, disabled: [ 'Unassigned', 'Assigned', 'Dispatched', 'Acknowledged', 'Started', 'In Progress', 'Completed', 'Cancelled' ].some((x: any) => x == item.jobStatusName) });
            }
          });
        }
      }

      state.status = arr;
      state.isLoadingStatus = false;
    },
  };


  const apis = {
    callReadApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        let paramsData: any = params;

        await dispatchCrudApi.createApi(paramsData, 'job/get-list').then((result: any) => {
            let data = result.data;
            
            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch((error: any) => {
            let res: ParseResult = {
                isError: false,
                errorMessage: null,
                status: null,
            };
    
            Utils.parseErrorTS(error, (result: ParseResult): void => {
                res = result
            });
            
            let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
            if(res.isError && err){
                Utils.toast(err, 'error');
            }
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },
    
    callReadDriverApi: (search: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadDriver());

      let params: any = {
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
        searchQuery: search,
      }

      await dispatchCrudApi.readApi(params, 'driver').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishReadDriver(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishReadDriver(null));
      });
    },

    callReadVehicleApi: (search: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadVehicle());

      let params: any = {
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
        searchQuery: search,
      }

      await dispatchCrudApi.readApi(params, 'vehicle').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishReadVehicle(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishReadVehicle(null));
      });
    },

    callReadStatusApi: (search: any, item: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadStatus());

      let params: any = {
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
        searchQuery: search,
      }

      await dispatchCrudApi.readApi(params, 'jobStatus').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishReadStatus({ data: data, item: item }));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishReadStatus({ data: null, item: null }));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();