/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as yup from 'yup';
import _ from 'lodash';
import moment from 'moment';

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from "../../../utils/utils";
import { XeroPaymentTermType } from "../../../utils/enums";

import { ParseResult } from "../../../utils/interfaces";


export const fields = {
    isActive: {
        id: 'isActive',
        label: 'Status',
        placeholder: ' ',
    },
    customerName: {
        id: 'customerName',
        label: 'Name',
        placeholder: ' ',
    },
    billingName: {
        id: 'billingName',
        label: 'Billing Name',
        placeholder: ' ',
    },
    billingAddress: {
        id: 'billingAddress',
        label: 'Billing Address',
        placeholder: Utils.placeholderRows(4),
    },
    contactNumber: {
        id: 'contactNumber',
        label: 'Phone Number',
        placeholder: ' ',
    },
    note: {
        id: 'note',
        label: 'Customer Note',
        labelDetails: '(For Internal Use)',
        placeholder: Utils.placeholderRows(5),
    },
    customerCategoryId: {
        id: 'customerCategoryId',
        label: 'Category',
        placeholder: ' ',
    },
    businessRegNo: {
        id: 'businessRegNo',
        label: 'Business Reg. No.',
        placeholder: ' ',
    },
    accountBillingProfileId: {
        id: 'accountBillingProfileId',
        label: 'Billing By',
        placeholder: ' ',
    },
    paymentTermsId: {
        id: 'paymentTermsId',
        label: 'Payment Terms',
        placeholder: ' ',
    },
    xeroPaymentTerm: {
        id: 'xeroPaymentTerm',
        label: 'Payment Terms',
        placeholder: ' ',
    },
    xeroPaymentTermType: {
        id: 'xeroPaymentTermType',
        label: '',
        placeholder: ' ',
        error: 'Due date for sales invoices must be between 1 and 31 when set to the following (or current) month.',
    },
    creditLimit: {
        id: 'creditLimit',
        label: 'Credit Limit',
        placeholder: ' ',
    },
    isRequirePaymentCollection: {
        id: 'isRequirePaymentCollection',
        label: 'Collect Payment Onsite',
        placeholder: ' ',
    },
    isAutoSendDoDoc: {
        id: 'isAutoSendDoDoc',
        label: 'Notify Recipients',
        placeholder: ' ',
    },
    emailAddresses: {
          id: 'emailAddresses',
          label: 'Company Email',
          placeholder: '',
          info: 'You can enter only one email address',
    },

    expiryDate: {
      id: 'expiryDate',
      label: 'Expiry Date',
      placeholder: ' ',
      info: 'This customer will be auto inactive at the selected date',
    },

    contactPersons: {
        id: 'contactPersons',
        label: 'Contact Persons',
        placeholder: ' ',
    },
    firstName: {
        id: 'firstName',
        label: 'First Name',
        placeholder: ' ',
    },
    emailAddress: {
        id: 'emailAddress',
        label: 'Email Address',
        placeholder: ' ',
    },
    workPhone: {
        id: 'workPhone',
        label: 'Work Phone',
        placeholder: ' ',
    },
    mobilePhone: {
        id: 'mobilePhone',
        label: 'Mobile',
        placeholder: ' ',
    },
    department: {
        id: 'department',
        label: 'Department',
        placeholder: ' ',
    },
};

export const formSchema = (id: number|null= null) => {
  return yup.object().shape({
    isConnectQuickbook: yup.bool().oneOf([true, false]),
    isConnectXero: yup.bool().oneOf([true, false]),

    isActive: yup.bool().oneOf([true, false]),
    customerName: yup.string().required().label(fields.customerName.label),
    billingName: yup.string().required().label(fields.billingName.label),
    billingAddress: yup.string().label(fields.billingAddress.label),
    contactNumber: yup.string().label(fields.contactNumber.label),
    note: yup.string().label(fields.note.label),
    customerCategoryId: yup.number().nullable().label(fields.customerCategoryId.label),
    businessRegNo: yup.string().label(fields.businessRegNo.label),
    accountBillingProfileId: yup.number().nullable().required().label(fields.accountBillingProfileId.label),
    paymentTermsId: yup.number().nullable().label(fields.paymentTermsId.label),
    
    expiryDate: yup.date().nullable().label(fields.expiryDate.label),

    xeroPaymentTerm: yup.number().when(['isConnectXero', 'xeroPaymentTermType'], (isConnectXero, xeroPaymentTermType) => {
      if(isConnectXero){
        if((xeroPaymentTermType == XeroPaymentTermType.OF_CURRENT_MONTH) || (xeroPaymentTermType == XeroPaymentTermType.OF_FOLLOWING_MONTH)){
          return yup.number().nullable().required().positive().min(0).max(31, fields.xeroPaymentTermType.error).label(fields.xeroPaymentTerm.label);
        } else {
          return yup.number().nullable().required().positive().min(0).label(fields.xeroPaymentTerm.label);
        }
      } else {
        return yup.number().nullable().positive().min(0).label(fields.xeroPaymentTerm.label);
      }
    }),
    xeroPaymentTermType: yup.number().when(['isConnectXero'], (isConnectXero) => {
      if(isConnectXero){
        return yup.number().nullable().required().positive().min(0).label(fields.xeroPaymentTermType.label);
      } else {
        return yup.number().nullable().label(fields.xeroPaymentTermType.label);
      }
    }),
    
    isRequirePaymentCollection: yup.bool().oneOf([true, false]),
    emailAddresses: yup.array().of(yup.string().email('Must be a valid email')).min(0).label(fields.emailAddresses.label),

    contactPersons: yup.array().of(yup.object().shape({
      firstName: yup.string().nullable().label(fields.firstName.label),
      emailAddress: yup.string().nullable().email('Must be a valid email').label(fields.emailAddress.label),
      workPhone: yup.string().nullable().label(fields.workPhone.label),
      mobilePhone: yup.string().nullable().label(fields.mobilePhone.label),
      department: yup.string().nullable().label(fields.department.label),
    })),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    let billingProfileItem = (form && form.billingProfile) ? form.billingProfile : null;
    let accountBillingProfileId = (billingProfileItem && billingProfileItem.accountBillingProfileId) ? billingProfileItem.accountBillingProfileId : null;
    let accountBillingProfileName = (billingProfileItem && billingProfileItem.billingProfileName) ? billingProfileItem.billingProfileName : '';
    let accountBillingProfileItems = (billingProfileItem) ? [{
        value: accountBillingProfileId,
        title: accountBillingProfileName,
        item: billingProfileItem,
    }] : [];


    let isConnectQuickbook = (billingProfileItem && billingProfileItem.isConnectQuickbook) ? billingProfileItem.isConnectQuickbook : false;
    let isConnectXero = (billingProfileItem && billingProfileItem.isConnectXero) ? billingProfileItem.isConnectXero : false;
    
    let paymentTermsId = (form && form.quickBookDefaultTermId) ? form.quickBookDefaultTermId : null;
    let paymentTermsName = (form && form.paymentTerms) ? form.paymentTerms : '';
    let paymentTermsItems = (isConnectQuickbook && paymentTermsId) ? [{
        value: paymentTermsId,
        title: paymentTermsName,
        item: form,
    }] : [];

    let quickBookApp = (billingProfileItem && billingProfileItem.quickBookApp) ? billingProfileItem.quickBookApp : null;
    let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;
    let xeroPaymentTerm = (form && Utils.isNumber(form.xeroPaymentTerm)) ? form.xeroPaymentTerm.toString() : '';
    let xeroPaymentTermType = (form && form.xeroPaymentTermType) ? form.xeroPaymentTermType.toString() : '0';
    
    let customerCategoryItem = (form && form.category) ? form.category : null;
    let customerCategoryId = (customerCategoryItem && customerCategoryItem.customerCategoryId) ? customerCategoryItem.customerCategoryId : null;
    let customerCategoryName = (customerCategoryItem && customerCategoryItem.customerCategoryName) ? customerCategoryItem.customerCategoryName : '';
    let customerCategoryItems = (customerCategoryItem) ? [{
        value: customerCategoryId,
        title: customerCategoryName,
        item: customerCategoryItem,
    }] : [];


    let contactPersons = (form.contactPersons && form.contactPersons.length > 0)
      ?
      form.contactPersons.map((item: any) => {
        let firstName = (item && item.firstName && item.firstName !== '') ? item.firstName : '';
        let emailAddress = (item && item.emailAddress && item.emailAddress !== '') ? item.emailAddress : '';
        let workPhone = (item && item.workPhone && item.workPhone !== '') ? item.workPhone : '';
        let mobilePhone = (item && item.mobilePhone && item.mobilePhone !== '') ? item.mobilePhone : '';
        let department = (item && item.department && item.department !== '') ? item.department : '';
      
        let contactPerson: ContactPerson = {
          firstName: firstName,
          emailAddress: emailAddress,
          workPhone: workPhone,
          mobilePhone: mobilePhone,
          department: department,
        };
        return contactPerson
      })
    :
    [initContactPerson];

    data['isActive'] = ((form.isActive === false) || (form.isActive === true)) ? form.isActive : false;

    data['customerName'] = (form && form.customerName && form.customerName !== '') ? form.customerName : '';
    data['billingName'] = (form && form.billingName && form.billingName !== '') ? form.billingName : '';
    data['billingAddress'] = (form && form.billingAddress && form.billingAddress !== '') ? form.billingAddress : '';
    data['contactNumber'] = (form && form.contactNumber && form.contactNumber !== '') ? form.contactNumber : '';
    data['note'] = (form && form.note && form.note !== '') ? form.note : '';

    data['customerCategoryId'] = customerCategoryId;
    data['customerCategoryName'] = customerCategoryName;

    data['businessRegNo'] = (form && form.businessRegNo && form.businessRegNo !== '') ? form.businessRegNo : '';
    data['accountBillingProfileId'] = accountBillingProfileId;
    data['accountBillingProfileName'] = accountBillingProfileName;
    
    data['paymentTermsId'] = paymentTermsId;
    data['paymentTermsName'] = paymentTermsName;

    data['xeroPaymentTerm'] = xeroPaymentTerm;
    data['xeroPaymentTermType'] = xeroPaymentTermType;

    data['isRequirePaymentCollection'] = ((form.isRequirePaymentCollection === false) || (form.isRequirePaymentCollection === true)) ? form.isRequirePaymentCollection : false;
    data['emailAddresses'] = (form && form.emailAddresses && form.emailAddresses.length > 0) ? form.emailAddresses.split(',') : [];

    data['contactPersons'] = contactPersons;
    
    data['isConnectQuickbook'] = isConnectQuickbook;
    data['isConnectXero'] = isConnectXero;
    data['quickBookAppId'] = quickBookAppId;
    
    data['expiryDate'] = (form && form.expiryDate) ? moment(form.expiryDate).toDate() : null;
  }

  return data;
};
export const prepareData = (values: any = null) => {
  let data: any = {};

  if(values){
    let category = null;
    let customerCategoryId = values.customerCategoryId;
    if(customerCategoryId){
      category = {
        customerCategoryName: values.customerCategoryName,
      }
    }
    
    let accountBillingProfileId = values.accountBillingProfileId;
    let emailAddresses = (values && values.emailAddresses && values.emailAddresses.length > 0) ?  values.emailAddresses.join(",") : '';
    
    let contactPersons: any = [];
    if(values.contactPersons && values.contactPersons.length > 0){
      contactPersons = Utils.removeEmptyRows(values.contactPersons, [ 'firstName', 'emailAddress', 'workPhone', 'mobilePhone', 'department']);
    }

    let expiryDate = values.expiryDate ? moment.utc(values.expiryDate).toDate() : null;


    data['isActive'] = values.isActive;

    data['customerName'] = values.customerName;
    data['billingName'] = values.billingName;
    data['billingAddress'] = values.billingAddress;
    data['contactNumber'] = values.contactNumber;
    data['note'] = values.note;
    data['customerCategoryId'] = values.customerCategoryId;
    data['category'] = category;
    data['businessRegNo'] = values.businessRegNo;
    data['accountBillingProfileId'] = accountBillingProfileId;
    data['isRequirePaymentCollection'] = values.isRequirePaymentCollection;
    data['quickBookDefaultTermId'] = values.paymentTermsId;
    data['xeroPaymentTerm'] = values.xeroPaymentTerm;
    data['xeroPaymentTermType'] = values.xeroPaymentTermType;
    data['emailAddresses'] = emailAddresses;
    data['expiryDate'] = expiryDate;

    data['contactPersons'] = contactPersons;
  }

  return data;
};


interface ContactPerson {
  firstName: string|null,
  emailAddress: string|null,
  workPhone: string|null,
  mobilePhone: string|null,
  department: string|null,
};
export const initContactPerson: ContactPerson = {
  firstName: '',
  emailAddress: '',
  workPhone: '',
  mobilePhone: '',
  department: '',
};


export interface initialValuesStruct {
  isActive: boolean,
  customerName: string,
  billingName: string,
  billingAddress: string,
  contactNumber: string,
  note: string,
  customerCategoryId: any,
  customerCategoryName: string,
  businessRegNo: string,
  accountBillingProfileId: any,
  accountBillingProfileName: string,
  paymentTermsId: any,
  paymentTermsName: string,
  xeroPaymentTerm: string,
  xeroPaymentTermType: string,
  isRequirePaymentCollection: boolean,
  contactPersons: Array<ContactPerson>,
  emailAddresses: Array<any>,

  isConnectQuickbook: boolean,
  isConnectXero: boolean,
  quickBookAppId: any,

  expiryDate: any,
};
export const initialValues: initialValuesStruct = {
  isActive: true,
  customerName: '',
  billingName: '',
  billingAddress: '',
  contactNumber: '',
  note: '',
  customerCategoryId: null,
  customerCategoryName: '',
  businessRegNo: '',
  accountBillingProfileId: null,
  accountBillingProfileName: '',
  paymentTermsId: null,
  paymentTermsName: '',
  xeroPaymentTerm: '',
  xeroPaymentTermType: '0',
  isRequirePaymentCollection: false,
  contactPersons: [initContactPerson],
  emailAddresses: [],

  isConnectQuickbook: false,
  isConnectXero: false,
  quickBookAppId: 0,
  
	expiryDate: null,
};


interface InitState {
  isLoading: boolean,
  show: boolean,
  id: any|null,
  items: Array<any>,
  details: any,

  defaultBillingProfile: any,
  defaultTermItem: any,
}

function NewReducer() {
  const name = 'customerMiniSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    id: null,
    items: [],
    details: initialValues,

    defaultBillingProfile: null,
    defaultTermItem: null,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setId: (state: InitState, action: PayloadAction<any>) => {
      state.id = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, id: number|null}>) => {
      state.id = action.payload.id;
      state.show = action.payload.show;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      state.items = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.items = data;
    },

    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.details = action.payload;
    },

    startCreate: (state: InitState) => {
      state.isLoading = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoading = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },

    startDelete: (state: InitState) => {
      state.isLoading = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },

    startReadAccountBillingProfile: (state: InitState) => {
      state.isLoading = true;
      state.defaultBillingProfile = null;
    },
    finishReadAccountBillingProfile: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let defaultBillingProfile = null;
      let items = (action.payload && action.payload.length > 0) ? action.payload : [];
      if(items && items.length > 0){
        let defaultIndex = items.findIndex((x: any) => x.isDefault);
        if(defaultIndex != -1){
          defaultBillingProfile = items[defaultIndex];
        }
      }
      state.defaultBillingProfile = defaultBillingProfile;
    },

    startReadQuickbookCreditTerms: (state: InitState) => {
      state.isLoading = true;
      state.defaultTermItem = null;
    },
    finishReadQuickbookCreditTerms: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let defaultTermItem = null;
      let quickBookDefaultTermId = (action.payload && action.payload.quickBookDefaultTermId) ? action.payload.quickBookDefaultTermId : null;
      let items = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      if(items && items.length > 0){
        let defaultIndex = items.findIndex((x: any) => x.id === quickBookDefaultTermId);
        if(defaultIndex != -1){
          defaultTermItem = items[defaultIndex];
        }
      }
      state.defaultTermItem = defaultTermItem;
    },
  };


  const apis = {
    callReadApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        let paramsData: any = params;

        await dispatchCrudApi.readApi(paramsData, 'customer').then(result => {
            let data = result.data;
            
            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch(error => {
            let res: ParseResult = {
                isError: false,
                errorMessage: null,
                status: null,
            };
    
            Utils.parseErrorTS(error, (result: ParseResult): void => {
                res = result
            });
            
            let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
            if(res.isError && err){
                Utils.toast(err, 'error');
            }
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },

    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetails());
  
        await dispatchCrudApi.readApi(null, 'customer/' + id).then(result => {
            let data = result.data;
            
            callback(true, data);
            dispatch(actions.finishDetails(data));
        }).catch(error => {
            let res: ParseResult = {
                isError: false,
                errorMessage: null,
                status: null,
            };
    
            Utils.parseErrorTS(error, (result: ParseResult): void => {
                res = result
            });
            
            let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
            if(res.isError && err){
                Utils.toast(err, 'error');
            }
            
            callback(false, null);
            dispatch(actions.finishDetails(null));
        });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreate());
  
        await dispatchCrudApi.createApi(params, 'customer').then(result => {
            let data = result.data;
            
            callback(true, data);
            dispatch(actions.finishCreate(data));
        }).catch(error => {
            let res: ParseResult = {
                isError: false,
                errorMessage: null,
                status: null,
            };
    
            Utils.parseErrorTS(error, (result: ParseResult): void => {
                res = result
            });
            
            let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
            if(res.isError && err){
                Utils.toast(err, 'error');
            }
            
            callback(false, null);
            dispatch(actions.finishCreate(null));
        });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdate());
  
        await dispatchCrudApi.updateApi(params, 'customer').then(result => {
            let data = result.data;
            
            callback(true, data);
            dispatch(actions.finishUpdate(data));
        }).catch(error => {
            let res: ParseResult = {
                isError: false,
                errorMessage: null,
                status: null,
            };
    
            Utils.parseErrorTS(error, (result: ParseResult): void => {
                res = result
            });
            
            let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
            if(res.isError && err){
                Utils.toast(err, 'error');
            }
            
            callback(false, null);
            dispatch(actions.finishUpdate(null));
        });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDelete());
  
        let paramsData: any = { data: params };

        await dispatchCrudApi.deleteApi(paramsData, 'customer').then(result => {
            let data = result.data;
            
            callback(true, data);
            dispatch(actions.finishDelete(data));
        }).catch(error => {
            let res: ParseResult = {
                isError: false,
                errorMessage: null,
                status: null,
            };
    
            Utils.parseErrorTS(error, (result: ParseResult): void => {
                res = result
            });
            
            let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
            if(res.isError && err){
                Utils.toast(err, 'error');
            }
            
            callback(false, null);
            dispatch(actions.finishDelete(null));
        });
    },

    callReadAccountBillingProfileApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startReadAccountBillingProfile());
  
        let paramsData: any = params;

        await dispatchCrudApi.readApi(paramsData, 'AccountBillingProfile').then(result => {
            let data = result.data.data;
            
            callback(true, data);
            dispatch(actions.finishReadAccountBillingProfile(data));
        }).catch(error => {
            let res: ParseResult = {
                isError: false,
                errorMessage: null,
                status: null,
            };
    
            Utils.parseErrorTS(error, (result: ParseResult): void => {
                res = result
            });
            
            let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
            if(res.isError && err){
                Utils.toast(err, 'error');
            }
            
            callback(false, null);
            dispatch(actions.finishReadAccountBillingProfile(null));
        });
    },

    callReadQuickbookCreditTermsApi: (params: any, quickBookDefaultTermId: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startReadQuickbookCreditTerms());
  
        let paramsData: any = params;

        await dispatchCrudApi.readApi(paramsData, 'quickbookapp/term').then(result => {
            let data = result.data;
            
            callback(true, data);
            dispatch(actions.finishReadQuickbookCreditTerms({ data: data, quickBookDefaultTermId: quickBookDefaultTermId }));
        }).catch(error => {
            let res: ParseResult = {
                isError: false,
                errorMessage: null,
                status: null,
            };
    
            Utils.parseErrorTS(error, (result: ParseResult): void => {
                res = result
            });
            
            let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
            if(res.isError && err){
                Utils.toast(err, 'error');
            }
            
            callback(false, null);
            dispatch(actions.finishReadQuickbookCreditTerms({ data: null, quickBookDefaultTermId: null }));
        });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();