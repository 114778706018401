import React from 'react';
// import { useHistory } from "react-router-dom";
import moment from 'moment';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap-v5';
import styled from 'styled-components';

import { Badge as AntdBadge } from 'antd';

import Offcanvas from "react-bootstrap/Offcanvas";
import 'bootstrap/dist/css/bootstrap.min.css';

import { Spin, Tag } from 'antd';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../../../setup'
import slice from './slice';
import { DATE_FORMAT, VIEW_TIME_FORMAT, getJobInfo } from '../slice';

import { convertLatLngToCoord } from '../../../../components/v5/googleMap/GoogleMap';

// import DataTable from "../../../../components/OLD/DataTable/DataTable";

import { ReactComponent as ArrowUpIcon } from "../../../../../_metronic/assets/icons/arrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "../../../../../_metronic/assets/icons/arrow-down.svg";
import { ReactComponent as ClockIcon } from "../../../../../_metronic/assets/icons/gantt-list-clock.svg";
import { ReactComponent as PinIcon } from "../../../../../_metronic/assets/icons/gantt-list-map-pin.svg";
import { ReactComponent as RefreshIcon } from "../../../../../_metronic/assets/icons/gantt-list-refresh-new.svg";

import MapComponent from './Map';

import Utils from '../../../../utils/utils';
// import {  } from '../../../../utils/enums';
// import {  } from "../../../../utils/styles";

// ----------------------------------------------------------------------


const StyledColMap = styled(Col)`
  > div {
    height: 100%;
    
    @media (min-width: 767px) {
        overflow: auto;
        height: calc(100vh - 90px);
    }

    @media (max-width: 767px) {
        overflow: visible;
        height: 400px;
    }
  }
`;

const StyledBadge = styled(AntdBadge)`
    margin-left: 5px;
    margin-right: 5px;

    .ant-badge-status-dot {
        width: 3px !important;
        height: 3px !important;
    }
`;

const StyledCardRow = styled(Col)`
    hr {
        margin: 8px 0px 8px 0px;
        opacity: 1;
        border-color: #E9E9EA;
    }

  > div {
    border-radius: 6px;
    border: 1px solid #DEDEE1;
    padding: 16px;
    margin-bottom: 10px;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #7F818D;

    .title {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: #505363;

        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }

    .desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #505363;

        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }

    .desc-start-complete {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #505363;
    }

    .desc-time {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #7F818D;
    }

    .desc-top-time {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #505363;
    }

    &:first-child {
        .form-switch {
            display: flex;
            flex-direction: row-reverse;
            padding-left: 0px;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .form-check-input {
                order: 1;
            }

            .form-check-label {
                order: 2;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                color: #181C32;
            }
        }
    }

    &:last-child {
        margin-bottom: 0px;

        @media (min-width: 767px) {
            overflow: auto;
            max-height: calc(100vh - 160px);
        }
    
        @media (max-width: 767px) {
            overflow: visible;
            max-height: 100%;
        }
    }
  }
`;
const StyledListRow = styled(Col)`
  > div {
    border-radius: 6px;
    border: 1px solid transparent;
    padding: 8px 12px;
    margin-bottom: 8px;

    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #6E707E;

    &:last-child {
        margin-bottom: 0px;
    }

    &:hover, &.is-open {
        border: 1px solid #185CFF;
    }
  }
`;
const StyledColLiveAccordion = styled(Col)`
    border-radius: 6px;
    border: 1px solid #F0F0F1;
    padding: 4px 12px;
`;


const AccordionNotLive = ({ item, isOpen, onChange, children }: any) => {
    const [open, setOpen] = React.useState(((isOpen === false) || (isOpen === true)) ? isOpen : false);
  
    React.useEffect(() => {
        setOpen(((isOpen === false) || (isOpen === true)) ? isOpen : false);
    }, [isOpen]);
  
    return <>
      <Row
        className={'align-items-center'}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if(onChange){
            onChange(!open);
          }
        }}
      >
        <Col xs={10}>
            <Row className='align-items-center'>
                <Col xs={12} className='title'>{item?.data?.jobTemplateName}</Col>
                <Col xs={12} className='desc'>{item?.data?.customerName}</Col>
            </Row>
        </Col>
        <Col xs={true} className='text-end'>
          <Button
            className={'p-2'}
            variant={'custom-none-primary'}
            size='sm'
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
  
              if(onChange){
                onChange(!open);
              }
            }}
          >
            {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </Button>
        </Col>
      </Row>
      {open && <Row>
        <Col xs={12}><hr /></Col>
        <Col xs={12}>
            <Row className='align-items-center'>
                <Col xs={12}>{item?.address}</Col>

                {((item && item.binType && item.binType != '') || (item && item.wasteType && item.wasteType != '')) && <Col xs={12}>
                    {(item && item.binType && item.binType != '') && <span>{item.binType}</span>}
                    {((item && item.binType && item.binType != '') && (item && item.wasteType && item.wasteType != '')) && <StyledBadge color="#A3A5AD" />}
                    {(item && item.wasteType && item.wasteType != '') && <span>{item.wasteType}</span>}
                </Col>}

                <Col xs={12}>Job Time: {item?.fromTime} - {item?.toTime}</Col>
                
                {(item && item.data && item.data.jobNumber && item.data.jobNumber != '') && <Col xs={12}>
                    <span className='pe-1'>DO#:</span>
                    <a href={'/jobs-form?id=' + item.data.jobId} 
                        target='_blank'
                        className='menu-link'
                    >
                        {item.data.jobNumber}
                    </a>
                </Col>}
            </Row>
        </Col>
      </Row>}
    </>
}

const AccordionLive = ({ item, isOpen, onChange, children }: any) => {
    const [open, setOpen] = React.useState(((isOpen === false) || (isOpen === true)) ? isOpen : false);
  
    React.useEffect(() => {
        setOpen(((isOpen === false) || (isOpen === true)) ? isOpen : false);
    }, [isOpen]);
  
    return <>
      <Row className={'align-items-center'}>
        <Col xs={12} className='mb-3'>
            <Tag
                className={(item && item.statusName && item.statusName != '') ? item.statusName + '-tag' : ''}
                bordered={false}
                onMouseDown={(event: React.MouseEvent<HTMLSpanElement>) => {
                    event.preventDefault();
                    event.stopPropagation();
                }}
                closable={false}
                onClose={() => {}}
                style={{ marginInlineEnd: 4 }}
            >
                {item?.data?.statusName}
            </Tag>
            {
                (item && item.completedTime && item.completedTime != '')
                ?
                <span className='desc-top-time ms-2'>at {item?.completedTime}</span>
                :
                    (item && item.inProgressTime && item.inProgressTime != '')
                    ?
                    <span className='desc-top-time ms-2'>at {item?.inProgressTime}</span>
                    :
                        (item && item.startedTime && item.startedTime != '')
                        ?
                        <span className='desc-top-time ms-2'>at {item?.startedTime}</span>
                        :
                        null
            }
        </Col>
        
        <Col xs={12} className='title'>{item?.data?.jobTemplateName}</Col>
        <Col xs={12} className='desc'>{item?.data?.customerName}</Col>

        <Col xs={12}><hr /></Col>
        {
            item?.isCompleted
            ?
            <Col xs={12} className='mt-1'>
                <Row className={'align-items-center'}>
                    <Col xs={'auto'} className={'position-relative'}>
                        <div className={'dot dot-completed'} style={{ top: '-3px', left: '13px', width: '7px', height: '7px' }}></div>
                    </Col>
                    <Col xs={true} className='desc-start-complete'>Started</Col>
                    <Col xs={'auto'} className='desc-time'>{(item && item.startedTime && item.startedTime != '') ? item.startedTime : (item && item.jobTime && item.jobTime != '') ? item.jobTime : '-'}</Col>
                </Row>
                <Row className={'align-items-center'}>
                    <Col xs={'auto'} className={'position-relative'}>
                        <div className={'dot dot-completed'} style={{ top: '-3px', left: '13px', width: '7px', height: '7px' }}></div>
                    </Col>
                    <Col xs={true} className='desc-start-complete'>Completed</Col>
                    <Col xs={'auto'} className='desc-time'>{(item && item.completedTime && item.completedTime != '') ? item.completedTime : '-'}</Col>
                </Row>
            </Col>
            :
            <Col xs={12} className='mt-1'>
                <Row className={'align-items-center'}>
                    <Col xs={'auto'} className={'position-relative'}>
                        <div className={'dot dot-' + (item?.inProgressTime ? 'inprogress' : item?.startedTime ? 'started' : 'unallocated')} style={{ top: '-3px', left: '13px', width: '7px', height: '7px' }}></div>
                    </Col>
                    <Col xs={true} className='desc-start-complete'>Started</Col>
                    <Col xs={'auto'} className='desc-time'>{(item && item.inProgressTime && item.inProgressTime != '') ? item.inProgressTime : (item && item.startedTime && item.startedTime != '') ? item.startedTime : '-'}</Col>
                </Row>
                <Row className={'align-items-center'}>
                    <Col xs={'auto'} className={'position-relative'}>
                        <div className={'dot dot-unallocated'} style={{ top: '-3px', left: '13px', width: '7px', height: '7px' }}></div>
                    </Col>
                    <Col xs={true} className='desc-start-complete'>Completed</Col>
                    <Col xs={'auto'} className='desc-time'>{'-'}</Col>
                </Row>
            </Col>
        }
        
        <Col xs={12} className='mt-3'>
            <Row className='m-0'>
                <StyledColLiveAccordion xs={12}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        if(onChange){
                            onChange(!open);
                        }
                    }}
                >
                    <Row className={'align-items-center'}>
                        <Col xs={true} className='title'>Job details</Col>
                        <Col xs={'auto'}>
                            <Button
                                className={'p-2'}
                                variant={'custom-none-primary'}
                                size='sm'
                                onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                        
                                    if(onChange){
                                        onChange(!open);
                                    }
                                }}
                            >
                                {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
                            </Button>
                        </Col>
                    </Row>
                    {open && <Row>
                        <Col xs={12}>
                            <Row className='align-items-center'>
                                <Col xs={12}>{item?.address}</Col>

                                {((item && item.binType && item.binType != '') || (item && item.wasteType && item.wasteType != '')) && <Col xs={12}>
                                    {(item && item.binType && item.binType != '') && <span>{item.binType}</span>}
                                    {((item && item.binType && item.binType != '') && (item && item.wasteType && item.wasteType != '')) && <StyledBadge color="#A3A5AD" />}
                                    {(item && item.wasteType && item.wasteType != '') && <span>{item.wasteType}</span>}
                                </Col>}

                                <Col xs={12}>Job Time: {item?.fromTime} - {item?.toTime}</Col>

                                {(item && item.data && item.data.jobNumber && item.data.jobNumber != '') && <Col xs={12}>
                                    <span className='pe-1'>DO#:</span>
                                    <a href={'/jobs-form?id=' + item.data.jobId} 
                                        target='_blank'
                                        className='menu-link'
                                    >
                                        {item.data.jobNumber}
                                    </a>
                                </Col>}
                            </Row>
                        </Col>
                    </Row>}
                </StyledColLiveAccordion>
            </Row>
        </Col>
      </Row>
    </>
}


const ListComponent = (props: any) => {
    // let history = useHistory();

    const pageCaption = 'Route';
    const headerID = 'routemap_header_ID';

    const dispatch = useDispatch();
    const { show, details, isLoading, items, isLive, isLoadingLatestLocation, latestLocationTime, latestLocationPosition } = useSelector((state: RootState) => state.ganttChartRouteMapSlice);
    const { selectedDate } = useSelector((state: RootState) => state.ganttChartSlice);

    const refMap = React.useRef<any>(null);
    const refList = React.useRef<any>(null);
    const [isOpen, setIsOpen] = React.useState<any>({ jobId: null, isOpen: false });
    

    React.useEffect(() => {
        if(show){
            apiCall();
        }
    }, [show]);

    React.useEffect(() => {
        setIsOpen({ jobId: null, isOpen: false })
    }, [isLive]);


    const apiCall = () => {
        let fromToDate = moment(selectedDate, DATE_FORMAT).format(Utils.getAPIDateFormat());

        let params = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            fromDate: fromToDate,
            toDate: fromToDate,
            sortColumn: 'jobDate',
            sortDir: 'asc',
            driverFilter: details?.id?.toString(),
            isAssigned: true,
        };
        dispatch(slice.callReadApi(params, (state: boolean, data: any) => {}));

        
        let paramsLatestLocation = {
            driverId: details?.id,
        };
        dispatch(slice.callLatestLocationApi(paramsLatestLocation, (state: boolean, data: any) => {}));
    }

    const onCancel = () => {
        dispatch(slice.setShow({ show: false, details: null }));
        dispatch(slice.resetSlice());
    }

    const scrollToItem = (ref: any, className: any) => {
        const item = ref.current?.querySelector(className);
        if (item) {
            setTimeout(() => {
                item.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }, 50);
        }
    };

    
    const mapSection = () => {
        return <Row className='align-items-center'>
            <StyledColMap xs={12}>
                <MapComponent
                    ref={refMap}
                    onMarkerClick={(marker: any, item: any) => {
                        setIsOpen({ jobId: item.jobId, isOpen: true });
                        scrollToItem(refList, '.gc_routemap_list_' + item.jobId);
                    }}
                />
            </StyledColMap>
        </Row>
    }
    const listSection = () => {
        return <StyledCardRow className='align-items-center'>
          <Col xs={12}>
            <Form.Check
                id={'liveTrackingID'}
                type="switch"
                label={'Live Tracking'}
                disabled={isLoading}
                checked={isLive}
                onChange={(e) => {
                    dispatch(slice.setIsLive(e.target.checked));
                }}
            />
          </Col>
          <Col xs={12}>{isLive ? liveList() : notLiveList()}</Col>
        </StyledCardRow>
    }

    const notLiveList = () => {
        return <StyledListRow ref={refList} className='align-items-center'>
            {
                isLoading
                ?
                <div className='text-center'><Spin size='small' /></div>
                :
                    (items && items.length > 0)
                    ?
                    items.map((job: any, i: number) => {
                        let active = (isOpen && (isOpen.jobId === job.jobId) && (isOpen.isOpen === true));
                        let jobObj = getJobInfo(job);
                        
                        if(jobObj){
                            return <Col key={'gc_routemap_notlive_list' + i} xs={12} className={'gc_routemap_list_' + jobObj.jobId + ' ' + (active ? 'is-open' : '')}>
                                <AccordionNotLive
                                    item={jobObj}
                                    isOpen={active}
                                    onChange={(isOpen: boolean) => {
                                        setIsOpen({ jobId: job.jobId, isOpen: isOpen });

                                        if(isOpen){
                                            if(refMap && refMap.current){
                                                refMap.current.moveToMarker(jobObj);
                                                refMap.current.openMarkerInfo(jobObj);
                                            }
                                        }
                                    }}
                                />
                            </Col>
                        }
                    })
                    :
                    <Col xs={12} className=''>No Result</Col>
            }
        </StyledListRow>
    }
    const liveList = () => {
        return <>
            <Spin size='small' spinning={isLoadingLatestLocation}>
                <Row className='align-items-center'>
                    <Col xs={12}>
                        <Row className='align-items-center'>
                            <Col xs={true} className='title'>Driver Latest Status</Col>
                            <Col xs={'auto'}>
                                <Button
                                    variant={'custom-outlined'}
                                    size='sm'
                                    disabled={isLoadingLatestLocation}
                                    onClick={() => {
                                        let params = {
                                            driverId: details?.id,
                                        };
                                        dispatch(slice.callLatestLocationApi(params, (state: boolean, data: any) => {}));
                                    }}
                                >
                                    <RefreshIcon style={{ top: '-1px', marginRight: '5px' }} /><span>Refresh</span>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} className='position-relative'><ClockIcon style={{ top: '-1px', marginRight: '5px' }} /><span>{latestLocationTime ? moment(latestLocationTime).format(DATE_FORMAT + ' ' + VIEW_TIME_FORMAT) : '-'}</span></Col>
                    <Col xs={12} className='position-relative'><PinIcon style={{ top: '-1px', marginRight: '5px' }} /><span>{latestLocationPosition ? convertLatLngToCoord(latestLocationPosition?.lat, latestLocationPosition?.lng) : '-'}</span></Col>
                    <Col xs={12}><hr style={{ margin: '16px 0px 16px 0px' }} /></Col>
                </Row>
            </Spin>

            <StyledListRow ref={refList} className='align-items-center'>
                {
                    isLoading
                    ?
                    <div className='text-center'><Spin size='small' /></div>
                    :
                        (items && items.length > 0)
                        ?
                        items.map((job: any, i: number) => {
                            let active = (isOpen && (isOpen.jobId === job.jobId) && (isOpen.isOpen === true));
                            let jobObj = getJobInfo(job);
                            
                            if(jobObj){
                                return <Col key={'gc_routemap_live_list' + i} xs={12} className={'gc_routemap_list_' + jobObj.jobId + ' ' + (active ? 'is-open' : '')}>
                                    <AccordionLive
                                        item={jobObj}
                                        isOpen={active}
                                        onChange={(isOpen: boolean) => {
                                            setIsOpen({ jobId: job.jobId, isOpen: isOpen });

                                            if(isOpen){
                                                if(refMap && refMap.current){
                                                    refMap.current.moveToMarker(jobObj);
                                                    refMap.current.openMarkerInfo(jobObj);
                                                }
                                            }
                                        }}
                                    />
                                </Col>
                            }
                        })
                        :
                        <Col xs={12} className=''>No Result</Col>
                }
            </StyledListRow>
        </>
    }


    return <Offcanvas
        backdrop={true}
        enforceFocus={false}
        scroll={false}
        placement={'end'}
        show={show}
        onHide={() => {
            onCancel();
        }}
        style={{ width: '85%' }}
    >
        <>
            <Offcanvas.Header closeButton id={headerID} className='py-7'>
                <Offcanvas.Title className='mb-0'>{details?.data?.driverName} {pageCaption}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Spin spinning={isLoading}>
                    <Row className={'g-7'}>
                        <Col xs={12} md={8} xl={9}>{mapSection()}</Col>
                        <Col xs={12} md={4} xl={3}>{listSection()}</Col>
                    </Row>
                </Spin>
            </Offcanvas.Body>
        </>
    </Offcanvas>
}

export default ListComponent;
