/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as yup from 'yup';
import _ from 'lodash';
import moment from 'moment';

import * as dispatchCrudApi from '../../../../api/CRUD/DispatchCRUD'
import Utils from "../../../../utils/utils";
// import {  } from "../../../../utils/enums";

import { ParseResult } from "../../../../utils/interfaces";

import { GanttBinWasteType } from '../DailyList';
import { VIEW_TIME_FORMAT } from '../slice';


export const fields = {
  jobType: {
    id: 'jobType',
    label: 'Job Type',
    placeholder: 'Select',
  },
  jobTimeFrom: {
    id: 'jobTimeFrom',
    label: 'Job Time',
    placeholder: 'Select',
  },
  jobTimeTo: {
    id: 'jobTimeTo',
    label: 'Job Time',
    placeholder: 'Select',
  },
  status: {
    id: 'status',
    label: 'Status',
    placeholder: 'Select',
  },
  vehicle: {
    id: 'vehicle',
    label: 'Vehicle',
    placeholder: 'Select',
  },
  binType: {
    id: 'binType',
    label: 'Bin Type',
    placeholder: '',
    option1: 'Bin In',
    option2: 'Bin Out',
  },
};

export const formSchema = (id: number|null= null) => {
  return yup.object().shape({
    jobType: yup.array().nullable().label(fields.jobType.label),
    jobTimeFrom: yup.number().nullable().label(fields.jobTimeFrom.label),
    jobTimeTo: yup.number().nullable().label(fields.jobTimeTo.label),
    status: yup.array().nullable().label(fields.status.label),
    vehicle: yup.array().nullable().label(fields.vehicle.label),
    binType: yup.number().nullable().label(fields.binType.label),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const getTimeItems = (isFromTo: any = null, from: any = null, to: any = null) => {
  const step = 30;
  const timeObjects = [];
  const numObjects = Math.ceil(1440 / step);

  for (let i = 0; i < numObjects; i++) {
    let index = (i+1);
    const time = moment().startOf('day').add(i * step, 'minutes');

    let disabled = false;
    if(isFromTo && isFromTo != ''){
      if(isFromTo == 'from'){
        if(to){
          if(index >= to){
            disabled = true;
          }
        }

      } else if(isFromTo == 'to'){
        if(from){
          if(index <= from){
            disabled = true;
          }
        }
      }
    }

    timeObjects.push({
      value: index,
      label: time.format(VIEW_TIME_FORMAT),
      data: time.format('HH:mm:ss'),
      item: time,
      disabled: disabled,
    });
  }

  return timeObjects;
};

export const getFilterCount = (details: any = null) => {
  let count: number = 0;
  
  if(details){
    if(details.jobType && details.jobType.length > 0){
      count = count + 1;
    }

    if(details.status && details.status.length > 0){
      count = count + 1;
    }

    if(details.vehicle && details.vehicle.length > 0){
      count = count + 1;
    }

    if(details.jobTimeFrom != null){
      count = count + 1;
    }

    if(details.jobTimeTo != null){
      count = count + 1;
    }

    if(details.binType > 0){
      count = count + 1;
    }
  }

  return count;
};
export const isTimeFilterIncluded = (details: any = null) => {
  let state: boolean = false;
  
  if(details){
    if(details.jobTimeFrom != null){
      state = true;
    }

    if(details.jobTimeTo != null){
      state = true;
    }
  }

  return state;
};


export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form ){
    data = form;
  }
  
  return data;
};
export const prepareData = (values: any = null) => {
  let data: any = {};

  if(values){
    let times = getTimeItems();
    let fromItem = (times && times.length > 0) ? times.find((x: any) => x.value == values.jobTimeFrom) : null;
    let toItem = (times && times.length > 0) ? times.find((x: any) => x.value == values.jobTimeTo) : null;

    let from = (fromItem && fromItem.data) ? fromItem.data : null;
    let to = (toItem && toItem.data) ? toItem.data : null;

    let isBinIn = null;
    if(values.binType == GanttBinWasteType.OUT){
      isBinIn = false;
    } else if(values.binType == GanttBinWasteType.IN){
      isBinIn = true;
    } else {
      isBinIn = null;
    }

    data = {
      jobTypeFilter: (values && values.jobType && values.jobType.length > 0) ? values.jobType.join(',') : null,
      fromTime: from,
      toTime: to,
      jobStatusFilter: (values && values.status && values.status.length > 0) ? values.status.join(',') : null,
      vehicleFilter: (values && values.vehicle && values.vehicle.length > 0) ? values.vehicle.join(',') : null,
      isBinIn: isBinIn,
    };
  }

  return data;
};


export interface initialValuesStruct {
  jobType: Array<any>,
  jobTimeFrom: any,
  jobTimeTo: any,
  status: Array<any>,
  vehicle: Array<any>,
  binType: any,
};
export const initialValues: initialValuesStruct = {
  jobType: [],
  jobTimeFrom: null,
  jobTimeTo: null,
  status: [],
  vehicle: [],
  binType: 0,
};


interface InitState {
  isLoading: boolean,
  details: any,

  isLoadingJobType: boolean,
  jobType: Array<any>,

  isLoadingStatus: boolean,
  status: Array<any>,
  availableStatuses: Array<any>,

  isLoadingVehicle: boolean,
  vehicle: Array<any>,
}

function NewReducer() {
  const name = 'ganttChartFiltersSlice';


  const initialState: InitState = {
    isLoading: false,
    details: null,

    isLoadingJobType: false,
    jobType: [],

    isLoadingStatus: false,
    status: [],
    availableStatuses: [
      'Assigned',
      'Dispatched',
      'Acknowledged',
      'Started',
      'In Progress',
      'Completed',
      'Failed',
      'Cancelled',
    ],

    isLoadingVehicle: false,
    vehicle: [],
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setDetails: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startReadJobType: (state: InitState) => {
      state.isLoadingJobType = true;
    },
    finishReadJobType: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingJobType = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      
      state.jobType = data;
    },

    startReadStatus: (state: InitState) => {
      state.isLoadingStatus = true;
    },
    finishReadStatus: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingStatus = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];

      let arr: Array<any> = [];
      if(data && data.length > 0){
        data.forEach((item: any) => {
          if(state.availableStatuses.includes(item.jobStatusName)){
            arr.push(item);
          }
        });
      }
      state.status = arr;
    },

    startReadVehicle: (state: InitState) => {
      state.isLoadingVehicle = true;
    },
    finishReadVehicle: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingVehicle = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      
      state.vehicle = data;
    },
  };


  const apis = {
    callReadJobTypeApi: (search: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadJobType());

      let params: any = {
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
        searchQuery: search,
        sortColumn: 'common',
        sortDir: 'desc',
      }

      await dispatchCrudApi.readApi(params, 'jobtemplate').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishReadJobType(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishReadJobType(null));
      });
    },

    callReadStatusApi: (search: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadStatus());

      let params: any = {
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
        searchQuery: search,
      }

      await dispatchCrudApi.readApi(params, 'jobStatus').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishReadStatus(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishReadStatus(null));
      });
    },

    callReadVehicleApi: (search: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadVehicle());

      let params: any = {
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
        searchQuery: search,
      }

      await dispatchCrudApi.readApi(params, 'vehicle').then(result => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishReadVehicle(data));
      }).catch(error => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishReadVehicle(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();