import React from "react";
import axios from "axios";
import debounce from 'lodash/debounce';

import { 
    Row,
    Col,
    Form,
    InputGroup,
    Spinner,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import RSuiteSelectPicker from '../../../../components/OLD/Select/RSuiteSelectPicker';
import IconButtonTable from "../../../../components/Buttons/IconButtonTable";
import InputLayout from "../../../../components/input/InputLayout";

import Utils from "../../../../utils/utils";
import { CancelToken } from "../../../../utils/enums";
import { CustomerSiteAvailability } from "../../../../utils/enums";

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'


const StyledNewTag = styled.div`
    position: relative;
    display: inline-block;
    width: 36px;
    height: 19px;
    top: -2px;
    
    margin-right: 10px;
    padding: 0px;
    border-radius: 4px;

    text-align: center;
    font-size: 10px;
    font-weight: 600;
    line-height: 18px;

    background-color: #F0F4FF;
    color: #5480E8;
`;

const StyledHaveJobsTag = styled.i`
    position: relative;
    display: inline-block;
    top: 7px;
    
    margin-right: 5px;
    padding: 0px;
`;


class TopSection extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.name = 'job_form_top_section';

        this.carouselRef = React.createRef();

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
       
        this.state = {
            id: id,
            row: row,

            jobTemplateItems: [],
            jobTemplateIsLoading: false,

            currentPageCustomer: 1,
            totalPageCustomer: 0,
            queryStringCustomer: '',

            downloadInternalNoteIsLoading: false,
            jobNoteIsLoading: false,
        };
    }


    componentDidMount() {
        this.callReadJobtemplateApi();
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.name + '-customer', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;
                let total = 0;

                if(data && data.data && data.data.length > 0) {
                    total = data.total;

                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.customerId;
                        let title = item.customerNameDisplay;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    totalPageCustomer: total,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.name + '-customersite', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = []; //variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.customerSiteId;
                        let title = item.siteNameDisplay;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.name + '-billing_profile', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.accountBillingProfileId;
                        let title = item.billingProfileName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.name + '-quickBook_credit_terms', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.length > 0) {
                    for (var i = 0; i < data.length; i++) {
                        let item = data[i];
                        let value = item.id;
                        let title = item.name;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.name + '-jobtemplate', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.jobTemplateId;
                        let title = item.jobTemplateName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                if(variables){
                    let index = arr.findIndex(x => x.value == variables);
                    if(index !== -1){
                        arr.unshift(arr.splice(index, 1)[0]);
                    }
                }

                this.setState({
                    jobTemplateItems: arr,
                    jobTemplateIsLoading: false,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.name + '-details_jobtemplate', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.name + '-download_internal_note', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.name + '-refresh_job_note', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data);
                    }
                });
            }
        );
    }
	
    callReadCustomersApi = debounce(async (items, currentPage = null, callback = null) => {
        if(currentPage){
            this.setState({
                currentPageCustomer: currentPage,
            }, async () => {
                let data = {
                    currentPage: this.state.currentPageCustomer,
                    pageSize: Utils.getDefaultPageSize(15),
                    searchQuery: this.state.queryStringCustomer,
                    recentJob: true,
                };

                if(this.state.queryStringCustomer != ''){
                    data['sortColumn'] = 'updated';
                    data['sortDir'] = 'desc';
                }

                await this.props.dispatchApiCallGet(data, this.name + '-customer', 'customer', { items: items }, callback, () => {}, this.cancelTokenCustomer);
            });
        } else {
            let data = {
                currentPage: this.state.currentPageCustomer,
                pageSize: Utils.getDefaultPageSize(15),
                searchQuery: this.state.queryStringCustomer,
                recentJob: true,
            };
         
            if(this.state.queryStringCustomer != ''){
                data['sortColumn'] = 'updated';
                data['sortDir'] = 'desc';
            }

            await this.props.dispatchApiCallGet(data, this.name + '-customer', 'customer', { items: items }, callback, () => {}, this.cancelTokenCustomer);
        }
    }, 300)
    
    callReadCustomerSitesApi = (customerId, items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            customerId: customerId,
        };
     
        this.props.dispatchApiCallGet(data, this.name + '-customersite', 'customersite', { items: items }, callback, () => {});
    }

    callReadBillingProfileApi = (items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            isActive: true,
        };
     
        this.props.dispatchApiCallGet(data, this.name + '-billing_profile', 'AccountBillingProfile', { items: items }, callback, () => {});
    }
    
    callQuickbookReadCreditTermsApi = (quickBookAppId = 0, items, callback = null) => {
        let data = {
            quickBookAppId: quickBookAppId,
        };
     
        this.props.dispatchApiCallGet(data, this.name + '-quickBook_credit_terms', 'quickbookapp/term', { items: items }, callback, () => {});
    }

    callReadJobtemplateApi = (callback = null) => {
        let {
            values,
        } = this.props.formOptions;

        this.setState({
            jobTemplateIsLoading: true,
        });

        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            sortColumn: 'common',
            sortDir: 'desc',
        };
     
        this.props.dispatchApiCallGet(data, this.name + '-jobtemplate', 'jobtemplate', values.jobTemplateId, callback, () => {});
    }

    callDetailsJobtemplateApi = (jobTemplateId = null, callback = null) => {
        if(jobTemplateId){
            this.props.dispatchApiCallGet(null, this.name + '-details_jobtemplate', 'jobtemplate/' + jobTemplateId, null, callback, () => {});
        } else {
            if(callback){
                callback(null);
            }
        }
    }
  
    callDownloadInternalNoteIsLoadingApi = (jobId = 0, callback = null) => {
          this.setState({
              downloadInternalNoteIsLoading: true,
          });
  
          Utils.toast(<div style={{ fontWeight: 'bold' }}>Please Wait ...</div>, 'info');
      
          this.props.dispatchApiCallGet(null, this.name + '-download_internal_note', 'job/get-job-internal-note/' + jobId, null, callback, null);
    }
    
    callRefreshJobNoteApi = (jobId = 0, callback = null) => {
          this.setState({
            jobNoteIsLoading: true,
          });
      
          this.props.dispatchApiCallGet(null, this.name + '-refresh_job_note', 'job/' + jobId, null, callback, null);
    }
    /* END API */


    /* FUNCTIONS */
    getSelectedJobTemplate = () => {
        let {
            values,
        } = this.props.formOptions;

        let index = this.state.jobTemplateItems.findIndex(x => x.jobTemplateId === values.jobTemplateId);

        return (index > -1) ? index : 0;
    }
    /* END FUNCTIONS */


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            setValues,
            values,
            errors,
        } = this.props.formOptions;
        
        let timeAvailability = CustomerSiteAvailability.All;
        let isWeekend = Utils.isWeekend(Utils.weekDayNumb(this.props.formOptions));
        if(isWeekend){
            timeAvailability = values.customFormParams.weekendAvailability;
        } else {
            timeAvailability = values.customFormParams.weekdaysAvailability;
        }

        return <Row className={'gx-custom'}>

            {this.props.isCreditLimit && <Col xs={12} className={'pb-5'}>
                <div className="alert alert-danger d-flex align-items-center fade show" role="alert">
                    <i className={'material-icons text-danger'}>error</i>
                    <span className={'ps-3'}>{this.props.msgCreditLimit}</span>
                </div>
            </Col>}

            {!this.props.isCreditLimit && <>

                {(timeAvailability == CustomerSiteAvailability.NotAvailable) && <Col xs={12} className={'pb-5'}>
                    <div className="alert alert-warning d-flex align-items-center fade show" role="alert">
                        <i className={'material-icons text-warning'}>warning</i>
                        <span className={'ps-3'}>{(isWeekend ? 'Weekend' : 'Weekdays')} time is not available</span>
                    </div>
                </Col>}

                {values.isCopy && <Col xs={12} className={'pb-5'}>
                    <div className="alert alert-primary d-flex align-items-center fade show" role="alert">
                        <i className={'material-icons text-danger'}>error</i>
                        <span className={'ps-3'}>This is a copy of your current {(!values.isRecurringForm) ? '' : 'recurring'} job form <strong>{(!values.isRecurringForm) ? values.customFormParams.jobNumber : values.recurringJobName}</strong>. Revise as needed and save this form as new</span>
                        {/* <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"
                            onClick={() => {
                                if(this.props.onClickCloseCopy){
                                    this.props.onClickCloseCopy();
                                }
                            }}
                        ></button> */}
                    </div>
                </Col>}

                {values.promptUpdateBilling && <Col xs={12} className={'pb-5'}>
                    <div className="alert alert-primary alert-dismissible d-flex align-items-center fade show" role="alert">
                        <i className={'material-icons text-danger'}>error</i>
                        <span className={'ps-3'}>
                            This price has changed. Do you wish to update to the new pricing?
                            <br />
                            <a href={'/'} 
                                className="link-danger fw-bolder pe-5"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    
                                    if(this.props.onClickUpdateChangedPrice){
                                        this.props.onClickUpdateChangedPrice();
                                    }
                                }}
                            ><u>Update to New Price</u></a>
                            <a href={'/'} 
                                className="link-dark fw-bolder"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    if(this.props.onClickCloseChangedPrice){
                                        this.props.onClickCloseChangedPrice();
                                    }
                                }}
                            ><u>Stay with Old Price</u></a>
                        </span>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"
                            onClick={() => {
                                if(this.props.onClickCloseChangedPrice){
                                    this.props.onClickCloseChangedPrice();
                                }
                            }}
                        ></button>
                    </div>
                </Col>}

            </>}

            <Col xs={12} md={6}>

                <InputLayout
                    label={this.props.fields.customerId.label}
                    required
                >
                    <Form.Control
                        as = {RSuiteSelectPicker}
                        isInvalid={!!errors.customerId}

                        id={this.props.fields.customerId.id}
                        placeholder={this.props.fields.customerId.placeholder}
                        items={values.customFormParams.customerItems}
                        value={values.customerId}
                        selectedName={values.customerName}
                        isLoading={values.customFormParams.customerIsLoading}
                        readOnly={values.isParams || (values.isRecurringForm && values.isEdit) || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName) || values.isCollectJob}
                        searchable={true}
                        cleanable={true}
                        escapeCharsInSearch={true}
                        isDefault={true}
                        
                        renderMenuItem={(label, item) => {
                            let isNew = (item && item.item && ((item.item.isNew === false) || (item.item.isNew === true))) ? item.item.isNew : false;
                            let haveJobs = (item && item.item && ((item.item.haveJobs === false) || (item.item.haveJobs === true))) ? item.item.haveJobs : false;
                            
                            return <div>
                                {haveJobs && <StyledHaveJobsTag className={'material-icons'}>access_time</StyledHaveJobsTag>}
                                {isNew && <StyledNewTag>NEW</StyledNewTag>}
                                {label}
                            </div>
                        }}

                        renderExtraFooter={() => {
                            let totalCount = Utils.getPaginationCount(this.state.totalPageCustomer, Utils.getDefaultPageSize());

                            return <Row className={'align-items-center'}>
                                
                                {(((this.state.totalPageCustomer > 0) && (this.state.currentPageCustomer < totalCount))) && <Col xs={12}>
                                    <div className={'px-5 pt-3'}>
                                        <a href={'/'}
                                            className={"link-primary"}
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                await setFieldValue('customFormParams.customerIsLoading', true);
                                                this.callReadCustomersApi(values.customFormParams.customerItems, (this.state.currentPageCustomer + 1), async (items) => {
                                                    await setFieldValue('customFormParams.customerItems', items);
                                                    await setFieldValue('customFormParams.customerIsLoading', false);
                                                    Utils.scrollToBottomByClassName('ReactVirtualized__Grid');
                                                });
                                            }}
                                        >View more</a>
                                    </div>
                                </Col>}

                                <Col xs={12}>
                                    <hr />
                                </Col>
                                <Col xs={12}>
                                    <div className={'px-5 pb-3'}>
                                        <a href={'/'}
                                            className={"link-primary"}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                if(this.props.onCreateCustomer){
                                                    this.props.onCreateCustomer();
                                                }
                                            }}
                                        >Create new</a>
                                    </div>
                                </Col>
                            </Row>
                        }}
                        onSearch={(val) => {
                            if(this.cancelTokenCustomer){
                                this.cancelTokenCustomer.cancel(CancelToken.CANCELLED)
                            }
                            this.cancelTokenCustomer = axios.CancelToken.source();

                            this.setState({
                                queryStringCustomer: val,
                            }, async () => {
                                await setFieldValue('customFormParams.customerIsLoading', true);
                                await this.callReadCustomersApi([], 1, async (items) => {
                                    let item = values.customerItem;
                                    if(item){
                                        let value = item.customerId;
                                        let title = item.customerNameDisplay;
                                        let arrItem = {
                                            value: value,
                                            title: title,
                                            item: item,
                                        };
                                        items = Utils.addToArrayIfNotExist(items, value, arrItem);
                                    }

                                    await setFieldValue('customFormParams.customerItems', items);
                                    await setFieldValue('customFormParams.customerIsLoading', false);

                                });
                            });
                        }}
                        onClick={() => {
                            this.setState({
                                queryStringCustomer: '',
                            }, async () => {
                                await setFieldValue('customFormParams.customerIsLoading', true);
                                this.callReadCustomersApi([], 1, async (items) => {
                                    let item = values.customerItem;
                                    if(item){
                                        let value = item.customerId;
                                        let title = item.customerNameDisplay;
                                        let arrItem = {
                                            value: value,
                                            title: title,
                                            item: item,
                                        };
                                        items = Utils.addToArrayIfNotExist(items, value, arrItem);
                                    }

                                    await setFieldValue('customFormParams.customerItems', items);
                                    await setFieldValue('customFormParams.customerIsLoading', false);
                                });
                            });
                        }}
                        onSelect={async (value, item) => {
                            if(item){
                                if(this.props.onSelectCustomer){
                                    this.props.onSelectCustomer(item);
                                }

                                let billingProfile = (item && item.item && item.item.billingProfile) ? item.item.billingProfile : null;
                                let quickBookApp = (billingProfile && billingProfile.quickBookApp) ? billingProfile.quickBookApp : null;
                                let isConnectQuickbook = (billingProfile && billingProfile.isConnectQuickbook) ? billingProfile.isConnectQuickbook : false;
                                let isConnectXero = (billingProfile && billingProfile.isConnectXero) ? billingProfile.isConnectXero : false;
                                let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;
                                let accountBillingProfileId = (item && item.item && item.item.accountBillingProfileId) ? item.item.accountBillingProfileId : null;
                                let billingProfileName = (item && item.item && item.item.billingProfileName) ? item.item.billingProfileName : '';
                                let quickBookDefaultTermId = (item && item.item && item.item.quickBookDefaultTermId) ? item.item.quickBookDefaultTermId : null;
                                let paymentTerms = (item && item.item && item.item.paymentTerms) ? item.item.paymentTerms : '';
                                let xeroPaymentTerm = (item && item.item && item.item.xeroPaymentTerm) ? item.item.xeroPaymentTerm : '';
                                let xeroPaymentTermType = (item && item.item && item.item.xeroPaymentTermType) ? item.item.xeroPaymentTermType.toString() : '0';
                                let note = (item && item.item && item.item.note) ? item.item.note : '';
                                let isRequirePaymentCollection = (item && item.item && ((item.item.isRequirePaymentCollection === false) || (item.item.isRequirePaymentCollection === true))) ? item.item.isRequirePaymentCollection : false;
                                
                                let billingProfileItem = null;
                                if(billingProfile){
                                    billingProfileItem = {
                                        value: accountBillingProfileId,
                                        title: billingProfileName,
                                        item: billingProfile,
                                    };
                                }
                                let accountBillingProfileItems = (billingProfileItem) ? [billingProfileItem] : [];

                                let paymentTermsItem = null;
                                if(billingProfile){
                                    paymentTermsItem = {
                                        value: quickBookDefaultTermId,
                                        title: paymentTerms,
                                        item: item,
                                    };
                                }
                                let paymentTermsItems = (paymentTermsItem) ? [paymentTermsItem] : [];
                                
                                
                                let markers = Utils.getJobFormLocationMarkers({
                                    customerSiteId: values.customerSiteId,
                                    customerSiteItems: values.customFormParams.customerSiteItems,
                                    customerSiteShiftId: values.customerSiteShiftId,
                                    customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
                                    templateStepSites1Latitude: values.templateStepSites1Latitude,
                                    templateStepSites1Longitude: values.templateStepSites1Longitude,
                                    templateStepSites2Latitude: values.templateStepSites2Latitude,
                                    templateStepSites2Longitude: values.templateStepSites2Longitude,
                                    jobTemplateType: values.customFormParams.jobTemplateType
                                }, values)


                                let newValues = {
                                    ...values,

                                    customerId: value,
                                    customerName: item.title,
                                    customerItem: item.item,

                                    isRequirePaymentCollection: isRequirePaymentCollection,

                                    accountBillingProfileId: accountBillingProfileId,
                                    accountBillingProfileName: billingProfileName,
                                    paymentTermsId: quickBookDefaultTermId,
                                    paymentTermsName: paymentTerms,
                                    xeroPaymentTerm: xeroPaymentTerm,
                                    xeroPaymentTermType: xeroPaymentTermType,
                                    customerNote: note,

                                    detailsPOD: null,
                                    fromPOD: '',
                                    subjectPOD: '',
                                    bodyPOD: '',
                                    includeDeliveryOrderPOD: false,
                                    includePhotosPOD: false,
                                    includeWeighChitPOD: false,
                                    includeAttachmentPOD: true,

                                    customFormParams: {
                                        ...values.customFormParams,

                                        accountBillingProfileItems: accountBillingProfileItems,
                                        paymentTermsItems: paymentTermsItems,
                                        isConnectQuickbook: isConnectQuickbook,
                                        isConnectXero: isConnectXero,
                                        quickBookAppId: quickBookAppId,
                                        markers: markers,
                                    },
                                };

                                await setValues(newValues);
                                await Utils.onClearSteps(this.props.formOptions);
                                await Utils.onClearCustomerSite(this.props.formOptions);
                                
                                if(this.props.onInitCalcDistance){
                                    this.props.onInitCalcDistance();
                                }
                            }
                        }}
                        onClear={async () => {
                            if(this.props.onSelectCustomer){
                                this.props.onSelectCustomer(null);
                            }

                            let newValues = {
                                ...values,

                                customerId: null,
                                customerName: '',
                                customerItem: null,
                                
                                isRequirePaymentCollection: false,

                                jobTemplateId: null,
                                jobTemplateName: '',

                                accountBillingProfileId: null,
                                accountBillingProfileName: '',
                                paymentTermsId: null,
                                paymentTermsName: '',
                                xeroPaymentTerm: '',
                                xeroPaymentTermType: '0',
                                customerNote: '',

                                detailsPOD: null,
                                fromPOD: '',
                                subjectPOD: '',
                                bodyPOD: '',
                                includeDeliveryOrderPOD: false,
                                includePhotosPOD: false,
                                includeWeighChitPOD: false,
                                includeAttachmentPOD: true,

                                customFormParams: {
                                    ...values.customFormParams,

                                    jobTemplateDetails: null,
                                    jobTemplateType: null,

                                    accountBillingProfileItems: [],
                                    paymentTermsItems: [],
                                    isConnectQuickbook: false,
                                    isConnectXero: false,
                                    quickBookAppId: 0,
                                },
                            };

                            await setValues(newValues);
                            await Utils.onClearSteps(this.props.formOptions);
                            await Utils.onClearCustomerSite(this.props.formOptions);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.customerId}</Form.Control.Feedback>
                </InputLayout>
                
                {!this.props.isCreditLimit && <InputLayout
                    label={<>
                        <span className={'pe-2'}>{this.props.fields.remarks.label}</span>
                        {
                        values.isEdit
                        ?
                        (!this.state.downloadInternalNoteIsLoading)
                        ?
                        <IconButtonTable
                            className={'p-0'}
                            title={'Internal Note'}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                
                                this.callDownloadInternalNoteIsLoadingApi(values.jobId, (response) => {
                                    let byteArray = Utils.getUint8Array(response);
                
                                    const url = window.URL.createObjectURL(new Blob([byteArray], {type: 'application/pdf'}));
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', 'Internal note - ' + values.customFormParams.jobNumber + ((values.customerItem && values.customerItem.billingName && values.customerItem.billingName !== '') ? ' ' + values.customerItem.billingName : '') + '.pdf');
                                    document.body.appendChild(link);
                                    link.click();
                                    link.remove();
                                    
                                    this.setState({
                                        downloadInternalNoteIsLoading: false,
                                    });
                                });
                            }}
                        >
                            <i className={'fas fa-print ps-1'}></i>
                        </IconButtonTable>
                        :
                        <span style={{ paddingLeft: '5px' }}><Spinner animation="border" size="sm" /></span>
                        :
                        null
                        }

                        {
                        values.isEdit
                        ?
                        (!this.state.jobNoteIsLoading)
                        ?
                        <IconButtonTable
                            className={'p-0'}
                            title={'Refresh Job Note'}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                
                                this.callRefreshJobNoteApi(values.jobId, async (data) => {
                                    let remarks = (data && data.remarks && data.remarks != '') ? data.remarks : values.remarks;

                                    await setFieldValue('remarks', remarks);

                                    this.setState({
                                        jobNoteIsLoading: false,
                                    });
                                });
                            }}
                        >
                            <i className={'material-icons ps-1'}>refresh</i>
                        </IconButtonTable>
                        :
                        <span style={{ paddingLeft: '5px' }}><Spinner animation="border" size="sm" /></span>
                        :
                        null
                        }

                    </>}
                >
                    <Form.Control
                        // type="text"
                        as="textarea"
                        rows={5}
                        id={this.props.fields.remarks.id}
                        placeholder={this.props.fields.remarks.placeholder}
                        disabled={this.state.jobNoteIsLoading}
                        value={values.remarks}
                        onChange={async (e) => {
                            Utils.limitRows(e.target.value, 5, async (value) => {
                                await setFieldValue('remarks', value);
                            });
                        }}
                        isInvalid={!!errors.remarks}
                    />
                    <Form.Control.Feedback type="invalid">{errors.remarks}</Form.Control.Feedback>
                </InputLayout>}

            </Col>
            <Col xs={12} md={6}>

                <InputLayout
                    label={this.props.fields.customerNote.label}
                >
                    <Form.Control
                        type="text"
                        id={this.props.fields.customerNote.id}
                        placeholder={this.props.fields.customerNote.placeholder}
                        readOnly={true}
                        value={values.customerNote}
                        onChange={handleChange}
                        isInvalid={!!errors.customerNote}
                    />
                    <Form.Control.Feedback type="invalid">{errors.customerNote}</Form.Control.Feedback>
                </InputLayout>

                <InputLayout
                    label={this.props.fields.accountBillingProfileId.label}
                >
                    <Form.Control
                        as = {RSuiteSelectPicker}
                        isInvalid={!!errors.accountBillingProfileId}

                        id={this.props.fields.accountBillingProfileId.id}
                        placeholder={this.props.fields.accountBillingProfileId.placeholder}
                        items={values.customFormParams.accountBillingProfileItems}
                        value={values.accountBillingProfileId}
                        selectedName={values.accountBillingProfileName}
                        isLoading={values.customFormParams.accountBillingProfileIsLoading}
                        readOnly={true}
                        searchable={true}
                        cleanable={true}
                        isDefault={true}
                        onClick={async () => {
                            await setFieldValue('customFormParams.accountBillingProfileIsLoading', true);
                            this.callReadBillingProfileApi(values.customFormParams.accountBillingProfileItems, async (items) => {
                                await setFieldValue('customFormParams.accountBillingProfileItems', items);
                                await setFieldValue('customFormParams.accountBillingProfileIsLoading', false);
                            });
                        }}
                        onSelect={async (value, item) => {
                            let quickBookApp = (item && item.item && item.item.quickBookApp) ? item.item.quickBookApp : null;
                            let isConnectQuickbook = (item && item.item && item.item.isConnectQuickbook) ? item.item.isConnectQuickbook : false;
                            let isConnectXero = (item && item.item && item.item.isConnectXero) ? item.item.isConnectXero : false;
                            let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;

                            await setFieldValue('accountBillingProfileId', value);
                            await setFieldValue('accountBillingProfileName', item.title);
                            await setFieldValue('customFormParams.quickBookAppId', quickBookAppId);
                            await setFieldValue('customFormParams.isConnectQuickbook', isConnectQuickbook);
                            await setFieldValue('customFormParams.isConnectXero', isConnectXero);
                        }}
                        onClear={async () => {
                            await setFieldValue('accountBillingProfileId', null);
                            await setFieldValue('accountBillingProfileName', '');
                            await setFieldValue('customFormParams.quickBookAppId', 0);
                            await setFieldValue('customFormParams.isConnectQuickbook', false);
                            await setFieldValue('customFormParams.isConnectXero', false);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.accountBillingProfileId}</Form.Control.Feedback>
                </InputLayout>

                {(values.customFormParams.isConnectQuickbook) && (
                    <InputLayout
                        label={this.props.fields.paymentTermsId.label}
                    >
                        <Form.Control
                            as = {RSuiteSelectPicker}
                            isInvalid={!!errors.paymentTermsId}

                            id={this.props.fields.paymentTermsId.id}
                            placeholder={this.props.fields.paymentTermsId.placeholder}
                            items={values.customFormParams.paymentTermsItems}
                            value={values.paymentTermsId}
                            selectedName={values.paymentTermsName}
                            isLoading={values.customFormParams.paymentTermsIsLoading}
                            readOnly={true}
                            searchable={true}
                            cleanable={true}
                            isDefault={true}
                            onClick={async () => {
                                await setFieldValue('customFormParams.paymentTermsIsLoading', true);
                                this.callQuickbookReadCreditTermsApi(values.customFormParams.quickBookAppId, values.customFormParams.paymentTermsItems, async (items) => {
                                    await setFieldValue('customFormParams.paymentTermsItems', items);
                                    await setFieldValue('customFormParams.paymentTermsIsLoading', false);
                                });
                            }}
                            onSelect={async (value, item) => {
                                await setFieldValue('paymentTermsId', value);
                                await setFieldValue('paymentTermsName', item.title);
                                await setFieldValue('xeroPaymentTerm', '');
                                await setFieldValue('xeroPaymentTermType', '0');
                            }}
                            onClear={async () => {
                                await setFieldValue('paymentTermsId', null);
                                await setFieldValue('paymentTermsName', '');
                                await setFieldValue('xeroPaymentTerm', '');
                                await setFieldValue('xeroPaymentTermType', '0');
                            }}
                        />
                        <Form.Control.Feedback type="invalid">{errors.paymentTermsId}</Form.Control.Feedback>
                    </InputLayout>
                )}

                {(values.customFormParams.isConnectXero) && (
                    <InputLayout
                        label={this.props.fields.xeroPaymentTerm.label}
                    >
                        <InputGroup>
                            <Form.Control
                                type="number"
                                id={this.props.fields.xeroPaymentTerm.id}
                                placeholder={this.props.fields.xeroPaymentTerm.placeholder}
                                readOnly={true}
                                value={values.xeroPaymentTerm}
                                onChange={async (e) => {
                                    await setFieldValue('xeroPaymentTerm', e.target.value);
                                    await setFieldValue('paymentTermsId', null);
                                    await setFieldValue('paymentTermsName', '');
                                }}
                                isInvalid={!!errors.xeroPaymentTerm}
                            />
                            <InputGroup.Text>{Utils.getXeroPaymentTermTypeName(values.xeroPaymentTermType)}</InputGroup.Text>
                            <Form.Control.Feedback type="invalid">{errors.xeroPaymentTerm}</Form.Control.Feedback>
                        </InputGroup>
                    </InputLayout>
                )}
            </Col>

        </Row>
    }
    

    render() {
        return this.body()
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TopSection);
