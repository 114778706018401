import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import _ from 'lodash';

import {
  Row,
  Col,
  Overlay,
  Popover,
  Form,
  Button,
  Badge,
  ProgressBar,
} from 'react-bootstrap';
import styled from 'styled-components';

import { Spin, Select, Tag, Badge as AntdBadge } from 'antd';

import {
  SvelteGantt,
  SvelteGanttTable,
  MomentSvelteGanttDateAdapter
} from "svelte-gantt";

// Redux
import { useDispatch, useSelector } from "react-redux";
import slice, { DATE_FORMAT, TIME_FORMAT, VIEW_DATE_FORMAT, VIEW_TIME_FORMAT, getStatusName, isDraggableResizable } from './slice';

import FiltersForm, { SelectDropdownMenu, SelectDropdownMenuItem } from './Filters/Form';
import filtersSlice, { getFilterCount, prepareData, isTimeFilterIncluded } from './Filters/slice';

import DriversForm from './Drivers/Form';
import driversSlice from './Drivers/slice';

import TaskForm from './Task/Form';
import taskSlice from './Task/slice';

import unsetTimeSlice from './UnsetTime/slice';
import routeMapSlice from './RouteMap/slice';

import * as dispatchCrudApi from '../../../api/CRUD/DispatchCRUD'
import Utils from '../../../utils/utils.js';
// import {  } from '../../../utils/enums.js';

import { updateApi } from '../../../api/CRUD/DispatchCRUD'

import { ReactComponent as SearchIcon } from "../../../../_metronic/assets/icons/search.svg";
import { ReactComponent as FilterIcon } from "../../../../_metronic/assets/icons/filter-gantt-chart.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../_metronic/assets/icons/arrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "../../../../_metronic/assets/icons/arrow-down.svg";
import { ReactComponent as RightIcon } from "../../../../_metronic/assets/icons/right.svg";
import { ReactComponent as PlusIcon } from "../../../../_metronic/assets/icons/plus-gantt.svg";
import { ReactComponent as MinusIcon } from "../../../../_metronic/assets/icons/minus-gantt.svg";
import { ReactComponent as UncheckedIcon } from "../../../../_metronic/assets/icons/gantt-cb-unchecked.svg";
import { ReactComponent as CheckedIcon } from "../../../../_metronic/assets/icons/gantt-cb-checked.svg";
import { ReactComponent as MinimizeIcon } from "../../../../_metronic/assets/icons/gantt-minimize.svg";
import { ReactComponent as MaximizeIcon } from "../../../../_metronic/assets/icons/gantt-maximize.svg";


export const GanttType = {
  None: 0,
  Empty: 1,
  Task: 2,
  Actual: 3,
  Row: 4,
}
export const GanttTableHeader = {
  Drivers: 'Drivers',
  Vehicle: 'Vehicle',
  Jobs: 'Jobs',
  Dispatched: 'Dispatched',
  Completed: 'Completed',
  Route: 'Route',
}
export const GanttTablePeriod = {
  Daily: {
    value: 0,
    title: 'Daily',
    picker: 'date',
  },
  Weekly: {
    value: 1,
    title: 'Weekly',
    picker: 'week',
  },
  Monthly: {
    value: 2,
    title: 'Monthly',
    picker: 'month',
  },
}
export const GanttTableJobInfo = [
  {
    id: 1,
    title: 'Bin Type',
    value: 'binType',
  },
  {
    id: 2,
    title: 'Waste Type',
    value: 'wasteType',
  },
  {
    id: 3,
    title: 'Job Time',
    value: 'jobTime',
  },
  {
    id: 4,
    title: 'DO#',
    value: 'jobNumber',
  },
]
export const GanttBinWasteType = {
  NONE: 0,
  IN: 1,
  OUT: 2,
}


const StyledBadge = styled(Badge)`
  &.bg-light {
    background-color: #F5E5D5 !important;
    color: #CD7B2E !important;
    width: 16px !important;
    height: 16px !important;
    font-size: 11px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
    padding: 0px;
  }
`;


/** FUNCTIONS */
const setDateTime = (input) => {
  return moment(input, DATE_FORMAT + ' ' + TIME_FORMAT);
}
const addNoneTasks = (rows = [], dateStr, x = 1) => {
  let emptyTasks = [];

  emptyTasks = rows.map((row, i) => {
    let items = [];
    
    if(x > 0){
      for(let j = 0; j < x; j++){
        items.push({
          id: 'none_row_' + row.id + '_item_' + j,
          resourceId: row.id,
          label: '',
          from: setDateTime(dateStr + " 00:00"),
          to: setDateTime(dateStr + " 00:01"),
          draggable: false,
          resizable: false,
          classes: '',
          type: GanttType.None,
          data: null,
        });
      }
    }

    return items;
  });

  return _.flatten(emptyTasks);
};
const addEmptyTasks = (rows = [], dateStr, x = 3) => {
  let emptyTasks = [];

  emptyTasks = rows.map((row, i) => {
    let items = [];
    
    if(x > 0){
      for(let j = 0; j < x; j++){
        items.push({
          id: 'empty_row_' + row.id + '_item_' + j,
          resourceId: row.id,
          label: '',
          from: setDateTime(dateStr + " 00:00"),
          to: setDateTime(dateStr + " 00:01"),
          draggable: false,
          resizable: false,
          classes: '',
          type: GanttType.Empty,
          data: null,
        });
      }
    }

    return items;
  });

  return _.flatten(emptyTasks);
};
/** END FUNCTIONS */



/** COMPONENTS */
const Accordion = (props) => {
  const [isOpen, setIsOpen] = React.useState(((props.isOpen === false) || (props.isOpen === true)) ? props.isOpen : false);

  React.useEffect(() => {
    setIsOpen(((props.isOpen === false) || (props.isOpen === true)) ? props.isOpen : false);
  }, [props.isOpen]);

  return <>
    <Row
      className={'align-items-center g-0'}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        if(props.onChange){
          props.onChange(!isOpen);
        }
      }}
    >
      <Col xs={8} className={'text-driver'} title={props.title}>
        {props.title}
      </Col>
      <Col xs={2} className='text-end'>
        {
        (props && props.unsetJobTimeCount > 0)
        ?
        <StyledBadge pill bg='light' title={'Unset Time'}>{props.unsetJobTimeCount}</StyledBadge>
        :
        <>&nbsp;</>
        }
      </Col>
      <Col xs={2} className='text-end'>
        <Button
          variant={'custom-secondary-light'}
          className='button-expand'
          size='sm'
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();

            if(props.onChange){
              props.onChange(!isOpen);
            }
          }}
        >
          {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </Button>
      </Col>
    </Row>
    {isOpen && <Row>
      <Col xs={12}>{props.children}</Col>
    </Row>}
  </>
}

const CustomTaskComponent = ({ task, jobInfo }) => {
  return <div className={'custom-task ' + (task.draggable ? 'draggable' : '') + ' ' + (task.resizable ? 'resizable' : '')}>
    <div className='title'>
      {(task?.data?.numberOfTrip > 1) && <Badge bg='light'>{task?.data?.numberOfTrip}</Badge>}
      <span>{task?.data?.jobTemplateName}</span>
    </div>
    <div className='desc'><span>{task?.data?.customerName}</span></div>
    <div className='desc'><span>{task?.address}</span></div>

    {(jobInfo.filter((x: any) => x.value == 'binType' || x.value == 'wasteType').length > 0) && <div className='desc'>
      {(jobInfo.findIndex((x: any) => x.value == 'binType') > -1) && <span>{task?.binType}</span>}
      {(jobInfo.filter((x: any) => x.value == 'binType' || x.value == 'wasteType').length > 1) && <div style={{ 
        position: 'relative',
        top: '-1px',
        width: '5px',
        height: '5px',
        backgroundColor: 'white',
        display: 'inline-block',
        borderRadius: '50%',
        marginLeft: '5px',
        marginRight: '5px'
      }}></div>}
      {(jobInfo.findIndex((x: any) => x.value == 'wasteType') > -1) && <span>{task?.wasteType}</span>}
    </div>}

    {(jobInfo.findIndex((x: any) => x.value == 'jobTime') > -1) && <div className='desc'>
      <span>{task?.fromTime}</span> - <span>{task?.toTime}</span>
    </div>}

    {(jobInfo.findIndex((x: any) => x.value == 'jobNumber') > -1) && <div className='desc'>
      <span>{task?.data?.jobNumber}</span>
    </div>}
  </div>
};

export const CustomTableHead = ({ isLoading = false, isVertical, minWidth, onClick, showExpand = false, isExpanded = false, onExpand }) => {
  if(isVertical){
    return <Row className='custom-head-driver-row'
      onClick={(e: any) => {
        if(!isLoading){
          onClick(e);
        }
      }}
    >
    <Col xs={true}>{GanttTableHeader.Drivers}</Col>
    {/* {showExpand && <Col xs={'auto'}
      onClick={(e: any) => {
        e.stopPropagation();
        e.preventDefault();

        if(!isLoading){
          let isExp = !isExpanded;
          onExpand(isExp);
        }
      }}
    >{isExpanded ? <MinimizeIcon /> : <MaximizeIcon />}</Col>} */}
    <Col xs={'auto'}><FilterIcon /></Col>
  </Row>
  } else {
    return <Row style={{ minWidth: minWidth }}>
      <Col xs={3}>
        <Row className='g-2'>
          <Col xs={'auto'}>{GanttTableHeader.Drivers}</Col>
          <Col xs={'auto'}>
            <Button
              variant={'custom-icon-secondary'}
              onClick={onClick}
            >
              <FilterIcon />
            </Button>
          </Col>
        </Row>
      </Col>
      <Col xs={true}>{GanttTableHeader.Vehicle}</Col>
      <Col xs={true}>{GanttTableHeader.Jobs}</Col>
      <Col xs={true}>{GanttTableHeader.Dispatched}</Col>
      <Col xs={true}>{GanttTableHeader.Completed}</Col>
      <Col xs={true}>{GanttTableHeader.Route}</Col>
    </Row>
  }
};
const CustomTableRow = ({ index, isVertical, row, minWidth, onOpen, onInit, onClickUnsetTime, onClickShowRoute }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const isInitialRender = React.useRef(true);


  React.useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      
      if(onInit){
        onInit();
      }
    } else {
      if(onOpen){
        onOpen(isOpen);
      }
    }
  }, [isOpen]);


  if(isVertical){
    return <div>
      <Accordion
        title={row?.data?.driverName}
        unsetJobTimeCount={row?.data?.unsetJobTimeCount}
        isOpen={isOpen}
        onChange={(isOpen) => {
          setIsOpen(isOpen);
        }}
      >
        <div className='card-driver-info'>
          <Row>
            <Col xs={6} className='text-other-vertical'>{GanttTableHeader.Vehicle}</Col>
            <Col xs={6} className='text-other-vertical active text-end'>{(row && row.data && row.data.vehicleName && row.data.vehicleName != '') ? row.data.vehicleName : '-'}</Col>
          </Row>
          <Row>
            <Col xs={6} className='text-other-vertical'>{GanttTableHeader.Jobs}</Col>
            <Col xs={6} className='text-other-vertical active text-end'>{row?.data?.totalJobCount}</Col>
          </Row>
          <Row>
            <Col xs={6} className='text-other-vertical'>{GanttTableHeader.Dispatched}</Col>
            <Col xs={6} className='text-other-vertical active text-end'>{row?.data?.dispatchedJobCount}</Col>
          </Row>
          <Row>
            <Col xs={5} className='text-other-vertical'>{GanttTableHeader.Completed}</Col>
            <Col xs={7} className='text-other-vertical active text-end'>
              <Row className='g-2'>
                <Col xs={true}>
                  <ProgressBar variant='success' now={row?.data?.completedJobCount} max={row?.data?.totalJobCount} />
                </Col>
                <Col xs={'auto'}>{row?.data?.completedJobCount}/{row?.data?.totalJobCount}</Col>
              </Row>
            </Col>
          </Row>
          {(row && row.data && row.data.unsetJobTimeCount > 0) && <Row className={(row && row.data && row.data.unsetJobTimeCount > 0) ? 'mt-4 mb-2' : 'mt-0'}>
            <Col xs={12}>
              <Button
                className='w-100 d-flex justify-content-between align-items-center'
                variant={'custom-outlined-warning'}
                size='sm'
                onClick={() => {
                  if(onClickUnsetTime){
                    onClickUnsetTime(row);
                  }
                }}
              >
                <span className='pe-2'>{row?.data?.unsetJobTimeCount} Unset Time Job</span><RightIcon />
              </Button>
            </Col>
          </Row>}
          <Row className={(row && row.data && row.data.unsetJobTimeCount > 0) ? 'mt-0' : 'mt-4 mb-2'}>
            <Col xs={12}>
              <Button
                className='w-100 d-flex justify-content-between align-items-center'
                variant={'custom-outlined'}
                size='sm'
                onClick={() => {
                  if(onClickShowRoute){
                    onClickShowRoute(row);
                  }
                }}
              >
                <span className='pe-2'>Show {GanttTableHeader.Route}</span><RightIcon />
              </Button>
            </Col>
          </Row>
        </div>
      </Accordion>
    </div>

  } else {
    return <Row style={{ minWidth: minWidth }}>
      <Col xs={true} className='text-driver'>D</Col>
      <Col xs={true} className='text-other-horisontal'>V</Col>
      <Col xs={true} className='text-other-horisontal'>J</Col>
      <Col xs={true} className='text-other-horisontal'>D</Col>
      <Col xs={true} className='text-other-horisontal'>
        <Row className='g-0 text-center'>
          <Col xs={12}>
            <ProgressBar variant='success' now={50} />
          </Col>
          <Col xs={12}>2/4</Col>
        </Row>
      </Col>
      <Col xs={true} className='text-other-horisontal'>R</Col>
    </Row>
  }
};
const CustomTableRowActual = ({ index, row, minWidth }) => {
  return <Row style={{ minWidth: minWidth }}>
    <Col xs={12} className='text-driver' title={row?.data?.driverName}>{row?.data?.driverName}</Col>
    <Col xs={12} className='text-other-vertical'>Actual Job Time</Col>
  </Row>
};
const CustomZoom = ({ onZoomIn, onZoomOut }) => {
  return <Row className='g-0'>
    <Col xs={'auto'}>
      <Button
        variant={'zoom'}
        onClick={() => {
          if(onZoomOut){
            onZoomOut();
          }
        }}
      >
        <MinusIcon />
      </Button>
    </Col>
    <Col xs={'auto'}>
      <Button
        variant={'zoom'}
        onClick={() => {
          if(onZoomIn){
            onZoomIn();
          }
        }}
      >
        <PlusIcon />
      </Button>
    </Col>
  </Row>
};
/** END COMPONENTS */



const ListComponent = React.forwardRef((props: any, ref: any) => {
  const GANTT = React.useRef(null);
  const refGanttChart = React.useRef();

  const [isExpanded, setIsExpanded] = React.useState(false);

  const [showTaskPopup, setShowTaskPopup] = React.useState(false);
  const [targetTaskPopup, setTargetTaskPopup] = React.useState(null);
  const [clickedTask, setClickedTask] = React.useState(null);
  const refTaskPopup = React.useRef(null);

  const [showFiltersPopup, setShowFiltersPopup] = React.useState(false);
  const [targetFiltersPopup, setTargetFiltersPopup] = React.useState(null);
  const refFiltersPopup = React.useRef(null);

  const [showDriversPopup, setShowDriversPopup] = React.useState(false);
  const [targetDriversPopup, setTargetDriversPopup] = React.useState(null);
  const refDriversPopup = React.useRef(null);
  const refDriversPopupForm = React.useRef(null);

  const [headers, setHeaders] = React.useState([ { unit: 'minute', offset: 30, format: VIEW_TIME_FORMAT } ]);
  const [minWidth, setMinWidth] = React.useState(3200);
  const [columnUnit, setColumnUnit] = React.useState('minute');
  const [columnOffset, setColumnOffset] = React.useState(30);
  let columnOffsetValue = 30;

  
  const { isInitLoading, isVertical, showActualJobTime, selectedDate, period, jobInfo, search, isLoadingDaily, rowsDaily, rowsActualDaily, tasksDaily, isLoadingDispatchAll } = useSelector((state) => state.ganttChartSlice);
  const { selectedValues } = useSelector((state) => state.ganttChartDriversSlice);
  const { details } = useSelector((state: RootState) => state.ganttChartFiltersSlice);
  const dispatch = useDispatch();
  


  const TABLE_WITDH_HORISONTAL = 455;
  const TABLE_WITDH_VERTICAL = 250;

  const periodArray = [
    {
      headers: [
        { unit: 'minute', offset: 30, format: VIEW_TIME_FORMAT },
      ],
      minWidth: 3200,
      columnUnit: 'minute',
      columnOffset: 30
    },
    {
      headers: [
        { unit: 'day', format: 'ddd' },
      ],
      minWidth: 1600,
      columnUnit: 'day',
      columnOffset: 1
    },
    {
      headers: [
        { unit: 'day', format: 'DD' },
      ],
      minWidth: 3200,
      columnUnit: 'day',
      columnOffset: 1
    },
  ];
  const periodDailyArray = [
    {
      headers: [
        { unit: 'minute', offset: 60, format: VIEW_TIME_FORMAT },
      ],
      minWidth: 1200,
      columnUnit: 'minute',
      columnOffset: 60
    },

    {
      headers: [
        { unit: 'minute', offset: 30, format: VIEW_TIME_FORMAT },
      ],
      minWidth: 3200,
      columnUnit: 'minute',
      columnOffset: 30
    },


    {
      headers: [
        { unit: 'minute', offset: 20, format: VIEW_TIME_FORMAT },
      ],
      minWidth: 4200,
      columnUnit: 'minute',
      columnOffset: 20
    },
    {
      headers: [
        { unit: 'minute', offset: 15, format: VIEW_TIME_FORMAT },
      ],
      minWidth: 6200,
      columnUnit: 'minute',
      columnOffset: 15
    },
    {
      headers: [
        { unit: 'minute', offset: 10, format: VIEW_TIME_FORMAT },
      ],
      minWidth: 8200,
      columnUnit: 'minute',
      columnOffset: 10
    },
    {
      headers: [
        { unit: 'minute', offset: 15, format: VIEW_TIME_FORMAT },
      ],
      minWidth: 10200,
      columnUnit: 'minute',
      columnOffset: 5
    },
  ];
  const periodWeeklyArray = [
    {
      headers: [
        { unit: 'day', format: 'ddd' },
      ],
      minWidth: 1600,
      columnUnit: 'day',
      columnOffset: 1
    },
    {
      headers: [
        { unit: 'day', format: 'ddd' },
      ],
      minWidth: 1600,
      columnUnit: 'day',
      columnOffset: 3
    },
    {
      headers: [
        { unit: 'day', format: 'ddd' },
      ],
      minWidth: 1600,
      columnUnit: 'day',
      columnOffset: 5
    },
    {
      headers: [
        { unit: 'day', format: 'ddd' },
      ],
      minWidth: 1600,
      columnUnit: 'day',
      columnOffset: 7
    },
  ];
  const periodMonthlyArray = [
    {
      headers: [
        { unit: 'day', format: 'DD' },
      ],
      minWidth: 3200,
      columnUnit: 'day',
      columnOffset: 1
    },
    {
      headers: [
        { unit: 'day', format: 'DD' },
      ],
      minWidth: 1600,
      columnUnit: 'day',
      columnOffset: 3
    },
    {
      headers: [
        { unit: 'day', format: 'DD' },
      ],
      minWidth: 3200,
      columnUnit: 'day',
      columnOffset: 5
    },
    {
      headers: [
        { unit: 'day', format: 'DD' },
      ],
      minWidth: 3200,
      columnUnit: 'day',
      columnOffset: 7
    },
  ];



	React.useImperativeHandle(
    ref,
    () => ({
      callAPI(params) {
        callAPI(params);
      },
      callStatusAPI(params) {
        callStatusAPI(params);
      },
      showFilters(e) {
        setShowFiltersPopup(true);
        setTargetFiltersPopup(e.target);
      },
      scrollToTask(params) {
        scrollToTask(params);
      },
      scrollToRow(params) {
        scrollToRow(params);
      },
      scrollToNow(params) {
        scrollToNow(params);
      },
      updateTaskStatus(params) {
        updateTaskStatus(params);
      },
      updateTasksStatus(params) {
        updateTasksStatus(params);
      },
    }),
  );



  // Init Page
  React.useEffect(() => {
    return () => {
      destroy();
    };
  }, []);
  
  // Init Gantt Chart
  React.useEffect(() => {
    if(!isLoadingDaily){
      setTimeout(() => {
        initGanttChart();
      }, 600);
    }
  }, [isLoadingDaily]);

  // Init on Task click popup
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (refTaskPopup && refTaskPopup.current && !refTaskPopup.current.contains(event.target)) {
          if (!event.target.closest('.rs-picker-menu') && !event.target.closest('.ant-select-dropdown') && !event.target.closest('.ant-select-clear')) {
          setShowTaskPopup(false);
          setTargetTaskPopup(null);
          setClickedTask(null);
        }
      }
    };

    if (showTaskPopup) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showTaskPopup]);
  
  // Init on Filters click popup
  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (refFiltersPopup && refFiltersPopup.current && !refFiltersPopup.current.contains(event.target)) {
        if (!event.target.closest('.rs-picker-menu') && !event.target.closest('.ant-select-dropdown') && !event.target.closest('.ant-select-clear')) {
          setShowFiltersPopup(false);
          setTargetFiltersPopup(null);
        }
      }
    };

    if (showFiltersPopup) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showFiltersPopup]);

  // Init on Drivers click popup
  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (refDriversPopup && refDriversPopup.current && !refDriversPopup.current.contains(event.target)) {
        if (!event.target.closest('.rs-picker-menu') && !event.target.closest('.ant-select-dropdown') && !event.target.closest('.ant-select-clear')) {
          setShowDriversPopup(false);
          setTargetDriversPopup(null);
        }
      }
    };

    if (showDriversPopup) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDriversPopup]);

  // On show actual job time
  React.useEffect(() => {
    dispatch(slice.setIsInitLoading(false));
    setTimeout(() => {
      initGanttChart();
      dispatch(slice.setIsInitLoading(true));
    }, 500);
  }, [showActualJobTime]);

  // On period change
  React.useEffect(() => {
    dispatch(slice.setIsInitLoading(false));
    setTimeout(() => {
      setHeaders(periodArray[period.value].headers);
      setMinWidth(periodArray[period.value].minWidth);
      setColumnUnit(periodArray[period.value].columnUnit);
      setColumnOffset(periodArray[period.value].columnOffset);
      columnOffsetValue = periodArray[period.value].columnOffset;

      dispatch(slice.setIsInitLoading(true));
    }, 500);
  }, [period]);

  // On job info change
  React.useEffect(() => {
    dispatch(slice.setIsInitLoading(false));
    setTimeout(() => {
      initGanttChart();
      dispatch(slice.setIsInitLoading(true));
    }, 500);
  }, [jobInfo]);

  // On Expanded change
  React.useEffect(() => {
    const buttons = document.querySelectorAll('.button-expand');
    buttons.forEach(button => {
      button.click();
    });
  }, [isExpanded]);

  // On zoom change
  React.useEffect(() => {
    if(GANTT && GANTT.current){
      GANTT.current.$set({
        headers: headers,
        minWidth: minWidth,
        columnUnit: columnUnit,
        columnOffset: columnOffset,
      });
      scrollToNow();
    }
  }, [columnOffset]);

  // On Filters change
  React.useEffect(() => {
    let firstTask = (tasksDaily && tasksDaily.length > 0) ? tasksDaily[0] : null;
    if(firstTask && isTimeFilterIncluded(details)){
      scrollToTask(firstTask.id);
    }
  }, [details]);

  // On Date, Drivers, Filters, Search change
  React.useEffect(() => {
    callAPI();
  }, [selectedDate, selectedValues, details, search]);



  /** API */
  const callAPI = () => {
    let fromToDate = getFromToDate();
    
    let params = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: search,
      fromDate: fromToDate.fromDate,
      toDate: fromToDate.toDate,
      isAssigned: true,
      sortColumn: 'updated',
      sortDir: 'desc',
    }
    
    let driverFilter = (selectedValues && selectedValues.length > 0) ? selectedValues.join(',') : null;

    let filterParams = [];
    if(getFilterCount(details) > 0){
      filterParams = prepareData(details);
    }
    let newParams = { ...params, ...filterParams, driverFilter: driverFilter }

    dispatch(slice.callReadDailyApi(newParams, (state: boolean, data: any) => {
      if(state){
        scrollToNow();
      }
    }));
    dispatch(slice.callReadDailyStatusApi(newParams, (state: boolean, data: any) => {}));
  }

  const callStatusAPI = () => {
    let fromToDate = getFromToDate();
    
    let params = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: search,
      fromDate: fromToDate.fromDate,
      toDate: fromToDate.toDate,
      isAssigned: true,
      sortColumn: 'updated',
      sortDir: 'desc',
    }
    
    let driverFilter = (selectedValues && selectedValues.length > 0) ? selectedValues.join(',') : null;

    let filterParams = [];
    if(getFilterCount(details) > 0){
      filterParams = prepareData(details);
    }
    let newParams = { ...params, ...filterParams, driverFilter: driverFilter }

    dispatch(slice.callReadDailyStatusApi(newParams, (state: boolean, data: any) => {}));
  }

  const callMoveTimeAPI = (jobIds: any, params: any) => {
    let arr: any = [{
      api: updateApi,
      path: 'job/' + params.jobId + '/time',
      params: () => {
        return params
      },
    }];
    
    if (jobIds && jobIds.length > 0) {
      jobIds.forEach((jobId: any) => {
        let newParams = _.cloneDeep(params);
        newParams.jobId = jobId;
        arr.push({
          api: updateApi,
          path: 'job/' + jobId + '/time',
          params: () => {
            return newParams
          },
        })
      });
    }

    Utils.callAllApi(arr, null, (results: any) => {});
  }
  /** END API */



  /** FUNCTIONS */
  const getFromToDate = () => {
    let fromDate = moment(selectedDate, Utils.getDefaultDateFormat()).format(Utils.getAPIDateFormat());
    let toDate = moment(selectedDate, Utils.getDefaultDateFormat()).format(Utils.getAPIDateFormat());

    return {
      fromDate: fromDate,
      toDate: toDate,
    }
  }

  const initGanttChart = () => {
    destroy();

    try {
      GANTT.current = new SvelteGantt({ target: refGanttChart.current, props: getOptions() });

      if(GANTT && GANTT.current){
        setTimeout(() => {
          addRowsComponenent();
          addZoomButtonsComponenent();

          let taskChanged = false;
          const tasksEls = document.querySelectorAll('.sg-task');
          tasksEls.forEach(taskElement => {
            taskElement.addEventListener('click', (event) => {
              if (!taskChanged) {
                const taskId = taskElement.getAttribute('data-task-id');
                let task = GANTT.current.getTask(taskId);

                onTaskClick(task?.model, event);
              }
              taskChanged = false;
            });
          });

          GANTT.current.api.tasks.on.changed((task) => {
            onTaskMove(task);
            taskChanged = true;
          });
          GANTT.current.api.tasks.on.move((task) => {
            taskChanged = true;
            setShowTaskPopup(false);
            setTargetTaskPopup(null);
            setClickedTask(null);
          });
          GANTT.current.api.tasks.on.resize((task) => {
            setShowTaskPopup(false);
            setTargetTaskPopup(null);
            setClickedTask(null);
          });

          scrollToNow();
        }, 100);
      }
    }catch(e){}
  };
  const getOptions = () => {
    let rows = _.cloneDeep(showActualJobTime ? rowsActualDaily : rowsDaily);
    let task = _.cloneDeep(tasksDaily);
    
    let currentTime = moment(selectedDate, DATE_FORMAT).set({ hour: moment().hour(), minute: moment().minute() });
    let currentTimePlus1 = currentTime.clone().add(1, 'second');
    
    return {
      rows: rows,
      tasks: task,
      from: setGanttFrom(),
      to: setGanttTo(),

      dateAdapter: new MomentSvelteGanttDateAdapter(moment),
      ganttTableModules: [SvelteGanttTable],
      
      magnetUnit: "minute",
      magnetOffset: 1,
      headers: headers,
      minWidth: minWidth,
      columnUnit: columnUnit,
      columnOffset: columnOffset,
      zoomLevels: getZoomLever(),

      timeRanges: [
        {
          id: 1,
          from: (moment().format(DATE_FORMAT) == selectedDate) ? moment() : currentTime,
          to: (moment().format(DATE_FORMAT) == selectedDate) ? moment().add(1, 'minute') : currentTimePlus1,
          classes: 'time-range-now',
          label: '',
          resizable: false,
        },
      ],

      dependencies: [],
      layout: 'expand',
      rowHeight: getRowHeight(),
      rowPadding: 0,
      taskContent: (task) => {
        const fromMoment = moment.isMoment(task.from) ? task.from : moment(task.from);
        const toMoment = moment.isMoment(task.to) ? task.to : moment(task.to);
        
        return ReactDOMServer.renderToStaticMarkup(<CustomTaskComponent task={{ ...task, from: fromMoment, to: toMoment }} jobInfo={jobInfo} />);
      },
      columnStrokeColor: '#ececec',
      fitWidth: true,
      tableHeaders: [
        { title: 'header', property: 'header' },
      ],
      tableWidth: isVertical ? TABLE_WITDH_VERTICAL : TABLE_WITDH_HORISONTAL,
    }
  };
  const destroy = () => {
    if(GANTT && GANTT.current){
      GANTT.current.$destroy();
      GANTT.current = null;
    }
    dispatch(slice.setIsInitLoading(false));
  };

  const addRowsComponenent = () => {
    try {
      let rows = showActualJobTime ? rowsActualDaily : rowsDaily;

      const headers = document.querySelectorAll('.sg-table-header .sg-table-header-cell');
      headers.forEach((header) => {
        ReactDOM.render(<CustomTableHead
          showExpand={true}
          isExpanded={isExpanded}
          isLoading={isLoadingDaily}
          isVertical={isVertical}
          minWidth={isVertical ? TABLE_WITDH_VERTICAL : TABLE_WITDH_HORISONTAL}
          onClick={(e) => {
            setShowDriversPopup(true);
            setTargetDriversPopup(e.target);
          }}
          onExpand={(isExpanded) => {
            setIsExpanded(isExpanded);
          }}
        />, header);
      });
      
      const rowsEls = document.querySelectorAll('.my-custom-row');
      rowsEls.forEach((row, i) => {
        const rowId = row?.dataset?.id;
        let rowItem = rows.find((x: any) => x.id == rowId);

        if(rowItem && rowItem.type == GanttType.Actual){
          // row.style.alignItems = 'center';
          row.classList.add('actual');

          ReactDOM.render(<CustomTableRowActual
            index={i}
            minWidth={isVertical ? TABLE_WITDH_VERTICAL : TABLE_WITDH_HORISONTAL}
            row={rowItem}
          />, row);

        } else if(rowItem && rowItem.type == GanttType.Row){
          // row.style.alignItems = isVertical ? 'flex-start' : 'center';
          
          ReactDOM.render(<CustomTableRow
            index={i}
            isVertical={isVertical}
            minWidth={isVertical ? TABLE_WITDH_VERTICAL : TABLE_WITDH_HORISONTAL}
            row={rowItem}
            onOpen={(isOpen) => {
              if(isOpen){
                addEmptyTask(rowItem);
              } else {
                removeEmptyTask(rowItem);
              }
            }}
            onInit={() => {
              addNoneTask(rowItem);
            }}
            onClickUnsetTime={(row: any) => {
              dispatch(unsetTimeSlice.setShow({ show: true, details: rowItem }));
            }}
            onClickShowRoute={(row: any) => {
              dispatch(routeMapSlice.setShow({ show: true, details: rowItem }));
            }}
          />, row);
        }
      });
    }catch(e){}
  };
  const addZoomButtonsComponenent = () => {
    try {
      const elements = document.querySelectorAll('.custom-zoom-buttons');
      elements.forEach(element => element.remove());
    }catch(e){}

    try {
      if(refGanttChart && refGanttChart.current){
        const zoomDiv = document.createElement('div');
        zoomDiv.classList.add('custom-zoom-buttons');
        refGanttChart.current.appendChild(zoomDiv);
        ReactDOM.render(<CustomZoom
          onZoomIn={zoomIn}
          onZoomOut={zoomOut}
        />, zoomDiv);
      }
    }catch(e){}
  };

  const getRowHeight = () => {
    let rowHeight = 57;

    if(jobInfo.length === 1){
      rowHeight = 75;
    } else if(jobInfo.length === 2){
      rowHeight = 93;
    } else if(jobInfo.length === 3){
      rowHeight = 111;
    } else if(jobInfo.length === 4){
      rowHeight = 129;
    }

    return rowHeight;
  };

  const addNoneTask = (row = null) => {
    try {
      if(row){
        if(GANTT && GANTT.current){
          let noneRows = addNoneTasks([row], selectedDate);
          let taskArr = GANTT.current.getTasks(row.id);
          let taskModelArr = (taskArr && taskArr.length > 0) ? taskArr.map(x => x.model) : []
          let alltasks = _.concat(noneRows, taskModelArr);
        
          GANTT.current.updateTasks(alltasks);
        }
      }
    }catch(e){}
  };
  const addEmptyTask = (row = null) => {
    try {
      if(row){
        if(GANTT && GANTT.current){
          let emptyRows = addEmptyTasks([row], selectedDate);
          let taskArr = GANTT.current.getTasks(row.id);
          let taskModelArr = (taskArr && taskArr.length > 0) ? taskArr.map(x => x.model) : []
          let alltasks = _.concat(emptyRows, taskModelArr);
        
          GANTT.current.updateTasks(alltasks);
        }
      }
    }catch(e){}
  };
  const removeEmptyTask = (row = null) => {
    try {
      if(row){
        if(GANTT && GANTT.current){
          let taskArr = GANTT.current.getTasks(row.id);
          let taskModelArr = (taskArr && taskArr.length > 0) ? taskArr.map(x => x.model) : []
          let removetasks = taskModelArr.filter(x => x.type === GanttType.Empty)
        
          GANTT.current.removeTasks(removetasks.map(x => x.id));
        }
      }
    }catch(e){}
  };
  const updateTaskStatus = (itemJob = null) => {
    try {
      if(itemJob && GANTT && GANTT.current){
        let task = GANTT.current.getTask(itemJob.jobId);
        if(task && task.model){
          let statusName = getStatusName(itemJob);
          let isDraggableResizableObj = isDraggableResizable(itemJob);

          let newModel = {
            ...task.model,
            draggable: isDraggableResizableObj.draggable,
            resizable: isDraggableResizableObj.resizable,
            classes: statusName,
            data: itemJob,
            statusName: statusName,
          }

          GANTT.current.updateTask(newModel);
        }
      }
    }catch(e){}
  };
  const updateTasksStatus = (itemsJob = []) => {
    try {
      if(itemsJob && itemsJob.length > 0){
        let allModels = [];

        itemsJob.forEach((itemJob: any) => {
          if(itemJob && GANTT && GANTT.current){
            let task = GANTT.current.getTask(itemJob.jobId);
            if(task && task.model){
              let statusName = getStatusName(itemJob);
              let isDraggableResizableObj = isDraggableResizable(itemJob);
    
              let newModel = {
                ...task.model,
                draggable: isDraggableResizableObj.draggable,
                resizable: isDraggableResizableObj.resizable,
                classes: statusName,
                data: itemJob,
                statusName: statusName,
              }
    
              allModels.push(newModel);
            }
          }
        });
        
        GANTT.current.updateTasks(allModels);
      }
    }catch(e){}
  };
  const updateTaskMove = (taskArray = null) => {
    try {
      if(taskArray && taskArray.length > 0){
        let taskObject = taskArray[0];
        let sourceRow = (taskObject.sourceRow && taskObject.sourceRow.model) ? taskObject.sourceRow.model : null;
        let targetRow = (taskObject.targetRow && taskObject.targetRow.model) ? taskObject.targetRow.model : null;
        let task = (taskObject.task && taskObject.task.model) ? taskObject.task.model : null;

        var dif = moment.duration(moment(task.to).diff(moment(task.from)));
        let jobDurationHours = dif.hours();
        let jobDurationMinutes = dif.minutes();

        let startTime = Utils.getLocalIsoDateTime(moment(task.from).format());

        let params = {
          jobId: task.data.jobId,
          jobStatusId: task.data.jobStatusId,
          driverId: targetRow.id,
          vehicleId: targetRow.data.vehicleId,
          
          jobDate: startTime,
          jobTimeFrom: startTime,
          jobTimeSpecific: startTime,
          jobDurationHours: jobDurationHours,
          jobDurationMinutes: jobDurationMinutes,
        }

        if(task && task.childJobsIds && task.childJobsIds.length > 0){
          callMoveTimeAPI(task.childJobsIds, params)
        } else {
          dispatch(slice.callTimeApi(params, (state: boolean, data: any) => {}));
        }
      }
    }catch(e){}
  };

  const setGanttFrom = () => {
    if(period.picker === GanttTablePeriod.Monthly.picker){
      return moment(selectedDate, DATE_FORMAT + ' ' + TIME_FORMAT).startOf('month');
    } else if(period.picker === GanttTablePeriod.Weekly.picker){
      return moment(selectedDate, DATE_FORMAT + ' ' + TIME_FORMAT).startOf('week');
    } else {
      return moment(selectedDate, DATE_FORMAT + ' ' + TIME_FORMAT).startOf('day');
    }
  }
  const setGanttTo = () => {
    if(period.picker === GanttTablePeriod.Monthly.picker){
      return moment(selectedDate, DATE_FORMAT + ' ' + TIME_FORMAT).endOf('month');
    } else if(period.picker === GanttTablePeriod.Weekly.picker){
      return moment(selectedDate, DATE_FORMAT + ' ' + TIME_FORMAT).endOf('week');
    } else {
      return moment(selectedDate, DATE_FORMAT + ' ' + TIME_FORMAT).endOf('day');
    }
  }

  const scrollToTask = ({ id = null, offset = 100, scrollBehavior = 'auto' } = {}) => {
    try {
      if(id){
        setTimeout(() => {
          if(refGanttChart && refGanttChart.current){
            const elementBody = refGanttChart.current.querySelector('.sg-timeline-body');
            const element = refGanttChart.current.querySelector(`.sg-foreground [data-task-id="${id}"]`);
            
            if (elementBody && element) {
              const elementRect = element.getBoundingClientRect();
              const bodyRect = elementBody.getBoundingClientRect();

              const scrollLeft = elementRect.left - bodyRect.left + elementBody.scrollLeft - offset;

              elementBody.scrollTo({
                left: scrollLeft,
                behavior: scrollBehavior
              });
            }
          }
        }, 50);
      }
    }catch(e){}
  };
  const scrollToRow = ({ id = null, offset = 65, scrollBehavior = 'auto' } = {}) => {
    try {
      if(id){
        setTimeout(() => {
          if(refGanttChart && refGanttChart.current){
            const rowElement = refGanttChart.current.querySelector(`[data-row-id="${id}"]`);
            
            if (rowElement) {
              const elementPosition = rowElement.getBoundingClientRect().top + window.pageYOffset;
              const offsetPosition = elementPosition - offset;

              window.scrollTo({
                top: offsetPosition,
                behavior: scrollBehavior,
              });
            }
          }
        }, 50);
      }
    }catch(e){}
  };
  const scrollToNow = ({ offset = 300, scrollBehavior = 'auto' } = {}) => {
    try {
      setTimeout(() => {
        if(refGanttChart && refGanttChart.current){
          const elementBody = refGanttChart.current.querySelector('.sg-timeline-body');
          const element = refGanttChart.current.querySelector('.sg-foreground .time-range-now');
          
          if (elementBody && element) {
            const elementRect = element.getBoundingClientRect();
            const bodyRect = elementBody.getBoundingClientRect();

            const scrollLeft = elementRect.left - bodyRect.left + elementBody.scrollLeft - offset;

            elementBody.scrollTo({
              left: scrollLeft,
              behavior: scrollBehavior
            });
          }
        }
      }, 50);
    }catch(e){}
  };

  const getZoomLever = () => {
    if(period.picker === GanttTablePeriod.Monthly.picker){
      return periodMonthlyArray;
    } else if(period.picker === GanttTablePeriod.Weekly.picker){
      return periodWeeklyArray;
    } else {
      return periodDailyArray;
    }
  }
  const zoomIn = () => {
    if(period.picker === GanttTablePeriod.Monthly.picker){
      let index = periodMonthlyArray.findIndex((x) => x.columnOffset == columnOffsetValue);
      if(index > -1){
        if(index < (periodMonthlyArray.length -1)){
          index = index + 1;
        } else {
          index = 0;
        }
      } else {
        index = periodArray[period.value].columnOffset;
      }

      let headers = periodMonthlyArray[index].headers;
      let minWidth = periodMonthlyArray[index].minWidth;
      let columnUnit = periodMonthlyArray[index].columnUnit;
      let colOffset = periodMonthlyArray[index].columnOffset;

      setHeaders(headers);
      setMinWidth(minWidth);
      setColumnUnit(columnUnit);
      setColumnOffset(colOffset);
      columnOffsetValue = colOffset;

    } else if(period.picker === GanttTablePeriod.Weekly.picker){
      let index = periodWeeklyArray.findIndex((x) => x.columnOffset == columnOffsetValue);
      if(index > -1){
        if(index < (periodWeeklyArray.length -1)){
          index = index + 1;
        } else {
          index = 0;
        }
      } else {
        index = periodArray[period.value].columnOffset;
      }

      let headers = periodWeeklyArray[index].headers;
      let minWidth = periodWeeklyArray[index].minWidth;
      let columnUnit = periodWeeklyArray[index].columnUnit;
      let colOffset = periodWeeklyArray[index].columnOffset;

      setHeaders(headers);
      setMinWidth(minWidth);
      setColumnUnit(columnUnit);
      setColumnOffset(colOffset);
      columnOffsetValue = colOffset;
      
    } else {
      let index = periodDailyArray.findIndex((x) => x.columnOffset == columnOffsetValue);
      if(index > -1){
        if(index < (periodDailyArray.length -1)){
          index = index + 1;
        } else {
          index = 0;
        }
      } else {
        index = periodArray[period.value].columnOffset;
      }

      let headers = periodDailyArray[index].headers;
      let minWidth = periodDailyArray[index].minWidth;
      let columnUnit = periodDailyArray[index].columnUnit;
      let colOffset = periodDailyArray[index].columnOffset;

      setHeaders(headers);
      setMinWidth(minWidth);
      setColumnUnit(columnUnit);
      setColumnOffset(colOffset);
      columnOffsetValue = colOffset;
    }
  }
  const zoomOut = () => {
    if(period.picker === GanttTablePeriod.Monthly.picker){
      let index = periodMonthlyArray.findIndex((x) => x.columnOffset == columnOffsetValue);
      if(index > -1){
        if(index > 0){
          index =  index - 1;
        } else {
          index = (periodMonthlyArray.length -1);
        }
      } else {
        index = periodArray[period.value].columnOffset;
      }

      let headers = periodMonthlyArray[index].headers;
      let minWidth = periodMonthlyArray[index].minWidth;
      let columnUnit = periodMonthlyArray[index].columnUnit;
      let colOffset = periodMonthlyArray[index].columnOffset;

      setHeaders(headers);
      setMinWidth(minWidth);
      setColumnUnit(columnUnit);
      setColumnOffset(colOffset);
      columnOffsetValue = colOffset;

    } else if(period.picker === GanttTablePeriod.Weekly.picker){
      let index = periodWeeklyArray.findIndex((x) => x.columnOffset == columnOffsetValue);
      if(index > -1){
        if(index > 0){
          index =  index - 1;
        } else {
          index = (periodWeeklyArray.length -1);
        }
      } else {
        index = periodArray[period.value].columnOffset;
      }

      let headers = periodWeeklyArray[index].headers;
      let minWidth = periodWeeklyArray[index].minWidth;
      let columnUnit = periodWeeklyArray[index].columnUnit;
      let colOffset = periodWeeklyArray[index].columnOffset;

      setHeaders(headers);
      setMinWidth(minWidth);
      setColumnUnit(columnUnit);
      setColumnOffset(colOffset);
      columnOffsetValue = colOffset;
      
    } else {
      let index = periodDailyArray.findIndex((x) => x.columnOffset == columnOffsetValue);
      if(index > -1){
        if(index > 0){
          index =  index - 1;
        } else {
          index = (periodDailyArray.length -1);
        }
      } else {
        index = periodArray[period.value].columnOffset;
      }

      let headers = periodDailyArray[index].headers;
      let minWidth = periodDailyArray[index].minWidth;
      let columnUnit = periodDailyArray[index].columnUnit;
      let colOffset = periodDailyArray[index].columnOffset;

      setHeaders(headers);
      setMinWidth(minWidth);
      setColumnUnit(columnUnit);
      setColumnOffset(colOffset);
      columnOffsetValue = colOffset;
    }
  }

  const onTaskClick = (task, e) => {
    if(showTaskPopup){
      setShowTaskPopup(false);
      setTargetTaskPopup(null);
      setClickedTask(null);
    } else {
      setShowTaskPopup(true);
      setTargetTaskPopup(e.target);
      setClickedTask(task);

      setShowFiltersPopup(false);
      setTargetFiltersPopup(null);
    }
  };
  const onTaskMove = (task) => {
    updateTaskMove(task);
  };
  /** END FUNCTIONS */

  
  return <>
    <Spin spinning={isLoadingDaily || isLoadingDispatchAll} size={'small'}>
      <div ref={refGanttChart} className='position-relative' />
    </Spin>

    {/* OVERLAYS */}
    <Overlay
      show={showTaskPopup}
      target={targetTaskPopup}
      placement={'top'}
    >
      <Popover
        className='gantt-popover-container'
      >
        <Popover.Body ref={refTaskPopup} style={{ padding: '0px' }}>
          <TaskForm
            task={clickedTask}
            onChange={(data) => {
              updateTaskStatus(data);
              callStatusAPI();

              setShowTaskPopup(false);
              setTargetTaskPopup(null);
              setClickedTask(null);
            }}
            onHide={() => {
              setShowTaskPopup(false);
              setTargetTaskPopup(null);
              setClickedTask(null);
            }}
          />
        </Popover.Body>
      </Popover>
    </Overlay>

    <Overlay
      show={showFiltersPopup}
      target={targetFiltersPopup}
      placement={'bottom'}
    >
      <Popover 
        className='gantt-popover-container'
        style={{ minWidth: '350px' }}
      >
        <Popover.Body ref={refFiltersPopup} className='px-3 py-0'>
          <FiltersForm
            onSave={(data) => {
              setShowFiltersPopup(false);
              setTargetFiltersPopup(null);
            }}
          />
        </Popover.Body>
      </Popover>
    </Overlay>

    <Overlay
      show={showDriversPopup}
      target={targetDriversPopup}
      placement={'bottom'}
      onExited={() => {
        if(refDriversPopupForm && refDriversPopupForm.current){
          let values = refDriversPopupForm.current?.onSave();
          dispatch(driversSlice.setSelectedValues(values));
        }
      }}
    >
      <Popover 
        className='gantt-popover-container'
        style={{ minWidth: '250px' }}
      >
        <Popover.Body ref={refDriversPopup} className='px-3 py-0'>
          <DriversForm ref={refDriversPopupForm} />
        </Popover.Body>
      </Popover>
    </Overlay>
    {/* END OVERLAYS */}
  </>
});

export default ListComponent;
