import React from "react";
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';

import { 
    Row,
    Col,
    Form,
    Button,
    Dropdown,
    Badge,
    Spinner,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import { Icon } from '@iconify/react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
// import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

// import { ReactComponent as LightbulbIcon } from '../../../../_metronic/assets/img/icons/material-lightbulb-outline.svg';

import Utils from "../../../utils/utils";
import { PageCookieSettingType, CustomerSiteAvailability, SortColumns, PagesPermissions } from "../../../utils/enums";

import WrappedButton from '../../../components/Buttons/WrappedButton';
import DropdownToggle from '../../../components/dropdown/DropdownToggle';
import SearchDialog from '../../../components/dialog/SearchDialog';
import DateDialog from '../../../components/dialog/DateDialog';
import Popup from '../../../components/popup/Popup';
import LoadingButton from '../../../components/Buttons/LoadingButton';
import makeDropdownList from "../../../components/dropdown/makeDropdownList";
import DataTableActionMenu from '../../../components/OLD/DataTable/DataTableActionMenu';
import DataTable from "../../../components/OLD/DataTable/DataTable";
import DataTableAjaxPagination from '../../../components/OLD/DataTable/Pagination/DataTableAjaxPaginationV5';
import DataTableScrollSync from '../../../components/OLD/DataTable/DataTableScrollSync.jsx';
import RemoveDialog from '../../../components/dialog/RemoveDialog';
import IconButtonSearch from '../../../components/input/IconButtonSearch';
import CustomTooltip from '../../../components/Tooltip/Tooltip';

import ViewTopSection from "../partial/ViewTopSection";
import BulkUpdateCustomerSiteForm from "./BulkUpdateCustomerSiteForm"
import MailTemplateCustomerSiteForm from "./MailTemplateCustomerSiteForm"

import CustomerForm from "./CustomerForm";
import CustomerSiteForm from "./CustomerSiteForm";

import { readApi, updateApi } from '../../../api/CRUD/DispatchCRUD'

import { connect } from 'react-redux'
import { clear } from "../../../../setup/redux/actions";
import { actions } from "../../../../setup/redux/auth/saga";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'
import signalRslice from "../../../components/signalR/signalRslice";


const { saveUserCookieSettings } = actions;
const DropdownMenu = makeDropdownList(Button);


const StyledDropdownToggleText = styled.span`
    font-size: 14px;
`;
const StyledBadge = styled(Badge)`
    background-color: transparent;
    border: 1px solid #DFDFDF;
    font-weight: normal;
`;
const StyledTabButton = styled.button`
    background-color: transparent;
    color: #185CFF;
    border-bottom: 2px solid transparent;
    padding-bottom: 7px;

    &:hover {
        border-bottom: 2px solid #185CFF;
    }

    ${props => {
        if(props.active){
            return 'color: #484848; border-bottom: 2px solid #185CFF;';
        }
    }}
`;



const StyledFormCheck = styled(Form.Check)`
    position: relative;
    border-bottom: 1px solid #F5F5F5;
    min-height: 60px;
    display: flex;
    align-items: center;

    .form-check-input[type=checkbox] {
        margin-left: 10px;
        border-radius: 0px;
        border: 0px;
        cursor: pointer;
        background-size: contain;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3e%3cg id='Icon_ionic-ios-checkbox-outline' data-name='Icon ionic-ios-checkbox-outline' transform='translate(-2.5 -2.5)'%3e%3cpath id='Path_24397' data-name='Path 24397' d='M16.25,2.5H3.75A1.249,1.249,0,0,0,2.5,3.75v12.5A1.249,1.249,0,0,0,3.75,17.5h12.5a1.249,1.249,0,0,0,1.25-1.25V3.75A1.249,1.249,0,0,0,16.25,2.5Zm.156,13.594a.313.313,0,0,1-.312.313H3.906a.313.313,0,0,1-.312-.312V3.906a.313.313,0,0,1,.313-.312H16.094a.313.313,0,0,1,.313.313Z' fill='%23dfdfdf'/%3e%3cpath id='Path_24398' data-name='Path 24398' d='M14.2,7.535l-.687-.707a.148.148,0,0,0-.109-.047h0a.142.142,0,0,0-.109.047l-4.766,4.8L6.8,9.895a.151.151,0,0,0-.219,0l-.7.7a.156.156,0,0,0,0,.223L8.07,13a.692.692,0,0,0,.457.223.725.725,0,0,0,.453-.215h0l5.223-5.25A.167.167,0,0,0,14.2,7.535Z' fill='%23dfdfdf'/%3e%3c/g%3e%3c/svg%3e");
        
        &:checked{
            background-color: transparent;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15'%3e%3cg id='Icon_ionic-ios-checkbox-outline' data-name='Icon ionic-ios-checkbox-outline' transform='translate(-2.5 -2.5)'%3e%3cpath id='Path_24397' data-name='Path 24397' d='M16.25,2.5H3.75A1.249,1.249,0,0,0,2.5,3.75v12.5A1.249,1.249,0,0,0,3.75,17.5h12.5a1.249,1.249,0,0,0,1.25-1.25V3.75A1.249,1.249,0,0,0,16.25,2.5Zm.156,13.594a.313.313,0,0,1-.312.313H3.906a.313.313,0,0,1-.312-.312V3.906a.313.313,0,0,1,.313-.312H16.094a.313.313,0,0,1,.313.313Z' fill='%23185cff'/%3e%3cpath id='Path_24398' data-name='Path 24398' d='M14.2,7.535l-.687-.707a.148.148,0,0,0-.109-.047h0a.142.142,0,0,0-.109.047l-4.766,4.8L6.8,9.895a.151.151,0,0,0-.219,0l-.7.7a.156.156,0,0,0,0,.223L8.07,13a.692.692,0,0,0,.457.223.725.725,0,0,0,.453-.215h0l5.223-5.25A.167.167,0,0,0,14.2,7.535Z' fill='%23185cff'/%3e%3c/g%3e%3c/svg%3e");
        }
    }

    label {
        position: relative;
        left: 10px;
        width: 100%;
        height: 100%;
        padding: 0px 10px 0px 0px;
        cursor: pointer;
        
        display: flex;
        align-items: center;
        
        width: 100%;
    
        ${(props) => {
            return props.isActive ? 'color: #185CFF;' : 'color: #FF0090;';
        }}

        &.text-secondary {
            color: #7B7B7B !important;
            font-size: 12px;
        }
    }

    .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
        opacity: 1;
    }

    ${(props) => {
        return props.active ? 'background-color: #ECF5DF;' : '';
    }}
`;
const StyledSortItem = styled(Dropdown.Item)`
    position: relative;
    display: flex;
    align-items: center;

    .custom-title {
        display: inline-block;
        flex-basis: 100%;
        width: 130px;
        white-space: pre-wrap;
        color: #484848;

        ${(props) => {
            return (props.active) ? 'font-weight: bold;' : '';
        }}
    }

    .custom-icons {
        display: inline-block;
        position: relative;
        width: 20px;
        height: 20px;
        overflow: hidden;
        background-color: transparent !important;

        .custom-icon-up {
            overflow: hidden;
            position: relative;
            height: 12px;

            > div {
                position: absolute;
                top: -6px;

                i {
                    ${(props) => {
                        return (props.active && props.direction == 'asc') ? 'color: #484848;' : 'color: #B8B8B8;';
                    }}

                    &:hover {
                        color: #484848;
                    }
                }
            }

            ${(props) => {
                return (props.active) ? 'display: block;' : 'display: none;';
            }}
        }

        .custom-icon-down {
            overflow: hidden;
            position: relative;
            height: 12px;

            > div {
                position: absolute;
                top: -9px;

                i {
                    ${(props) => {
                        return (props.active && props.direction == 'desc') ? 'color: #484848;' : 'color: #B8B8B8;';
                    }}

                    &:hover {
                        color: #484848;
                    }
                }
            }

            ${(props) => {
                return (props.active) ? 'display: block;' : 'display: none;';
            }}
        }
    }
    
    &.active, &:active, &:focus {
        ${(props) => {
            return props.active ? 'background-color: #ECECEC !important;' : 'background-color: transparent !important;';
        }}
    }

    &:hover {
        .custom-icons {
            .custom-icon-up {
                display: block;
            }
            .custom-icon-down {
                display: block;
            }
        }
    }
`;

const StyledNoResult = styled.div`
    max-width: 600px;
    height: 200px;
    background-color: #F0F7E5;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin: 50px auto;
`;


class CustomerSites extends React.Component {
  
    constructor(props) {
        super(props);

        this.pageName = 'customer_sites';
        this.backLink = '/Customers';

        this.pageIdField = "customerSiteId";
        this.pageCaption = 'Customer Sites'

        this.refForm = React.createRef();
        this.refCustomerForm = React.createRef();
        this.refCustomerSitesForm = React.createRef();
        this.tableRef = React.createRef();
        this.refDataTableScrollSync = React.createRef();
        this.refBulkUpdate = React.createRef();
        this.refMailTemplate = React.createRef();
        
        let id = Utils.getIntProps(props);
        
        let user = (props && props.auth && props.auth.user) ? props.auth.user : null;
        this.permissions = (user && user.permissions && user.permissions.length > 0) ? user.permissions : [];
        
        
        if(this.permissions && this.permissions.length > 0){
            if(this.permissions.findIndex(x => x.permissionId === PagesPermissions.CustomerSites.read) === -1){
                props.history.push('/no-permission');
            }
        }
        

        this.showNewPopup = this.props.history.location.state?.showNewPopup || false;
        if(this.showNewPopup){
            this.props.history.push({
                pathname: '/Customer-overview-sites',
                search: '?id=' + id,
                state: { showNewPopup: false },
            });
        }

        
        this.isDeactivateOrDeleteTemplate = (id, row, col, obj) => {
            return <div className={(row.isDeactivateOrDelete) ? 'text-danger' : ''}>{(row.isDeactivateOrDelete) ? 'Inactive' : 'Active'}</div>
        };
        this.isDeactivateOrDeleteExportTemplate = (row) => {
            return row.isDeactivateOrDelete === true ? 'Inactive' : 'Active';
        };
        this.isDeactivateOrDeleteExportCSVTemplate = (row) => {
            return row.isDeactivateOrDelete === true ? 'Inactive' : 'Active';
        };
        
        this.contactPersonPhoneNumberTemplate = (id, row, col, obj) => {
            return <div>
                {row.contactPersonPhoneNumber}
                {/* {
                (row.isContactPersonSendNotification)
                ?
                <CustomTooltip title={'Notification'}>
                    <i className={'material-icons ms-2'} style={{ position: 'relative', top: '4px', fontSize: '20px' }}>phone_iphone</i>
                </CustomTooltip>
                :
                ''} */}
            </div>
        };
        this.contactPersonPhoneNumberExportTemplate = (row) => {
            return row.contactPersonPhoneNumber;
        };
        this.contactPersonPhoneNumberExportCSVTemplate = (row) => {
            return row.contactPersonPhoneNumber;
        };
        
        this.contactPersonPhoneNumberTwoTemplate = (id, row, col, obj) => {
            return <div>
                {row.contactPersonPhoneNumberTwo}
                {/* {
                (row.isContactPersonTwoSendNotification)
                ?
                <CustomTooltip title={'Notification'}>
                    <i className={'material-icons ms-2'} style={{ position: 'relative', top: '4px', fontSize: '20px' }}>phone_iphone</i>
                </CustomTooltip>
                :
                ''} */}
            </div>
        };
        this.contactPersonPhoneNumberTwoExportTemplate = (row) => {
            return row.contactPersonPhoneNumberTwo;
        };
        this.contactPersonPhoneNumberTwoExportCSVTemplate = (row) => {
            return row.contactPersonPhoneNumberTwo;
        };
        
        this.expiryDateTemplate = (id, row, col, obj) => {
            return <div>{row.expiryDate ? moment(row.expiryDate).format(Utils.getDefaultDateFormat()) : ''}</div>
        };
        this.expiryDateExportTemplate = (row) => {
            return row.expiryDate ? moment(row.expiryDate).format(Utils.getDefaultDateFormat()) : '';
        };
        this.expiryDateExportCSVTemplate = (row) => {
            return row.expiryDate ? moment(row.expiryDate).format(Utils.getDefaultDateFormat()) : '';
        };
    
        this.zoneTemplate = (id, row, col, obj) => {
            let zone = row.zone ? row.zone : null;
            return <div className={'d-flex align-items-center'}>
              <span 
                className="position-relative d-inline-block border border-light rounded-circle"
                style={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: (zone && zone.zoneColor) ? zone.zoneColor : '',
                }}
              >
              </span>
              <span className={'ps-2'}>{zone && zone.zoneName ? zone.zoneName : ""}</span>
            </div>
        };
        this.zoneExportTemplate = (row) => {
            let zone = row.zone ? row.zone : null;
            return zone && zone.zoneName ? zone.zoneName : "";
        };
        this.zoneExportCSVTemplate = (row) => {
            let zone = row.zone ? row.zone : null;
            return zone && zone.zoneName ? zone.zoneName : "";
        };
    
        this.defaultDriverNameTemplate = (id, row, col, obj) => {
            return Utils.getDefaultDrivers(row);
        };
        this.defaultDriverNameExportTemplate = (row) => {
            return Utils.getDefaultDrivers(row);
        };
        this.defaultDriverNameExportCSVTemplate = (row) => {
            return Utils.getDefaultDrivers(row);
        };
        
        this.coordinatesTemplate = (id, row, col, obj) => {
            return (row.latitude && row.latitude !== '') ? row.latitude + ', ' + row.longitude : '';
        };
        this.coordinatesExportTemplate = (row) => {
            return (row.latitude && row.latitude !== '') ? row.latitude + ', ' + row.longitude : '';
        };
        this.coordinatesExportCSVTemplate = (row) => {
            return (row.latitude && row.latitude !== '') ? row.latitude + ', ' + row.longitude : '';
        };
    
        this.weekdaysAvailabilityTemplate = (id, row, col, obj) => {
            if(row.weekdaysAvailability == CustomerSiteAvailability.SelectedHours){
              return 'Selected';
            } else if(row.weekdaysAvailability == CustomerSiteAvailability.NotAvailable){
              return 'Not Available';
            } else if(row.weekdaysAvailability == CustomerSiteAvailability.All){
              return 'All';
            } else {
              return '';
            }
        };
        this.weekdaysAvailabilityExportTemplate = (row) => {
            if(row.weekdaysAvailability == CustomerSiteAvailability.SelectedHours){
              return 'Selected';
            } else if(row.weekdaysAvailability == CustomerSiteAvailability.NotAvailable){
              return 'Not Available';
            } else if(row.weekdaysAvailability == CustomerSiteAvailability.All){
              return 'All';
            } else {
              return '';
            }
        };
        this.weekdaysAvailabilityExportCSVTemplate = (row) => {
            if(row.weekdaysAvailability == CustomerSiteAvailability.SelectedHours){
              return 'Selected';
            } else if(row.weekdaysAvailability == CustomerSiteAvailability.NotAvailable){
              return 'Not Available';
            } else if(row.weekdaysAvailability == CustomerSiteAvailability.All){
              return 'All';
            } else {
              return '';
            }
        };
    
        this.weekendAvailabilityTemplate = (id, row, col, obj) => {
            if(row.weekendAvailability == CustomerSiteAvailability.SelectedHours){
              return 'Selected';
            } else if(row.weekendAvailability == CustomerSiteAvailability.NotAvailable){
              return 'Not Available';
            } else if(row.weekendAvailability == CustomerSiteAvailability.All){
              return 'All';
            } else {
              return '';
            }
        };
        this.weekendAvailabilityExportTemplate = (row) => {
            if(row.weekendAvailability == CustomerSiteAvailability.SelectedHours){
              return 'Selected';
            } else if(row.weekendAvailability == CustomerSiteAvailability.NotAvailable){
              return 'Not Available';
            } else if(row.weekendAvailability == CustomerSiteAvailability.All){
              return 'All';
            } else {
              return '';
            }
        };
        this.weekendAvailabilityExportCSVTemplate = (row) => {
            if(row.weekendAvailability == CustomerSiteAvailability.SelectedHours){
              return 'Selected';
            } else if(row.weekendAvailability == CustomerSiteAvailability.NotAvailable){
              return 'Not Available';
            } else if(row.weekendAvailability == CustomerSiteAvailability.All){
              return 'All';
            } else {
              return '';
            }
        };


        let columns = [
            { 
                id: this.pageIdField, 
                filterKey: this.pageIdField, 
                name: this.pageIdField, 
                label: 'ID',
                visible: false
            },
            {
              id: "accountCustomerSiteId",
              name: "accountCustomerSiteId",
              label: "Site ID",
              responsiveLabel: "Site ID",
              width: "80px",
              orderable: true,
            },
            {
              id: "siteName",
              name: "siteName",
              label: "Site name",
              responsiveLabel: "Site name",
              width: "200px",
              orderable: true,
            },
            {
              id: "isDeactivateOrDelete",
              name: "isDeactivateOrDelete",
              label: "Status",
              responsiveLabel: "Status",
              width: "80px",
              orderable: true,
              template: this.isDeactivateOrDeleteTemplate,
              exportTemplate: this.isDeactivateOrDeleteExportTemplate,
              exportCSVTemplate: this.isDeactivateOrDeleteExportCSVTemplate,
            },
            {
              id: "contactPersonName",
              name: "contactPersonName",
              label: "Contact 1",
              responsiveLabel: "Contact 1",
              width: "120px",
              orderable: true,
            },
            {
              id: "contactPersonPhoneNumber",
              name: "contactPersonPhoneNumber",
              label: "Phone 1",
              responsiveLabel: "Phone 1",
              width: "120px",
              orderable: true,
              template: this.contactPersonPhoneNumberTemplate,
              exportTemplate: this.contactPersonPhoneNumberExportTemplate,
              exportCSVTemplate: this.contactPersonPhoneNumberExportCSVTemplate,
            },
            {
              id: "contactPersonNameTwo",
              name: "contactPersonNameTwo",
              label: "Contact 2",
              responsiveLabel: "Contact 2",
              width: "120px",
              orderable: true,
            },
            {
              id: "contactPersonPhoneNumberTwo",
              name: "contactPersonPhoneNumberTwo",
              label: "Phone 2",
              responsiveLabel: "Phone 2",
              width: "120px",
              orderable: true,
              template: this.contactPersonPhoneNumberTwoTemplate,
              exportTemplate: this.contactPersonPhoneNumberTwoExportTemplate,
              exportCSVTemplate: this.contactPersonPhoneNumberTwoExportCSVTemplate,
            },
            {
              id: "expiryDate",
              name: "expiryDate",
              label: "Expiry Date",
              responsiveLabel: "Expiry Date",
              width: "150px",
              orderable: true,
              template: this.expiryDateTemplate,
              exportTemplate: this.expiryDateExportTemplate,
              exportCSVTemplate: this.expiryDateExportCSVTemplate,
            },
            {
              id: "instructions",
              name: "instructions",
              label: "Driver remarks",
              responsiveLabel: "Driver remarks",
              width: "150px",
              orderable: true,
            },
            {
              id: "defaultDriverName",
              name: "defaultDriverName",
              label: "Default driver(s)",
              responsiveLabel: "Default driver(s)",
              width: "200px",
              orderable: true,
                template: this.defaultDriverNameTemplate,
                exportTemplate: this.defaultDriverNameExportTemplate,
                exportCSVTemplate: this.defaultDriverNameExportCSVTemplate,
            },
            {
              id: "remarks",
              name: "remarks",
              label: "Site remarks (Internal)",
              responsiveLabel: "Site remarks (Internal)",
              width: "150px",
              orderable: true,
            },
            {
              id: "zone",
              name: "zone",
              label: "Group as",
              responsiveLabel: "Group as",
              width: "120px",
              orderable: true,
              template: this.zoneTemplate,
              exportTemplate: this.zoneExportTemplate,
              exportCSVTemplate: this.zoneExportCSVTemplate,
            },
            {
              id: "geofenceGroupName",
              name: "geofenceGroupName",
              label: "Geofence group",
              responsiveLabel: "Geofence group",
              width: "150px",
              orderable: false,
            },
            {
              id: "geofenceName",
              name: "geofenceName",
              label: "Geofence",
              responsiveLabel: "Geofence",
              width: "150px",
              orderable: false,
            },
            {
              id: "weekdaysAvailability",
              name: "weekdaysAvailability",
              label: "Weekday availability",
              responsiveLabel: "Weekday availability",
              width: "150px",
              orderable: true,
              template: this.weekdaysAvailabilityTemplate,
              exportTemplate: this.weekdaysAvailabilityExportTemplate,
              exportCSVTemplate: this.weekdaysAvailabilityExportCSVTemplate,
            },
            {
              id: "weekendAvailability",
              name: "weekendAvailability",
              label: "Weekend availability",
              responsiveLabel: "Weekend availability",
              width: "150px",
              orderable: true,
              template: this.weekendAvailabilityTemplate,
              exportTemplate: this.weekendAvailabilityExportTemplate,
              exportCSVTemplate: this.weekendAvailabilityExportCSVTemplate,
            },
            {
              id: "blockNo",
              name: "blockNo",
              label: "Block",
              responsiveLabel: "Block",
              width: "100px",
              orderable: true,
            },
            {
              id: "unitNo",
              name: "unitNo",
              label: "Unit No.",
              responsiveLabel: "Unit No.",
              width: "70px",
              orderable: true,
            },
            {
              id: "postalCode",
              name: "postalCode",
              label: "Post code",
              responsiveLabel: "Post code",
              width: "70px",
              orderable: true,
            },
            {
              id: "coordinates",
              name: "coordinates",
              label: "Coordinates",
              responsiveLabel: "Coordinates",
              width: "150px",
              orderable: true,
              template: this.coordinatesTemplate,
              exportTemplate: this.coordinatesExportTemplate,
              exportCSVTemplate: this.coordinatesExportCSVTemplate,
            },
            {
              id: "street",
              name: "street",
              label: "Street name",
              responsiveLabel: "Street name",
              width: "200px",
              orderable: true,
            },
            {
              id: "autoSendDocDisplay",
              name: "IsAutoSendDoDoc",
              label: "Auto Send Doc",
              responsiveLabel: "Auto Send Doc",
              width: "200px",
              orderable: true,
            },
        ];
        let initColumns = Array.from(columns);
        columns = Array.from(initColumns);
        
        
        let columnVisibility = [
            false,
            true,
            true,
            true,
            true,
            true,
            false,
            false,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            false,
            false,
        ];
        let initColumnVisibility = Array.from(columnVisibility);
        columnVisibility = Array.from(initColumnVisibility);
    
        
        this.state = {
            id: id,
            details: null,
            mainLoading: false,
            
            popupShowCustomerForm: false,
            popupLoadingCustomerForm: false,
            popupIdCustomerForm: 0,
            popupIsCopyCustomerForm: false,

            popupShowCustomerSitesForm: this.showNewPopup,
            popupLoadingCustomerSitesForm: false,
            popupIdCustomerSitesForm: 0,
            popupIsCopyCustomerSitesForm: false,

            leftItems: [],
            leftSearch: '',
            leftSortColumn: 'updated',
            leftSortDir: 'desc',
            leftCurrentPage: 1,
            leftPageSize: 30,
            leftTotal: 0,
            leftIsIncludeInactive: null,
            leftIsInactive: false,
            leftIsSearchDialog: false,
            sortColumnsLoading: false,
            sortColumns: SortColumns.customer,

            search: '',
            sortColumn: 'updated',
            sortDir: 'asc',
            isIncludeInactive: null,
            isInactive: false,
            
            selected: [],
            columnVisibility: columnVisibility,
            initColumnVisibility: initColumnVisibility,
            columns: columns,
            initColumns: initColumns,
            rows: [],
            currentPage: 1,
            totalRows: 0,
            rowsPerPageSelected: 50,
            rowsPerPageOptions: [50, 100, 300],
            
            isDisabledBatchAction: true,
            isCookies: false,

            size: 0,
            
            popupShowBulkUpdate: false,
            popupLoadingBulkUpdate: false,
            popupIdBulkUpdate: null,

            popupShowMailTemplate: false,
            popupLoadingMailTemplate: false,
            popupIdMailTemplate: null,
            popupItemMailTemplate: null,
            
            isBulkRemoveDialog: false,
            isDateDialog: false,
        };
    }


    componentDidMount(){
        this.setLayoutForm({
            leftHeaderContent: this.leftHeader,
            leftBodyContent: this.leftBody,
            rightHeaderContent: this.rightHeader,
            rightHeaderTabs: this.rightTabs,
            // onWindowResize: () => {
            //     this.getFilterInLocalStorage();
            // },
            onChangeSize: (size) => {
                this.setState({
                    size: size
                });
            },
        });

        this.getFilterInLocalStorage(() => {
            this.callInitApis();
        });
    }

    componentWillReceiveProps(nextProps) {
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.pageName + '-read', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];
                if(data && data.data && data.data.length > 0){
                    arr = data.data.map((item, i) => {
                        return {
                            value: item.customerId,
                            title: item.customerName,
                            checked: false,
                            disabled: false,
                            active: (item.customerId === this.state.id) ? true : false,
                            isActive: item.isActive,
                            item: item,
                        }
                    });

                    arr = arr.sort((x, y) => {
                        return (x.active === y.active) ? 0 : x.active ? -1 : 1;
                    });
                }

                let total = (data && data.total) ? data.total : 0;

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr, total);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-read_more', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if (data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.customerId;
                        let title = item.customerName;
                        let arrItem = {
                            value: value,
                            title: title,
                            checked: false,
                            disabled: false,
                            active: (item.customerId === this.state.id) ? true : false,
                            isActive: item.isActive,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }

                    arr = arr.sort((x, y) => {
                        return (x.active === y.active) ? 0 : x.active ? -1 : 1;
                    });
                }

                let total = (data && data.total) ? data.total : 0;

                this.setState({
                    leftItems: arr,
                    leftTotal: total,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback();
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-read_customer_site', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = [];
                let total = 0;
                if(data && data.data && data.data.length > 0){
                    arr = data.data;
                    total = data.total;
                }

                this.setState({
                    rows: arr,
                    totalRows: total,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    this.setLoading(false);

                    if(callback){
                        callback(arr, total);
                    }
                });
            }
        );
    
        Utils.reduxProps(nextProps,
            this.pageName + '-bulk-update', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(isError){
                        Utils.toast(<div style={{ fontWeight: 'bold' }}>{(err && err != '') ? err : 'An error has occurred'}</div>, 'error');
                    } else {
                        Utils.toast(<div style={{ fontWeight: 'bold' }}>{(data && data != '') ? data : 'Changes saved'}</div>, 'success');
                    }

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );
    
        Utils.reduxProps(nextProps,
            this.pageName + '-mail-template-update', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-bulk-delete', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.pageName + '-bulk-mailing-list', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );
        
        Utils.reduxProps(nextProps,
            this.pageName + '-bulk-deactivate-by-job-date', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();
                    this.props.action.signalRslice.disconnect();

                    this.setLoading(false);

                    if(callback){
                        callback(data, isError);
                    }
                });
            }
        );
    }
	
    callInitApis = () => {
        Utils.callAllApi([
            {
                api: readApi,
                path: 'customer',
                params: () => {
                    let data = {
                        currentPage: this.state.leftCurrentPage,
                        pageSize: this.state.leftPageSize,
                        searchQuery: this.state.leftSearch,
                        sortColumn: this.state.leftSortColumn,
                        sortDir: this.state.leftSortDir,
                    };
            
                    let leftIsIncludeInactive = ((this.state.leftIsIncludeInactive === true) || (this.state.leftIsIncludeInactive === false)) ? this.state.leftIsIncludeInactive : null;
                    if(leftIsIncludeInactive){
                        data['isIncludeInactive'] = leftIsIncludeInactive;
                    }

                    let leftIsInactive = ((this.state.leftIsInactive === true) || (this.state.leftIsInactive === false)) ? this.state.leftIsInactive : null;
                    if(leftIsInactive){
                        data['isInactive'] = leftIsInactive;
                    }

                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let arr = [];
                        if(data && data.data && data.data.length > 0){
                            arr = data.data.map((item, i) => {
                                return {
                                    value: item.customerId,
                                    title: item.customerName,
                                    checked: false,
                                    disabled: false,
                                    active: (item.customerId === this.state.id) ? true : false,
                                    isActive: item.isActive,
                                    item: item,
                                }
                            });

                            arr = arr.sort((x, y) => {
                                return (x.active === y.active) ? 0 : x.active ? -1 : 1;
                            });
                        }

                        let total = (data && data.total) ? data.total : 0;

                        this.setState({
                            leftItems: arr,
                            leftTotal: total,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            },
            {
                api: readApi,
                path: 'customer/' + this.state.id + '?includeEmailTemplate=true',
                params: null,
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        this.setState({
                            details: data,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            },
            {
                api: readApi,
                path: 'customersite',
                params: () => {
                    let data = {
                        currentPage: this.state.currentPage,
                        pageSize: this.state.rowsPerPageSelected,
                        searchQuery: this.state.search,
                        sortColumn: this.state.sortColumn,
                        sortDir: this.state.sortDir,
                        customerId: this.state.id,
                    };
            
                    let isIncludeInactive = ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : null;
                    if(isIncludeInactive){
                        data['isIncludeInactive'] = isIncludeInactive;
                    }

                    let isInactive = ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : null;
                    if(isInactive){
                        data['isInactive'] = isInactive;
                    }

                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let arr = [];
                        let total = 0;
                        if(data && data.data && data.data.length > 0){
                            arr = data.data;
                            total = data.total;
                        }

                        this.setState({
                            rows: arr,
                            totalRows: total,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            }
        ], (result) => {
            this.setState({
                mainLoading: true,
            });

            this.setLoading(true);
        }, (results) => {
            this.setLoading(false);
            this.refreshLayout();
            
            this.setState({
                mainLoading: false,
            });
        });
    }

    callDetailsApis = (additionalParams = null) => {
        Utils.callAllApi([
            {
                api: readApi,
                path: 'customer/' + this.state.id + '?includeEmailTemplate=true',
                params: null,
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        this.setState({
                            details: data,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            },
            {
                api: readApi,
                path: 'customersite',
                params: () => {
                    let data = {
                        currentPage: this.state.currentPage,
                        pageSize: this.state.rowsPerPageSelected,
                        searchQuery: this.state.search,
                        sortColumn: this.state.sortColumn,
                        sortDir: this.state.sortDir,
                        customerId: this.state.id,
                    };
            
                    let isIncludeInactive = ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : null;
                    if(isIncludeInactive){
                        data['isIncludeInactive'] = isIncludeInactive;
                    }

                    let isInactive = ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : null;
                    if(isInactive){
                        data['isInactive'] = isInactive;
                    }

                    return data;
                },
                callback: (result) => {
                    Utils.parseResult(result, (data) => {
                        let arr = [];
                        let total = 0;
                        if(data && data.data && data.data.length > 0){
                            arr = data.data;
                            total = data.total;
                        }

                        this.setState({
                            rows: arr,
                            totalRows: total,
                        }, () => {
                            this.refreshLayout();
                        });
                    });
                }
            }
        ], (result) => {
            this.setState({
                mainLoading: true,
            });

            this.setLoading(true);
        }, (result) => {
            if(additionalParams){
                this.setState(additionalParams);
            }

            this.setLoading(false);
            this.refreshLayout();

            this.setState({
                mainLoading: false,
            });
        });
    }

    callReadApi = (callback = null) => {
        this.setLoading(true);
        
        let data = {
            currentPage: this.state.leftCurrentPage,
            pageSize: this.state.leftPageSize,
            searchQuery: this.state.leftSearch,
            sortColumn: this.state.leftSortColumn,
            sortDir: this.state.leftSortDir,
        };
   
        let leftIsIncludeInactive = ((this.state.leftIsIncludeInactive === true) || (this.state.leftIsIncludeInactive === false)) ? this.state.leftIsIncludeInactive : null;
        if(leftIsIncludeInactive){
            data['isIncludeInactive'] = leftIsIncludeInactive;
        }

        let leftIsInactive = ((this.state.leftIsInactive === true) || (this.state.leftIsInactive === false)) ? this.state.leftIsInactive : null;
        if(leftIsInactive){
            data['isInactive'] = leftIsInactive;
        }

        this.props.dispatchApiCallGet(data, this.pageName + '-read', 'customer', null, callback, () => {});
    }
    
    callReadMoreApi = (callback = null) => {
        this.setLoading(true);
        
        let data = {
            currentPage: this.state.leftCurrentPage,
            pageSize: this.state.leftPageSize,
            searchQuery: this.state.leftSearch,
            sortColumn: this.state.leftSortColumn,
            sortDir: this.state.leftSortDir,
        };
   
        let leftIsIncludeInactive = ((this.state.leftIsIncludeInactive === true) || (this.state.leftIsIncludeInactive === false)) ? this.state.leftIsIncludeInactive : null;
        if(leftIsIncludeInactive){
            data['isIncludeInactive'] = leftIsIncludeInactive;
        }

        let leftIsInactive = ((this.state.leftIsInactive === true) || (this.state.leftIsInactive === false)) ? this.state.leftIsInactive : null;
        if(leftIsInactive){
            data['isInactive'] = leftIsInactive;
        }

        this.props.dispatchApiCallGet(data, this.pageName + '-read_more', 'customer', { items: this.state.leftItems }, callback, () => {});
    }
    
    callReadCustomerSIteApi = (callback = null) => {
        this.setLoading(true);
        
        let data = {
            currentPage: this.state.currentPage,
            pageSize: this.state.rowsPerPageSelected,
            searchQuery: this.state.search,
            sortColumn: this.state.sortColumn,
            sortDir: this.state.sortDir,
            customerId: this.state.id,
        };
   
        let isIncludeInactive = ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : null;
        if(isIncludeInactive){
            data['isIncludeInactive'] = isIncludeInactive;
        }

        let isInactive = ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : null;
        if(isInactive){
            data['isInactive'] = isInactive;
        }

        this.props.dispatchApiCallGet(data, this.pageName + '-read_customer_site', 'customersite', null, callback, () => {});
    }

    callBuldUpdateApi = (data, callback = null) => {
        this.setLoading(true);
        this.props.dispatchApiCallPut(data, this.pageName + '-bulk-update', 'customerSite/bulk', data, callback, () => {});
    }

    callMailTemplateUpdateApi = (data, callback = null) => {
        this.setLoading(true);
        this.props.dispatchApiCallPut(data, this.pageName + '-mail-template-update', 'customerSite', data, callback, () => {});
    }

    callBulkDeleteApi = (callback = null) => {
        this.setLoading(true);
        
        let selected = Utils.getSelected(this.tableRef);

        let data = { 
            ids: selected,
            forceDelete: true
        };

        this.props.dispatchApiCallDelete(data, this.pageName + '-bulk-delete', 'customersite/bulk-delete', data, callback, null);
    }

    callMailTemplateApi = (data, callback = null) => {
        this.setLoading(true);
        
        let selected = Utils.getSelected(this.tableRef);

        this.props.dispatchApiCallPut(data, this.pageName + '-bulk-mailing-list', 'customersite/bulk-mailing-list', data, callback, null);
    }
    
    callBulkDeactivateByJobDateApi = async (value, callback = null) => {
        this.setLoading(true);
        
        await this.props.action.signalRslice.connect({
            callback: (percent) => {
                this.props.action.signalRslice.onPercentage(percent);
            },
            callbackInit: async (hubId) => {
                let data = {
                    customerId: this.state.id,
                    jobDate: value,
                    hubId: hubId,
                };

                this.props.dispatchApiCallPut(data, this.pageName + '-bulk-deactivate-by-job-date', 'customersite/bulk-deactivate-by-job-date', data, callback, null);
            }
        });
    }
    /* END API */


    /* LAYOUT FUNCTIONS */
    setLayoutForm = (options) => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setLayoutForm(this, options);
        }
    }
    setLayoutLoading = (state = false) => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.setLayoutLoading(state);
        }
    }
    refreshLayout = () => {
        if(this.props.layoutRef && this.props.layoutRef.current){
            this.props.layoutRef.current.refreshLayout();
        }
    }
    getLayoutObj = (options) => {
        if(this.props.layoutRef && this.props.layoutRef.current && this.props.layoutRef.current.obj && this.props.layoutRef.current.obj.current){
            return this.props.layoutRef.current.obj.current;
        }
    }
    /* END LAYOUT FUNCTIONS */


    /* FUNCTIONS */
    setLoading = (state = false) => {
        this.setLayoutLoading(state);

        if(this.props.onLoading){
            this.props.onLoading(state);
        }
    }

    setFilterInLocalStorage = (isCookies = true) => {
        if(this.props.settingsType >= 0){
            let { token, userCookieSettings } = this.props.auth;
        
            this.setState({
                isCookies: isCookies,
            });
            
            let data = {
                name: this.pageName,
                type: this.props.settingsType,
            };
        
            let valueLocalStorage = {
                [PageCookieSettingType.currentPage]: this.state.currentPage,
            };
            window.localStorage.setItem(this.pageName + '_' + this.props.settingsType, JSON.stringify(valueLocalStorage));
        
            let value = {
                [PageCookieSettingType.isCookies]: isCookies,
                // [PageCookieSettingType.search]: this.state.search,
                [PageCookieSettingType.sortColumn]: this.state.sortColumn,
                [PageCookieSettingType.sortDir]: this.state.sortDir,
                [PageCookieSettingType.rowsPerPageSelected]: this.state.rowsPerPageSelected,
                [PageCookieSettingType.columnVisibility]: this.state.columnVisibility,
                [PageCookieSettingType.columns]: this.state.columns,
                [PageCookieSettingType.isIncludeInactive]: this.state.isIncludeInactive,
                [PageCookieSettingType.isInactive]: this.state.isInactive,
                [PageCookieSettingType.leftIsIncludeInactive]: this.state.leftIsIncludeInactive,
                [PageCookieSettingType.leftIsInactive]: this.state.leftIsInactive,
            };
            data['value'] = JSON.stringify(value);
        
            Utils.saveUserCookieSettings(data, token, (settings) => {
                if(settings){
                    let settingsArr = (userCookieSettings && userCookieSettings.length > 0) ? userCookieSettings : [];
                    let settingsIndex = Utils.getUserCookieSettingsIndex(settingsArr, this.props.settingsType);
                    if(settingsIndex !== -1 && settingsArr[settingsIndex]){
                        settingsArr[settingsIndex] = settings;
                    } else {
                        settingsArr.push(settings);
                    }
            
                    this.props.saveUserCookieSettings(settingsArr)
                }
            });
        }
    };
    getFilterInLocalStorage = (callback = null) => {
        let settingsItem = null;
    
        if(this.props.settingsType >= 0){
            const { userCookieSettings } = this.props.auth;
            settingsItem = Utils.getUserCookieSettings(userCookieSettings, this.props.settingsType, this.pageName);
        }
    
        let valueLocalStorageStr = window.localStorage.getItem(this.pageName + '_' + this.props.settingsType);
        let valueLocalStorage = JSON.parse(valueLocalStorageStr);
        let currentPageValue = valueLocalStorage && valueLocalStorage[PageCookieSettingType.currentPage] && valueLocalStorage[PageCookieSettingType.currentPage] !== "" ? valueLocalStorage[PageCookieSettingType.currentPage] : this.state.currentPage;
        
        let isCookiesValue = settingsItem && settingsItem[PageCookieSettingType.isCookies] && settingsItem[PageCookieSettingType.isCookies] !== "" ? settingsItem[PageCookieSettingType.isCookies] : this.state.isCookies;
        let searchValue = (settingsItem && settingsItem[PageCookieSettingType.search] && settingsItem[PageCookieSettingType.search] !== '') ? settingsItem[PageCookieSettingType.search] : this.state.search;
        let sortColumnValue = settingsItem && settingsItem[PageCookieSettingType.sortColumn] && settingsItem[PageCookieSettingType.sortColumn] !== "" ? settingsItem[PageCookieSettingType.sortColumn] : this.state.sortColumn;
        let sortDirValue = settingsItem && settingsItem[PageCookieSettingType.sortDir] && settingsItem[PageCookieSettingType.sortDir] !== "" ? settingsItem[PageCookieSettingType.sortDir] : this.state.sortDir;
        let rowsPerPageSelectedValue = settingsItem && settingsItem[PageCookieSettingType.rowsPerPageSelected] && settingsItem[PageCookieSettingType.rowsPerPageSelected] !== "" ? settingsItem[PageCookieSettingType.rowsPerPageSelected] : this.state.rowsPerPageSelected;
        let columnVisibilityValue = (settingsItem && settingsItem[PageCookieSettingType.columnVisibility] && settingsItem[PageCookieSettingType.columnVisibility] !== '') ? settingsItem[PageCookieSettingType.columnVisibility] : this.state.columnVisibility;
        let columnsValue = (settingsItem && settingsItem[PageCookieSettingType.columns] && settingsItem[PageCookieSettingType.columns] !== '') ? settingsItem[PageCookieSettingType.columns] : this.state.columns;
        let isIncludeInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.isIncludeInactive] && settingsItem[PageCookieSettingType.isIncludeInactive] !== '') ? settingsItem[PageCookieSettingType.isIncludeInactive] : this.state.isIncludeInactive;
        let isInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.isInactive] && settingsItem[PageCookieSettingType.isInactive] !== '') ? settingsItem[PageCookieSettingType.isInactive] : this.state.isInactive;
        let leftIsIncludeInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.leftIsIncludeInactive] && settingsItem[PageCookieSettingType.leftIsIncludeInactive] !== '') ? settingsItem[PageCookieSettingType.leftIsIncludeInactive] : this.state.leftIsIncludeInactive;
        let leftIsInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.leftIsInactive] && settingsItem[PageCookieSettingType.leftIsInactive] !== '') ? settingsItem[PageCookieSettingType.leftIsInactive] : this.state.leftIsInactive;
        
        let columns = Utils.setTemplateFunctionsToColumns(this.state.columns, columnsValue);
        
        this.setState({
            isCookies: isCookiesValue,
            search: searchValue,
            sortColumn: sortColumnValue,
            sortDir: sortDirValue,
            currentPage: currentPageValue,
            rowsPerPageSelected: rowsPerPageSelectedValue,
            columnVisibility: columnVisibilityValue,
            isIncludeInactive: isIncludeInactiveValue,
            isInactive: isInactiveValue,
            leftIsIncludeInactive: leftIsIncludeInactiveValue,
            leftIsInactive: leftIsInactiveValue,
            columns: columns,
        }, () => {
            if (callback) {
                callback();
            }
        });
    };
    /* END FUNCTIONS */


    /* FORM */
    leftHeader = () => {
        return <Row className={'align-items-center gx-1'}>
            <Col xs={true}>
                <Dropdown alignRight>
                    <Dropdown.Toggle as={DropdownToggle} className={'px-0'}>
                        <StyledDropdownToggleText>{Utils.getAllActiveInactiveText(this.state.leftIsIncludeInactive, this.state.leftIsInactive, 1)} Customers</StyledDropdownToggleText>
                        <ArrowDropDownIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            active={(this.state.leftIsIncludeInactive && this.state.leftIsInactive == null)}
                            onClick={() => {
                                this.setState({
                                    leftCurrentPage: 1,
                                    leftSearch: '',
                                    leftIsIncludeInactive: true,
                                    leftIsInactive: null,
                                }, () => {
                                    this.setFilterInLocalStorage();
                                    this.callReadApi((items, total) => {
                                        this.setState({
                                            leftItems: items,
                                            leftTotal: total,
                                        }, () => {
                                            this.setLoading(false);
                                            this.refreshLayout();
                                        });
                                    });
                                });
                            }}
                        >All</Dropdown.Item>
                        <Dropdown.Item
                            active={(this.state.leftIsIncludeInactive == null && !this.state.leftIsInactive)}
                            onClick={() => {
                                this.setState({
                                    leftCurrentPage: 1,
                                    leftSearch: '',
                                    leftIsIncludeInactive: null,
                                    leftIsInactive: false,
                                }, () => {
                                    this.setFilterInLocalStorage();
                                    this.callReadApi((items, total) => {
                                        this.setState({
                                            leftItems: items,
                                            leftTotal: total,
                                        }, () => {
                                            this.setLoading(false);
                                            this.refreshLayout();
                                        });
                                    });
                                });
                            }} 
                        >Active</Dropdown.Item>
                        <Dropdown.Item
                            active={(this.state.leftIsIncludeInactive == null && this.state.leftIsInactive)}
                            onClick={() => {
                                this.setState({
                                    leftCurrentPage: 1,
                                    leftSearch: '',
                                    leftIsIncludeInactive: null,
                                    leftIsInactive: true,
                                }, () => {
                                    this.setFilterInLocalStorage();
                                    this.callReadApi((items, total) => {
                                        this.setState({
                                            leftItems: items,
                                            leftTotal: total,
                                        }, () => {
                                            this.setLoading(false);
                                            this.refreshLayout();
                                        });
                                    });
                                });
                            }} 
                        >Inactive</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col xs={'auto'}>
                <WrappedButton
                    className={'px-3 pe-1'}
                    variant={"primary"}
                    size={'sm'}
                    onClick={() => {
                        this.setState({
                          popupShowCustomerForm: true,
                          popupLoadingCustomerForm: false,
                          popupIdCustomerForm: 0,
                          popupIsCopyCustomerForm: false,
                        });
                    }}
                >
                    <i className={'material-icons'}>add</i>
                </WrappedButton>
            </Col>
            <Col xs={'auto'}>
                <Dropdown alignRight>
                    <Dropdown.Toggle
                        as={DropdownToggle}
                        isButton
                        className={'px-3 pe-1'}
                        variant={"light"}
                        size={'sm'}
                        style={{ backgroundColor: 'transparent' }}
                    >
                        <i className={'material-icons'}>format_list_bulleted</i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Header>SORT BY</Dropdown.Header>

                        {this.state.sortColumns.map((item, i) => {
                            return <StyledSortItem
                                key={i}
                                active={(this.state.leftSortColumn == item.value) ? true : false}
                                direction={this.state.leftSortDir}
                                disabled={false}
                                onClick={() => {
                                    this.setState({
                                        sortColumnsLoading: true,
                                        leftCurrentPage: 1,
                                        leftSortColumn: item.value,
                                        leftSortDir: 'asc',
                                    }, () => {
                                        this.callReadApi((items, total) => {
                                            this.setState({
                                                sortColumnsLoading: false,
                                                leftItems: items,
                                                leftTotal: total,
                                            }, () => {
                                                this.setLoading(false);
                                                this.refreshLayout();
                                            });
                                        });
                                    });
                                }}
                            >
                                <div className={'custom-title'}>{item.title}</div>
                                <div className={'custom-icons'}>
                                    <div className={'custom-icon-up'}>
                                        <div>
                                            <i className={'material-icons ' + ((this.state.leftSortDir == 'asc') ? true : false)}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();

                                                    this.setState({
                                                        sortColumnsLoading: true,
                                                        leftCurrentPage: 1,
                                                        leftSortColumn: item.value,
                                                        leftSortDir: 'asc',
                                                    }, () => {
                                                        Utils.clickOutside();
                                                        this.callReadApi((items, total) => {
                                                            this.setState({
                                                                sortColumnsLoading: false,
                                                                leftItems: items,
                                                                leftTotal: total,
                                                            }, () => {
                                                                this.setLoading(false);
                                                                this.refreshLayout();
                                                            });
                                                        });
                                                    });
                                                }}
                                            >arrow_drop_up</i>
                                        </div>
                                    </div>
                                    <div className={'custom-icon-down'}>
                                        <div>
                                            <i className={'material-icons ' + ((this.state.leftSortDir == 'desc') ? true : false)}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();

                                                    this.setState({
                                                        sortColumnsLoading: true,
                                                        leftCurrentPage: 1,
                                                        leftSortColumn: item.value,
                                                        leftSortDir: 'desc',
                                                    }, () => {
                                                        Utils.clickOutside();
                                                        this.callReadApi((items, total) => {
                                                            this.setState({
                                                                sortColumnsLoading: false,
                                                                leftItems: items,
                                                                leftTotal: total,
                                                            }, () => {
                                                                this.setLoading(false);
                                                                this.refreshLayout();
                                                            });
                                                        });
                                                    });
                                                }}
                                            >arrow_drop_down</i>
                                        </div>
                                    </div>
                                </div>
                            </StyledSortItem>
                        })}
                        
                        <Dropdown.Divider />

                        <Dropdown.Item
                            active={false}
                            disabled={false}
                            onClick={() => {
                                this.setState({
                                    sortColumnsLoading: true,
                                    leftCurrentPage: 1,
                                    leftSearch: '',
                                    leftSortColumn: 'updated',
                                    leftSortDir: 'desc',
                                }, () => {
                                    this.callReadApi((items, total) => {
                                        this.setState({
                                            sortColumnsLoading: false,
                                            leftItems: items,
                                            leftTotal: total,
                                        }, () => {
                                            this.setLoading(false);
                                            this.refreshLayout();
                                        });
                                    });
                                });
                            }} 
                        >Refresh List</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col xs={'auto'}>
                <WrappedButton
                    className={'px-3 pe-1'}
                    variant={"light"}
                    size={'sm'}
                    style={{ backgroundColor: 'transparent' }}
                    onClick={() => {
                        this.setState({
                            leftIsSearchDialog: true,
                        });
                    }}
                >
                    <i className={'material-icons'}>search</i>
                </WrappedButton>
            </Col>
            {this.leftClearSearch()}
        </Row>
    }

    leftBody = () => {
        return <div style={{ width: '100%' }}>
            {
            (this.state.leftItems && this.state.leftItems.length > 0)
            ?
            <>
                <InfiniteScroll
                    loader={<div key={0} className={'text-center pt-2'}><Spinner animation="border" size={'sm'} /></div>}
                    useWindow={false}
                    initialLoad={false}
                    getScrollParent={() => document.getElementById((window.innerWidth < 992) ? 'left_panel_1_ID' : 'left_panel_2_ID')}
                    threshold={1}

                    pageStart={1}
                    hasMore={((this.state.leftTotal > this.state.leftPageSize) && (this.state.leftItems.length < this.state.leftTotal))}

                    loadMore={(page) => {
                        if((this.state.leftTotal > this.state.leftPageSize) && (this.state.leftItems.length < this.state.leftTotal)){
                            this.setState({
                                leftCurrentPage: (this.state.leftCurrentPage+1)
                            }, () => {
                                this.callReadMoreApi(() => {
                                    this.setLoading(false);
                                    this.refreshLayout();
                                });
                            });
                        }
                    }}
                >
                    {this.state.leftItems.map((item, i) => {
                        return <div key={i}
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                                if(!item.active){
                                    this.setState({
                                        id: item.value,
                                        details: null,
                                    }, () => {
                                        this.props.history.push('/Customer-overview-sites?id=' + this.state.id);

                                        this.state.leftItems.forEach(x => x.active = false);
                                        item.active = true;
                                        this.refreshLayout();

                                        this.callDetailsApis();
                                    });
                                }
                            }}
                        >
                            <StyledFormCheck
                                type={'checkbox'}
                                active={item.active}
                                isActive={item.isActive}
                            >
                                <Row className={'align-items-center g-0'}>
                                    {/* <Col xs={'auto'}>
                                        <Form.Check.Input
                                            type={'checkbox'}
                                            checked={item.checked}
                                            disabled={item.disabled}
                                            onChange={(e) => {
                                                item.checked = e.target.checked;
                                                this.refreshLayout();
                                            }}
                                        />
                                    </Col> */}
                                    <Col xs={true}>
                                        <Row className={'align-items-center g-0'}>
                                            <Col xs={12}>
                                                <Form.Check.Label
                                                    // onClick={(e) => {
                                                    //     if(!item.active){
                                                    //         this.setState({
                                                    //             id: item.value,
                                                    //             details: null,
                                                    //         }, () => {
                                                    //             this.props.history.push('/Customer-overview-sites?id=' + this.state.id);
                            
                                                    //             this.state.leftItems.forEach(x => x.active = false);
                                                    //             item.active = true;
                                                    //             this.refreshLayout();
                            
                                                    //             this.callDetailsApis();
                                                    //         });
                                                    //     }
                                                    // }}
                                                >{item.title}</Form.Check.Label>
                                            </Col>
                                            {!this.state.sortColumnsLoading && <Col xs={12}>
                                                <Form.Check.Label
                                                    className={'text-secondary'}
                                                    onClick={(e) => {
                                                        if(!item.active){
                                                            this.setState({
                                                                id: item.value,
                                                                details: null,
                                                            }, () => {
                                                                this.props.history.push('/Customer-overview-sites?id=' + this.state.id);
                            
                                                                this.state.leftItems.forEach(x => x.active = false);
                                                                item.active = true;
                                                                this.refreshLayout();
                            
                                                                this.callDetailsApis();
                                                            });
                                                        }
                                                    }}
                                                >{Utils.findSortItemTemplate(this.state.sortColumns, this.state.leftSortColumn, ((item && item.item) ? item.item : null))}</Form.Check.Label>
                                            </Col>}
                                        </Row>
                                    </Col>
                                </Row>
                            </StyledFormCheck>
                        </div>
                    })}
                </InfiniteScroll>

                
                
                {/* {((this.state.leftTotal > this.state.leftPageSize) && (this.state.leftItems.length < this.state.leftTotal)) && <div className={'pt-3 pb-3'}>
                    <a href={'/'}
                        className={'link-primary'}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            
                            this.setState({
                                leftCurrentPage: (this.state.leftCurrentPage + 1)
                            }, () => {
                                this.callReadMoreApi(() => {
                                    this.setLoading(false);
                                    this.refreshLayout();
                                });
                            });
                        }}
                    >
                        Show More
                    </a>
                </div>} */}

            </>
            :
            <h6 className={'text-center pt-5 m-0'}>No Results!</h6>
            }
        </div>
    }

    leftClearSearch = () => {
        if(this.state.leftSearch !== ''){
            return <Col xs={12} className={'text-end'}>
                <a href={'/'}
                    className={'link-primary'}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        this.setState({
                            leftCurrentPage: 1,
                            leftSearch: '',
                            leftIsSearchDialog: false,
                        }, () => {
                            this.callReadApi((items, total) => {
                                this.setState({
                                    leftItems: items,
                                    leftTotal: total,
                                }, () => {
                                    this.setLoading(false);
                                    this.refreshLayout();
                                });
                            });
                        });
                    }}
                >
                    Clear Search
                </a>
            </Col>
        }
    }

    rightHeader = () => {
        if((this.state.id > 0) && this.state.details){
            return <Row className={'align-items-center justify-content-center justify-content-md-start py-5'}>
                <Col xs={'auto'}>
                    <h2 className={'m-0'}>{(this.state.details && this.state.details.customerName && this.state.details.customerName != '') ? this.state.details.customerName : <>&nbsp;</>}</h2>
                </Col>
                <Col xs={'auto'}>
                    {this.state.details && <StyledBadge 
                        pill 
                        className={'px-5 py-2'} 
                        bg={this.state.details.isActive ? 'default' : 'danger'}
                        text={this.state.details.isActive ? 'dark' : 'light'}
                    >
                        {this.state.details.isActive ? 'Active' : 'Inactive'}
                    </StyledBadge>}
                </Col>
            </Row>
        }
    }

    rightTabs = () => {
        if((this.state.id > 0) && this.state.details){
            return <Row className={'gx-md-0 gx-lg-5 align-items-center justify-content-center justify-content-md-start'}>
                {(Utils.hasPermission(this.permissions, PagesPermissions.CustomersOverview.read)) && <Col xs={'auto'}>
                    <StyledTabButton
                        active={false}
                        onClick={() => {
                            this.props.history.push('/Customer-overview?id=' + this.state.id);
                        }}
                    >Overview</StyledTabButton>
                </Col>}
                {(Utils.hasPermission(this.permissions, PagesPermissions.CustomerServices.read)) && <Col xs={'auto'}>
                    <StyledTabButton
                        active={false}
                        onClick={() => {
                            this.props.history.push('/Customer-overview-local-services?id=' + this.state.id);
                        }}
                    >Local Service</StyledTabButton>
                </Col>}
                {(Utils.hasPermission(this.permissions, PagesPermissions.CustomerSites.read)) && <Col xs={'auto'}>
                    <StyledTabButton
                        active={true}
                        onClick={() => {
                            this.props.history.push('/Customer-overview-sites?id=' + this.state.id);
                        }}
                    >Sites</StyledTabButton>
                </Col>}
                {(Utils.hasPermission(this.permissions, PagesPermissions.CustomersEmail.read)) && <Col xs={'auto'}>
                    <StyledTabButton
                        active={false}
                        onClick={() => {
                            this.props.history.push('/Customer-overview-email?id=' + this.state.id);
                        }}
                    >Email Logs</StyledTabButton>
                </Col>}
            </Row>
        }
    }

    rightBodyNoResult = () => {
        if(this.state.id > 0){
            if(this.state.details){
                return <Row className={'gx-0'}>
                    <Col xs={12}>
                        {this.topSection(false)}
                    </Col>
                    <Col xs={12}>
                        <StyledNoResult>
                            <p className={'mb-5'}>
                                {
                                    this.state.search != ''
                                    ?
                                    "Sorry, there are no results for your search. To create, click \"+New\" button."
                                    :
                                    "You don't have sites for this customer. To create, click \"+New\" button."
                                }
                            </p>
                            <Button
                                variant={"primary"}
                                size={'sm'}
                                onClick={() => {
                                    this.setState({
                                        popupShowCustomerSitesForm: true,
                                        popupLoadingCustomerSitesForm: false,
                                        popupIdCustomerSitesForm: 0,
                                        popupIsCopyCustomerSitesForm: false,
                                    });
                                }}
                            >
                                + New
                            </Button>
                        </StyledNoResult>
                    </Col>
                </Row>
            } else {
                return <h5 className={'text-center mt-10 pt-10'}>The customer was not found</h5>
            }
        } else {
            return <h5 className={'text-center mt-10 pt-10'}>The customer doesn't exist</h5>
        }
    }

    rightBody = () => {
        return <Row className={'gx-0'}>
            <Col xs={12}>
                {this.topSection()}
            </Col>
            <Col xs={12}>
                {this.tableSection()}
            </Col>
            <Col xs={12}>
                {this.paginationSection()}
            </Col>
        </Row>
    }

    topSection = (state = true) => {
		return <ViewTopSection
            hasChecked={(!this.state.isDisabledBatchAction)}
            unchecked={this.topSectionUnchecked(state)}
            checked={this.topSectionChecked(state)}
        />
	}
	topSectionUnchecked = (state = true) => {
        return <Row className={"align-items-center"}>
            <Col xs={'auto'}>
                <Dropdown alignRight>
                    <Dropdown.Toggle as={DropdownToggle}>
                        <StyledDropdownToggleText>{Utils.getAllActiveInactiveText(this.state.isIncludeInactive, this.state.isInactive, 2)} Sites</StyledDropdownToggleText>
                        <ArrowDropDownIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            active={(this.state.isIncludeInactive && this.state.isInactive == null)}
                            onClick={() => {
                                this.setState({
                                    isIncludeInactive: true,
                                    isInactive: null,
                                    currentPage: 1,
                                }, () => {
                                    this.setFilterInLocalStorage();
                                    this.callReadCustomerSIteApi((rows, total) => {
                                        this.refreshLayout();
                                    });
                                });
                            }} 
                        >All</Dropdown.Item>
                        <Dropdown.Item
                            active={(this.state.isIncludeInactive == null && !this.state.isInactive)}
                            onClick={() => {
                                this.setState({
                                    isIncludeInactive: null,
                                    isInactive: false,
                                    currentPage: 1,
                                }, () => {
                                    this.setFilterInLocalStorage();
                                    this.callReadCustomerSIteApi((rows, total) => {
                                        this.refreshLayout();
                                    });
                                });
                            }} 
                        >Active</Dropdown.Item>
                        <Dropdown.Item
                            active={(this.state.isIncludeInactive == null && this.state.isInactive)}
                            onClick={() => {
                                this.setState({
                                    isIncludeInactive: null,
                                    isInactive: true,
                                    currentPage: 1,
                                }, () => {
                                    this.setFilterInLocalStorage();
                                    this.callReadCustomerSIteApi((rows, total) => {
                                        this.refreshLayout();
                                    });
                                });
                            }} 
                        >Inactive</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col xs={12} md={4} lg={3} className={'pb-2 pb-md-0'}>
                <IconButtonSearch 
                    value={this.state.search}
                    isOpen={(this.state.search !== '') ? true : false}
                    onPressEnter={(value) => {
                        this.setState({
                            search: value,
                            currentPage: 1,
                        }, () => {
                            this.setFilterInLocalStorage();
                            this.callReadCustomerSIteApi((rows, total) => {
                                this.refreshLayout();
                            });
                        });
                    }}
                    onClear={() => {
                        this.setState({
                            search: '',
                            currentPage: 1,
                        }, () => {
                            this.setFilterInLocalStorage();
                            this.callReadCustomerSIteApi((rows, total) => {
                                this.refreshLayout();
                            });
                        });
                    }}
                />
            </Col>

            
                {/* <Col xs={'auto'}>
                    <CustomTooltip title={'Title'}>
                        <InfoOutlinedIcon style={{ color: '#DFDFDF' }} />
                    </CustomTooltip>
                </Col> */}
                <Col xs={true}></Col>
                {state && <Col xs={'auto'} className={'text-center'}>
                    <Button
                        size={"sm"}
                        onClick={() => {
                            this.setState({
                                popupShowCustomerSitesForm: true,
                                popupLoadingCustomerSitesForm: false,
                                popupIdCustomerSitesForm: 0,
                                popupIsCopyCustomerSitesForm: false,
                            });
                        }}
                    >+ New</Button>
                </Col>}

                <Col xs={'auto'} className={'text-center'}>
                    <Dropdown>
                        <Dropdown.Toggle as={DropdownToggle} isButton className={'px-3'} variant={"light"} size={"sm"}>
                            <SettingsOutlinedIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                active={false}
                                onClick={() => {
                                    this.setState({
                                        isDateDialog: true,
                                    });
                                }} 
                            >Deactivate by last job date</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>

                <Col xs={'auto'} className={'text-center'}>
                    <WrappedButton
                        className={'px-3'}
                        variant={"light"}
                        size={'sm'}
                        style={{ border: '1px solid #ECECEC' }}
                        onClick={() => {
                            this.setState({
                                popupShowCustomerForm: true,
                                popupLoadingCustomerForm: false,
                                popupIdCustomerForm: this.state.id,
                                popupIsCopyCustomerForm: false,
                            });
                        }}
                    >
                        <Icon icon="feather:edit-2" width="17" />
                    </WrappedButton>
                </Col>
                <Col xs={'auto'} className={'text-center'}>
                    <DropdownMenu
                        className={'px-3'}
                        variant={"light"}
                        size={"sm"}
                        text={<ViewHeadlineIcon />}
                    >
                        <DataTableActionMenu 
                            pageName={this.pageName}
                            columns={this.state.columns}
                            selected={this.state.rowsPerPageSelected}
                            selectItems={this.state.rowsPerPageOptions}
                            isCheckedIncludeInactive={this.state.isIncludeInactive}
                            columnVisibility={this.state.columnVisibility}
                            canSelectOption={false}
                            onChangeColumnVisibility={(index, value) => {
                                let colVis = this.state.columnVisibility;
                                colVis[index] = value;

                                this.setState({
                                    columnVisibility: colVis
                                }, () => {
                                    this.setFilterInLocalStorage();
                                });
                            }}
                            canInactive={false}
                            onSelectChangeAjax={(val) => {
                                this.setState({
                                    currentPage: 1,
                                    rowsPerPageSelected: val
                                }, () => {
                                    this.setFilterInLocalStorage();
                                    this.callReadCustomerSIteApi((rows, total) => {
                                        this.refreshLayout();
                                    });
                                });
                            }}
                            onChangeIncludeInactive={(val) => {
                                this.setState({
                                    isIncludeInactive: val,
                                    currentPage: 1,
                                }, () => {
                                    this.setFilterInLocalStorage();
                                    this.callReadCustomerSIteApi((rows, total) => {
                                        this.refreshLayout();
                                    });
                                });
                            }}
                            isCookies={this.state.isCookies}
                            onResetColumns={() => {
                                this.setState({
                                    columns: Array.from(this.state.initColumns),
                                    columnVisibility: Array.from(this.state.initColumnVisibility),
                                    sortColumn: this.state.sortColumnInit,
                                    sortDir: this.state.sortDirInit,
                                }, () => {
                                    this.setFilterInLocalStorage(false);
                                    this.callReadCustomerSIteApi((rows, total) => {
                                        this.refreshLayout();
                                    });
                                    if(this.refDropdownActionMenu && this.refDropdownActionMenu.current){
                                        this.refDropdownActionMenu.current.hide();
                                    }
                                });
                            }}
                            onShowMore={(isShowedMore) => {
                                this.setState({
                                    pageName: this.pageName,
                                });
                            }}
                        />
                    </DropdownMenu>
                </Col>

        </Row>
    }
	topSectionChecked = (state = true) => {
        return <Row className={"align-items-center"}>
            <Col xs={12} md={'auto'}>
                <Dropdown alignRight>
                    <Dropdown.Toggle>
                        Batch Action
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            active={false}
                            onClick={() => {
                                let selected = Utils.getSelected(this.tableRef);

                                let data = {
                                    ids: selected,
                                    isActive: true,
                                }

                                this.callBuldUpdateApi(data, () => {
                                    this.callReadCustomerSIteApi((rows, total) => {
                                        this.setState({
                                            isDisabledBatchAction: !this.state.isDisabledBatchAction,
                                        }, () => {
                                            Utils.setSelected(this.tableRef);
                                            this.refreshLayout();
                                        });
                                    });
                                });

                                // Utils.callSameApiMultipleTime(
                                //     this.tableRef,
                                //     'customerSite',
                                //     updateApi,
                                //     (item) => {
                                //         return {
                                //             customerSiteId: item.customerSiteId,
                                //             customSiteName: item.customSiteName,
                                //             customerId: item.customerId,
                                //             isActive: true,
                                //         }
                                //     },
                                //     () => {
                                //         this.callReadCustomerSIteApi((rows, total) => {
                                //             this.setState({
                                //                 isDisabledBatchAction: !this.state.isDisabledBatchAction,
                                //             }, () => {
                                //                 Utils.setSelected(this.tableRef);
                                //                 this.refreshLayout();
                                //             });
                                //         });
                                //     }
                                // );
                            }} 
                        >Mark As Active</Dropdown.Item>
                        <Dropdown.Item
                            active={false}
                            onClick={() => {
                                let selected = Utils.getSelected(this.tableRef);

                                let data = {
                                    ids: selected,
                                    isActive: false,
                                }

                                this.callBuldUpdateApi(data, () => {
                                    this.callReadCustomerSIteApi((rows, total) => {
                                        this.setState({
                                            isDisabledBatchAction: !this.state.isDisabledBatchAction,
                                        }, () => {
                                            Utils.setSelected(this.tableRef);
                                            this.refreshLayout();
                                        });
                                    });
                                });

                                // Utils.callSameApiMultipleTime(
                                //     this.tableRef,
                                //     'customerSite',
                                //     updateApi,
                                //     (item) => {
                                //         return {
                                //             customerSiteId: item.customerSiteId,
                                //             customSiteName: item.customSiteName,
                                //             customerId: item.customerId,
                                //             isActive: false,
                                //         }
                                //     },
                                //     () => {
                                //         this.callReadCustomerSIteApi((rows, total) => {
                                //             this.setState({
                                //                 isDisabledBatchAction: !this.state.isDisabledBatchAction,
                                //             }, () => {
                                //                 Utils.setSelected(this.tableRef);
                                //                 this.refreshLayout();
                                //             });
                                //         });
                                //     }
                                // );
                            }} 
                        >Mark As Inactive</Dropdown.Item>
                        <Dropdown.Item
                            disabled={false}
                            active={false}
                            onClick={() => {
                                this.setState({
                                    isBulkRemoveDialog: true,
                                });
                            }} 
                        >Delete</Dropdown.Item>

                        <Dropdown.Divider />

                        <Dropdown.Item
                            active={false}
                            onClick={() => {
                                let selected = Utils.getSelected(this.tableRef);

                                this.setState({
                                    popupShowBulkUpdate: true,
                                    popupLoadingBulkUpdate: false,
                                    popupIdBulkUpdate: selected,
                                });
                            }} 
                        >Site Form</Dropdown.Item>
                        <Dropdown.Item
                            active={false}
                            onClick={() => {
                                let selected = Utils.getSelected(this.tableRef);

                                let customerSites = [];
                                if(selected && selected != ''){
                                    let ids = selected.split(',');
                                    if(ids && ids.length > 0){
                                        customerSites = this.state.rows.filter(x => ids.includes(x.customerSiteId.toString()));
                                    }
                                }

                                let emailAddresses = (this.state.details && this.state.details.emailAddresses && this.state.details.emailAddresses != '') ? this.state.details.emailAddresses : '';
                                let contactPersons = (this.state.details && this.state.details.contactPersons) ? this.state.details.contactPersons : [];
                                
                                let billingProfile = (this.state.details && this.state.details.billingProfile) ? this.state.details.billingProfile : null;
                                let accountEmailTemplates = (billingProfile && billingProfile.accountEmailTemplates && billingProfile.accountEmailTemplates.length > 0) ? billingProfile.accountEmailTemplates : [];
                                let emailTemplate = (accountEmailTemplates && accountEmailTemplates.length > 0) ? accountEmailTemplates[0] : null;
                                
                                this.setState({
                                    popupShowMailTemplate: true,
                                    popupLoadingMailTemplate: false,
                                    popupIdMailTemplate: selected,
                                    popupItemMailTemplate: {
                                        emailAddresses: emailAddresses,
                                        emailTemplate: emailTemplate,
                                        contactPersons: contactPersons,
                                        customerSites: customerSites,
                                    },
                                });
                            }} 
                        >Mailing List</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>

            {/* <Col xs={12} md={'auto'}>
                <Dropdown alignRight>
                    <Dropdown.Toggle variant={'light'}>
                        Bulk Update
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            active={false}
                            onClick={() => {
                                let selected = Utils.getSelected(this.tableRef);

                                this.setState({
                                    popupShowBulkUpdate: true,
                                    popupLoadingBulkUpdate: false,
                                    popupIdBulkUpdate: selected,
                                });
                            }} 
                        >Site Form</Dropdown.Item>
                        <Dropdown.Item
                            active={false}
                            onClick={() => {
                                let selected = Utils.getSelected(this.tableRef);

                                this.setState({
                                    popupShowMailTemplate: true,
                                    popupLoadingMailTemplate: false,
                                    popupIdMailTemplate: selected,
                                    popupItemMailTemplate: null,
                                });
                            }} 
                        >Mailing List</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col> */}

            <Col xs={12} md={true}>
                <span>{((this.tableRef && this.tableRef.current) ? this.tableRef.current.getSelected().length : '0')} item(s) selected</span>
            </Col>
        </Row>
    }
    
	tableSection = () => {
		return <DataTableScrollSync ref={this.refDataTableScrollSync} className={"table-sync-v4"}>{this.setDataTable()}</DataTableScrollSync>
	}
    setDataTable = () => {
        return <DataTable 
            ref={this.tableRef}

            className={"table-style-v4"}
            classNameWrapper={"table-v4"}

            pageName={this.pageName}
            rowId={this.pageIdField}
            caption={this.pageCaption}
            
            noMarginBottom={false}
            verticalAlign={'middle'}
            
            stickyHeader={false}
            isHeader={true}
            resizable={true}
            isDragableHeaderColumns={true}
            isDragable={false}
            isCheckbox={true}
            isBorder={false}
            isPagination={false}
            isVirtualize={false}
            
            columnVisibility={this.state.columnVisibility}
            disabledResizeColumns={[0]}

            isLoading={this.state.isLoading}
            rowsPerPageSelected={this.state.rowsPerPageSelected}
            rowsPerPageOptions={this.state.rowsPerPageOptions}

            search={this.state.search}
            rows={this.state.rows}
            columns={this.state.columns}

            onRowClick={(id, row) => {
                this.props.history.push('/Customer-site-overview?customerId=' + this.state.id + '&id=' + id);
            }}

            onRemove={(row) => {

            }}

            onClickCheckAll={(obj, checked, isDisabledBatchAction) => {
                this.setState({
                    isDisabledBatchAction: isDisabledBatchAction
                });
            }}
            onCheckRow={(obj, id, isDisabledBatchAction) => {
                this.setState({
                    isDisabledBatchAction: isDisabledBatchAction
                });
            }}
            
            onSortEnd={(columns, columnVisibility) => {
                this.setState({
                    columns: columns,
                    columnVisibility: columnVisibility,
                }, () => {
                    this.setFilterInLocalStorage();
                });
            }}
            onOrder={(name, sort) => {
                if(this.tableRef && this.tableRef.current){
                    this.tableRef.current.setSelected([], () => {
                        this.setState({
                            isDisabledBatchAction: true,
                        });
                    });
                }
                
                this.setState({
                    sortColumn: name,
                    sortDir: sort,
                }, () => {
                    this.setFilterInLocalStorage();
                    this.callReadCustomerSIteApi((rows, total) => {
                        this.refreshLayout();
                    });
                });
            }}
            onResize={(columns) => {
                if(columns){
                  this.setState({
                    columns: columns
                  });
                }
                this.setFilterInLocalStorage();
            }}
        />
    }

	paginationSection = () => {
		return <DataTableAjaxPagination
            style={{
                padding: '10px 0px',
                border: '0px',
                // left: this.state.size + 'px',
                // width: 'calc(100% - ' + this.state.size + 'px)'
                position: 'absolute',
                left: '0px',
                width: '100%',
            }}
            page={this.state.currentPage}
            selected={this.state.rowsPerPageSelected}
            count={this.state.totalRows}
            fixed={true}
            onPageChange={(page) => {
                this.setState({
                    currentPage: page,
                }, () => {
                    this.setFilterInLocalStorage();
                    this.callReadCustomerSIteApi((rows, total) => {
                        this.refreshLayout();
                    });
                });
            }}
            onOptionsChange={(option) => {
              this.setState({
                currentPage: 1,
                rowsPerPageSelected: option,
              }, () => {
                this.setFilterInLocalStorage();
                this.callReadCustomerSIteApi((rows, total) => {
                    this.refreshLayout();
                });
              });
            }}
        />
	}
    /* END FORM */


    /* DIALOG */
    setSearchDialog = () => {
        return <SearchDialog 
            show={this.state.leftIsSearchDialog}
            onCancel={() => {
                this.setState({
                    leftIsSearchDialog: false,
                });
            }}
            onSearch={(value) => {
                this.setState({
                    leftCurrentPage: 1,
                    leftSearch: value,
                    leftIsSearchDialog: false,
                }, () => {
                    this.callReadApi((items, total) => {
                        this.setState({
                            leftItems: items,
                            leftTotal: total,
                        }, () => {
                            this.setLoading(false);
                            this.refreshLayout();
                        });
                    });
                });
            }}
        />
    }

    setDateDialog = () => {
        return <DateDialog 
            show={this.state.isDateDialog}
            title={'Deactivate by last job date'}
            label={'Deactivate customer site that don\'t have job beyond this date'}
            okText={'Deactivate'}
            okVariant={'danger'}
            disabledDate={(date: any) => {
                return (moment(date).isAfter(moment())) ? true : false;
            }}
            onCancel={() => {
                this.setState({
                    isDateDialog: false,
                });
            }}
            onAccept={(value, formatedValue) => {
                this.callBulkDeactivateByJobDateApi(formatedValue, (data, isError) => {
                    this.setState({
                        isDateDialog: false,
                    }, () => {
                        this.callReadCustomerSIteApi((rows, total) => {
                            this.refreshLayout();
                        });
                    });
                });
            }}
        />
    }

    setPopupCustomerForm = () => {
        return <Popup 
            className={'no-bg'}
            auth={this.props.auth}
            settingsType={this.props.settingsType}

            show={this.state.popupShowCustomerForm}
            isLoading={this.state.popupLoadingCustomerForm}
            id={this.state.popupIdCustomerForm}
            isCopy={this.state.popupIsCopyCustomerForm}

            showHeader={true}
            header={this.state.popupIdCustomerForm ? this.state.popupIsCopyCustomerForm ? 'Copy Customer' : 'Edit Customer' : 'New Customer'}
            showFooter={true}
            centerFooter={'start'}
            footer={({ isLoading, id, onHide, onSave, onSaveCreateNewSite }) => {
                return <Row>
                    <Col xs={'auto'}>
                        <LoadingButton
                            isLoading={isLoading}
                            onClick={onSave}
                        >Save</LoadingButton>
                    </Col>

                    {(!id) && <Col xs={'auto'}>
                        <Button 
                            type={'button'}
                            variant={'primary'}
                            disabled={isLoading}
                            onClick={onSaveCreateNewSite}
                        >
                            Save & Create New Site
                            {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                        </Button>
                    </Col>}

                    <Col xs={'auto'}>
                        <Button
                            variant={'light'}
                            disabled={isLoading}
                            onClick={onHide}
                        >Cancel</Button>
                    </Col>
                </Row>
            }}

            scrollable={true}
            centered={true}
            closeButton={true}
            fullscreen={true}
            noBodyPadding={true}

            onHide={() => {
                this.setState({
                    popupShowCustomerForm: false,
                    popupLoadingCustomerForm: false,
                    popupIdCustomerForm: 0,
                    popupIsCopyCustomerForm: false,
                });
            }}
            onSave={() => {
                if(this.refCustomerForm && this.refCustomerForm.current){
                    if(this.refCustomerForm.current.refForm && this.refCustomerForm.current.refForm.current){
                        this.refCustomerForm.current.refForm.current.handleSubmit();
                    }
                }
            }}
            onSaveCreateNewSite={() => {
                if(this.refCustomerForm && this.refCustomerForm.current){
                    if(this.refCustomerForm.current.onSaveCreateNewSite){
                        this.refCustomerForm.current.onSaveCreateNewSite((res) => {
                            this.setState({
                                id: res.customerId,
                                details: null,

                                popupShowCustomerForm: false,
                                popupLoadingCustomerForm: false,
                                popupIdCustomerForm: 0,
                                popupIsCopyCustomerForm: false,
                                details: null,
                                
                                popupShowCustomerSitesForm: true,
                                popupLoadingCustomerSitesForm: false,
                                popupIdCustomerSitesForm: 0,
                                popupIsCopyCustomerSitesForm: false,
                            }, () => {
                                this.props.history.push('/Customer-overview-sites?id=' + this.state.id);

                                this.callReadApi((items, total) => {
                                    this.setState({
                                        leftItems: items,
                                        leftTotal: total,
                                    }, () => {
                                        this.setLoading(false);
                                        this.refreshLayout();
                                    });
                                });
            
                                this.callDetailsApis();
                            });
                        });
                    }
                }
            }}
            
            onLoading={(state) => {
                this.setState({
                    popupLoadingCustomerForm: state,
                });
            }}
            onSubmit={(form) => {
                this.setState({
                    popupShowCustomerForm: false,
                    popupLoadingCustomerForm: false,
                    popupIdCustomerForm: 0,
                    popupIsCopyCustomerForm: false,
                    details: null,
                }, () => {
                    this.callReadApi((items, total) => {
                        this.setState({
                            leftItems: items,
                            leftTotal: total,
                        }, () => {
                            this.setLoading(false);
                            this.refreshLayout();
                        });
                    });

                    this.callDetailsApis();
                });
            }}
        >
            {(props) => {
                return <CustomerForm
                    ref={this.refCustomerForm}
                    {...props}
                />
            }}
        </Popup>
    }

    setPopupCustomerSitesForm = () => {
        return <Popup 
            className={'no-bg'}
            auth={this.props.auth}
            settingsType={this.props.settingsType}

            customerId={this.state.id}

            show={this.state.popupShowCustomerSitesForm}
            isLoading={this.state.popupLoadingCustomerSitesForm}
            id={this.state.popupIdCustomerSitesForm}
            isCopy={this.state.popupIsCopyCustomerSitesForm}

            showHeader={true}
            header={this.state.popupIdCustomerSitesForm ? this.state.popupIsCopyCustomerSitesForm ? 'Copy Site' : 'Edit Site' : 'Add Site'}
            showFooter={true}
            centerFooter={'start'}
            footer={({ isLoading, id, onHide, onSave }) => {
                return <Row style={{ width: '100%' }}>
                    <Col xs={'auto'}>
                        <LoadingButton
                            isLoading={isLoading}
                            onClick={onSave}
                        >Save</LoadingButton>
                    </Col>
                    <Col xs={'auto'}>
                        <Button
                            variant={'light'}
                            disabled={isLoading}
                            onClick={onHide}
                        >Cancel</Button>
                    </Col>
                </Row>
            }}

            scrollable={true}
            centered={true}
            closeButton={true}
            fullscreen={true}
            noBodyPadding={true}

            onHide={() => {
                this.setState({
                    popupShowCustomerSitesForm: false,
                    popupLoadingCustomerSitesForm: false,
                    popupIdCustomerSitesForm: 0,
                    popupIsCopyCustomerSitesForm: false,
                });
            }}
            onSave={() => {
                if(this.refCustomerSitesForm && this.refCustomerSitesForm.current){
                    if(this.refCustomerSitesForm.current.refForm && this.refCustomerSitesForm.current.refForm.current){
                        this.refCustomerSitesForm.current.refForm.current.handleSubmit();
                    }
                }
            }}
            
            onLoading={(state) => {
                this.setState({
                    popupLoadingCustomerSitesForm: state,
                });
            }}
            onSubmit={(form) => {
                this.setState({
                    popupShowCustomerSitesForm: false,
                    popupLoadingCustomerSitesForm: false,
                    popupIdCustomerSitesForm: 0,
                    popupIsCopyCustomerSitesForm: false,
                }, () => {
                    this.callReadCustomerSIteApi((items) => {
                        this.refreshLayout();
                    });
                });
            }}
        >
            {(props) => {
                return <CustomerSiteForm
                    ref={this.refCustomerSitesForm}
                    {...props}
                />
            }}
        </Popup>
    }

    setPopupBulkUpdate = () => {
        return <Popup 
          className={'no-bg'}
          auth={this.props.auth}
          settingsType={this.props.settingsType}
    
          show={this.state.popupShowBulkUpdate}
          isLoading={this.state.popupLoadingBulkUpdate}
          id={this.state.popupIdBulkUpdate}
    
          showHeader={true}
          header={'Bulk Update - Site Form'}
          showFooter={true}
          centerFooter={'center'}
          footer={({ isLoading, id, onHide, onSave }) => {
            return <Row>
              <Col xs={'auto'}>
                <LoadingButton
                  isLoading={isLoading}
                  onClick={onSave}
                >Save</LoadingButton>
              </Col>
              <Col xs={'auto'}>
                <Button
                  variant={'light'}
                  disabled={isLoading}
                  onClick={onHide}
                >Cancel</Button>
              </Col>
            </Row>
          }}
    
          scrollable={true}
          centered={true}
          closeButton={true}
          fullscreen={false}
          noBodyPadding={false}
          size={'lg'}
    
          onHide={() => {
            this.setState({
              popupShowBulkUpdate: false,
              popupLoadingBulkUpdate: false,
              popupIdBulkUpdate: null,
            });
          }}
          onSave={() => {
            if(this.refBulkUpdate && this.refBulkUpdate.current){
              if(this.refBulkUpdate.current.refForm && this.refBulkUpdate.current.refForm.current){
                this.refBulkUpdate.current.refForm.current.handleSubmit();
              }
            }
          }}
          
          onLoading={(state) => {
            this.setState({
              popupLoadingBulkUpdate: state,
            });
          }}
          onSubmit={(form) => {
            this.setState({
              popupLoadingBulkUpdate: true,
            }, () => {
              this.callBuldUpdateApi(form, (res) => {
                this.setState({
                  popupShowBulkUpdate: false,
                  popupLoadingBulkUpdate: false,
                  popupIdBulkUpdate: null,
                }, () => {
                    this.callReadCustomerSIteApi((rows, total) => {
                        this.setState({
                            isDisabledBatchAction: !this.state.isDisabledBatchAction,
                        }, () => {
                            Utils.setSelected(this.tableRef);
                            this.refreshLayout();
                        });
                    });
                });
              });
            });
          }}
        >
          {(props) => {
            return <BulkUpdateCustomerSiteForm
              ref={this.refBulkUpdate}
              {...props}
            />
          }}
        </Popup>
    }

    setPopupMailTemplate = () => {
        return <Popup 
          className={'no-bg'}
          auth={this.props.auth}
          settingsType={this.props.settingsType}
    
          show={this.state.popupShowMailTemplate}
          isLoading={this.state.popupLoadingMailTemplate}
          id={this.state.popupIdMailTemplate}
          item={this.state.popupItemMailTemplate}
    
          showHeader={true}
          header={({ isLoading, id, onHide, onSave }) => {
            let accountBillingProfileId = Utils.getDataIntValue(this.state.details, 'accountBillingProfileId', v => v, '');
            return <>
                <h3>
                    Email Template
                    <small className={'ps-2'} style={{ fontSize: '13px' }}>
                        <a href={'/manage/profile/company?id=' + accountBillingProfileId + '&selectedTab=3'}
                            target={'_blank'}
                            rel="noreferrer"
                        >(Setup)</a>
                    </small>
                    <br />
                    <span style={{ fontSize: '13px', fontWeight: 'normal', color: '#7A7A7A' }}>Notify recipients via email when job is completed.</span>
                </h3>
                
            </>
        }}
          showFooter={true}
          centerFooter={'start'}
          footer={({ isLoading, id, onHide, onSave }) => {
            return <Row>
              <Col xs={'auto'}>
                <LoadingButton
                  isLoading={isLoading}
                  onClick={onSave}
                >Save</LoadingButton>
              </Col>
              <Col xs={'auto'}>
                <Button
                  variant={'light'}
                  disabled={isLoading}
                  onClick={onHide}
                >Cancel</Button>
              </Col>
            </Row>
          }}
    
          scrollable={true}
          centered={true}
          closeButton={true}
          fullscreen={true}
          noBodyPadding={false}
          size={'lg'}
    
          onHide={() => {
            this.setState({
              popupShowMailTemplate: false,
              popupLoadingMailTemplate: false,
              popupIdMailTemplate: null,
              popupItemMailTemplate: null,
            });
          }}
          onSave={() => {
            if(this.refMailTemplate && this.refMailTemplate.current){
              if(this.refMailTemplate.current.refForm && this.refMailTemplate.current.refForm.current){
                this.refMailTemplate.current.refForm.current.handleSubmit();
              }
            }
          }}
          
          onLoading={(state) => {
            this.setState({
              popupLoadingMailTemplate: state,
            });
          }}
          onSubmit={(form) => {
            this.setState({
              popupLoadingMailTemplate: true,
            }, () => {
              this.callMailTemplateApi(form, (res) => {
                this.setState({
                  popupShowMailTemplate: false,
                  popupLoadingMailTemplate: false,
                  popupIdMailTemplate: null,
                  popupItemMailTemplate: null,
                }, () => {
                  this.setLoading(false);

                  if(this.tableRef && this.tableRef.current){
                    this.tableRef.current.setSelected([], () => {
                      this.setState({
                        isDisabledBatchAction: true,
                      });
                    });
                  }
    
                  this.callReadCustomerSIteApi();
                  this.callDetailsApis();
                });
              });
            });
          }}
        >
          {(props) => {
            return <MailTemplateCustomerSiteForm
              ref={this.refMailTemplate}
              {...props}
            />
          }}
        </Popup>
    }

    setBulkRemoveDialog = () => {
        return <RemoveDialog 
          title={'Remove customer site'}
          show={this.state.isBulkRemoveDialog}
          isLoading={false}
          onCancel={() => {
            this.setState({
                isBulkRemoveDialog: false,
            });
          }}
          onRemove={() => {
            this.setState({
                isBulkRemoveDialog: false,
            }, () => {
                this.callBulkDeleteApi((res, isError) => {
                    this.callReadCustomerSIteApi((rows, total) => {
                        this.setState({
                            isDisabledBatchAction: !this.state.isDisabledBatchAction,
                        }, () => {
                            Utils.setSelected(this.tableRef);
                            this.setLoading(false);
                            this.refreshLayout();
                        });
                    });
                });
            });
          }}
        />
    }
    /* END DIALOG */


    render() {
        return <>
            <Row style={{ display: 'contents', width: '100%' }}>
                <Col xs={12} className={'p-0'}>
                    {this.state.mainLoading ? null : (this.state.details && (this.state.rows && this.state.rows.length > 0)) ? this.rightBody() : this.rightBodyNoResult()}
                </Col>
            </Row>
            {this.setSearchDialog()}
            {this.setDateDialog()}
            {this.setPopupCustomerForm()}
            {this.setPopupCustomerSitesForm()}
            {this.setPopupBulkUpdate()}
            {this.setPopupMailTemplate()}
            {this.setBulkRemoveDialog()}
        </>
    }
}


const mapStateToProps = state => {
    return {
      ...state,
      ...Utils.mapStateToProps(state),
    };
  };
  const mapDispatchToProps = (dispatch) => ({
    dispatchApiCallGet: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallGet(p1, p2, p3, p4, p5, p6)),
    dispatchApiCallPost: (p1, p2, p3, p4, p5, p6, p7) => dispatch(dispatchApiCallPost(p1, p2, p3, p4, p5, p6, p7)),
    dispatchApiCallPut: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallPut(p1, p2, p3, p4, p5, p6)),
    dispatchApiCallDelete: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallDelete(p1, p2, p3, p4, p5, p6)),
    saveUserCookieSettings: (payload) => dispatch(saveUserCookieSettings(payload)),
    clear: (payload) => dispatch(clear(payload)),
    action: {
      signalRslice: {
        connect: (payload) => dispatch(signalRslice.connect(payload)),
        disconnect: (payload) => dispatch(signalRslice.disconnect(payload)),
        onPercentage: (payload) => dispatch(signalRslice.onPercentage(payload)),
      },
    }
  });
  
  export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CustomerSites);