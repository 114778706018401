/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import * as dispatchCrudApi from '../../../../api/CRUD/DispatchCRUD'
import Utils from "../../../../utils/utils";
// import {  } from "../../../../utils/enums";

import { ParseResult } from "../../../../utils/interfaces";


interface InitState {
  isLoading: boolean,
  drivers: Array<any>,
  selectedValues: Array<any>,
}

function NewReducer() {
  const name = 'ganttChartDriversSlice';


  const initialState: InitState = {
    isLoading: false,
    drivers: [],
    selectedValues: [],
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setDrivers: (state: InitState, action: PayloadAction<Array<any>>) => {
      state.drivers = action.payload;
    },
    setSelectedValues: (state: InitState, action: PayloadAction<Array<any>>) => {
      state.selectedValues = action.payload;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      
      state.drivers = data;
      state.isLoading = false;
    },
  };


  const apis = {
    callReadApi: (search: string, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startRead());

      let params: any = {
        currentPage: 1,
        pageSize: Utils.getMaxPageSize(),
        searchQuery: search,
        IsAssignJobToPhone: true,
        sortColumn: 'updated',
        sortDir: 'desc',
      }

      await dispatchCrudApi.readApi(params, 'driver').then(result => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishRead(data));
      }).catch(error => {
        let res: ParseResult = {
          isError: false,
          errorMessage: null,
          status: null,
        };

        Utils.parseErrorTS(error, (result: ParseResult): void => {
          res = result
        });
        
        let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
        if(res.isError && err){
          Utils.toast(err, 'error');
        }
        
        callback(false, null);
        dispatch(actions.finishRead(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();