import React from 'react';
// import ReactDOMServer from 'react-dom/server';

// import { MarkerClusterer } from '@googlemaps/markerclusterer';

import {
  Row,
  Col,
} from 'react-bootstrap';
import styled from 'styled-components';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../../../setup'
import slice from './slice';
import { getJobInfo } from '../slice';

import GoogleMap, { DEFAULT_ZOOM, DEFAULT_CENTER, addMarker, clearAllMarkers, panTo, CURRENT_INFO_WINDOW, setCurrentInfoWindow, dinamicZoom, dinamicMultiZoom, changeMarkerColor, validateLatLng } from '../../../../components/v5/googleMap/GoogleMap';

import CurrentMarkerIcon from "../../../../../_metronic/assets/icons/gantt-marker-current.svg";

import Utils from '../../../../utils/utils';
import { AccountSettingType } from '../../../../utils/enums';

import { LegendList } from '../Page';

// ----------------------------------------------------------------------

const StyledDiv = styled.div`
  position: absolute;
  top: 34px;
  left: 10px;
  z-index: 1;
  padding: 5px 15px 5px 20px;
  border-radius: 6px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1) !important;
  user-select: none;

  background-color: white;
`;


const MapComponent = React.forwardRef(({ onMarkerClick }: any, ref) => {
  const dispatch = useDispatch();
  const { show, details, isLoading, items, isLive, latestLocationPosition, latestLocationDriverName } = useSelector((state) => state.ganttChartRouteMapSlice);

  const [map, setMap] = React.useState(null);
  const [center, setCenter] = React.useState(DEFAULT_CENTER);
  const [zoom, setZoom] = React.useState(DEFAULT_ZOOM);

  const markersRef = React.useRef([]);
  const currentMarkerRef = React.useRef(null);
  
  const [markerCluster, setMarkerCluster] = React.useState(null);

  
  React.useImperativeHandle(
    ref,
    () => ({
      moveToMarker(jobObj) {
        panTo(map, jobObj.position);
      },
      openMarkerInfo(jobObj) {
        if(markersRef && markersRef.current && markersRef.current.length > 0){
          let marker = markersRef.current.find(x => x.jobId === jobObj.jobId);

          if(marker){
            closeInfoWindow();
                  
            const infoWindow = new window.google.maps.InfoWindow({ headerContent: jobObj?.data?.customerName, content: jobObj.address });
            infoWindow.open(map, marker);
    
            setCurrentInfoWindow(infoWindow);
          }
        }
      },
     }),
  );


  React.useEffect(() => {
    let settings = Utils.getAccountSettings();
          
    let latObj = (settings && settings.length > 0) ? settings.find((x) => x.type === AccountSettingType.DefaultMapPositionLatitude) : null;
    let lngObj = (settings && settings.length > 0) ? settings.find((x) => x.type === AccountSettingType.DefaultMapPositionLongitude) : null;
    let zoomObj = (settings && settings.length > 0) ? settings.find((x) => x.type === AccountSettingType.DefaultMapPositionZoom) : null;
    
    let lat = (latObj && latObj.value && latObj.value != '') ? parseFloat(latObj.value) : DEFAULT_CENTER.lat;
    let lng = (lngObj && lngObj.value && lngObj.value != '') ? parseFloat(lngObj.value) : DEFAULT_CENTER.lng;
    let zoom = (zoomObj && zoomObj.value && zoomObj.value != '') ? parseInt(zoomObj.value) : DEFAULT_ZOOM;

    setCenter({ lat: lat, lng: lng });
    setZoom(zoom);

    
    document.addEventListener('mousedown', closeInfoWindow);
    return () => {
      document.removeEventListener('mousedown', closeInfoWindow);
    };
  }, []);

  React.useEffect(() => {
    if (window.google && window.google.maps && window.google.maps.Size) {
      clearAllMarkers(markersRef);
    }
  }, [map]);
  
  React.useEffect(() => {
    if (window.google && window.google.maps && window.google.maps.Size) {
      clearAllMarkers(markersRef);

      if(items && items.length > 0){
        items.forEach((job, i) => {
          let jobData = getJobInfo(job);

          if(jobData && validateLatLng(jobData.position.lat, jobData.position.lng)){
            addMarker(markersRef, {
              map: map,
              zIndex: 1,
              jobId: jobData.jobId,
              position: jobData.position,
              title: jobData.address,
              icon: {
                url: 'data:image/svg+xml;utf-8,' + changeMarkerColor(jobData.statusColor),
                scaledSize: new window.google.maps.Size(44, 44),
                labelOrigin: new window.google.maps.Point(22, 44),
              },
            }, (marker, item) => {
              closeInfoWindow();
              
              const infoWindow = new window.google.maps.InfoWindow({ headerContent: job.customerName, content: jobData.address });
              infoWindow.open(map, marker);
      
              setCurrentInfoWindow(infoWindow);

              if(onMarkerClick){
                onMarkerClick(marker, item);
              }
            });
          }
        });
      }

      centerAllMarkers();
    }
  }, [items]);

  // React.useEffect(() => {
  //   if (map) {
  //     if(markerCluster){
  //       markerCluster.clearMarkers();
  //       markerCluster.addMarkers(markersRef.current);
  //     } else {
  //       let markerClusterer = new MarkerClusterer({ map: map, markers: markersRef.current });
  //       setMarkerCluster(markerClusterer);
  //     }
  //   }
  // }, [items]);

  React.useEffect(() => {
    try {
      currentMarkerRef.current.setMap(null);
      currentMarkerRef.current = null;
    } catch(e){}

    if(isLive){
      try {
        if(latestLocationPosition){
          let item = {
            map: map,
            zIndex: 2,
            position: latestLocationPosition,
            title: latestLocationDriverName,
            icon: {
              url: CurrentMarkerIcon,
              scaledSize: new window.google.maps.Size(44, 44),
              labelOrigin: new window.google.maps.Point(22, 22),
            },
          };
          let marker = new window.google.maps.Marker(item);
          currentMarkerRef.current = marker;

          if (marker && typeof marker.getPosition === 'function') {
            const position = marker.getPosition();
            if (position) {
              dinamicZoom(map, position.lat(), position.lng(), 0.0600);
            }
          }
        }
      } catch(e){}
    } else {
      try {
        currentMarkerRef.current.setMap(null);
        currentMarkerRef.current = null;
      } catch(e){}
      
      centerAllMarkers();
    }
  }, [isLive, latestLocationPosition]);

  
  const centerAllMarkers = () => {
    if(markersRef && markersRef.current && markersRef.current.length === 1){
      let marker = markersRef.current[0];

      if (marker && typeof marker.getPosition === 'function') {
        const position = marker.getPosition();
        if (position) {
          dinamicZoom(map, position.lat(), position.lng(), 0.0600);
        }
      }
    } else {
      dinamicMultiZoom(map, markersRef.current);
    }
  }
  const closeInfoWindow = () => {
    if (CURRENT_INFO_WINDOW) {
      CURRENT_INFO_WINDOW.close();
    }
  }
  const changeMarkerColor = (color = '#C3C4C9', cls = '') => {
    return encodeURIComponent(`<svg class="${cls}" width="44" height="54" viewBox="0 0 44 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="22" fill="white"/>
      <rect x="3" y="3" width="38" height="38" rx="19" fill="white"/>
      <rect x="3" y="3" width="38" height="38" rx="19" stroke="${color}" stroke-width="2"/>
      <rect x="6" y="6" width="32" height="32" rx="16" fill="${color}"/>
      <rect x="21" y="40" width="3" height="14" rx="1.5" fill="${color}"/>
    </svg>`)
  }


  return <div>
    <StyledDiv>
      <LegendList />
    </StyledDiv>

    <GoogleMap
      style={{
        borderRadius: '6px',
        border: '1px solid #DEDEE1',
      }}
      canConextMenu={false}
      options={{
        center: center,
        zoom: zoom,
        mapTypeControl: false,
        streetViewControl: false,
      }}
      onLoad={(map, ref, maps) => {
        setMap(map);
      }}
    />
  </div>
});

export default MapComponent;
