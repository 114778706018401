import React from 'react';
// import { useHistory } from "react-router-dom";
import moment from 'moment';
import _ from 'lodash';
import { useFormik } from 'formik';

import {
  Row,
  Col,
  Button,
  Modal,
  Form,
} from 'react-bootstrap-v5';
import styled from 'styled-components';

import Offcanvas from "react-bootstrap/Offcanvas";
import 'bootstrap/dist/css/bootstrap.min.css';

import { Spin, Select, Tag } from 'antd';

import { DatePicker } from 'rsuite';
import "rsuite/dist/rsuite.min.css";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../../../setup'
import slice, { FormikContext, fields, formSchema, prepareForm, prepareData } from './slice';
import ganttChartSlice from '../slice';
import bulkUpdateJobForm from "../../redux/bulkUpdateJobForm";
import checkboxCount from "../../../../../setup/redux/slices/checkboxCount";

// import { ReactComponent as UpIcon } from "../../../../../_metronic/assets/icons/gantt-sort-up.svg";
// import { ReactComponent as DownIcon } from "../../../../../_metronic/assets/icons/gantt-sort-down.svg";
import { ReactComponent as SearchIcon } from "../../../../../_metronic/assets/icons/search.svg";

// import { updateApi } from '../../../../api/CRUD/DispatchCRUD'

import Utils from '../../../../utils/utils';
// import {  } from '../../../../utils/enums';
// import {  } from "../../../../utils/styles";

// ----------------------------------------------------------------------

const StyledDatePickerField = styled(DatePicker)`
    padding: 0px;

    .rs-picker-toggle {
        padding: 5px 5px 5px 15px;
        padding-right: 5px !important;
        border-radius: 6px;
        border: 0px;
        box-shadow: none !important;

        &.rs-btn {
            padding-bottom: 5px !important;
            padding-top: 5px !important;
        }

        &:hover {
            background-color: white !important;
        }

        input, .rs-picker-toggle-value, .rs-picker-toggle-placeholder {
            font-size: 1.1rem !important;
            font-weight: 500 !important;
            line-height: 1.5 !important;
            color: #6E707E;
        }

        .rs-stack-item {
            &:first-child {
                left: -6px !important;
                position: relative;
            }

            .rs-picker-toggle-textbox {
                padding-left: 0px !important;
            }
            .rs-picker-toggle-placeholder {
                padding-left: 0px !important;
            
                color: #A1A5B7 !important;
            }
        
            svg {
                display: none;
            }
        }
    }
`;
const StyledTimePickerField = styled(DatePicker)`
    padding: 0px;
    padding-right: 0px !important;
    
    .rs-btn-subtle {
        box-shadow: none !important;
        background-color: transparent !important;
    }

    .rs-picker-toggle {
        padding: 5px 5px 5px 15px;
        padding-right: 5px !important;
        border-radius: 6px;

        &:hover {
            background-color: white !important;
        }

        input, .rs-picker-toggle-value, .rs-picker-toggle-placeholder {
            font-size: 1.1rem !important;
            font-weight: 500 !important;
            line-height: 1.5 !important;
            color: #6E707E;
        }

        .rs-stack-item {
            &:first-child {
                left: -6px !important;
                position: relative;
            }
        
            .rs-picker-toggle-textbox {
                padding-left: 0px !important;
            }
            .rs-picker-toggle-placeholder {
                padding-left: 0px !important;
            
                color: #A1A5B7 !important;
            }
        
            svg {
                display: none;
            }
        }
    }
`;


const SelectDropdownMenu = ({ menu, search, footer }: any) => {
    return <Row>
        <Col xs={12} className='px-6 py-3' style={{ borderBottom: '1px solid #DEDEE1' }}>{search}</Col>
        <Col xs={12} className='px-4 py-3'>{menu}</Col>
    </Row>
}
const SelectDropdownMenuItem = ({ values, option, field }: any) => {
    return <Row className={'align-items-center g-4'}>
        <Col xs={true} className={(option.data.disabled === true) ? 'muted-text' : option?.data[field].toString().trim().toLowerCase().replaceAll(' ', '') + '-txt'}>{option?.data[field]}</Col>
    </Row>
}
const SelectLabel = ({ label, value, selectedValue }: any) => {
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return <Tag
        className={(label && label != '') ? label.toString().trim().toLowerCase().replaceAll(' ', '') : ''}
        bordered={false}
        onMouseDown={onPreventMouseDown}
        closable={false}
        onClose={() => {}}
        style={{ marginInlineEnd: 4 }}
    >
        {(label && label != '') ? label : (selectedValue && selectedValue != '') ? selectedValue : ''}
    </Tag>
}


const ListComponent = React.forwardRef((props: any, ref) => {
    // let history = useHistory();

    const dispatch = useDispatch();
    const { show, isLoading, driver, vehicle, status } = useSelector((state: RootState) => state.ganttChartUnassignedSlice);
    const { selectedDate, statusDaily, isLoadingDispatchAll, isLoadingDispatch, isLoadingTime } = useSelector((state: RootState) => state.ganttChartSlice);
    const { selectedValues } = useSelector((state: RootState) => state.ganttChartDriversSlice);
    const { selected, isSelected } = useSelector((state: RootState) => state.checkboxCount);
    
    const pageCaption = 'Unassigned Jobs';

    const [ sortColumnDir, setSortColumnDir ] = React.useState<any>({ sortColumn: 'jobDate', sortDir: 'asc' });


    const [isOpenDriver, setIsOpenDriver] = React.useState<any>(null);
    const [searchDriver, setSearchDriver] = React.useState<string>('');
    const [isLoadingDriver, setIsLoadingDriver] = React.useState<any>(null);

    const [isOpenVehicle, setIsOpenVehicle] = React.useState<any>(null);
    const [searchVehicle, setSearchVehicle] = React.useState<string>('');
    const [isLoadingVehicle, setIsLoadingVehicle] = React.useState<any>(null);

    const [isOpenStatus, setIsOpenStatus] = React.useState<any>(null);
    const [searchStatus, setSearchStatus] = React.useState<string>('');
    const [isLoadingStatus, setIsLoadingStatus] = React.useState<any>(null);


    React.useImperativeHandle(
        ref,
        () => ({
            updateList(results: any) {
                if(results && results.length > 0){
                    let newValue = _.cloneDeep(values);
                    newValue.form = newValue.form.map((x: any) => {
                        let jobItem = results.find((y: any) => y.jobId === x.jobId);
                        let jobObj = (jobItem) ? jobItem : x;

                        let jobDate = (jobObj.jobDate) ? moment(jobObj.jobDate).toDate() : null;
                        let jobTimeSpecific = (jobObj.jobTimeSpecific) ? moment(jobObj.jobTimeSpecific).toDate() : null;
                  
                        return { ...jobObj, scheduledDate: jobDate, scheduledTime: jobTimeSpecific };
                    });
                    setValues(newValue);
                }
            },
        }),
    );


    React.useEffect(() => {
        if(show){
            dispatch(checkboxCount.clear());
            apiCall();
        }
    }, [show]);


    const getFromToDate = () => {
        let fromDate = moment(selectedDate, Utils.getDefaultDateFormat()).startOf('day').format(Utils.getAPIDateFormat());
        let toDate = moment(selectedDate, Utils.getDefaultDateFormat()).endOf('day').format(Utils.getAPIDateFormat());
    
        return {
          fromDate: fromDate,
          toDate: toDate,
        }
    }

    const apiCall = () => {
        let fromToDate = getFromToDate();
        let driverFilter = (selectedValues && selectedValues.length > 0) ? selectedValues.join(',') : null;

        let params = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            fromDate: fromToDate.fromDate,
            toDate: fromToDate.toDate,
            sortColumn: sortColumnDir?.sortColumn,
            sortDir: sortColumnDir?.sortDir,
            JobStatusFilter: statusDaily?.unassigned?.jobStatusId?.toString(),
            driverFilter: driverFilter,
        };
        dispatch(slice.callReadApi(params, (state: boolean, data: any) => {
            let form = prepareForm(data?.data);
            setValues(form);
        }));
    }


    const formik = useFormik({
        initialValues: [],
        validationSchema: formSchema(),
        validateOnMount: false,
        validateOnChange: false,
        onSubmit: values => {
            onSubmit(values, (data: any) => {
                onCancel();
            });
        },
    });
    const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = formik;


    const onSubmit = (values: any, callback: (data: any) => void) => {
        dispatch(slice.setLoading(true));
        let data = prepareData(values);
        callback(data);
    }
    const onCancel = () => {
        let form = prepareForm(null);
        dispatch(slice.setShow({ show: false, details: null }));
        setValues(form);
        dispatch(slice.resetSlice());
    }

    const debouncedSearch = React.useCallback(
        _.debounce((callback) => {
            if(callback){
                callback()
            }
        }, 500),
        []
    );

    const dispatchAll = () => {
        if(values && values.form && values.form.length > 0){
          let assignedTasks = values.form.filter((x: any) => x.jobStatusId == statusDaily?.assigned?.jobStatusId);
          
          if(assignedTasks && assignedTasks.length > 0){
            let jobIds = assignedTasks.map((x: any) => x.jobId).join(',');
            
            let params = {
              jobIds: jobIds,
              jobStatusId: statusDaily?.dispatched?.jobStatusId,
            };
            dispatch(ganttChartSlice.callDispatchAllApi(params, (state: boolean, data: any) => {
              if(state){
                if(props.onDispatchAll){
                    props.onDispatchAll(data);
                }
                
                onCancel();
              }
            }));
          } else {
            Utils.toast('There are no assigned jobs', 'error');
          }
        } else {
          Utils.toast('There are no jobs in list', 'error');
        }
    }
    
    const updateDriver = (item: any, value: any, vehicleId: any, jobStatusId: any, index: number) => {
        let params = {
            jobId: item?.jobId,
            jobDurationHours: item?.jobDurationHours,
            jobDurationMinutes: item?.jobDurationMinutes,
            
            driverId: value,
            vehicleId: vehicleId,
            jobStatusId: jobStatusId,
            
            jobDate: item?.jobDate,
            jobTimeFrom: item?.jobTimeFrom,
            jobTimeSpecific: item?.jobTimeSpecific,
        }
        dispatch(ganttChartSlice.callDispatchApi(params, (state: boolean, data: any) => {
            if(state){
                let jobDate = (data.jobDate) ? moment(data.jobDate).toDate() : null;
                let jobTimeSpecific = (data.jobTimeSpecific) ? moment(data.jobTimeSpecific).toDate() : null;

                setFieldValue('form.' + index, { ...data, scheduledDate: jobDate, scheduledTime: jobTimeSpecific });

                if(props.onUpdate){
                    props.onUpdate(data);
                }
            }
        }));
    }
    const updateVehicle = (item: any, value: any, driverId: any, jobStatusId: any, index: number) => {
        let params = {
            jobId: item?.jobId,
            jobDurationHours: item?.jobDurationHours,
            jobDurationMinutes: item?.jobDurationMinutes,
            
            driverId: driverId,
            vehicleId: value,
            jobStatusId: jobStatusId,
            
            jobDate: item?.jobDate,
            jobTimeFrom: item?.jobTimeFrom,
            jobTimeSpecific: item?.jobTimeSpecific,
        }
        dispatch(ganttChartSlice.callDispatchApi(params, (state: boolean, data: any) => {
            if(state){
                let jobDate = (data.jobDate) ? moment(data.jobDate).toDate() : null;
                let jobTimeSpecific = (data.jobTimeSpecific) ? moment(data.jobTimeSpecific).toDate() : null;
                
                setFieldValue('form.' + index, { ...data, scheduledDate: jobDate, scheduledTime: jobTimeSpecific });

                if(props.onUpdate){
                    props.onUpdate(data);
                }
            }
        }));
    }
    const updateStatus = (item: any, value: any, driverId: any, index: number) => {
        let params = {
            jobId: item?.jobId,
            jobDurationHours: item?.jobDurationHours,
            jobDurationMinutes: item?.jobDurationMinutes,
            
            driverId: driverId,
            vehicleId: item?.vehicleId,
            jobStatusId: value,
            
            jobDate: item?.jobDate,
            jobTimeFrom: item?.jobTimeFrom,
            jobTimeSpecific: item?.jobTimeSpecific,
        }
        dispatch(ganttChartSlice.callDispatchApi(params, (state: boolean, data: any) => {
            if(state){
                let jobDate = (data.jobDate) ? moment(data.jobDate).toDate() : null;
                let jobTimeSpecific = (data.jobTimeSpecific) ? moment(data.jobTimeSpecific).toDate() : null;
                
                setFieldValue('form.' + index, { ...data, scheduledDate: jobDate, scheduledTime: jobTimeSpecific });

                if(props.onUpdate){
                    props.onUpdate(data);
                }
            }
        }));
    }
    const updateDate = (item: any, value: any, scheduledTime: any, index: number) => {
        let scheduledDateTime = value ? Utils.getLocalIsoDateTime(value) : null;

        let jobTimeFrom = item?.jobTimeFrom;
        let jobTimeSpecific = item?.jobTimeSpecific;
        if(scheduledTime){
            jobTimeFrom = scheduledDateTime;
            jobTimeSpecific = scheduledDateTime;
        }

        let params = {
            jobId: item?.jobId,
            jobDurationHours: item?.jobDurationHours,
            jobDurationMinutes: item?.jobDurationMinutes,
            
            driverId: item?.driverId,
            vehicleId: item?.vehicleId,
            jobStatusId: item?.jobStatusId,
            
            jobDate: scheduledDateTime,
            jobTimeFrom: jobTimeFrom,
            jobTimeSpecific: jobTimeSpecific,
        }
        dispatch(ganttChartSlice.callTimeApi(params, (state: boolean, data: any) => {
            if(state){
                let jobDate = (data.jobDate) ? moment(data.jobDate).toDate() : null;
                let jobTimeSpecific = (data.jobTimeSpecific) ? moment(data.jobTimeSpecific).toDate() : null;
                
                setFieldValue('form.' + index, { ...data, scheduledDate: jobDate, scheduledTime: jobTimeSpecific });

                if(props.onUpdate){
                    props.onUpdate(data);
                }
            }
        }));
    }
    const updateTime = (item: any, value: any, scheduledDate: any, index: number) => {
        let scheduledDateTime = value ? Utils.getLocalIsoDateTime(value) : null;

        let jobDate = item?.jobDate;
        if(scheduledDate){
            jobDate = scheduledDateTime;
        }

        let params = {
            jobId: item?.jobId,
            jobDurationHours: item?.jobDurationHours,
            jobDurationMinutes: item?.jobDurationMinutes,
            
            driverId: item?.driverId,
            vehicleId: item?.vehicleId,
            jobStatusId: item?.jobStatusId,
            
            jobDate: jobDate,
            jobTimeFrom: scheduledDateTime,
            jobTimeSpecific: scheduledDateTime,
        }
        dispatch(ganttChartSlice.callTimeApi(params, (state: boolean, data: any) => {
            if(state){
                let jobDate = (data.jobDate) ? moment(data.jobDate).toDate() : null;
                let jobTimeSpecific = (data.jobTimeSpecific) ? moment(data.jobTimeSpecific).toDate() : null;
                
                setFieldValue('form.' + index, { ...data, scheduledDate: jobDate, scheduledTime: jobTimeSpecific });

                if(props.onUpdate){
                    props.onUpdate(data);
                }
            }
        }));
    }

    
    const statusSection = () => {
        return <Row className='align-items-center'>
          <Col xs={12}>
            <Button
                variant={'custom-outlined'}
                size='sm'
                disabled={!isSelected}
                onClick={() => {
                    let jobIds = (selected && selected.length > 0) ? selected.join(',') : '';
                    
                    dispatch(bulkUpdateJobForm.setShow(jobIds));
                }}
            >
                Bulk Update
            </Button>
          </Col>
        </Row>
    }

    const tableSection = () => {
		return <table className='gantt-chart-side-panel-table w-100 mt-4'>
            <thead>
                <tr>
                    <th style={{ width: '00px' }} className='text-center'>
                        <Form.Check 
                            type={'checkbox'}
                            id={'selectall_unassigned_gc'}
                            label={''}
                            checked={(isSelected && selected.length == values?.form?.length)}
                            onChange={() => {
                                let allIds: any = (values && values.form && values.form.length > 0) ? values.form.map((x: any) => x.jobId) : [];
                                let isChecked = (isSelected && selected.length == values?.form?.length);
                                let checkedIds: any = !isChecked ? allIds : [];

                                dispatch(checkboxCount.onCheckAll({ allIds: allIds, checkedIds: checkedIds }));
                            }}
                        />
                    </th>
                    <th style={{ minWidth: '150px' }}>{fields.customerName.label}</th>
                    <th style={{ minWidth: '150px' }}>{fields.jobTemplateName.label}</th>
                    <th style={{ minWidth: '150px', maxWidth: '150px' }}>{fields.driverId.label}</th>
                    <th style={{ minWidth: '150px', maxWidth: '150px' }}>{fields.vehicleId.label}</th>
                    <th style={{ minWidth: '150px', maxWidth: '150px' }}>{fields.jobStatusId.label}</th>
                    <th style={{ minWidth: '150px', maxWidth: '150px' }}>{fields.scheduledDate.label}</th>
                    <th style={{ minWidth: '150px', maxWidth: '150px' }}>{fields.scheduledTime.label}</th>
                </tr>
            </thead>
            
            <tbody>
            {
                (values && values.form && values.form.length > 0)
                ?
                values.form.map((item: any, i: number)=> {
                    return <tr key={'no-responsive-table gantt-chart-unassigned-table-row-' + i}>
                        <td className='text-center'>
                            <Form.Check 
                                type={'checkbox'}
                                id={'selectall_unassigned_gc_' + item.jobId}
                                label={''}
                                checked={(selected.findIndex((x: any) => x == item.jobId) > -1)}
                                onChange={() => {
                                    dispatch(checkboxCount.onCheckRow(item.jobId));
                                }}
                            />
                        </td>

                        <td className='text-left'>{(item.customerName && item.customerName !== '') ? item.customerName : ''}</td>
                        <td className='text-left'>{(item.jobTemplateName && item.jobTemplateName !== '') ? item.jobTemplateName : ''}</td>
                        
                        <td>
                            <Form.Group>
                                <Form.Control
                                    as = {Select}
                                    isInvalid={false}
                                    id={fields.driverId.id + '_' + item.jobId}
                                    placeholder={fields.driverId.placeholder}

                                    fieldNames={{ value: 'driverId', label: 'driverName' }}
                                    loading={(isLoadingDriver && (isLoadingDriver.jobId === item.jobId) && (isLoadingDriver.isLoading === true))}
                                    disabled={isLoadingTime || (isLoadingDriver && (isLoadingDriver.jobId === item.jobId) && (isLoadingDriver.isLoading === true))}
                                    options={driver}
                                    value={item.driverId}
                                    
                                    open={(isOpenDriver && (isOpenDriver.jobId === item.jobId) && (isOpenDriver.open === true))}
                                    allowClear={false}
                                    showSearch={false}
                                    virtual={true}
                                    menuItemSelectedIcon={null}

                                    labelRender={(props: any) => {
                                        return <SelectLabel {...props} selectedValue={item.driverName} />
                                    }}
                                    optionRender={(option: any) => {
                                        return <SelectDropdownMenuItem
                                            values={driver}
                                            option={option}
                                            field={'driverName'}
                                        />
                                    }}
                                    dropdownRender={(menu: any) => {
                                        return <SelectDropdownMenu
                                            menu={menu}
                                            search={<div className={'search-input'}>
                                                <SearchIcon />
                                                <Form.Control
                                                    type="text"
                                                    size='sm'
                                                    placeholder={'Search'}
                                                    disabled={(isLoadingDriver && (isLoadingDriver.jobId === item.jobId) && (isLoadingDriver.isLoading === true))}
                                                    value={searchDriver}
                                                    onChange={(e) => {
                                                        setSearchDriver(e.target.value);
                                                        debouncedSearch(() => {
                                                            setIsLoadingDriver({ jobId: item.jobId, isLoading: true })
                                                            dispatch(slice.callReadDriverApi(e.target.value, (state, data) => {
                                                                setIsLoadingDriver({ jobId: item.jobId, isLoading: false })
                                                            }));
                                                        });
                                                    }}
                                                />
                                            </div>}
                                        />
                                    }}
                                    onDropdownVisibleChange={(open: any) => {
                                        setIsOpenVehicle({ jobId: item.jobId, open: false })
                                        setIsOpenStatus({ jobId: item.jobId, open: false })
                                        setIsOpenDriver({ jobId: item.jobId, open: open })
                                        if(open){
                                            setIsLoadingDriver({ jobId: item.jobId, isLoading: true })
                                            dispatch(slice.callReadDriverApi('', (state: boolean, data: any) => {
                                                setIsLoadingDriver({ jobId: item.jobId, isLoading: false })
                                            }));
                                        } else {
                                            setSearchDriver('');
                                        }
                                    }}
                                    onChange={async (value: any) => {
                                        let vehicleId = item.vehicleId;
                                        let jobStatusId = item.jobStatusId;
                                        
                                        if(value){
                                            await setFieldValue('form.' + i + '.driverId', value);

                                            if(vehicleId === undefined || vehicleId === null || vehicleId === 0){
                                                let obj = driver.find((x: any) => x.driverId === value);
                                                if(obj && obj.defaultVehicle && obj.defaultVehicle.vehicleId){
                                                    vehicleId = obj.defaultVehicle.vehicleId;
                                                    await setFieldValue('form.' + i + '.vehicleId', obj.defaultVehicle.vehicleId);
                                                    await setFieldValue('form.' + i + '.vehicleName', obj.defaultVehicle.vehicleName);
                                                }
                                            }

                                            if (item.jobStatusId === undefined || item.jobStatusId === null || item.jobStatusId === 0 || item.jobStatusId == statusDaily?.unassigned?.jobStatusId) {
                                                jobStatusId = statusDaily?.assigned?.jobStatusId;
                                                await setFieldValue('form.' + i + '.jobStatusId', statusDaily?.assigned?.jobStatusId);
                                                await setFieldValue('form.' + i + '.statusName', statusDaily?.assigned?.jobStatusName);
                                            }
                                        } else {
                                            await setFieldValue('form.' + i + '.driverId', null);
                                            
                                            if (item.jobStatusId === undefined || item.jobStatusId === null || item.jobStatusId === 0 || item.jobStatusId == statusDaily?.unassigned?.jobStatusId) {
                                                jobStatusId = statusDaily?.unassigned?.jobStatusId;
                                                await setFieldValue('form.' + i + '.jobStatusId', statusDaily?.unassigned?.jobStatusId);
                                                await setFieldValue('form.' + i + '.statusName', statusDaily?.unassigned?.jobStatusName);
                                            }
                                        }

                                        setTimeout(() => {
                                            updateDriver(values.form[i], value, vehicleId, jobStatusId, i);
                                        }, 0);
                                    }}
                                />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group>
                                <Form.Control
                                    as = {Select}
                                    isInvalid={false}
                                    id={fields.vehicleId.id + '_' + item.jobId}
                                    placeholder={fields.vehicleId.placeholder}

                                    fieldNames={{ value: 'vehicleId', label: 'vehicleName' }}
                                    loading={(isLoadingVehicle && (isLoadingVehicle.jobId === item.jobId) && (isLoadingVehicle.isLoading === true))}
                                    disabled={isLoadingTime || (isLoadingVehicle && (isLoadingVehicle.jobId === item.jobId) && (isLoadingVehicle.isLoading === true))}
                                    options={vehicle}
                                    value={item.vehicleId}
                                    
                                    open={(isOpenVehicle && (isOpenVehicle.jobId === item.jobId) && (isOpenVehicle.open === true))}
                                    allowClear={false}
                                    showSearch={false}
                                    virtual={true}
                                    menuItemSelectedIcon={null}

                                    labelRender={(props: any) => {
                                        return <SelectLabel {...props} selectedValue={item.vehicleName} />
                                    }}
                                    optionRender={(option: any) => {
                                        return <SelectDropdownMenuItem
                                            values={vehicle}
                                            option={option}
                                            field={'vehicleName'}
                                        />
                                    }}
                                    dropdownRender={(menu: any) => {
                                        return <SelectDropdownMenu
                                            menu={menu}
                                            search={<div className={'search-input'}>
                                                <SearchIcon />
                                                <Form.Control
                                                    type="text"
                                                    size='sm'
                                                    placeholder={'Search'}
                                                    disabled={(isLoadingVehicle && (isLoadingVehicle.jobId === item.jobId) && (isLoadingVehicle.isLoading === true))}
                                                    value={searchVehicle}
                                                    onChange={(e) => {
                                                        setSearchVehicle(e.target.value);
                                                        debouncedSearch(() => {
                                                            dispatch(slice.callReadVehicleApi(e.target.value, (state, data) => {}));
                                                        });
                                                    }}
                                                />
                                            </div>}
                                        />
                                    }}
                                    onDropdownVisibleChange={(open: any) => {
                                        setIsOpenDriver({ jobId: item.jobId, open: false })
                                        setIsOpenStatus({ jobId: item.jobId, open: false })
                                        setIsOpenVehicle({ jobId: item.jobId, open: open })
                                        if(open){
                                            dispatch(slice.callReadVehicleApi('', (state: boolean, data: any) => {}));
                                        } else {
                                            setSearchVehicle('');
                                        }
                                    }}
                                    onChange={async (value: any) => {
                                        let driverId = item.driverId;
                                        let jobStatusId = item.jobStatusId;
                                        
                                        if(value){
                                            await setFieldValue('form.' + i + '.vehicleId', value);
                                            
                                            if(driverId === undefined || driverId === null || driverId === 0){
                                                let obj = vehicle.find((x: any) => x.vehicleId === value);
                                                if(obj && obj.defaultDriver && obj.defaultDriver.driverId){
                                                    driverId = obj.defaultDriver.driverId;
                                                    await setFieldValue('form.' + i + '.driverId', obj.defaultDriver.driverId);
                                                    await setFieldValue('form.' + i + '.driverName', obj.defaultDriver.driverName);

                                                    if (item.jobStatusId === undefined || item.jobStatusId === null || item.jobStatusId === 0 || item.jobStatusId == statusDaily?.unassigned?.jobStatusId) {
                                                        jobStatusId = statusDaily?.assigned?.jobStatusId;
                                                        await setFieldValue('form.' + i + '.jobStatusId', statusDaily?.assigned?.jobStatusId);
                                                        await setFieldValue('form.' + i + '.statusName', statusDaily?.assigned?.jobStatusName);
                                                    }
                                                }
                                            }
                                        } else {
                                            await setFieldValue('form.' + i + '.vehicleId', null);
                                        }

                                        updateVehicle(values.form[i], value, driverId, jobStatusId, i);
                                    }}
                                />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group>
                                <Form.Control
                                    as = {Select}
                                    isInvalid={false}
                                    id={fields.jobStatusId.id + '_' + item.jobId}
                                    placeholder={fields.jobStatusId.placeholder}

                                    fieldNames={{ value: 'jobStatusId', label: 'statusName' }}
                                    loading={(isLoadingStatus && (isLoadingStatus.jobId === item.jobId) && (isLoadingStatus.isLoading === true))}
                                    disabled={isLoadingTime || (isLoadingStatus && (isLoadingStatus.jobId === item.jobId) && (isLoadingStatus.isLoading === true))}
                                    options={status}
                                    value={item.jobStatusId}
                                    
                                    open={(isOpenStatus && (isOpenStatus.jobId === item.jobId) && (isOpenStatus.open === true))}
                                    allowClear={false}
                                    showSearch={false}
                                    virtual={true}
                                    menuItemSelectedIcon={null}

                                    labelRender={(props: any) => {
                                        return <SelectLabel {...props} selectedValue={item.statusName} />
                                    }}
                                    optionRender={(option: any) => {
                                        return <SelectDropdownMenuItem
                                            values={status}
                                            option={option}
                                            field={'statusName'}
                                        />
                                    }}
                                    dropdownRender={(menu: any) => {
                                        return <SelectDropdownMenu
                                            menu={menu}
                                            search={<div className={'search-input'}>
                                                <SearchIcon />
                                                <Form.Control
                                                    type="text"
                                                    size='sm'
                                                    placeholder={'Search'}
                                                    disabled={(isLoadingStatus && (isLoadingStatus.jobId === item.jobId) && (isLoadingStatus.isLoading === true))}
                                                    value={searchStatus}
                                                    onChange={(e) => {
                                                        setSearchStatus(e.target.value);
                                                        debouncedSearch(() => {
                                                            dispatch(slice.callReadStatusApi(e.target.value, values.form[i], (state, data) => {}));
                                                        });
                                                    }}
                                                />
                                            </div>}
                                        />
                                    }}
                                    onDropdownVisibleChange={(open: any) => {
                                        setIsOpenDriver({ jobId: item.jobId, open: false })
                                        setIsOpenVehicle({ jobId: item.jobId, open: false })
                                        setIsOpenStatus({ jobId: item.jobId, open: open })
                                        if(open){
                                            dispatch(slice.callReadStatusApi('', values.form[i], (state: boolean, data: any) => {}));
                                        } else {
                                            setSearchStatus('');
                                        }
                                    }}
                                    onChange={async (value: any) => {
                                        let driverId = item.driverId;
                                        
                                        if(value){
                                            let statusName = '';
                                            let statusItem = status.find((x: any) => x.jobStatusId == value);
                                            if(statusItem && statusItem.statusName){
                                                statusName = statusItem.statusName
                                            }

                                            await setFieldValue('form.' + i + '.jobStatusId', value);
                                            await setFieldValue('form.' + i + '.statusName', statusName);
                                        } else {
                                            await setFieldValue('form.' + i + '.jobStatusId', null);
                                            await setFieldValue('form.' + i + '.statusName', null);
                                        }
                                        
                                        if (value == statusDaily?.unassigned?.jobStatusId) {
                                            driverId = null;
                                            await setFieldValue('form.' + i + '.driverId', null);
                                            await setFieldValue('form.' + i + '.driverName', null);
                                        }

                                        updateStatus(values.form[i], value, driverId, i);
                                    }}
                                />
                            </Form.Group>
                        </td>

                        <td>
                            <Form.Group>
                                <Form.Control
                                    as = {StyledDatePickerField}
                                    isInvalid={!!(errors && errors.form && errors.form[i] && errors.form[i].scheduledDate)}
                                    
                                    placeholder={fields.scheduledDate.placeholder}
                                    id={fields.scheduledDate.id}
                                    value={item.scheduledDate}
                                    icon={<></>}
                                    placement={'bottom'}
                                    readOnly={false}
                                    disabled={isLoadingTime}
                                    cleanable={true}
                                    oneTap={false}
                                    isoWeek
                                    // disabledDate={(date) => {
                                    //     return ((!values.isEdit || values.isCopy)) ? false : (moment(date).isAfter(moment().subtract(1, 'days'))) ? false;
                                    // }}
                                    onChange={async (value: any) => {
                                        if(value){
                                            let jobDate = moment(value).format(Utils.getAPIDateFormat());
                                            let jobTime = (item.scheduledTime) ? moment(item.scheduledTime).format(Utils.getDefaultTimeFormat()) : moment().format(Utils.getDefaultTimeFormat());
                                            let jobDateTime = moment(jobDate + ' ' + jobTime, Utils.getAPIDateTimeFormat())
                                            let jobDateTimeObj = jobDateTime.toDate();
                                            
                                            await setFieldValue('form.' + i + '.scheduledDate', jobDateTimeObj);

                                            if(item.scheduledTime){
                                                await setFieldValue('form.' + i + '.scheduledTime', jobDateTimeObj);
                                            }

                                            updateDate(item, jobDateTimeObj, item.scheduledTime, i);
                                        } else {
                                            await setFieldValue('form.' + i + '.scheduledDate', null);

                                            updateDate(values.form[i], null, item.scheduledTime, i);
                                        }
                                    }}
                                />
                                {/* <Form.Control.Feedback type="invalid">{(errors && errors.form && errors.form[i] && errors.form[i].scheduledDate) && errors.form[i].scheduledDate}</Form.Control.Feedback> */}
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group>
                                <Form.Control
                                    as = {StyledTimePickerField}
                                    isInvalid={!!(errors && errors.form && errors.form[i] && errors.form[i].scheduledTime)}
                                    
                                    appearance={'subtle'}
                                    preventOverflow
                                    showMeridian={false}
                                    format="HH:mm"
                                    hideMinutes={(minute: any) => minute % 5 !== 0}
                                    ranges={props.ranges ? props.ranges : [
                                        {
                                            label: 'Now',
                                            value: new Date()
                                        }
                                    ]}

                                    placeholder={fields.scheduledTime.placeholder}
                                    id={fields.scheduledTime.id}
                                    value={item.scheduledTime}
                                    placement={'bottom'}
                                    readOnly={false}
                                    disabled={isLoadingTime}
                                    cleanable={true}
                                    onChange={async (value: any) => {
                                        if(value){
                                            let jobDate = (item.scheduledDate) ? moment(item.scheduledDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                                            let jobTime = moment(value).format(Utils.getDefaultTimeFormat());
                                            let jobDateTime = moment(jobDate + ' ' + jobTime, Utils.getAPIDateTimeFormat())
                                            let jobDateTimeObj = jobDateTime.toDate();
                                            
                                            await setFieldValue('form.' + i + '.scheduledTime', jobDateTimeObj);

                                            if(item.scheduledDate){
                                                await setFieldValue('form.' + i + '.scheduledDate', jobDateTimeObj);
                                            }

                                            updateTime(item, jobDateTimeObj, item.scheduledDate, i);
                                        } else {
                                            await setFieldValue('form.' + i + '.scheduledTime', null);

                                            updateTime(values.form[i], null, item.scheduledDate, i);
                                        }
                                    }}
                                />
                                {/* <Form.Control.Feedback type="invalid">{(errors && errors.form && errors.form[i] && errors.form[i].scheduledTime) && errors.form[i].scheduledTime}</Form.Control.Feedback> */}
                            </Form.Group>
                        </td>
                    </tr>
                })
                :
                <tr>
                    <td colSpan={8} className={'title text-center'}>{(isLoading) ? <Spin spinning={isLoading} size={'small'} /> : 'No results'}</td>
                </tr>
            }
            </tbody>
        </table>
	}


    return <Offcanvas
        backdrop={true}
        enforceFocus={false}
        scroll={false}
        placement={'end'}
        show={show}
        onHide={() => {
            onCancel();
        }}
        style={{ width: '85%' }}
    >
        <>
            <FormikContext.Provider value={formik}>
                <Offcanvas.Header closeButton className='py-7'>
                    <Offcanvas.Title className='mb-0'>{statusDaily?.unassigned?.count} {pageCaption}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Spin spinning={isLoading || isLoadingDispatchAll || isLoadingDispatch || isLoadingTime}>
                        <Row className={'g-1'}>
                            <Col xs={12}>{statusSection()}</Col>
                            <Col xs={12}>{tableSection()}</Col>
                        </Row>
                    </Spin>
                </Offcanvas.Body>
                <Modal.Footer>
                    <Row className='px-2 pb-2 pt-5'>
                        <Col xs={true} className='text-end'>
                            <Button
                                className='me-5'
                                variant={'light'}
                                size={'sm'}
                                disabled={isLoading}
                                onClick={(e) => {
                                    onCancel();
                                }}
                            >Cancel</Button>
                            <Button
                                variant={'primary'}
                                size={'sm'}
                                disabled={isLoading || isLoadingDispatchAll || isLoadingDispatch || isLoadingTime}
                                onClick={() => {
                                    dispatchAll();
                                }}
                            >Dispatch All</Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </FormikContext.Provider>
        </>
    </Offcanvas>
});

export default ListComponent;
