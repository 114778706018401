import React from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Overlay,
  Popover,
} from 'react-bootstrap';

import { Spin } from "antd";

import Carousel from 'react-elastic-carousel';

// Redux
import { useDispatch, useSelector } from "react-redux";
import slice, { DATE_FORMAT, TIME_FORMAT, VIEW_DATE_FORMAT } from './slice';

import DriversForm from './Drivers/Form';
import driversSlice from './Drivers/slice';

import jobDetailsSlice from './JobDetails/slice';

import { CustomTableHead } from './DailyList';

import Utils from '../../../utils/utils.js';
import { PagesPermissions } from '../../../utils/enums.js';

import { ReactComponent as RightIcon } from "../../../../_metronic/assets/icons/right.svg";
import { ReactComponent as UpIcon } from "../../../../_metronic/assets/icons/gantt-sort-up.svg";
import { ReactComponent as DownIcon } from "../../../../_metronic/assets/icons/gantt-sort-down.svg";


const ListComponent = React.forwardRef((props: any, ref: any) => {
  let history = useHistory();

  const { selectedDate, period, isLoadingMonthly, rowsMonthly, sortMonthly, isLoadingMonthlyStatus, statusMonthly } = useSelector((state) => state.ganttChartSlice);
  const { selectedValues } = useSelector((state) => state.ganttChartDriversSlice);
  const dispatch = useDispatch();

  const [showDriversPopup, setShowDriversPopup] = React.useState(false);
  const [targetDriversPopup, setTargetDriversPopup] = React.useState(null);
  const refDriversPopup = React.useRef(null);
  const refDriversPopupForm = React.useRef(null);


  // Init on Drivers click popup
  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (refDriversPopup && refDriversPopup.current && !refDriversPopup.current.contains(event.target)) {
        if (!event.target.closest('.rs-picker-menu') && !event.target.closest('.ant-select-dropdown') && !event.target.closest('.ant-select-clear')) {
          setShowDriversPopup(false);
          setTargetDriversPopup(null);
        }
      }
    };

    if (showDriversPopup) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDriversPopup]);

  // On Drivers change
  React.useEffect(() => {
    callAPI(sortMonthly);
  }, [selectedValues]);

  // On date change
  React.useEffect(() => {
    callAPI(sortMonthly);
  }, [selectedDate]);


  const getFromToDate = () => {
    let fromDate = moment(selectedDate, Utils.getDefaultDateFormat()).startOf('month').format(Utils.getAPIDateFormat());
    let toDate = moment(selectedDate, Utils.getDefaultDateFormat()).endOf('month').format(Utils.getAPIDateFormat());

    return {
      fromDate: fromDate,
      toDate: toDate,
    }
  }

  const callAPI = (sort) => {
    let fromToDate = getFromToDate();
    let driverFilter = (selectedValues && selectedValues.length > 0) ? selectedValues.join(',') : null;

    let params = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: '',
      fromDate: fromToDate.fromDate,
      toDate: fromToDate.toDate,
      isAssigned: true,
      sortColumn: sort.sortColumn,
      sortDir: sort.sortDir,
      driverFilter: driverFilter,
    }

    dispatch(slice.callReadMonthlyApi(params, (state: boolean, data: any) => {}));
    dispatch(slice.callReadMonthlyStatusApi(params, (state: boolean, data: any) => {}));
  }


  const statusSection = () => {
    return <Row className='align-items-center'>
      <Col xs={12}>
        <Carousel
          className={'custom-carousel monthly'}
          itemsToShow={3}
          outerSpacing={0}
          itemPadding={[0, 0]}
          initialActiveIndex={0}
          breakPoints={[
            { width: 1, itemsToShow: 1 },
            { width: 200, itemsToShow: 2 },
            { width: 300, itemsToShow: 2 },
            { width: 400, itemsToShow: 2 },
            { width: 500, itemsToShow: 3 },
          ]}
          showEmptySlots={false}
          showArrows={true}
          pagination={false}
          isRTL={false}
        >
          <div className='custom-carousel-item active'>
            <Spin spinning={isLoadingMonthlyStatus} size={'small'}>
              <div className='count'>{statusMonthly?.total?.count}</div>
              <div className='text'>{statusMonthly?.total?.jobStatusName}</div>
              <div className='v-divider v-divider-full position-absolute end-0' style={{ top: '-10px', height: 'calc(100% + 20px)' }}></div>
            </Spin>
          </div>

          <div className='custom-carousel-item'>
            <Spin spinning={isLoadingMonthlyStatus} size={'small'}>
              <div className='count completed'>{statusMonthly?.completed?.count}</div>
              <div className='text'>{statusMonthly?.completed?.jobStatusName}</div>
              <div className='v-divider v-divider-full position-absolute top-0 end-0'></div>
            </Spin>
          </div>

          <div className='custom-carousel-item'>
            <Spin spinning={isLoadingMonthlyStatus} size={'small'}>
              <div className='count cancelled'>{statusMonthly?.unsuccessful?.count}</div>
              <div className='text'>{statusMonthly?.unsuccessful?.jobStatusName}</div>
            </Spin>
          </div>
        </Carousel>
      </Col>
    </Row>
  }

  const tableSection = () => {
		return <table className='gantt-chart-weekly-monthly-table w-100 mt-4'>
      <thead>
        <tr>
          <th style={{ minWidth: '300px' }}>
            <CustomTableHead
              isLoading={isLoadingMonthly}
              isVertical={true}
              showExpand={false}
              isExpanded={false}
              minWidth={0}
              onClick={(e) => {
                setShowDriversPopup(true);
                setTargetDriversPopup(e.target);
              }}
            />
          </th>
          <th style={{ minWidth: '150px' }}>
            Total Jobs
            {!isLoadingMonthly && <div className='custom-cell-sort custom-sort'>
              <UpIcon
                className={'sort-up ' + (((sortMonthly.sortColumn == 'totalJobCount') && (sortMonthly.sortDir == 'asc')) ? 'active' : '')}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  dispatch(slice.setSortMonthly({ sortColumn: 'totalJobCount', sortDir: 'asc' }));
                  callAPI({ sortColumn: 'totalJobCount', sortDir: 'asc' });
                }}
              />
              <DownIcon
                className={'sort-down ' + (((sortMonthly.sortColumn == 'totalJobCount') && (sortMonthly.sortDir == 'desc')) ? 'active' : '')}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  dispatch(slice.setSortMonthly({ sortColumn: 'totalJobCount', sortDir: 'desc' }));
                  callAPI({ sortColumn: 'totalJobCount', sortDir: 'desc' });
                }}
              />
            </div>}
          </th>
          
          <th style={{ minWidth: '150px' }}>
            Completed
            {!isLoadingMonthly && <div className='custom-cell-sort custom-sort'>
              <UpIcon
                className={'sort-up ' + (((sortMonthly.sortColumn == 'completedJobCount') && (sortMonthly.sortDir == 'asc')) ? 'active' : '')}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  dispatch(slice.setSortMonthly({ sortColumn: 'completedJobCount', sortDir: 'asc' }));
                  callAPI({ sortColumn: 'completedJobCount', sortDir: 'asc' });
                }}
              />
              <DownIcon
                className={'sort-down ' + (((sortMonthly.sortColumn == 'completedJobCount') && (sortMonthly.sortDir == 'desc')) ? 'active' : '')}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  dispatch(slice.setSortMonthly({ sortColumn: 'completedJobCount', sortDir: 'desc' }));
                  callAPI({ sortColumn: 'completedJobCount', sortDir: 'desc' });
                }}
              />
            </div>}
          </th>

          <th style={{ minWidth: '150px' }}>
            Unsuccessful
            {!isLoadingMonthly && <div className='custom-cell-sort custom-sort'>
              <UpIcon
                className={'sort-up ' + (((sortMonthly.sortColumn == 'unsuccessfulJobCount') && (sortMonthly.sortDir == 'asc')) ? 'active' : '')}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  dispatch(slice.setSortMonthly({ sortColumn: 'unsuccessfulJobCount', sortDir: 'asc' }));
                  callAPI({ sortColumn: 'unsuccessfulJobCount', sortDir: 'asc' });
                }}
              />
              <DownIcon
                className={'sort-down ' + (((sortMonthly.sortColumn == 'unsuccessfulJobCount') && (sortMonthly.sortDir == 'desc')) ? 'active' : '')}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  dispatch(slice.setSortMonthly({ sortColumn: 'unsuccessfulJobCount', sortDir: 'desc' }));
                  callAPI({ sortColumn: 'unsuccessfulJobCount', sortDir: 'desc' });
                }}
              />
            </div>}
          </th>

          <th style={{ minWidth: '200px' }}>Job Details</th>
          {(Utils.hasPermission(Utils.getPermissions(), PagesPermissions.ManagePayroll.read)) && <th style={{ minWidth: '200px' }}>Payroll</th>}
        </tr>
      </thead>

      <tbody>
      {
        (rowsMonthly && rowsMonthly.length > 0)
        ?
        rowsMonthly.map((item: any, i: number)=> {
          return <tr key={'no-responsive-table gantt-chart-monthly-table-row-' + i}>
            <td className={'title'}>{item.driverName}</td>
            <td className='text-center'>{item.totalJobCount}</td>

            <td className={'text-center text-success'}>{item.completedJobCount}</td>
            <td className={'text-center text-danger'}>{item.unsuccessfulJobCount}</td>

            <td>
              <Button
                className='w-100 d-flex justify-content-between align-items-center'
                variant={'custom-outlined'}
                size='sm'
                onClick={() => {
                  dispatch(jobDetailsSlice.setShow({ show: true, details: item, period: 'month' }));
                }}
              >
                <span className='pe-2'>Job Details</span><RightIcon />
              </Button>
            </td>
            {(Utils.hasPermission(Utils.getPermissions(), PagesPermissions.ManagePayroll.read)) && <td>
              <Button
                className='w-100 d-flex justify-content-between align-items-center'
                variant={'custom-outlined'}
                size='sm'
                onClick={() => {
                  window.open('manage/resource/drivers-details?tab=tab2&id=' + item.driverId, '_blank');
                }}
              >
                <span className='pe-2'>Payroll</span><RightIcon />
              </Button>
            </td>}
          </tr>
        })
        :
        <tr>
          <td colSpan={6} className={'title text-center'}>{(isLoadingMonthly) ? <Spin spinning={isLoadingMonthly} size={'small'} /> : 'No results'}</td>
        </tr>
      }
      </tbody>
    </table>
	}
  

  return <>
    <Row>
      <Col xs={12}>{statusSection()}</Col>
      <Col xs={12} md={9}>{tableSection()}</Col>
    </Row>

    <Overlay
      show={showDriversPopup}
      target={targetDriversPopup}
      placement={'bottom'}
      onExited={() => {
        if(refDriversPopupForm && refDriversPopupForm.current){
          let values = refDriversPopupForm.current?.onSave();
          dispatch(driversSlice.setSelectedValues(values));
        }
      }}
    >
      <Popover 
        className='gantt-popover-container'
        style={{ minWidth: '250px' }}
      >
        <Popover.Body ref={refDriversPopup} className='px-3 py-0'>
          <DriversForm ref={refDriversPopupForm} />
        </Popover.Body>
      </Popover>
    </Overlay>
  </>
});

export default ListComponent;
