import React from 'react';
// import { useHistory } from "react-router-dom";
import moment from 'moment';
import _ from 'lodash';

import {
  Row,
  Col,
  Badge,
} from 'react-bootstrap-v5';
// import styled from 'styled-components';

import Offcanvas from "react-bootstrap/Offcanvas";
import 'bootstrap/dist/css/bootstrap.min.css';

import Carousel from 'react-elastic-carousel';
import { Spin } from 'antd';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../../../setup'
import slice from './slice';
import { DATE_FORMAT, VIEW_DATE_FORMAT, getStatusName } from '../slice';

import DataTable from "../../../../components/OLD/DataTable/DataTable";
import DataTableScrollSync from '../../../../components/OLD/DataTable/DataTableScrollSync';

import Utils from '../../../../utils/utils';
// import {  } from '../../../../utils/enums';
// import {  } from "../../../../utils/styles";

// ----------------------------------------------------------------------


const ListComponent = (props: any) => {
    // let history = useHistory();

    const dispatch = useDispatch();
    const { show, details, isLoading, items } = useSelector((state: RootState) => state.ganttChartMultipleTripsSlice);
    const { selectedDate } = useSelector((state: RootState) => state.ganttChartSlice);

    
    const refDataTableScrollSync = React.useRef<any>(null);
    const tableRef = React.useRef<any>(null);

    const pageName = "multiple-trips-list";
    const pageIdField = "jobId";
    const pageCaption = 'Multiple Trips';

    const rowsPerPageSelected = -1;
    const rowsPerPageOptions = [-1];

    const [ sortColumnDir, setSortColumnDir ] = React.useState<any>({ sortColumn: 'jobDate', sortDir: 'asc' });


    const customerNameTemplate = (id: any, row: any, col: any, obj: any) => {
        return (row.customerNameDisplay && row.customerNameDisplay !== '') ? row.customerNameDisplay : '';
    };
    const customerNameExportTemplate = (row: any) => {
        return (row.customerNameDisplay && row.customerNameDisplay !== '') ? row.customerNameDisplay : '';
    };
  
    const jobTemplateNameTemplate = (id: any, row: any, col: any, obj: any, index: any) => {
        return (row.jobTemplateName && row.jobTemplateName !== '') ? row.jobTemplateName : ''
    };
    const jobTemplateNameExportTemplate = (row: any) => {
        return (row.jobTemplateName && row.jobTemplateName !== '') ? row.jobTemplateName : ''
    };
  
    const jobTimeSpecificTemplate = (id: any, row: any, col: any, obj: any, index: any) => {
        return (row.jobTimeSpecificDisplay && row.jobTimeSpecificDisplay !== '') ? row.jobTimeSpecificDisplay : ''
    };
    const jobTimeSpecificExportTemplate = (row: any) => {
        return (row.jobTimeSpecificDisplay && row.jobTimeSpecificDisplay !== '') ? row.jobTimeSpecificDisplay : ''
    };
  
    const jobNumberTemplate = (id: any, row: any, col: any, obj: any, index: any) => {
        return <a href={'/jobs-form?id=' + id} 
            target='_blank'
            className='menu-link'
        >
            {(row.jobNumber && row.jobNumber !== '') ? row.jobNumber : ''}
        </a>
    };
    const jobNumberExportTemplate = (row: any) => {
        return (row.jobNumber && row.jobNumber !== '') ? row.jobNumber : ''
    };
  
    const vehicleNameTemplate = (id: any, row: any, col: any, obj: any, index: any) => {
        return (row.vehicleName && row.vehicleName !== '') ? row.vehicleName : ''
    };
    const vehicleNameExportTemplate = (row: any) => {
        return (row.vehicleName && row.vehicleName !== '') ? row.vehicleName : ''
    };
  
    const statusNameTemplate = (id: any, row: any, col: any, obj: any, index: any) => {
        return <Badge bg={'custom-' + ((row && row.statusName != '') ? getStatusName(row) : 'default')}>{(row.statusName && row.statusName !== '') ? row.statusName : ''}</Badge>
    };
    const statusNameExportTemplate = (row: any) => {
        return (row.statusName && row.statusName !== '') ? row.statusName : ''
    };

  
    let columns = [
        { 
            id: pageIdField, 
            filterKey: pageIdField, 
            name: pageIdField, 
            label: 'ID',
            visible: false
        },
        { 
            id: 'customerName',
            filterKeyParam: 'customerName',
            name: 'customerName',
            nestedOrderBy: 'customer',  
            label: 'Customer name',
            width: '150px',
            responsiveLabel: 'Customer name',
            orderable: true,
            template: customerNameTemplate,
            exportTemplate: customerNameExportTemplate
        },
        { 
            id: 'jobTemplateName', 
            filterKeyParam: 'jobTemplateName', 
            name: 'jobTemplateName', 
            label: 'Job type',
            width: '150px',
            responsiveLabel: 'Job type',
            orderable: true,
            template: jobTemplateNameTemplate,
            exportTemplate: jobTemplateNameExportTemplate
        },
        { 
            id: 'jobTimeSpecific', 
            filterKeyParam: 'jobTimeSpecific', 
            name: 'jobTimeSpecific', 
            label: 'Scheduled Time',
            width: '150px',
            responsiveLabel: 'Scheduled Time',
            orderable: true,
            template: jobTimeSpecificTemplate,
            exportTemplate: jobTimeSpecificExportTemplate,
        },
        { 
            id: 'location', 
            filterKeyParam: 'location', 
            name: 'location', 
            label: 'Addresses',
            width: '150px',
            responsiveLabel: 'Addresses',
            orderable: false,
        },
        { 
            id: 'jobNumber', 
            filterKeyParam: 'jobNumber', 
            name: 'jobNumber', 
            label: 'DO#',
            labelClass: 'uppercase-text',
            width: '150px',
            responsiveLabel: 'DO#',
            orderable: true,
            template: jobNumberTemplate,
            exportTemplate: jobNumberExportTemplate,
        },
        { 
            id: 'vehicleName', 
            filterKeyParam: 'vehicleName', 
            name: 'vehicleName', 
            label: 'Vehicle',
            width: '150px',
            responsiveLabel: 'Vehicle',
            orderable: true,
            template: vehicleNameTemplate,
            exportTemplate: vehicleNameExportTemplate,
        },
        { 
            id: 'statusName', 
            filterKeyParam: 'statusName', 
            name: 'statusName', 
            label: 'Status',
            width: '150px',
            responsiveLabel: 'Status',
            orderable: true,
            template: statusNameTemplate,
            exportTemplate: statusNameExportTemplate,
        },
    ];
      
    let columnVisibility = [
        false,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
    ];


    React.useEffect(() => {
        if(show){
            apiCall();
        }
    }, [show]);

    React.useEffect(() => {
        if(show){
            apiCall();
        }
    }, [sortColumnDir]);


    const apiCall = () => {
        let fromToDate = moment(selectedDate, DATE_FORMAT).format(Utils.getAPIDateFormat());

        let params = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            fromDate: fromToDate,
            toDate: fromToDate,
            sortColumn: sortColumnDir?.sortColumn,
            sortDir: sortColumnDir?.sortDir,
            driverFilter: details?.driverId?.toString(),
            parentJobId: details?.jobId,
        };
        dispatch(slice.callReadApi(params, (state: boolean, data: any) => {}));
    }

    const onCancel = () => {
        dispatch(slice.setShow({ show: false, details: null }));
        dispatch(slice.resetSlice());
    }

    
    const statusSection = () => {
        return <Row className='align-items-center'>
          <Col xs={12}>
            <Carousel
              className={'custom-carousel multiple-trips'}
              itemsToShow={9}
              outerSpacing={0}
              itemPadding={[0, 0]}
              initialActiveIndex={0}
              breakPoints={[
                { width: 1, itemsToShow: 1 },
                { width: 200, itemsToShow: 2 },
              ]}
              showEmptySlots={false}
              showArrows={true}
              pagination={false}
              isRTL={false}
            >
              <div className='custom-carousel-item'>
                <Spin spinning={false} size={'small'}>
                  <div className='count'>Driver</div>
                  <div className='text'>{details?.driverName}</div>
                  <div className='v-divider v-divider-full position-absolute end-0' style={{ top: '-10px', height: 'calc(100% + 20px)' }}></div>
                </Spin>
              </div>
              <div className='custom-carousel-item'>
                <Spin spinning={false} size={'small'}>
                  <div className='count'>Date</div>
                  <div className='text'>{moment(selectedDate, DATE_FORMAT).format(VIEW_DATE_FORMAT)}</div>
                </Spin>
              </div>
            </Carousel>
          </Col>
        </Row>
    }

    const tableSection = () => {
		return <DataTableScrollSync ref={refDataTableScrollSync} className={"table-sync-v4"}>
            <DataTable 
                ref={tableRef}

                className={"table-style-v4"}
                classNameWrapper={"gantt-chart-weekly-monthly-table"}

                pageName={pageName}
                rowId={pageIdField}
                caption={pageCaption}
                
                noMarginBottom={true}
                verticalAlign={'middle'}
                
                stickyHeader={false}
                isHeader={true}
                resizable={false}
                isDragableHeaderColumns={false}
                isDragable={false}
                isCheckbox={false}
                isBorder={false}
                isPagination={false}
                isVirtualize={false}
                
                columnVisibility={columnVisibility}
                disabledResizeColumns={[]}

                isLoading={isLoading}
                rowsPerPageSelected={rowsPerPageSelected}
                rowsPerPageOptions={rowsPerPageOptions}

                search={''}
                rows={items}
                columns={columns}

                onRemove={(row: any) => {

                }}

                onClickCheckAll={(obj: any, checked: any, isDisabledBatchAction: any) => {
                    
                }}
                onCheckRow={(obj: any, id: any, isDisabledBatchAction: any) => {
                    
                }}
                
                onSortEnd={(columns: any, columnVisibility: any) => {
                    
                }}
                onOrder={(name: any, sort: any) => {
                    setSortColumnDir({ sortColumn: name, sortDir: sort });
                }}
                onResize={(columns: any) => {
                    
                }}
            />
        </DataTableScrollSync>
	}


    return <Offcanvas
        backdrop={true}
        enforceFocus={false}
        scroll={false}
        placement={'end'}
        show={show}
        onHide={() => {
            onCancel();
        }}
        style={{ width: '85%' }}
    >
        <>
            <Offcanvas.Header closeButton className='py-7'>
                <Offcanvas.Title className='mb-0'>{details?.numberOfTrip} {pageCaption}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Spin spinning={isLoading}>
                    <Row className={'g-7'}>
                        <Col xs={12}>{statusSection()}</Col>
                        <Col xs={12}>{tableSection()}</Col>
                    </Row>
                </Spin>
            </Offcanvas.Body>
        </>
    </Offcanvas>
}

export default ListComponent;
