import React from 'react';
import _ from 'lodash';

import {
  Row,
  Col,
  Form,
} from 'react-bootstrap-v5';
// import styled from 'styled-components';

import { Empty, Spin } from "antd";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../../../setup'
import slice from './slice';

import { ReactComponent as SearchIcon } from "../../../../../_metronic/assets/icons/search.svg";
import { ReactComponent as UncheckedIcon } from "../../../../../_metronic/assets/icons/gantt-cb-unchecked.svg";
import { ReactComponent as CheckedIcon } from "../../../../../_metronic/assets/icons/gantt-cb-checked.svg";

// import Utils from '../../../../utils/utils';
// import {  } from '../../../../utils/enums';
// import {  } from "../../../../utils/styles";

// ----------------------------------------------------------------------


const FormComponent = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { isLoading, drivers, selectedValues } = useSelector((state: RootState) => state.ganttChartDriversSlice);

    const [values, setValues] = React.useState<Array<any>>([]);
    const [search, setSearch] = React.useState<string>('');
    const refSearch = React.useRef<any>(null);


    React.useEffect(() => {
        setValues(selectedValues);
        dispatch(slice.callReadApi('', (state: boolean, data: any) => {}));

        return () => {
            setSearch('')
        }
    }, []);

    React.useEffect(() => {
        if (!isLoading && refSearch.current) {
            refSearch.current.focus();

            if(selectedValues && selectedValues.length === 0){
                setValues(drivers.map(x => x.driverId))
            }
        }
    }, [isLoading]);


    React.useImperativeHandle(
        ref,
        () => ({
            onSave() {
                return values;
            },
         }),
    );


    const debouncedSearch = React.useCallback(
        _.debounce((callback) => {
            if(callback){
                callback()
            }
        }, 500),
        []
    );


    const setSearchField = () => {
        return <div className={'search-input'}>
            <SearchIcon />
            <Form.Control
                ref={refSearch}
                type="text"
                size='sm'
                id={'custom_gantt_search_driver_ID'}
                placeholder={'Search Driver'}
                disabled={isLoading}
                value={search}
                onChange={(e) => {
                    setSearch(e.target.value)
                    debouncedSearch(() => {
                        dispatch(slice.callReadApi(e.target.value, (state: boolean, data: any) => {}));
                    });
                }}
            />
        </div>
    }

    const setMmenu = () => {
        if(isLoading){
            return <Row className={'align-items-center mt-5 pb-5'}>
                <Col xs={12} className='text-center'>
                    <Spin />
                </Col>
            </Row>
        } else {
            if(drivers && drivers.length > 0){
                return drivers.map((item: any, i: number) => {
                    return <Row key={'driver_menu_' + i}
                        className={'align-items-center py-1'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            let valArr = _.cloneDeep(values);
                            
                            let index = valArr.findIndex((x: any) => x === item.driverId);
                            if (index > -1) {
                                valArr.splice(index, 1);
                            } else {
                                valArr.push(item.driverId);
                            }

                            setValues(valArr);
                        }}
                    >
                        <Col xs={'auto'}>{(values?.includes(item?.driverId)) ? <CheckedIcon className='position-relative' style={{ top: '-2px' }} /> : <UncheckedIcon className='position-relative' style={{ top: '-2px' }} />}</Col>
                        <Col xs={true}>{item?.driverName}</Col>
                    </Row>
                });
            } else {
                return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }
        }
    }

    const setFooter = () => {
        return <Row
            className={'align-items-center'}
            style={{ cursor: 'pointer' }}
            onClick={() => {
                if(((values.length > 0) && (drivers.length > 0) && (values.length == drivers.length))){
                    setValues([])
                } else {
                    setValues(drivers.map(x => x.driverId))
                }
            }}
        >
            <Col xs={'auto'}>{((values.length > 0) && (drivers.length > 0) && (values.length == drivers.length)) ? <CheckedIcon className='position-relative' style={{ top: '-1px' }} /> : <UncheckedIcon className='position-relative' style={{ top: '-1px' }} />}</Col>
            <Col xs={true}>Select all</Col>
        </Row>
    }


    return <>
        <Row>
            <Col xs={12}>
                <Row>
                    <Col xs={12} className='px-4 py-3' style={{ borderBottom: '1px solid #DEDEE1' }}>{setSearchField()}</Col>
                    <Col xs={12} className='px-4 py-3' style={{ maxHeight: '250px', overflow: 'auto' }}>{setMmenu()}</Col>
                    {(drivers && drivers.length > 0) && <Col xs={12} className='px-4 py-3' style={{ borderTop: '1px solid #DEDEE1' }}>{setFooter()}</Col>}
                </Row>
            </Col>
        </Row>
    </>
});

export default FormComponent;
