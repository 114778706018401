/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import * as dispatchCrudApi from '../../../../api/CRUD/DispatchCRUD'
import Utils from "../../../../utils/utils";
// import {  } from "../../../../utils/enums";

import { ParseResult } from "../../../../utils/interfaces";


interface InitState {
  show: boolean,
  details: any,
  isLoading: boolean,
  items: Array<any>,
  isLive: boolean,
  isLoadingLatestLocation: boolean,
  latestLocationPosition: any,
  latestLocationTime: any,
  latestLocationDriverName: string,
  latestLocationAddress: string,
}

function NewReducer() {
  const name = 'ganttChartRouteMapSlice';


  const initialState: InitState = {
    show: false,
    details: null,
    isLoading: false,
    items: [],
    isLive: false,
    isLoadingLatestLocation: true,
    latestLocationPosition: null,
    latestLocationTime: null,
    latestLocationDriverName: '',
    latestLocationAddress: '',
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsLive: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLive = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, details: any }>) => {
      state.details = action.payload.details;
      state.show = action.payload.show;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      state.items = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.items = data;
    },

    startLatestLocation: (state: InitState) => {
      state.isLoadingLatestLocation = true;
      state.latestLocationPosition = null;
      state.latestLocationTime = null;
      state.latestLocationDriverName = '';
      state.latestLocationAddress = '';
    },
    finishLatestLocation: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload) ? action.payload : null;

      let coordinate = data ? {
        lat: data?.latitude,
        lng: data?.longitude,
      } : null;

      state.latestLocationPosition = coordinate;
      state.latestLocationTime = data?.updated;
      state.latestLocationDriverName = data?.driver?.driverName;
      state.latestLocationAddress = '';
      state.isLoadingLatestLocation = false;
    },
    setLatestLocationAddress: (state: InitState, action: PayloadAction<string>) => {
      state.latestLocationAddress = action.payload;
    },
  };


  const apis = {
    callReadApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        let paramsData: any = params;

        await dispatchCrudApi.createApi(paramsData, 'job/get-list').then((result: any) => {
            let data = result.data;
            
            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch((error: any) => {
            let res: ParseResult = {
                isError: false,
                errorMessage: null,
                status: null,
            };
    
            Utils.parseErrorTS(error, (result: ParseResult): void => {
                res = result
            });
            
            let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
            if(res.isError && err){
                Utils.toast(err, 'error');
            }
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },

    callLatestLocationApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startLatestLocation());

      let paramsData: any = params;

      await dispatchCrudApi.readApi(paramsData, 'driver/' + params.driverId  + '/latest-location').then((result: any) => {
          let data = result.data;
          
          callback(true, data);
          dispatch(actions.finishLatestLocation(data));
      }).catch((error: any) => {
          let res: ParseResult = {
              isError: false,
              errorMessage: null,
              status: null,
          };
  
          Utils.parseErrorTS(error, (result: ParseResult): void => {
              res = result
          });
          
          let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
          if(res.isError && err){
              Utils.toast(err, 'error');
          }
          
          callback(false, null);
          dispatch(actions.finishLatestLocation(null));
      });
  },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();