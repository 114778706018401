import React from 'react';
import _ from 'lodash';

import {
  Row,
  Col,
  Form,
  Button,
  Badge,
} from 'react-bootstrap-v5';
import styled from 'styled-components';

import { Select, Tag, Badge as AntdBadge } from 'antd';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../../../setup'
import slice from './slice';
import ganttChartSlice from '../slice';

import multipleTripsSlice from '../MultipleTrips/slice';

import { ReactComponent as SearchIcon } from "../../../../../_metronic/assets/icons/search.svg";

// import Utils from '../../../../utils/utils';
// import {  } from '../../../../utils/enums';
// import {  } from "../../../../utils/styles";

// ----------------------------------------------------------------------

const StyledRow = styled(Row)`
    padding: 0px;

    .title {
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
        color: #181C32;
        margin-bottom: 4px;
    }

    .desc {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        color: #6E707E;
    }

    hr {
        border-top: 1px solid #F0F0F1 !important;
        margin: 0px;
    }
`;

const StyledBadge = styled(AntdBadge)`
    margin-left: 5px;
    margin-right: 5px;

    .ant-badge-status-dot {
        width: 3px !important;
        height: 3px !important;
    }
`;

const StyledBadgeTrip = styled(Badge)`
    padding: 0px 4px;
    margin-right: 5px;
    color: white;
    border-radius: 2px;

    font-size: 11px;
    line-height: 16px;
    font-weight: 600;

    &.bg-light {
        background-color: #7F818D !important;
    }
`;


const SelectDropdownMenu = ({ menu, search, footer }: any) => {
    return <Row>
        <Col xs={12} className='px-6 py-3' style={{ borderBottom: '1px solid #DEDEE1' }}>{search}</Col>
        <Col xs={12} className='px-4 py-3'>{menu}</Col>
    </Row>
}

const SelectDropdownMenuItem = ({ values, option, field }: any) => {
    return <Row className={'align-items-center g-4'}>
        <Col xs={true} className={(option.data.disabled === true) ? 'muted-text' : option?.data[field].toString().trim().toLowerCase().replaceAll(' ', '') + '-txt'}>{option?.data[field]}</Col>
    </Row>
}

const SelectLabel = ({ label, value }: any) => {
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return <Tag
        className={(label && label != '') ? label.toString().trim().toLowerCase().replaceAll(' ', '') : ''}
        bordered={false}
        onMouseDown={onPreventMouseDown}
        closable={false}
        onClose={() => {}}
        style={{ marginInlineEnd: 4 }}
    >
        {(label && label != '') ? label : ''}
    </Tag>
}


const FormComponent = ({ task, onChange, onHide }: any) => {
    const dispatch = useDispatch();
    const { isLoading, status, unavailableStatuses } = useSelector((state: RootState) => state.ganttChartTaskSlice);
    const { isLoadingDispatch } = useSelector((state: RootState) => state.ganttChartSlice);
    
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [search, setSearch] = React.useState<string>('');
    const [values, setValues] = React.useState<any>([]);


    React.useEffect(() => {
        let selectedStatusId = (task && task.data && task.data.jobStatusId) ? [task.data.jobStatusId] : [];
        let selectedStatus = (task && task.data && task.data.jobStatusId) ? [{ jobStatusId: task.data.jobStatusId, jobStatusName: task.data.statusName }] : [];

        dispatch(slice.setStatus(selectedStatus));
        setValues(selectedStatusId);
    }, [task]);

    
    const debouncedSearch = React.useCallback(
        _.debounce((callback) => {
            if(callback){
                callback()
            }
        }, 500),
        []
    );


    return <StyledRow>
        <Col xs={12}>
            <Row className='p-4 pb-2'>
                <Col xs={12} className='title'>
                    {(task?.data?.numberOfTrip > 1) && <StyledBadgeTrip bg='light'>{task?.data?.numberOfTrip}</StyledBadgeTrip>}
                    <span>{task?.data?.customerName}</span>
                </Col>
                <Col xs={12} className='desc'>{task?.address}</Col>
                {((task && task.binType && task.binType != '') || (task && task.wasteType && task.wasteType != '')) && <Col xs={12} className='desc'>
                    {(task && task.binType && task.binType != '') && <span>{task.binType}</span>}
                    {((task && task.binType && task.binType != '') && (task && task.wasteType && task.wasteType != '')) && <StyledBadge color="#A3A5AD" />}
                    {(task && task.wasteType && task.wasteType != '') && <span>{task.wasteType}</span>}
                </Col>}
                <Col xs={12} className='desc'>
                    Job Time: {task?.fromTime} - {task?.toTime}
                </Col>
                {(task && task.data && task.data.jobNumber && task.data.jobNumber != '') && <Col xs={12} className='desc'>
                    DO#: {task.data.jobNumber}
                </Col>}
            </Row>
        </Col>
        
        <Col xs={12}>
            <hr />
        </Col>
        
        <Col xs={12}>
            <Row className='p-4 pt-2'>
                {!(unavailableStatuses.some((x: any) => x == task?.statusName) || (task?.data?.numberOfTrip > 1)) && <Col xs={12} className={'mb-3'}>
                    <Form.Group>
                        <Form.Control
                            as = {Select}
                            isInvalid={false}
                            id={'gantt_task_popup_status_field_ID'}
                            placeholder={'Select'}

                            fieldNames={{ value: 'jobStatusId', label: 'jobStatusName' }}
                            loading={isLoading}
                            disabled={isLoadingDispatch}
                            options={status}
                            value={values}
                            
                            open={isOpen}
                            allowClear={false}
                            showSearch={false}
                            virtual={true}
                            menuItemSelectedIcon={null}

                            labelRender={(props: any) => {
                                return <SelectLabel {...props} />
                            }}
                            optionRender={(option: any) => {
                                return <SelectDropdownMenuItem
                                    values={values.status}
                                    option={option}
                                    field={'jobStatusName'}
                                />
                            }}
                            dropdownRender={(menu: any) => {
                                return <SelectDropdownMenu
                                    menu={menu}
                                    search={<div className={'search-input'}>
                                        <SearchIcon />
                                        <Form.Control
                                            type="text"
                                            size='sm'
                                            placeholder={'Search'}
                                            disabled={isLoading}
                                            value={search}
                                            onChange={(e) => {
                                                setSearch(e.target.value);
                                                debouncedSearch(() => {
                                                    dispatch(slice.callReadStatusApi(e.target.value, (state, data) => {}));
                                                });
                                            }}
                                        />
                                    </div>}
                                />
                            }}
                            onDropdownVisibleChange={(open: any) => {
                                setIsOpen(open)
                                if(open){
                                    dispatch(slice.callReadStatusApi('', (state: boolean, data: any) => {}));
                                } else {
                                    setSearch('');
                                }
                            }}
                            onChange={(value: any) => {
                                if(value){
                                    setValues([value]);
                                    
                                    let params = {
                                        jobId: task.data.jobId,
                                        jobStatusId: value,
                                    }
                                    dispatch(ganttChartSlice.callDispatchApi(params, (state: boolean, data: any) => {
                                        if(state){
                                            if(onChange){
                                                onChange(data);
                                            }
                                        }
                                    }));
                                } else {
                                    setValues([]);
                                }
                            }}
                        />
                    </Form.Group>
                </Col>}

                <Col xs={12}>
                    <Button
                        className='w-100'
                        variant={'custom-primary'}
                        size={'sm'}
                        disabled={isLoading}
                        onClick={(e: any) => {
                            e.stopPropagation();
                            
                            if(task?.data?.numberOfTrip > 1){
                                dispatch(multipleTripsSlice.setShow({ show: true, details: task?.data }));
                                if(onHide){
                                    onHide();
                                }
                            } else {
                                window.open('jobs-form?id=' + task?.data?.jobId, '_blank');
                            }
                        }}
                    >Job Detail</Button>
                </Col>
            </Row>
        </Col>
    </StyledRow>
}

export default FormComponent;
