/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as yup from 'yup';
import _ from 'lodash';
import moment from 'moment';

import * as dispatchCrudApi from '../../../../api/CRUD/DispatchCRUD'
import Utils from "../../../../utils/utils";
// import {  } from "../../../../utils/enums";

import { ParseResult } from "../../../../utils/interfaces";


export const fields = {
  customerName: {
    id: 'customerName',
    label: 'Customer Name',
    placeholder: '',
  },
  jobNumber: {
    id: 'jobNumber',
    label: 'DO#',
    placeholder: '',
  },
  jobTemplateName: {
    id: 'jobTemplateName',
    label: 'Job Type',
    placeholder: '',
  },
  location: {
    id: 'location',
    label: 'Address',
    placeholder: '',
  },
  scheduledDate: {
    id: 'scheduledDate',
    label: 'Scheduled Date',
    placeholder: 'Pick a Date',
  },
  scheduledTime: {
    id: 'scheduledTime',
    label: 'Scheduled Time',
    placeholder: 'Pick a time',
  },
};

export const formSchema = () => {
  return yup.object().shape({
    form: yup.array().of(yup.object().shape({
      scheduledDate: yup.date().nullable().label(fields.scheduledDate.label),

      scheduledTime: yup.date().when(['scheduledDate'], (scheduledDate) => {
        if(scheduledDate){
          return yup.date().nullable().required().label(fields.scheduledTime.label);
        } else {
          return yup.date().nullable().label(fields.scheduledTime.label);
        }
      }),
    }))
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
  formikContext = useContext(FormikContext);
  if (!formikContext) {
    throw new Error('useFormikContext must be used within a FormikProvider');
  }
  return formikContext;
};


export const prepareForm = (values: any = []) => {
  let form = _.cloneDeep(values);
  let data: any = {
    form: [],
  };
  
  if(form && form.length > 0){
    data.form = form.map((item: any, i: number) => {
      let jobDate = (item.jobDate) ? moment(item.jobDate).toDate() : null;
      let jobTimeSpecific = (item.jobTimeSpecific) ? moment(item.jobTimeSpecific).toDate() : null;

      return { ...item, scheduledDate: jobDate, scheduledTime: jobTimeSpecific };
    });
  }

  return data;
};
export const prepareData = (values: any = null) => {
  let data: any = {};

  if(values.form && values.form.length > 0){
    data = values.form.map((item: any, i: number) => {
      let scheduledDate = item.scheduledDate ? Utils.getLocalIsoDateTime(item.scheduledDate) : null;
      let scheduledTime = item.scheduledTime ? Utils.getLocalIsoDateTime(item.scheduledTime) : null;

      return { ...item, scheduledDate: scheduledDate, scheduledTime: scheduledTime };
    });
  }

  return data;
};


interface InitState {
  show: boolean,
  details: any,
  isLoading: boolean,
  items: Array<any>,
}

function NewReducer() {
  const name = 'ganttChartUnsetTimeSlice';


  const initialState: InitState = {
    show: false,
    details: null,
    isLoading: false,
    items: [],
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, details: any }>) => {
      state.details = action.payload.details;
      state.show = action.payload.show;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      state.items = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.items = data;
      state.isLoading = false;
    },
  };


  const apis = {
    callReadApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        let paramsData: any = params;

        await dispatchCrudApi.createApi(paramsData, 'job/get-list').then((result: any) => {
            let data = result.data;
            
            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch((error: any) => {
            let res: ParseResult = {
                isError: false,
                errorMessage: null,
                status: null,
            };
    
            Utils.parseErrorTS(error, (result: ParseResult): void => {
                res = result
            });
            
            let err = (res && res.errorMessage && res.errorMessage.error && res.errorMessage.error != '') ? res.errorMessage.error : null;
            if(res.isError && err){
                Utils.toast(err, 'error');
            }
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();