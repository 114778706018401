import React from 'react';
import { useHistory } from "react-router-dom";
import { useFormik } from 'formik';
import _ from 'lodash';
import queryString from 'query-string';

import {
    Container, 
    Row,
    Col,
    Modal,
    CloseButton,
    Spinner,
    Tab,
} from "react-bootstrap-v5";

import Utils from '../../../utils/utils';
// import {  } from '../../../utils/enums';
import { StyledBlockerLoading, StyledOverlay } from "../../../utils/styles";

import ReduxPopup from '../../../components/popup/ReduxPopup';
import ReduxDeletePopup from '../../../components/popup/ReduxDeletePopup';
import signalRslice from "../../../components/signalR/signalRslice";

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../../setup'
import driversDetails, { FormikContext, formSchema, initialValues, prepareData, prepareForm } from './redux/driversDetails';

import TopSection from './partial/TopSection';
import BasicInfoTab from './partial/BasicInfoTab';
import SalaryTab from './partial/SalaryTab';

import { StyledTopSection, StyledTabs, StyledButton } from './style/driversDetailsStyles';


const DriversDetailsForm = (props: any) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { isLoading, isMobileDispatch, id, details, months } = useSelector((state: RootState) => state.driversDetails);
    const { index } = useSelector((state: RootState) => state.reduxPopupSlice);
    const reduxDeletePopupSlice = useSelector((state: RootState) => state.reduxDeletePopupSlice);


    const [tab, setTab] = React.useState('tab1');


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: formSchema(id, isMobileDispatch, tab),
        onSubmit: values => {
            let data = prepareData(values);
            if(id && id > 0){
                data['driverId'] = id;
                dispatch(driversDetails.callUpdateApi(data, (state) => {
                    if(state){
                        onHide();

                        if(props && props.onUpdate){
                            props.onUpdate(data);
                        }
                    }
                }));
            } else {
                dispatch(driversDetails.callCreateApi(data, (state) => {
                    if(state){
                        onHide();

                        if(props && props.onCreate){
                            props.onCreate(data);
                        }
                    }
                }));
            }
        },
    });
    const { values, errors, setValues, setFieldValue, validateForm, handleSubmit } = formik;

    
    React.useEffect(() => {
        let id = Utils.getIntProps();
        if(id && id > 0){
            dispatch(driversDetails.setId(id));
        }
        
        let q: any = queryString.parse(window.location.search);
        let tab = (q['tab']) ? decodeURIComponent(q['tab']) : 'tab1';
        setTab(tab);
        dispatch(driversDetails.callReadApi(props.sortColumn, props.sortDir));
    }, []);

    React.useEffect(() => {
        if(id && id > 0){
            history.push('/manage/resource/drivers-details?id=' + id)
            dispatch(driversDetails.callDetailsApi(id));

            let clonedMnths = _.cloneDeep(months);
            clonedMnths = clonedMnths.map((itm: any, j: number) => {
                itm.isOpen = false;
                itm.details = null;
                itm.isLoading = false;
                itm.isReRun = false;
                itm.startDate = '';
                itm.endDate = '';
                return itm;
            });
            dispatch(driversDetails.setMonths(clonedMnths));
        }
    }, [id]);
    
    React.useEffect(() => {
        dispatch(driversDetails.setLoading(true));
        let form = prepareForm(details, initialValues);
        setValues(form);
        dispatch(driversDetails.setLoading(false));
    }, [details]);


    const onHide = () => {
        setTab('tab1');
        dispatch(driversDetails.resetSlice());
        history.push('/manage/resource/drivers');
    }
    const onSave = () => {
        handleSubmit()
    }


    return <>
        <Modal.Header className={'px-3 sticky-top border-bottom'} closeButton={false} style={{ top: '65px', zIndex: '3', backgroundColor: 'white' }}>
            <Container className={'px-0 px-md-10 px-lg-20 px-xl-30'}>
                <Row className={'mb-5 mt-8'}>
                    <Col xs={true}>
                        <h3>Driver’s details</h3>
                    </Col>
                    <Col xs={'auto'}>
                        <CloseButton
                            className={'rounded-2'}
                            onClick={() => {
                                onHide();
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </Modal.Header>

        <Modal.Body className={'px-3 pt-0'}>
            <FormikContext.Provider value={formik}>
                <Row>
                    {(id && id > 0) && <StyledTopSection xs={12}>
                        <Container className={'px-0 px-md-10 px-lg-20 px-xl-30'}>
                            <Row>
                                <Col xs={12}>
                                    <TopSection />
                                </Col>
                            </Row>
                        </Container>
                    </StyledTopSection>}
                    <Col xs={12}>
                        <Container className={'px-0 px-md-10 px-lg-20 px-xl-30'}>
                            <Row className={'mt-8'}>
                                <Col xs={12}>
                                    <StyledTabs
                                        activeKey={tab}
                                        onSelect={(k: any) => setTab(k)}
                                        className="mb-8"
                                    >
                                        <Tab eventKey="tab1" title="Basic Info" disabled={months.some(x => x.isLoading)}>
                                            <BasicInfoTab />
                                        </Tab>
                                        {((id && id > 0) && !isMobileDispatch) && <Tab eventKey="tab2" title="Salary" disabled={months.some(x => x.isLoading)}>
                                            <SalaryTab />
                                        </Tab>}
                                    </StyledTabs>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </FormikContext.Provider>
        </Modal.Body>

        {(tab === 'tab1') && <Modal.Footer className={'px-3 py-5 sticky-bottom border-top'} style={{ justifyContent: 'flex-start', zIndex: '3', backgroundColor: 'white' }}>
            <Container className={'px-0 px-md-10 px-lg-20 px-xl-30'}>
                <Row className={'m-0 align-items-center'}>
                    <Col xs={'auto'}>
                        <StyledButton 
                            type={'button'}
                            variant={'primary'}
                            size={'sm'}
                            onClick={onSave}
                            disabled={isLoading || months.some(x => x.isLoading)}
                        >
                            Save
                            {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                        </StyledButton>
                    </Col>
                    <Col xs={'auto'}>
                        <StyledButton 
                            type={'button'}
                            variant={'secondary'}
                            size={'sm'}
                            onClick={onHide}
                            disabled={isLoading || months.some(x => x.isLoading)}
                        >
                            Cancel
                            {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                        </StyledButton>
                    </Col>
                </Row>
            </Container>
        </Modal.Footer>}

        <ReduxPopup
            title={'Warning'}
            body={'All your manual entries for the selected date period will be deleted. Are you sure?'}
            cancel={'Cancel'}
            save={'Ok, proceed'}
            onCancel={async () => {
                let clonedMnths = _.cloneDeep(months);
                clonedMnths[index].isReRun = false;
                dispatch(driversDetails.setMonths(clonedMnths));
            }}
            onSave={async () => {
                await dispatch(signalRslice.connect({
                    callback: (percent: any) => {
                        dispatch(signalRslice.onPercentage(percent));
                    },
                    callbackInit: async (hubId: any) => {
                        await dispatch(driversDetails.callReGenerateApi(hubId, months[index], index, values?.data?.payrollTemplateId, async (state) => {
                            if(state){
                                let clonedMnths = _.cloneDeep(months);
                                clonedMnths[index].isReRun = false;
                                dispatch(driversDetails.setMonths(clonedMnths));

                                if(state){
                                    setTimeout(async () => {
                                        await dispatch(driversDetails.callMonthDetailsApi(months[index].date, index, false, true, (state: any, data: any) => {
                                            if(data){
                                                data.isAdHoc = false;
                                            }
                                                        
                                            setTimeout(async () => {
                                                setFieldValue('data', data);
                                            }, 100);
                                        }));
                                    }, 100);
                                }
                            }
                        }));

                        dispatch(signalRslice.disconnect());
                    }
                }));
            }}
        />

        <ReduxDeletePopup
            title={'Remove'}
            body={'Are you sure you want to remove?'}
            cancel={'Cancel'}
            save={'Yes, Please remove'}
            onCancel={async () => {
                
            }}
            onSave={async () => {
                if(values && values.data && values.data.days && values.data.days.length > 0 && values.data.days[reduxDeletePopupSlice.index] && values.data.days[reduxDeletePopupSlice.index].details){
                    if(reduxDeletePopupSlice.type === 'adhoc'){
                        dispatch(driversDetails.callDeleteAdHocRowApi(reduxDeletePopupSlice.item, (state: any, row: any) => {
                            if(state){
                                let adhocDetails = _.cloneDeep(values.data.adhocDetails);
                                adhocDetails.splice(reduxDeletePopupSlice.index, 1);
                                setFieldValue('data.adhocDetails', adhocDetails);
                            }
                        }));

                    } else {
                        dispatch(driversDetails.callDeleteRowApi(reduxDeletePopupSlice.item, (state: any, row: any) => {
                            if(state){
                                let details = _.cloneDeep(values.data.days[reduxDeletePopupSlice.index].details);
                                details.splice(reduxDeletePopupSlice.subIndex, 1);
                                setFieldValue('data.days.' + reduxDeletePopupSlice.index + '.details', details);
                            }
                        }));
                    }
                }
            }}
        />

        {(isLoading) && <StyledBlockerLoading>
            <StyledOverlay />
            <Spinner animation="border" variant="primary" />
        </StyledBlockerLoading>}
    </>
}


export default DriversDetailsForm;
