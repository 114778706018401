import React from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';


const DataTableScrollSync = React.forwardRef((props: any, scrollListRef: any) => {
    // const scrollListRef = React.useRef();

    const [offsetWidth, setOffsetWidth] = React.useState((scrollListRef && scrollListRef.current) ? scrollListRef.current.offsetWidth : 0);
    const [scrollWidth, setScrollWidth] = React.useState((scrollListRef && scrollListRef.current) ? scrollListRef.current.scrollWidth : 0);

    React.useEffect(() => {
        setOffsetWidth((scrollListRef && scrollListRef.current) ? scrollListRef.current.offsetWidth : 0);
        setScrollWidth((scrollListRef && scrollListRef.current) ? scrollListRef.current.scrollWidth : 0);
        
        if(props.onLoad){
            props.onLoad();
        }
    });

    const horizontalScrollBar = () => {
        return <ScrollSyncPane>
            <div  className={'custom-virtualized-scrollbar'} 
                style={{ 
                    width: offsetWidth + 'px',
                    overflowY: 'hidden',
                    bottom: ((props && props.bottom && props.bottom != '') ? props.bottom : '5px'),
                }}
            >
                <div 
                    style={{ width: scrollWidth + 'px' }}
                    className={'custom-virtualized-scrollbar-child'}
                ></div>
            </div>
        </ScrollSyncPane>
    }

    return <ScrollSync>
        <>
            <ScrollSyncPane>
                <div 
                    ref={scrollListRef}
                    className={'hideScroll ' + props.className}
                    style={{ 
                        overflowX: 'auto',
                        overflowY: 'hidden',
                    }}
                >
                    {props.children}
                </div>
            </ScrollSyncPane>

            {horizontalScrollBar()}
        </>
    </ScrollSync>
  })

  export default DataTableScrollSync;