import React from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';

import {
    Container, 
    Row,
    Col,
    Modal,
    CloseButton,
    Button,
    Spinner,
    Form,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import Utils from '../../../utils/utils';
// import {  } from '../../../utils/enums';
import { StyledBlockerLoading, StyledOverlay, StyledDatePickerField, StyledTimePickerField } from "../../../utils/styles";

import signalRslice from "../../../components/signalR/signalRslice";

import SmartInputDropdown from '../../../components/dropdown/smartInputDropdown/SmartInputDropdown';
import makeCRUD from "../../../components/dropdown/smartInputDropdown/makeCRUD";

import { useDispatch, useSelector } from "react-redux";
import {RootState} from '../../../../setup'
import bulkUpdateJobForm, { FormikContext, formSchema, fields, initialValues, prepareData, prepareForm } from '../redux/bulkUpdateJobForm';
import { date } from 'yup';


const SmartDropdown = makeCRUD(SmartInputDropdown);

const StyledCol = styled(Col)`
  i {
    font-size: 15px;
    color: #aaaaae;
    right: 12px;

    :hover {
        color: #f44336;
        font-size: 16px !important;
        opacity: 1 !important;
    }
  }
`;


const BulkUpdateJobForm = (props: any) => {
    const dispatch = useDispatch();
    const { isLoading, show, ids } = useSelector((state: RootState) => state.bulkUpdateJobForm);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: formSchema(),
        onSubmit: async (values) => {
            await dispatch(signalRslice.connect({
                callback: (percent: any) => {
                    dispatch(signalRslice.onPercentage(percent));
                },
                callbackInit: async (hubId: any) => {
                    let data = prepareData(values, ids, hubId);
                    await dispatch(bulkUpdateJobForm.callBulkUpdateApi(data, (state, backData) => {
                        if(state){
                            onHide();
        
                            if(props && props.onUpdate){
                                props.onUpdate(data, backData);
                            }
                        }
                    }));

                    dispatch(signalRslice.disconnect());
                }
            }));
        },
    });
    const { values, errors, setValues, setFieldValue, validateForm, handleSubmit } = formik;

    
    const onHide = () => {
        dispatch(bulkUpdateJobForm.setHide());
    }
    const onSave = () => {
        handleSubmit()
    }


    React.useEffect(() => {
        setValues(initialValues);
    }, [show]);


    return <Modal
        show={show}
        onHide={onHide}
        size={'lg'}
        backdrop={"static"}
        fullscreen={false}
        scrollable={true}
        keyboard={false}
        enforceFocus={false}
    >
        <Modal.Header closeButton={false}>
            <Row className={'m-0 align-items-center w-100'}>
                <Col xs={true}>
                    <Modal.Title>Bulk Update</Modal.Title>
                </Col>
                <Col xs={'auto'}>
                    <CloseButton
                        className={'rounded-2'}
                        onClick={() => {
                            onHide();
                        }}
                    />
                </Col>
            </Row>
        </Modal.Header>

        <Modal.Body>
            <FormikContext.Provider value={formik}>
                <Row className={'m-0 align-items-center w-100'}>
                    <Col xs={12}>

                        <Form.Group as={Row} className={'mb-3'}>
                            <Form.Label column sm={3}>{fields.jobDate.label}</Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    as = {StyledDatePickerField}
                                    isInvalid={!!errors.jobDate}
                                    
                                    placeholder={fields.jobDate.placeholder}
                                    id={fields.jobDate.id}
                                    value={values.jobDate}
                                    icon={<></>}
                                    placement={'bottom'}
                                    readOnly={false}
                                    cleanable={true}
                                    isDefault={true}
                                    oneTap={false}
                                    // disabledDate={(date) => {
                                    //     return ((!values.isEdit || values.isCopy)) ? false : (moment(date).isAfter(moment().subtract(1, 'days'))) ? false;
                                    // }}
                                    onChange={async (value) => {
                                        if(value){
                                            let jobDate = moment(value).format(Utils.getAPIDateFormat());
                                            let jobTime = (values.jobTimeSpecific) ? moment(values.jobTimeSpecific).format(Utils.getDefaultTimeFormat()) : moment().format(Utils.getDefaultTimeFormat());
                                            let jobDateTime = moment(jobDate + ' ' + jobTime, Utils.getAPIDateTimeFormat())
                                            let jobDateTimeObj = jobDateTime.toDate();
                                            
                                            await setFieldValue('jobDate', jobDateTimeObj);

                                            if(values.jobTimeSpecific){
                                                await setFieldValue('jobTimeSpecific', jobDateTimeObj);
                                            }
                                        } else {
                                            await setFieldValue('jobDate', null);
                                        }
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.jobDate}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className={'mb-3'}>
                            <Form.Label column sm={3}>{fields.jobTimeSpecific.label}</Form.Label>
                            <Col sm={9}>
                                <Form.Control
                                    as = {StyledTimePickerField}
                                    isInvalid={!!errors.jobTimeSpecific}
                                    
                                    placeholder={fields.jobTimeSpecific.placeholder}
                                    id={fields.jobTimeSpecific.id}
                                    value={values.jobTimeSpecific}
                                    placement={'bottom'}
                                    readOnly={false}
                                    cleanable={true}
                                    onChange={async (value) => {
                                        if(value){
                                            let jobDate = (values.jobDate) ? moment(values.jobDate).format(Utils.getAPIDateFormat()) : moment().format(Utils.getAPIDateFormat());
                                            let jobTime = moment(value).format(Utils.getDefaultTimeFormat());
                                            let jobDateTime = moment(jobDate + ' ' + jobTime, Utils.getAPIDateTimeFormat())
                                            let jobDateTimeObj = jobDateTime.toDate();
                                            
                                            await setFieldValue('jobTimeSpecific', jobDateTimeObj);

                                            if(values.jobDate){
                                                await setFieldValue('jobDate', jobDateTimeObj);
                                            }
                                        } else {
                                            await setFieldValue('jobTimeSpecific', null);
                                        }
                                    }}
                                />
                                <Form.Control.Feedback type="invalid" className={!!errors.jobTimeSpecific ? 'd-block' : 'd-none'}>{errors.jobTimeSpecific}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className={'mb-3'}>
                            <Form.Label column sm={3}>{fields.driverId.label}</Form.Label>
                            <StyledCol sm={9}>
                                <SmartDropdown
                                    isInvalid={!!errors.driverId}
                                    errorText={errors.driverId}

                                    componentTitle={'driver'}
                                    componentApi={'driver'}
                                    componentId={'driverId'}
                                    componentName={'driverName'}

                                    placeholder={fields.driverId.placeholder}
                                    value={values.driverId}
                                    label={values.driverName}

                                    disabled={false}
                                    showDefault={false}
                                    isInfiniteScroll={false}

                                    onChange={(value: any, item: any, i: any) => {
                                        setFieldValue('driverId', value);
                                        setFieldValue('driverName', item.title);

                                        if(values.vehicleId === null || values.vehicleId <= 0){
                                            if(item && item.item && item.item.defaultVehicle){
                                                let vehicleItem = item.item.defaultVehicle;
                                                let vehicleId = (vehicleItem.vehicleId && vehicleItem.vehicleId > 0) ? vehicleItem.vehicleId : null;
                                                let vehicleName = (vehicleItem.vehicleName && vehicleItem.vehicleName !== '') ? vehicleItem.vehicleName : '';
                                                
                                                setFieldValue('vehicleId', vehicleId);
                                                setFieldValue('vehicleName', vehicleName);
                                            }
                                        }
                                    }}
                                    onClear={() => {
                                        setFieldValue('driverId', null);
                                        setFieldValue('driverName', '');
                                    }}
                                />
                            </StyledCol>
                        </Form.Group>

                        <Form.Group as={Row} className={'mb-3'}>
                            <Form.Label column sm={3}>{fields.vehicleId.label}</Form.Label>
                            <StyledCol sm={9}>
                                <SmartDropdown
                                    isInvalid={!!errors.vehicleId}
                                    errorText={errors.vehicleId}

                                    componentTitle={'vehicle'}
                                    componentApi={'vehicle'}
                                    componentId={'vehicleId'}
                                    componentName={'vehicleName'}

                                    placeholder={fields.vehicleId.placeholder}
                                    value={values.vehicleId}
                                    label={values.vehicleName}

                                    disabled={false}
                                    showDefault={false}
                                    isInfiniteScroll={false}

                                    onChange={(value: any, item: any, i: any) => {
                                        setFieldValue('vehicleId', value);
                                        setFieldValue('vehicleName', item.title);

                                        if(values.driverId === null || values.driverId <= 0){
                                            if(item && item.item && item.item.defaultDriver){
                                                let driverItem = item.item.defaultDriver;
                                                let driverId = (driverItem.driverId && driverItem.driverId > 0) ? driverItem.driverId : null;
                                                let driverName = (driverItem.driverName && driverItem.driverName !== '') ? driverItem.driverName : '';
                                                
                                                setFieldValue('driverId', driverId);
                                                setFieldValue('driverName', driverName);
                                            }
                                        }
                                    }}
                                    onClear={() => {
                                        setFieldValue('vehicleId', null);
                                        setFieldValue('vehicleName', '');
                                    }}
                                />
                            </StyledCol>
                        </Form.Group>
                    
                    </Col>
                </Row>
            </FormikContext.Provider>
        </Modal.Body>

        <Modal.Footer style={{ justifyContent: 'center' }}>
            <Row className={'m-0 align-items-center'}>
                <Col xs={'auto'}>
                    <Button 
                        type={'button'}
                        variant={'primary'}
                        size={'sm'}
                        onClick={onSave}
                        disabled={isLoading}
                    >
                        Save
                        {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>
                <Col xs={'auto'}>
                    <Button 
                        type={'button'}
                        variant={'light'}
                        size={'sm'}
                        onClick={onHide}
                        disabled={isLoading}
                    >
                        Cancel
                        {isLoading ? <>&nbsp;<Spinner animation="border" size={'sm'} /></> : null}
                    </Button>
                </Col>
            </Row>
        </Modal.Footer>

        {(isLoading) && <StyledBlockerLoading>
            <StyledOverlay />
            <Spinner animation="border" variant="primary" />
        </StyledBlockerLoading>}
    </Modal>
}


export default BulkUpdateJobForm;
